import React, { useState } from "react";
import { useGetOwnerDashBoardQuery } from "../../../redux/CommonApi";
import Totalunits from "./Totalunits";
import PropertyDetail from "./PropertyDetail";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import totalunitActiveImg from "../../../../public/assets/img/icons/totalunit-w.png";
import totalunitInactiveImg from "../../../../public/assets/img/icons/totalunit.png";
import totalunitActive from "../../../../public/assets/img/icons/totalunitActive.png";
import totalunitInactive from "../../../../public/assets/img/icons/totalunitInactiv.png";
import partiallyOccupiedActiveImg from "../../../../public/assets/img/icons/partiallyOccupiedActive.png";
import partiallyOccupiedInactiveImg from "../../../../public/assets/img/icons/partiallyOccupiedInactive.png";
import OccupiedUnitsActiveImg from "../../../../public/assets/img/icons/OccupiedInactive.png";
import OccupiedUnitsInactiveImg from "../../../../public/assets/img/icons/occupiedActive.png";
import vacantUnitsActiveImg from "../../../../public/assets/img/icons/vacantActive.png";
import vacantUnitsInactiveImg from "../../../../public/assets/img/icons/vacantInactive.png";
import inactiveUnitsActiveImg from "../../../../public/assets/img/icons/inactive.png";
import inactiveUnitsInactiveImg from "../../../../public/assets/img/icons/inactive.png";
import UnitDetail from "./UnitDetail";
import { OwnerDashBoard } from "../../Models/Pms/OwnerDashBoard";
import { Loader } from "../../../shared/Loader";

const PropertyMasterCards = () => {

  const landlordId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetOwnerDashBoardQuery({
    userID: +landlordId,
  }) as {data:OwnerDashBoard, error:any, isLoading:boolean};
  const [activeIndex, setActiveIndex] = useState("Total Units");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPropertyUnit, setSelectedPropertyUnit] = useState(null);
  const [PageNumber, setPageNumber] = useState(1);
  const statusMapping = {
    "Total Units": 1,
    "Partially Occupied Units": 13,
    "Occupied Units": 3,
    "Vacant Units": 5,
    "Inactive Units": 7, // Adjust the ID as needed
  };
  const handleActiveTabs = (TabId) => {
    debugger
    setActiveIndex(TabId);
    setPageNumber(1);
    setSelectedProperty(null);
    setSelectedPropertyUnit(null);
  };
  const iconMapping = {
    "Total Units": {
      active: totalunitActive,
      inactive: totalunitInactive,
    },
    "Partially Occupied Units": {
      active: partiallyOccupiedActiveImg,
      inactive: partiallyOccupiedInactiveImg,
    },
    "Occupied Units": {
      active:   OccupiedUnitsActiveImg,
      inactive: OccupiedUnitsInactiveImg,
    },
    "Vacant Units": {
      active: vacantUnitsActiveImg,
      inactive: vacantUnitsInactiveImg,
    },
    "Inactive Units": {
      active: inactiveUnitsActiveImg,
      inactive: inactiveUnitsInactiveImg,
    },

  };

  const displayData = {
    "Total Units": data?.listedProperty || 0,
    "Partially Occupied Units": data?.partiallyOccupied || 0,
    "Occupied Units": data?.propertiesOccupied || 0,
    "Vacant Units": data?.propertyListForRent || 0,
    "Inactive Units": data?.propertyListingQuota || 0,
  };

  const categories = Object.keys(displayData);

  if (isLoading) return <Loader />;
  if (error) return <p>Error loading data</p>;
  const chartOptions: ApexOptions = {
    series: [29, 71],
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["transparent"],
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,

        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical", // Try 'vertical' to simplify and test
        gradientToColors: ["#CB0753CC", "#BC0177"], // Simplified two-color gradient
        stops: [0, 50],
      },
    },
    grid: {
      padding: {
        bottom: -80,
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    colors: ["#E63332E5", "#F9F5FF"], // Base colors (overridden by gradient)
    responsive: [
      {
        breakpoint: 150,
        options: {
          chart: {
            width: 10,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const options: ApexOptions = {
    series: [
      {
        data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14],
      },
    ],
    colors: ["#F72328"],
    chart: {
      type: "line",
      width: 100,
      height: 55,
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };
  var sCol: ApexOptions = {
    chart: {
      height: 0,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        borderRadius: 6, // Slight rounding for the bars
        // borderRadiusApplication: 'around', // Apply rounding to both top and bottom for all bars
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["transparent"],
    },
    colors: ["#F72328", "#E9EDF7"], // Gradient colors for the bars
    series: [
      {
        name: "Current Data",
        data: [12, 14, 2, 47, 42, 100],
      },
      {
        name: "Background Data",
        data: [20, 30, 15, 8, 20, 40],
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        gradientToColors: ["#ef5e5f"], // Gradient end color
        stops: [0, 100],
      },
      opacity: 1,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false, // Hide X-axis labels
      },
      axisBorder: {
        show: false, // Hide X-axis border
      },
      axisTicks: {
        show: false, // Hide X-axis ticks
      },
    },
    yaxis: {
      min: 0,
      labels: {
        show: false, // Hide Y-axis labels
      },
      axisBorder: {
        show: false, // Hide Y-axis border
      },
      axisTicks: {
        show: false, // Hide Y-axis ticks
      },
    },
    grid: {
      show: false, // Hide grid lines
    },
    tooltip: {
      // Add any specific tooltip customization here
    },
    legend: {
      show: false, // Hide series labels
    },
  };
  var Col: ApexOptions = {
    chart: {
      height: 10,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 2, // Slight rounding for the bars
        borderRadiusApplication: "around", // Apply rounding to both top and bottom for all bars
        barHeight: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["transparent"],
    },
    colors: ["#F72328", "#E9EDF7"], // Gradient colors for the bars
    series: [
      {
        name: "Current Data",
        data: [24, 14, 22],
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        gradientToColors: ["#ef5e5f"], // Gradient end color
        stops: [0, 100],
      },
      opacity: 1,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false, // Hide X-axis labels
      },
      axisBorder: {
        show: false, // Hide X-axis border
      },
      axisTicks: {
        show: false, // Hide X-axis ticks
      },
    },
    yaxis: {
      min: 0,
      labels: {
        show: false, // Hide Y-axis labels
      },
      axisBorder: {
        show: false, // Hide Y-axis border
      },
      axisTicks: {
        show: false, // Hide Y-axis ticks
      },
    },
    grid: {
      show: false, // Hide grid lines
    },
    tooltip: {
      // Add any specific tooltip customization here
    },
    legend: {
      show: false, // Hide series labels
    },
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content largescreentext">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">PMS</div>
                  <div className="col-8 text-end">
                    <div className="head-icons"></div>
                  </div>
                </div>
              </div> */}
              <div className="card">
                <div className="card-body">
                  <div className="row position-relative top-35">
                    <div className="col-md-12 col-lg-2">
                      <div
                        className={`card totalunit-h propertycard ${activeIndex === "Total Units"
                          ? "pms-card"
                          : "pms-card-light"
                          }`}
                        onClick={() => handleActiveTabs("Total Units")}
                        style={{
                          color: activeIndex === "Total Units" ? "white" : "",
                        }}
                      >
                        <div className="card-body text-center align-content-center ">
                          <div className="mb-2">
                            <div className={`avatar avatar-md pms `}>
                              {activeIndex === "Total Units" ? (
                                <img
                                  src={totalunitActiveImg}
                                  alt="Total Units"
                                />
                              ) : (
                                <img
                                  src={totalunitInactiveImg}
                                  alt="Total Units"
                                />
                              )}
                            </div>
                          </div>
                          <p className=" ">Total Units</p>
                          <h2 className="">{displayData["Total Units"]}</h2>
                          {/* <p style={{ color: "#05CD99" }}> <i className="ti ti-circle-check-filled" />On track</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-8">
                      <div className="row ">
                        <div className="col-md-6">
                          <div
                            className={`campaign-box propertycard ${activeIndex === "Partially Occupied Units"
                              ? "bg-pms"
                              : "bg-pms-light"
                              }`}
                            onClick={() =>
                              handleActiveTabs("Partially Occupied Units")
                            }
                            style={{
                              color:
                                activeIndex === "Partially Occupied Units"
                                  ? "$white"
                                  : "",
                            }}
                          >
                            <div className="">
                              <h6>Partially Occupied units</h6>
                              <h2>{displayData["Partially Occupied Units"]}</h2>
                            </div>
                            {/* <h2>{displayData[category]}</h2> */}
                            <div id="chart">
                              <ReactApexChart
                                options={chartOptions}
                                series={chartOptions.series}
                                type="donut"
                                height={160}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`campaign-box propertycard ${activeIndex === "Vacant Units"
                              ? "bg-pms"
                              : "bg-pms-light"
                              }`}
                            onClick={() => handleActiveTabs("Vacant Units")}
                            style={{
                              color:
                                activeIndex === "Vacant Units" ? "$white" : "",
                            }}
                          >
                            <div className="">
                              <h6>Vacant Units</h6>
                              <h2>{displayData["Vacant Units"]}</h2>
                            </div>
                            {/* <h2>{displayData[category]}</h2> */}
                            <div id="chart">
                              <ReactApexChart
                                options={chartOptions}
                                series={chartOptions.series}
                                type="donut"
                                height={160}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`campaign-box propertycard propertymastercard-2 ${activeIndex === "Occupied Units"
                              ? "bg-pms"
                              : "bg-pms-light"
                              }`}
                            onClick={() => handleActiveTabs("Occupied Units")}
                            style={{
                              color:
                                activeIndex === "Occupied Units"
                                  ? "$white"
                                  : "",
                            }}
                          >
                            <div className="">
                              <h6>Occupied Units</h6>
                              <h2>{displayData["Occupied Units"]}</h2>
                            </div>
                            {/* <h2>{displayData[category]}</h2> */}
                            <div id="chart">
                              <Chart
                                options={options}
                                series={options.series}
                                type="line"
                                height={35}
                                width={100}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`campaign-box propertycard propertymastercard-1 ${activeIndex === "Inactive Units"
                              ? "bg-pms"
                              : "bg-pms-light"
                              }`}
                            onClick={() => handleActiveTabs("Inactive Units")}
                            style={{
                              color:
                                activeIndex === "Inactive Units"
                                  ? "$white"
                                  : "",
                            }}
                          >
                            <div className="">
                              <h6>Inactive Units</h6>
                              <h2>{displayData["Inactive Units"]}</h2>
                            </div>
                            {/* <h2>{displayData[category]}</h2> */}
                            <div id="chart">
                              <div className="inactive-chartspace">
                                <ReactApexChart
                                  className="inactive-chart"
                                  options={Col}
                                  series={Col.series}
                                  type="bar"
                                  height={90}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-2">
                      <div
                        className={` card propertycard pms-card-light totalunit-h`}
                      >
                        <div className="card-body totalunit-body text-center">
                          <div className=" totalbuilding pt-2 text-center">
                            <div>
                              <img
                                src={totalunitInactiveImg}
                                alt="Total Units"
                              />
                            </div>
                            <div className=" ">
                              <h6 className="">Total Units</h6>
                              <h2 className="">{displayData["Total Units"]}</h2>
                              {/* <p style={{ color: "#05CD99" }}> <i className="ti ti-circle-check-filled" />On track</p> */}
                            </div>
                          </div>
                          <div id="chart">
                            <ReactApexChart
                              options={sCol}
                              series={sCol.series}
                              type="bar"
                              height={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-4">


                <div className="mb-5 position-relative">
                  <div className="position-absolute d-flex pt-3">
                  {activeIndex || "N/A"}
                  </div>
                  {/* translate-middle-x */}
                  <div className="d-none py-2 d-xl-flex align-items-center position-absolute end-0  rounded-pill bg-white bg-gradient border border-white px-3 text-sm fw-medium text-dark shadow-lg">
                    {categories.map((category, index) => (
                      <a key={index} type="button" onClick={() => handleActiveTabs(category)} className={`align-items-center d-flex btn-link px-3 py-2 rounded-pill ${activeIndex === category ? "" : ""}`} style={{
                        color:
                          activeIndex === category ? "white" : "black",
                        background: activeIndex === category ? "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8), rgba(188, 1, 119, 0.9))" : "",
                        textDecoration: "none"
                      }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8), rgba(188, 1, 119, 0.9))";
                          e.currentTarget.style.color = "#9e2a2b";
                          const img = e.currentTarget.querySelector("img");
                          // if (img) img.src = iconMapping[category]?.active;
                        }}
                        onMouseLeave={(e) => {
                          if (activeIndex !== category) {
                            e.currentTarget.style.backgroundColor = "";
                            e.currentTarget.style.color = "black";
                            const img = e.currentTarget.querySelector("img");
                            // if (img) img.src = iconMapping[category]?.inactive;
                          }
                        }}
                      >
                        <img
                          src={
                            activeIndex === category
                              ? iconMapping[category]?.active
                              : iconMapping[category]?.inactive
                          }
                        
                          className="pe-2"
                          alt={category}
                          style={{ width:"30px",height:"20px",objectFit:"cover",objectPosition:"center",overflow:"hidden" }}
                        />
                        {category}
                      </a>
                    ))}

                  </div>
                </div>
              </div>
              <div>
                {/* <div className="row align-items-baseline">
                  <div className="col-lg-6 col-md-6 align-items-center">
                    <h4 className="totalunit-h4 py-2 fw-bolder">
                      Your Listed Properties
                    </h4>
                  </div>
                  <div className=" col-lg-6 col-md-6">
                    <div className="card card-shadow">
                      <div className="card-body tab-breadcrums align-content-center wrapper-nav">
                        <nav aria-label="breadcrumb ">
                          <ol className="breadcrumb  breadcrumb-line breadcrumb-li " style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            {categories.map((category, index) => (
                              <li
                                className={`breadcrumb-item `}
                                onClick={() => handleActiveTabs(category)}
                                style={{
                                  color:
                                    activeIndex === category ? "$white" : "",
                                }}
                                key={index}
                                aria-current="page"
                              >
                                <span
                                  className={`breadcrumb-hover d-flex ${activeIndex === category
                                      ? "active-breadcrumb"
                                      : ""
                                    }`}
                                >
                                  <img
                                    src={
                                      activeIndex === category
                                        ? iconMapping[category]?.active
                                        : iconMapping[category]?.inactive
                                    }
                                    alt={category}
                                    style={{ aspectRatio: '23/3' }}
                                  />

                                  <span className="ps-2">{category}</span>
                                </span>
                              </li>
                            ))}
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* {selectedProperty ? (
                <PropertyDetail propertyId={selectedProperty.propertyId} status={statusMapping[activeIndex]} setSelectedPropertyUnit={setSelectedPropertyUnit} />
              ) : (
                <Totalunits status={statusMapping[activeIndex]} setSelectedProperty={setSelectedProperty} />
              ) 
              ||
               (
                <UnitDetail propertyId={selectedProperty.propertyId} selectedPropertyUnit={selectedPropertyUnit} />
              )} */}
              {selectedPropertyUnit ? (
                <UnitDetail
                  propertyId={selectedProperty.propertyId}
                  selectedPropertyUnit={selectedPropertyUnit}
                />
              ) : selectedProperty ? (
                <PropertyDetail
                  propertyId={selectedProperty.propertyId}
                  status={statusMapping[activeIndex]}
                  setSelectedPropertyUnit={setSelectedPropertyUnit}
                />
              ) : (
                <Totalunits
                  status={statusMapping[activeIndex]}
                  setSelectedProperty={setSelectedProperty}
                  setPageNumber={setPageNumber}
                  pageNumber={PageNumber}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyMasterCards;
