// PropertyDetail.js
import React from "react";
import {
  useGetAgreementEndDateQuery,
  useGetPropertyfeatureQuery,
  useRenewableRequestAgMutation,
  useRentFrequencyQuery,
  useRentModeQuery,
} from "../../../redux/CommonApi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Form, Toast } from 'react-bootstrap';
import dayjs from 'dayjs';
import toastr from "toastr";
import { RenewableRequestAg } from "./model/RenewableRequestAg";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import "toastr/build/toastr.min.css";
import CustomLoading from "../CustomLoading";
const fallbackImage =
  "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";

const AggrementDetail = ({ expiredAggrementDetail }) => {
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const [showRenewtoast, setShowRenewtoast] = React.useState(false);
  const { data: rentFrequency, isLoading: rentloader } = useRentFrequencyQuery(null);
  const { data: rentMode } = useRentModeQuery(null);

  const handlechequeClose = () => {
    setShowRenewtoast(false);
  };
  const handlecheque = () => {
    setShowRenewtoast(true);
  };
  const [addRenewableRequest] = useRenewableRequestAgMutation();
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const { data: propertyfeature, isLoading: propertyloader } = useGetPropertyfeatureQuery({
    PropertyMasterId: expiredAggrementDetail.propertyMasterID,
  });


  const images = expiredAggrementDetail.imageString || [];
  const hasImages = images.length > 0;
  const hasMultipleImages = images.length > 1;
  const mainFeatures =
    propertyfeature?.filter(
      (feature) => feature.propertyUnitCategoryID === null
    ) || [];
  const UserID = sessionStorage.getItem("userId");
  const [customStartDate, setCustomStartDate] = React.useState(null);
  const [customEndDate, setCustomEndDate] = React.useState(null);
  const agreementId = useSelector((state: any) => state.CRMS.aggrementId) ?? null;
  const isRenewAgreement = useSelector((state: any) => state.CRMS.isRenewAgreement) ?? null;
  const {
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
  } = useForm<RenewableRequestAg>({
    defaultValues: {
      aggID: +agreementId,
      type: 2,
      status: 10,
      requestAggreement: {
        landLordID: +UserID,
        tenantID: null,
        corporateID: null,
        rentalModeID: 1,
        rentalFrequencyID: 1,
        aggStartDate: '',
        aggEndDate: customEndDate,
        terms: null,
        proposeRent: null,
        rentAmount: null,
        advanceAmount: null,
        propertyUnitStatusID: null,
        rentalUnitID: null,
        isApprovedByLandLord: true,
        isApprovedByTenant: false,
        requestTypeID: 3,
        status: 8,
        reqUsrID: +UserID,
        reqUsrTypeID: 1
      },

    },
  });
  const handlestart = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setValue('requestAggreement.aggStartDate', formattedDate, {
      shouldValidate: true,
    });
    setCustomStartDate(date);
  };
  const handleEnd = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setValue('requestAggreement.aggEndDate', formattedDate, {
      shouldValidate: true,
    });
    setCustomEndDate(formattedDate)
  };
  // Watches for changes in these fields
  const rentalModeID = watch("requestAggreement.rentalModeID");
  const rentalFrequencyID = watch("requestAggreement.rentalFrequencyID");
  const terms = watch("requestAggreement.terms");

  // Move the query to the top level
  const { data: expDate, error } = useGetAgreementEndDateQuery(
    {
      rentalModeID: Number(rentalModeID),
      rentalFrequencyID: Number(rentalFrequencyID),
      agStartDate: dayjs(expiredAggrementDetail.aggStartDate).add(1, 'day').format('YYYY-MM-DD'),
      terms: Number(terms),
    },
    {
      skip: !rentalModeID || !rentalFrequencyID || !terms, // Skip query if values are missing
    }
  );

  const handleproposeRentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setValue("requestAggreement.proposeRent", Number(selectedValue));

  };
  const handlerentalFrequencyIDChange = (e) => {
    const selectedValue = e.target.value;
    setValue("requestAggreement.rentalFrequencyID", Number(selectedValue));

  };
  const handleRentModeChange = (e) => {
    const selectedValue = e.target.value;
    setValue("requestAggreement.rentalModeID", Number(selectedValue));


  };
  const handleTermsChange = (e) => {
    const selectedValue = e.target.value;
    setValue("requestAggreement.terms", Number(selectedValue));

  };

  React.useEffect(() => {
    if (expDate) {
      console.log("Agreement End Date:", expDate);
    }
    if (error) {
      console.error("Error fetching end date:", error);
    }
  }, [expDate, error]);
  const onSubmit: SubmitHandler<RenewableRequestAg> = async (data) => {
    if (!data.requestAggreement.proposeRent || !data.requestAggreement.terms) {
      return;
    }
    data.requestAggreement.rentAmount = expiredAggrementDetail?.rentAmount;
    data.requestAggreement.tenantID = expiredAggrementDetail?.tenantId;
    data.requestAggreement.propertyUnitStatusID = expiredAggrementDetail?.propertyUnitStatusID;
    data.requestAggreement.aggStartDate = dayjs(expiredAggrementDetail?.aggEndDate).add(1, 'day').format('YYYY-MM-DD');

    // Check if expDate exists, fallback to default value or handle appropriately
    data.requestAggreement.aggEndDate = expDate ? dayjs(expDate).format('YYYY-MM-DD') : null;

    data.requestAggreement.corporateID = expiredAggrementDetail?.institutionalCorpID;
    data.requestAggreement.rentalUnitID = expiredAggrementDetail?.aggUnitID;


    try {
      const response = await addRenewableRequest(data);
      console.log("Success", response);
      setShowRenewtoast(false);
      reset()
      toastr.success("Successfully");
      navigate("/admin/tenantMaster");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      setError("root", {
        message: error.message,
      });
    }
  };
  if (
    propertyloader

  )
    return <CustomLoading />;
  return (
    <>
      <div className="row pb-4">
        <div className="col-md-6">
          {hasImages ? (
            hasMultipleImages ? (
              <>
                {/* Swiper for Property Images */}
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={`Slide ${index}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={`Thumb ${index}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            ) : (
              <img
                src={images[0]}
                alt="Single Image"
                style={{ height: "45vh", width: "100%" }}
                className="pb-4"
              />
            )
          ) : (
            <img
              className=" pb-4 img-fluid"
              src={fallbackImage}
              alt="Fallback"
              style={{ width: "100%" }}
            />
          )}
        </div>
        <div className="col-md-6">

          <div className="row">
            <div className="col-md-6 propertydetail">
              <h6 className="py-2 page-title">Property Information</h6>
              <div>
                <p className="page-title">
                  Building Type: <span className="page-title">{expiredAggrementDetail.buildingType}</span>
                </p>
                <p className="py-2 page-title">
                  Building id: <span className="page-title">{expiredAggrementDetail.buildingNumber}</span>
                </p>
                <p className="truncate page-title">
                  Building Name: <span className="page-title">{expiredAggrementDetail.buildingNumber}</span>
                </p>
                <p className="py-2 page-title">
                  Unit ID : <span className="page-title">{expiredAggrementDetail.aggUnitID}</span>
                </p>
                <p className="page-title">
                  Unit Category : <span className="page-title">{expiredAggrementDetail.properyUnitName}</span>
                </p>
                <p className="py-2 page-title">
                  Floor #  : <span className="page-title">{expiredAggrementDetail.floorNumber}</span>
                </p>
                <p className="page-title">
                  Monthly Rent : <span className="page-title">{expiredAggrementDetail.rentAmount}</span>
                </p>
                <p className="py-2 page-title">
                  Advance Payment : <span className="page-title">{expiredAggrementDetail.advancedDeposit}</span>
                </p>

                <p className=" totalunits-address page-title">
                  <i
                    className="ti ti-map-pin-filled page-title"
                    style={{
                      background:
                        "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }}
                  />{" "}
                  {expiredAggrementDetail.addressStr}
                </p>
                <p className="py-2 page-title">
                  Tenant : <span className="page-title">{expiredAggrementDetail.tenantName}</span>
                </p>
                <p className="page-title">
                  Aggrement Start Date: <span className="page-title">{dayjs(expiredAggrementDetail.aggStartDate).format('DD-MM-YYYY')}</span> </p>
                <p className="py-2 page-title">
                  Aggrement End Date: <span className="page-title">{dayjs(expiredAggrementDetail.aggEndDate).format('DD-MM-YYYY')}</span>
                </p>
                {isRenewAgreement === false && <p className="py-2 page-title">
                  Note: Your Agreement will be expire Soon if you want to Renew your agreement send renew request to Tenant.
                </p>}
                <p
                  className=""
                  style={{
                    background:
                      "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                  }}
                >

                </p>
              </div>
            </div>
            {mainFeatures && mainFeatures.length > 0 && (
              <div className="col-md-6">
                <h6 className="py-2 page-title">Main Features</h6>
                <div>
                  <ul>
                    {mainFeatures.map((feature, index) => (
                      <li key={index} className="pb-2 page-title">{feature.feature.desc}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {/* <pre>{JSON.stringify(expiredAggrementDetail, null, 2)}</pre> */}
            <div className="toast-container position-fixed top-50 start-50 translate-middle">
              <Toast
                show={showRenewtoast}
                onClose={handlechequeClose}
                id="middlecenter-Toast"
                className="colored-toast bg-white terminateToast negotiate-popup card"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="align-items-center d-flex justify-content-between pt-4" >
                  <p className="pt-3 page-title" style={{ color: "#2E2E2E" }}>Generate Renewal Request </p> <div className="" onClick={handlechequeClose}>
                    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.354492" width="36.5703" height="36.5703" rx="18.2852" fill="#D9D9D9" />
                      <path d="M20.0064 18.2863L24.5922 13.6991C24.7161 13.5795 24.815 13.4363 24.883 13.278C24.9509 13.1198 24.9867 12.9495 24.9882 12.7773C24.9897 12.605 24.9569 12.4342 24.8917 12.2748C24.8265 12.1154 24.7301 11.9705 24.6083 11.8487C24.4865 11.7269 24.3417 11.6306 24.1822 11.5654C24.0228 11.5001 23.852 11.4673 23.6797 11.4688C23.5075 11.4703 23.3373 11.5061 23.179 11.5741C23.0207 11.6421 22.8776 11.7409 22.7579 11.8648L18.1707 16.4507L13.5849 11.8648C13.4652 11.7409 13.3221 11.6421 13.1638 11.5741C13.0055 11.5061 12.8353 11.4703 12.663 11.4688C12.4908 11.4673 12.32 11.5001 12.1605 11.5654C12.0011 11.6306 11.8563 11.7269 11.7345 11.8487C11.6127 11.9705 11.5163 12.1154 11.4511 12.2748C11.3859 12.4342 11.353 12.605 11.3545 12.7773C11.356 12.9495 11.3918 13.1198 11.4598 13.278C11.5278 13.4363 11.6266 13.5795 11.7505 13.6991L16.3364 18.285L11.7505 22.8722C11.6266 22.9918 11.5278 23.135 11.4598 23.2932C11.3918 23.4515 11.356 23.6217 11.3545 23.794C11.353 23.9662 11.3859 24.1371 11.4511 24.2965C11.5163 24.4559 11.6127 24.6008 11.7345 24.7226C11.8563 24.8444 12.0011 24.9407 12.1605 25.0059C12.32 25.0712 12.4908 25.104 12.663 25.1025C12.8353 25.101 13.0055 25.0652 13.1638 24.9972C13.3221 24.9292 13.4652 24.8304 13.5849 24.7065L18.1707 20.1206L22.7579 24.7065C22.8776 24.8304 23.0207 24.9292 23.179 24.9972C23.3373 25.0652 23.5075 25.101 23.6797 25.1025C23.852 25.104 24.0228 25.0712 24.1822 25.0059C24.3417 24.9407 24.4865 24.8444 24.6083 24.7226C24.7301 24.6008 24.8265 24.4559 24.8917 24.2965C24.9569 24.1371 24.9897 23.9662 24.9882 23.794C24.9867 23.6217 24.9509 23.4515 24.883 23.2932C24.815 23.135 24.7161 22.9918 24.5922 22.8722L20.0064 18.2863Z" fill="#A64041" />
                    </svg>
                  </div>
                </div>
                {/* <Toast.Body><p>Are you sure you want to terminate agreement against building ID ({tenancyRequestDetail.buildingNumber}) and unit ID ({tenancyRequestDetail.aggUnitID}) </p></Toast.Body> */}
                <Form noValidate onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label className="col-form-label page-title">Property Rent </label>
                        <input
                          className="form-control negotiate-form"
                          placeholder="OMR 50.0"
                          // {...register("requestAggreement.rentAmount", {
                          //   required: "is required",
                          // })}
                          value={expiredAggrementDetail.rentAmount+ ' OMR'}
                          disabled={true}
                        />
                        {/* {errors.requestAggreement?.rentAmount && (
                          <p style={{ color: "#9E2A2B" }}>{errors.requestAggreement.rentAmount.message}</p>
                        )} */}
                      </div>

                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label className="col-form-label page-title">Proposed Rent </label>
                        <input
                          className="form-control negotiate-form page-title"
                          placeholder="OMR 50.0"
                          {...register("requestAggreement.proposeRent", {
                            required: "Proposed Rent is required",
                            valueAsNumber: false,
                            min: { value: 1, message: "Rent must be greater than 0" },
                          })}
                          onChange={(e) => handleproposeRentChange(e)}
                        />
                        {errors.requestAggreement?.proposeRent && (
                          <p className="ErrorColor">{errors.requestAggreement.proposeRent.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">

                      <div className="form-wrap">
                        <label className="col-form-label page-title">Rent Frequency</label>
                        <select
                          id="country"
                          className="form-select form-c addproperty-form page-title"
                          {...register("requestAggreement.rentalFrequencyID", {
                            required: "is required ",
                            valueAsNumber: true,
                          })}
                          onChange={(e) => handlerentalFrequencyIDChange(e)}
                        >
                          {/* <option value="" disabled>
                    Select Country
                  </option> */}
                          {rentFrequency?.map((role) => (
                            <option
                              key={role.rentalFrequencyType} value={role.rentalFrequencyType}
                            >
                              {role.desc}
                            </option>
                          ))}
                        </select>
                        {errors.requestAggreement?.rentalFrequencyID && (
                          <p style={{ color: "#9E2A2B" }}>
                            {errors.requestAggreement?.rentalFrequencyID.message}
                          </p>
                        )}

                      </div>

                    </div>
                    <div className="col-md-12">

                      <div className="form-wrap">
                        <label className="col-form-label page-title">Rent Mode</label>
                        <select
                          id="country"
                          className="form-select form-c addproperty-form "
                          {...register("requestAggreement.rentalModeID", {
                            required: "is required ",
                            valueAsNumber: false,
                          })}
                          onChange={(e) => handleRentModeChange(e)}
                        >
                          {/* <option value="" disabled>
Select Country
</option> */}
                          {rentMode?.map((role) => (
                            <option
                              key={role.rentalModeType} value={role.rentalModeType}
                            >
                              {role.desc}
                            </option>
                          ))}
                        </select>
                        {errors.requestAggreement?.rentalModeID && (
                          <p style={{ color: "#9E2A2B" }}>
                            {errors.requestAggreement?.rentalModeID.message}
                          </p>
                        )}

                      </div>

                    </div>
                    <div className="col-md-12">

                      <div className="form-wrap">
                        <label className="col-form-label page-title">Terms</label>
                        <input
                          className="form-control negotiate-form"
                          placeholder="0"
                          {...register("requestAggreement.terms", {
                            required: "Terms is required",
                            valueAsNumber: true,
                            min: { value: 1, message: "Terms must be greater than 0" },
                          })}
                          onChange={(e) => handleTermsChange(e)}
                          min="1" // Prevents negative numbers
                          step="1"
                        />
                        {errors.requestAggreement?.terms && (
                          <p className="ErrorColor">
                            {errors.requestAggreement?.terms.message}
                          </p>
                        )}

                      </div>

                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="col-form-label page-title">Agreement start Date</label>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <div className="form-wrap icon-form">
                          <input
                            className="form-control negotiate-form page-title"
                            placeholder="OMR 50.0"
                            value={dayjs(expiredAggrementDetail.aggEndDate).add(1, 'day').format('DD-MM-YYYY')}
                            disabled={true}


                            min="1" // Prevents negative numbers
                            step="1"
                          />
                          {/* <Controller
                            name="requestAggreement.aggStartDate"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <DatePicker
                                selected={customStartDate}
                                onChange={(date) => handlestart(date)}
                                selectsStart
                                startDate={customStartDate}
                                endDate={customEndDate}
                                placeholderText="mm/dd/yy"
                                className="form-control bookingrange"
                                onFocus={(e) => e.preventDefault()}
                              />
                            )}
                          /> */}

                          <span className="form-icon">
                            <i className="ti ti-calendar page-title" />
                          </span>

                        </div>
                        {errors.requestAggreement?.aggStartDate && (
                          <p style={{ color: "#9E2A2B" }}>
                            {errors.requestAggreement.aggStartDate.message}
                          </p>
                        )}

                      </div>

                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <div className="form-wrap icon-form page-title">
                          {/* <DatePicker
                            selected={customEndDate}
                            onChange={(date) =>
                              handleEnd(date)

                            }
                            selectsEnd
                            startDate={customStartDate}
                            endDate={customEndDate}
                            minDate={customStartDate}
                            placeholderText="mm/dd/yy"
                            className="form-control bookingrange"
                            onFocus={(e) => e.preventDefault()}
                          /> */}
                          {
                            expDate ? (
                              <input
                                className="form-control negotiate-form"
                                placeholder=""
                                value={dayjs(expDate).add(1, 'day').format('YYYY-MM-DD')}
                                disabled={true}
                                min="1" // Prevents negative numbers
                                step="1"
                              />
                            ) : (
                              <>
                                <input
                                  className="form-control negotiate-form"
                                  placeholder="YYYY-MM-DD"
                                  // value={dayjs(expDate).add(1, 'day').format('YYYY-MM-DD')}
                                  disabled={true}
                                  min="1" // Prevents negative numbers
                                  step="1"
                                />
                                <span className="form-icon">
                                  <i className="ti ti-calendar page-title" />
                                </span>

                              </>
                            )
                          }


                        </div>
                        {errors.requestAggreement?.aggEndDate && (
                          <p style={{ color: "#9E2A2B" }}>
                            {errors.requestAggreement.aggEndDate.message}
                          </p>
                        )}
                      </div>

                    </div>
                    {errors.root && <p style={{ color: "red" }}>{errors.root.message}</p>}
                  </div>

                  <div className="d-flex justify-content-end gap-2 pb-4 pt-3 ">
                    <button
                      type="submit"
                      className="btn  btn-danger page-title"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              </Toast>

            </div>
          </div>
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 text-end">
              {isRenewAgreement === false && (<button className="btn btn-primary add-popup page-title" onClick={handlecheque}>Renewal Request</button>)}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default AggrementDetail;
