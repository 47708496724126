import React, { useState } from 'react'
import dayjs from "dayjs";
import { Loader } from '../shared/Loader';
import { Controller, useForm } from 'react-hook-form';
import { styled, TextField } from '@mui/material';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { usePutRentCollectionMutation } from '../redux/CommonApi';
import toastr from "toastr";
import DatePicker from "./DatePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { TransactionUpdate } from '../feature-module/Models/RentCollection/TransactionUpdate';
const schema = yup
    .object({
        chequeValueDate: yup
            .date()
            .required()
            .typeError("Please select a valid date")
            .nullable(),
        // status: yup.number().required(),
        // TransactionDate: yup.date().required().typeError("Please select a valid date").nullable(),
        // trxID: yup.number().required(),
        // rentID: yup.number().required()
    })
    .required();
// import html2pdf from 'html2pdf.js';
export const TransactionDetail = ({ transaction, handleRejection, handleApprove, isLoading, error, html, rentID, setReopenpopup, status, paymentTypeid, isShowbutton, handleChange, handleRej, isRejectionbutton, setIsShowbutton, loading,descMes }) => {
    const [loader, setloader] = useState(false);
    console.log("transaction", transaction)
    // if (isLoading || error) {
    //     return <div>{isLoading ? <Loader /> : 'Error loading receipt'}</div>;
    //   }
    const [putRentCollections] = usePutRentCollectionMutation()
    const CustomLocalizationProvider = styled(LocalizationProvider)(
        ({ theme }) => ({
            display: "flex !important",
        })
    );
    // const downloadPdf = () => {
    //     const element = document.createElement('div');
    //     element.innerHTML = html;

    //     html2pdf()
    //       .set({
    //         margin: 1,
    //         filename: 'download.pdf',
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    //       })
    //       .from(element)
    //       .save();
    //   };
    const CustomDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
        fontFamily: "Arial, sans-serif",
        color: "#333",
        display: "flex !important",
        height: "0.4375em !important",
    }));
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset,

        setValue,
        getValues,
    } = useForm({
        defaultValues: {
            chequeValueDate: null,
            status: 11,
            // chequeValueDate: dayjs().toISOString(),
            // TransactionDate: dayjs().toISOString(),

            TransactionDate: null,
            trxID: null,
            rentID: rentID,
        },
        // resolver: yupResolver(schema),
        // reValidateMode: "onChange",
    });
    const onSubmit = async (data) => {
        debugger
        data.rentID = rentID;
        // console.log("data", data)      
        setloader(true);
        try {
            await putRentCollections({
                updatedData: data,
            });
            toastr.success("Rent Approved");
            setloader(false);
            setReopenpopup(false)
            setIsShowbutton(true)
        } catch (error) {
            console.error("error", error);
            toastr.error(error);
            setloader(false);
            setReopenpopup(false)
            setIsShowbutton(true)
        }
    }
    const onapprove = async () => {
        // const data = {
        //     status: 11,
        //     rentID: rentID,
        // }

        const data = new TransactionUpdate({
            status: 11,
            rentID: rentID,
          });

        data.rentID = rentID
        setloader(true);
        try {
            await putRentCollections({
                updatedData: JSON.parse(JSON.stringify(data)),
            });
            toastr.success("Rend Approved");
            setloader(false);
            setReopenpopup(false)
            setIsShowbutton(true)
        } catch (error) {
            console.error("error", error);
            toastr.error(error);
            setloader(false);
            setReopenpopup(false)
            setIsShowbutton(true)
        }
    }
    if (loading) return <Loader />;
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);

    // Get descriptions
    // const descriptionParts = transaction?.feedback.split(
    //     "</br>"
    // ) || ["", ""];

    const descriptionParts = (transaction?.feedback !== null && transaction?.feedback !== undefined)
    ? transaction.feedback.split("</br>")
    : ["", ""];

    const toggleExpansion1 = () => setIsExpanded1(!isExpanded1);
    return (
        <div>
            {isShowbutton === false && (<>
                <div className='pt-2 px-3'>
                    <img src="https://d2og5lryw1ajtt.cloudfront.net/img/mumtalikatilogred.png" alt="logo" width={"100px"} height={""} />
                </div>
                <div className='align-items-center d-flex justify-content-around  pt-2'>
                    <div></div>
                    <div></div>
                    <div> <img style={{ borderRadius: "50%" }} src={transaction?.businessImage} alt="profile" width={"80px"} height={"80px"} /></div>

                    <p className='page-title pt-2 m-0'>Token No: <span className='' style={{ color: "#9e2a2b" }}>{transaction?.receiptNo}</span> </p>
                </div>
                <div className="align-items-center d-flex flex-column py-2">


                    <p className='page-title m-0 pt-1'>Property Manager: <span className='' style={{ color: "#9e2a2b" }}>{transaction?.propertyManager}</span> </p>
                    <p className='page-title m-0 fw-bold pt-1'>Rent Receipt </p>
                    <p className='page-title m-0 pt-1 text-center px-2'>Transaction Originated By: <span className='' style={{ color: "#9e2a2b" }}>{transaction?.createdBy}</span> on <span className='' style={{ color: "#9e2a2b" }}>{dayjs(transaction?.receivedDate).format("DD-MM-YYYY")}</span></p>
                </div></>)}
            {transaction?.tenantName && (
                <div className='px-3 pt-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p className='page-title '>Tenant Name </p>
                        </div>
                        <div className='col-sm-6 text-end '>
                            <p className='page-title '>{transaction?.tenantName} </p>
                        </div>
                    </div>
                </div>
            )}
            {transaction?.createdTime && (
                <div className='px-3 pt-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p className='page-title '>Trx Date </p>
                        </div>
                        <div className='col-sm-6 text-end '>
                            <p className='page-title '>{dayjs(transaction?.createdTime).format("DD-MM-YYYY")} </p>
                        </div>
                    </div>
                </div>
            )}
            {transaction?.buildingID && (
                <div className='px-3 pt-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p className='page-title '>Building ID </p>
                        </div>
                        <div className='col-sm-6 text-end '>
                            <p className='page-title '>{transaction?.buildingID} </p>
                        </div>
                    </div>
                </div>
            )}
            {isShowbutton === true && (<>
                {transaction?.unitID && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Unit ID </p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{transaction?.unitID} </p>
                            </div>
                        </div>
                    </div>
                )}
                {transaction?.unitID && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Unit Caption </p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{transaction?.unitID} </p>
                            </div>
                        </div>
                    </div>
                )}

                {transaction?.tenantID && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Tenant ID</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{transaction?.tenantID} </p>
                            </div>
                        </div>
                    </div>
                )}
            </>)}

            {transaction?.rentAmount && (
                <div className='px-3 pt-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p className='page-title '>Total Payable (OMR)</p>
                        </div>
                        <div className='col-sm-6 text-end '>
                            <p className='page-title '>{transaction?.rentAmount}.0 </p>
                        </div>
                    </div>
                </div>
            )}
            {transaction?.amountPaid && (
                <div className='px-3 pt-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p className='page-title '>Amount paid (OMR)</p>
                        </div>
                        <div className='col-sm-6 text-end '>
                            <p className='page-title '>{transaction?.amountPaid}.0 </p>
                        </div>
                    </div>
                </div>
            )}
            {transaction?.pendingDues === 0 && transaction?.pendingDues === null ? (
                <></>
            ) : (<div className='px-3 pt-2'>
                <div className=' AwaitingApprovalPopup-outline'>
                    <div className='col-sm-6 '>
                        <p className='page-title '>Pending (OMR)</p>
                    </div>
                    <div className='col-sm-6 text-end '>
                        <p className='page-title '>{transaction?.pendingDues}.0 </p>
                    </div>
                </div>
            </div>)}
            {transaction?.paymentMethod && (
                <div className='px-3 pt-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p className='page-title '>Type</p>
                        </div>
                        <div className='col-sm-6 text-end '>
                            <p className='page-title '>{transaction?.paymentMethod}</p>
                        </div>
                    </div>
                </div>
            )}

            {transaction?.paymentMethod !== "Cheque" && transaction?.paymentMethod !== "Cash" && transaction?.url && (<>
                <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Receipt Image</h6>
                <div className='px-3 pt-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p className='page-title ' style={{textWrap:"nowrap",overflow:"hidden"}}>
                                {/* <img src={transaction?.url} alt={transaction?.url} style={{ width: '20px', height: '20px', objectFit: "cover" }} /> */}
                                <span>
                                    {transaction?.url.split('/').pop()}
                                </span>
                            </p>
                        </div>
                        <div className='col-sm-6 text-end '>
                            <a href={transaction?.url} className='page-title '><i className='fa fa-download'></i></a>
                        </div>
                    </div>
                </div>
            </>)}
            {transaction?.feedback && (<>
                <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Feedback</h6>
                <div className="px-3 pt-2">
                    <span className="page-title">
                        {descriptionParts[0].length > 50
                            ? isExpanded1
                                ? descriptionParts[0]
                                : `${descriptionParts[0].slice(0, 50)}...`
                            : descriptionParts[0]}
                        {descriptionParts[0].length > 50 && (
                            <button
                                onClick={toggleExpansion1}
                                className="btn btn-link p-0 ms-1"
                            >
                                {isExpanded1 ? "Read Less" : "Read More"}
                            </button>
                        )}
                    </span>
                    {/* <textarea
                        rows={10}
                        cols={10}
                        className="form-control form-textarea"
                        placeholder="Write about Request...."
                        name="description"
                        value={transaction?.feedback}
                        disabled={true}
                    /> */}
                </div>
            </>

            )}
            {isShowbutton === false && isRejectionbutton === true && (
                <>
                    <div>
                        <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Reason of Rejection</h6>
                        <div className="px-3 pt-2">
                            <textarea
                                rows={2}
                                cols={2}
                                className="form-control form-textarea"
                                placeholder="Description"
                                name="description"
                                onChange={handleChange}

                            />
                        </div>
                       {descMes &&(<p className='text-danger px-2'>Enter the reason for rejecting the request</p>)} 
                    </div>

                    <div className='text-end py-4'>
                        <button
                            type="button"
                            className="btn btn-danger btn-radius"
                            id="middlecenterToastBtn"
                            onClick={() => handleRej()}
                        >
                            Submit
                        </button>
                    </div>
                </>
            )}
            {paymentTypeid === 2 && isShowbutton === false && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h6 className='page-title AwaitingApprovalPopup-h6 py-4 px-3'>Reconaciliation Details</h6>
                        <div className="px-3 pt-2">
                            <div className="col-md-12">
                                <div className="form-wrap">
                                    <label className="col-form-label">Transaction Date</label>
                                    <div className="form-control addproperty-form duedate-form">

                                        {/* <Controller
                                            name="TransactionDate"
                                            control={control}
                                            // defaultValue={'22-22-2024'}
                                            rules={{
                                                required: "Transaction Date is required",
                                            }}
                                            render={({ field, fieldState }) => (
                                                <CustomLocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDesktopDatePicker
                                                        value={field.value ? dayjs(field.value) : null} 
                                                        // Ensure value is a Dayjs object
                                                        onChange={(date) => {
                                                            if (date) {
                                                                field.onChange(date.toISOString()); // Update the form value as an ISO string
                                                            }
                                                        }}
                                                        format="DD-MM-YYYY" // Set the display format
                                                    >
                                                        <TextField
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                    </CustomDesktopDatePicker>
                                                </CustomLocalizationProvider>
                                            )}
                                        /> */}
                        <Controller
    name="TransactionDate"
    control={control}
    rules={{
        required: "Transaction Date is required",
    }}
    render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                value={field.value ? dayjs(field.value) : null}
                onChange={(date) => {
                    field.onChange(date?.toISOString() || null);
                }}
                format="DD-MM-YYYY"
                slotProps={{
                    textField: {
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                        fullWidth: true,
                        variant: "outlined",
                    },
                }}
            />
        </LocalizationProvider>
    )}
/>




                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="form-wrap">
                                    <label className="col-form-label">Cheque Value Date</label>
                                    <div className="form-control addproperty-form duedate-form">
                                    <Controller
    name="chequeValueDate"
    control={control}
    rules={{
        required: "cheque Value Date is required",
    }}
    render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                value={field.value ? dayjs(field.value) : null}
                onChange={(date) => {
                    field.onChange(date?.toISOString() || null);
                }}
                format="DD-MM-YYYY"
                slotProps={{
                    textField: {
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                        fullWidth: true,
                        variant: "outlined",
                    },
                }}
            />
        </LocalizationProvider>
    )}
/>
                                        {/* <Controller
                                            name="chequeValueDate"
                                            control={control}
                                            rules={{
                                                required: "cheque Value Date Date is required",
                                            }}
                                            render={({ field, fieldState }) => (
                                                <CustomLocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDesktopDatePicker
                                                        value={field.value ? dayjs(field.value) : null} // Ensure value is a Dayjs object
                                                        onChange={(date) => {
                                                            if (date) {
                                                                field.onChange(date.toISOString()); // Update the form value as an ISO string
                                                            }
                                                        }}
                                                        format="DD-MM-YYYY" // Set the display format
                                                    >
                                                        <TextField
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                    </CustomDesktopDatePicker>
                                                </CustomLocalizationProvider>
                                            )}
                                        /> */}

                                    </div>
                                </div>
                            </div>
                            <div className='text-end py-4'>
                                <button
                                    type="submit"
                                    className="btn btn-danger btn-radius"
                                    id="middlecenterToastBtn"
                                    disabled={loader}
                                >
                                    {loader ? (
                                        <span className="spinner-border spinner-border-sm mr-1 "></span>
                                    ) : null}
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </>
            )}
            {isShowbutton === false && paymentTypeid === 3 && (<>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Reconaciliation Details</h6>
                    <div className="px-3 pt-2">
                        <div className="col-md-12">
                            <div className="form-wrap">
                                <label className="col-form-label">Transaction ID</label>
                                <input
                                    className="form-control addproperty-form"
                                    {...register("trxID", {
                                        required: "Enter Transaction ID",
                                        onChange: (e) => setValue("trxID", e.target.value),
                                    })}
                                />
                                {errors.trxID && (
                                    <p style={{ color: "#9E2A2B" }}>
                                        {errors.trxID.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-wrap">
                                <label className="col-form-label">Transaction Date</label>
                                <div className="form-control addproperty-form duedate-form">
                                <Controller
    name="TransactionDate"
    control={control}
    rules={{
        required: "Transaction Date is required",
    }}
    render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                value={field.value ? dayjs(field.value) : null}
                onChange={(date) => {
                    field.onChange(date?.toISOString() || null);
                }}
                format="DD-MM-YYYY"
                slotProps={{
                    textField: {
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                        fullWidth: true,
                        variant: "outlined",
                    },
                }}
            />
        </LocalizationProvider>
    )}
/>
                                    {/* <Controller
                                        name="TransactionDate"
                                        control={control}
                                        rules={{
                                            required: "Transaction Date is required",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <CustomLocalizationProvider dateAdapter={AdapterDayjs}>
                                                <CustomDesktopDatePicker
                                                    value={field.value ? dayjs(field.value) : null} // Ensure value is a Dayjs object
                                                    onChange={(date) => {
                                                        if (date) {
                                                            field.onChange(date.toISOString()); // Update the form value as an ISO string
                                                        }
                                                    }}
                                                    format="DD-MM-YYYY" // Set the display format
                                                >
                                                    <TextField
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </CustomDesktopDatePicker>
                                            </CustomLocalizationProvider>
                                        )}
                                    /> */}

                                </div>
                            </div>

                        </div>
                        <div className='text-end py-4'>
                            <button
                                type="submit"
                                className="btn btn-danger btn-radius"
                                id="middlecenterToastBtn"
                                disabled={loader}
                            >
                                {loader ? (
                                    <span className="spinner-border spinner-border-sm mr-1 "></span>
                                ) : null}
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </>)}
            {isShowbutton && <div className='pb-4'>

                <div className="justify-content-center d-flex agreementdoc gap-2 pb-3 pt-4">
                    <button
                        type="button"
                        className="btn btn-danger btn-radius "
                        id="middlecenterToastBtn"
                        onClick={handleRejection}
                    >Reject
                    </button>

                    {transaction?.paymentMethod === "Cash" ? (<button
                        type="button"
                        className="btn btn-danger btn-radius"
                        id="middlecenterToastBtn"

                        onClick={onapprove}
                    >
                        {loader ? (
                            <span className="spinner-border spinner-border-sm mr-1 "></span>
                        ) : null}  Approve
                    </button>) : (<button
                        type="button"
                        className="btn btn-danger btn-radius"
                        id="middlecenterToastBtn"

                        onClick={() => handleApprove(1)}
                    >
                        Approve
                    </button>)}

                </div>


            </div>}

        </div>
    )
}
