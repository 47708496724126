import React, { useState } from "react";
import {
  useGetExpiredAgreementin30DaysQuery,
  useAgreementExpiringIn30DaysCountQuery,
} from "../../../redux/CommonApi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import PropertyCard from "./PropertyCard"; // Import the common card component
import { formatDate } from "../../../formatDate";
import { RequestTypeEnum } from "../../../redux/RequestTypeEnum";
import Nodata from "../Nodata";
import { setAggrementId,setisRenewAgreement } from "../../../redux/Commonslice";
import { useDispatch } from "react-redux";
import CustomLoading from "../CustomLoading";
import { StackPagination } from "../../../shared/StackPagination";

const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background:
      "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#9E2A2B",
    },
    "& .MuiPaginationItem-previousNext:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
}));

const ExpiredAggrementsIn30Days = ({ Cardstatus,setAgreementExpire30Detail }) => {
  const classes = useStyles();
  const dispatch=useDispatch()
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
  const landlordId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState(6);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const onCardClick = (propertyId,isRenewAgreement) => {
    dispatch(setAggrementId(propertyId)); 
    dispatch(setisRenewAgreement(isRenewAgreement)); 
    setAgreementExpire30Detail(propertyId);
  };

  const {
    data: ExpiredAggrementsIn30Days = [],
    error: ExpiredAggrementsIn30Dayserror,
    isLoading: isExpiredAggrementsIn30DaysLoading,
  } = useGetExpiredAgreementin30DaysQuery({
    userID: landlordId,
    pageNumber: pageNumber,
    rowNumber: rowNumbers,
  });

  const {
    data: ExpiredAggrementsIn30DaysCount = 0,
    error: ExpiredAggrementsIn30DaysCounterror,
    isLoading: isExpiredAggrementsIn30DaysCountLoading,
  } = useAgreementExpiringIn30DaysCountQuery({
    userID: landlordId,
  });

  if (
    isExpiredAggrementsIn30DaysLoading ||
    isExpiredAggrementsIn30DaysCountLoading
  )
    return <CustomLoading/>;
  if (ExpiredAggrementsIn30Dayserror || ExpiredAggrementsIn30DaysCounterror)
    return <p>Error fetching properties.</p>;

  return (
    <>
     <div className="   mb-0">
     <div className="card-body pb-5">
    <div className="row">
      {Array.isArray(ExpiredAggrementsIn30Days) &&
      ExpiredAggrementsIn30Days.length > 0 ? (
        ExpiredAggrementsIn30Days.map((item, index) => (
          <PropertyCard
            key={index}
            item={{
              propertyImage: item.propertyImage || fallbackImage,
              userImage: item.userImage || profileimg,
              tenantName: item.tenantName,
              aggEndDate: formatDate(item.aggEndDate),
              buildingType: item.buildingType,
              buildingNumber: item.buildingNumber,
              aggUnitID: item.aggUnitID,
              terms: item.terms,
              isRenewAgreement:item.isRenewAgreement,
              status: "Pending",
              imageString:item.imageString,
              noOfDaysLeft:item.noOfDaysLeft
            }}
            fallbackImage={fallbackImage}
            profileimg={profileimg}
            onCardClick={() => onCardClick(item.aggreementID,item.isRenewAgreement)}
            Cardstatus={Cardstatus}
            activesubIndex={null}
          />
        ))
      ) : (
        <Nodata/>
      )}

      {/* <div className="d-flex justify-content-center pb-4">
        <Stack spacing={2} mt={3}>
          <div
            style={{
              padding: "4px",
              borderRadius: "12px",
              background:
              "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                backgroundColor: "#fff",
                padding: "8px",
              }}
            >
              <Pagination
                classes={{ ul: classes.ul }}
                count={Math.ceil(
                  parseInt(ExpiredAggrementsIn30DaysCount.toString(), 10) /
                    parseInt(rowNumbers.toString(), 10)
                )}
                page={pageNumber}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </Stack>
      </div> */}
    </div>
   </div>
   </div>
   {ExpiredAggrementsIn30DaysCount > 0 && (
<div className="row row1  fixed-bottom1" >
<div className="d-flex justify-content-center">
  <StackPagination tenancyRequestCount={ExpiredAggrementsIn30DaysCount} rowNumbers={rowNumbers}pageNumber={pageNumber} handlePageChange={handlePageChange}/>
  
</div>
</div>
   )}
</>
  );
};

export default ExpiredAggrementsIn30Days;
