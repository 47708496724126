import React from 'react'
import { ExpenseDashboardMaincard } from './ExpenseDashboardMaincard'
import Totalunits from '../pms/MainPmsDashboard/Totalunits'
import ExpenseBuilding from './ExpenseBuilding'

const ExpenseDashboard = () => {
  return (
    <div className="page-wrapper">
               <div className="content">
                   <div>
                    
                       <ExpenseDashboardMaincard />
                     <ExpenseBuilding />
                   </div>
               </div>
           </div>
  )
}

export default ExpenseDashboard