import React, { useEffect, useState } from "react";
import {
  useGetOwnerPropertyMasterQuery,
  useGetTenancyRequestQuery,
  useGetExpiredAgreementQuery,
  useExpiredAgreementCountQuery,
} from "../../../redux/CommonApi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import { RequestTypeEnum } from "../../../redux/RequestTypeEnum";
import { formatDate } from "../../../../src/formatDate";
import Nodata from "../Nodata";
import PropertyCard from "./PropertyCard";
import { useDispatch } from "react-redux";
import { setAggrementId } from "../../../redux/Commonslice";
import { StackPagination } from "../../../shared/StackPagination";
const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background:
      "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#9E2A2B",
    },
    "& .MuiPaginationItem-previousNext:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
}));
const ExpiredAggrements = ({ setSelectedProperty, status, Cardstatus,setExpireDetail }) => {
  const classes = useStyles();
  const landlordId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState<number>(6);
  const dispatch = useDispatch();
  const totalCount = 42;
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const {
    data: expirdAgreements = [],
    error: expirdAgreementerror,
    isLoading: isexpirdAgreementsLoading,
  } = useGetExpiredAgreementQuery({
    pageNumber: pageNumber,
    rowNumber: rowNumbers,
    userID: landlordId,
  });
console.log(expirdAgreements ,'ex');
  const {
    data: expiredAgreementCount = [],
    error: expiredAgreementCountError,
    isLoading: isExpiredAgreementCountLoading,
  } = useExpiredAgreementCountQuery({
    userID: landlordId,
  });
  const handleCardClick = (propertyId, status) => {
    setSelectedProperty({ propertyId, status });
  };
 
    const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
       if (isexpirdAgreementsLoading) return <p>Loading...</p>;
  if (expirdAgreementerror) return <p>Error fetching properties.</p>;
  const onCardClick = (agreementId) => {
    dispatch(setAggrementId(agreementId)); // Dispatch action with agreement ID
    setExpireDetail(agreementId); // Set the agreement doc detail
  };
  return (
    <>
       <div className="mb-0">
    <div className=" pb-5">
      <div className="row">
        <div className="col-12 ">
    <div className="row">
      
      {expirdAgreements.length > 0 ? (
        expirdAgreements.map((item, index) => (
          // <PropertyCard
          //   key={index}
          //   item={item}
          //   fallbackImage={fallbackImage}
          //   profileimg={profileimg}
          //   onCardClick={() => setTenenacyRequestDetail(item)}
          //   Cardstatus={Cardstatus}
          // />
          <PropertyCard
          key={index}
          item={item}
          fallbackImage={fallbackImage}
          profileimg={profileimg}
          onCardClick={() => onCardClick(item.aggreementID
          )}
          Cardstatus={Cardstatus}
          activesubIndex={null}
          />
          
        ))
      ) : (
       <Nodata/>
      )}

      {/* <div className="d-flex justify-content-center pb-4">
        <Stack spacing={2} mt={3}>
          <div
            style={{
              padding: "4px",
              borderRadius: "12px",
              background:
              "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                backgroundColor: "#fff",
                padding: "8px",
              }}
            >
              <Pagination
                classes={{ ul: classes.ul }}
                count={Math.ceil(expiredAgreementCount / rowNumbers)}
                page={pageNumber}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </Stack>
      </div> */}
    </div>
    </div>
    </div>
    </div>
    </div>
{expiredAgreementCount > 0 && (
    <div className="row row1  fixed-bottom1" >
     <div className="d-flex justify-content-center">
       <StackPagination tenancyRequestCount={expiredAgreementCount} rowNumbers={rowNumbers}pageNumber={pageNumber} handlePageChange={handlePageChange}/>
       
     </div>
     </div>
     )}
    </>
  );
};

export default ExpiredAggrements;
