import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ImageWithBasePath from "../ImageWithBasePath";
import Dropdown from "react-bootstrap/Dropdown";
import { all_routes } from "../router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";
import {
  setActivityTogglePopup,
  setExpandMenu,
  setMiniSidebar,
  setMobileSidebar,
  toggleTheme,
} from "../redux/Commonslice";
import { UserProfileImage } from "../Services/UserServices";
import { useGetUserImgQuery, useGetuserSmartNotificationQuery, useLogoutRequestMutation } from "../redux/CommonApi";
import PmsNotification from "../feature-module/pms/NotificationPanel/PmsNotification";
import { json } from "stream/consumers";
import Notificationscroll from "./Notificationscroll";
interface AvatarProps {
  name: string;
  imageUrl: string | null;
  showSuccessDot?: boolean;
}
const Header = ({ userID }) => {
  // const [profileImage , setprofileImage] = useState("");
  const userId = sessionStorage.getItem("userId");
  const { data: notification, isLoading: loader, refetch } = useGetuserSmartNotificationQuery({
    userID: userId,
    requestType: 6,
    pageNumber: 2,
    rowNumber: 20,
  });

  const showSuccessDot = true;
  // console.log("hfhksfhdf",notification)

    const [PUTLogOut] = useLogoutRequestMutation();

  const { data, error, isLoading } = useGetUserImgQuery(userID);
  // console.log("hfhksfh",data)
  // const profileImage = data?.imageString;
  const profileImage= sessionStorage.getItem("profileImage");
  
  const userName = sessionStorage.getItem("userName");
  // console.log("hfhksfh",userName)
  const letterAvatar = userName.charAt(0).toUpperCase();

  const browserID=sessionStorage.getItem("browerUniqueID");

  // console.log(profileImage);
  // const fetchUserImage = async () => {
  //   try {
  //     await getUserImg(landlordId).unwrap();
  //   } catch (err) {
  //     console.error('Failed to fetch user image:', err);
  //   }
  // };

  //   useEffect(() => {
  //     const UserProfile = async () => {

  //         try {

  //             const reqData = await UserProfileImage();
  //             setprofileImage(reqData.imageString);

  //         } catch (error) {
  //             console.error("Error fetching data:", error);

  //         }
  //     };
  //     UserProfile();
  // }, []);
  const route = all_routes;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mobileSidebar = useSelector((state: any) => state.CRMS.mobileSidebar);
  const miniSidebar = useSelector((state: any) => state.CRMS.miniSidebar);
  const expandMenu = useSelector((state: any) => state.CRMS.expandMenu);
  const isDarkTheme = useSelector((state: any) => state?.CRMS.isDarkTheme);
  const ActivityTogglePopup = useSelector(
    (state: any) => state.CRMS.ActivityTogglePopup
  );
  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };
  const toggleMiniSidebar = (e) => {
    e.preventDefault()
    dispatch(setMiniSidebar(!miniSidebar));
  };
  const toggleExpandMenu = () => {
    dispatch(setExpandMenu(!expandMenu));
  };

  const [themeSetting, setThemeSetting] = useState(false);

  const [layoutBs, setLayoutBs] = useState(
    localStorage.getItem("layoutThemeColors")
  );
  const isLockScreen = window.location.pathname === "/lock-screen";

  if (isLockScreen) {
    return null;
  }
  const LayoutDark = () => {
    localStorage.setItem("layoutThemeColors", "dark");
    setThemeSetting(true);
    setLayoutBs("dark");
    dispatch(toggleTheme());
    document.documentElement.setAttribute("data-theme", "dark");
  };
  const LayoutLight = () => {
    localStorage.setItem("layoutThemeColors", "light");
    // setLayoutTheme("light");
    setLayoutBs("light");
    dispatch(toggleTheme());
    setThemeSetting(false);
     const browserID=sessionStorage.getItem("browerUniqueID");
     console.log('browserID',browserID)
    document.documentElement.setAttribute("data-theme", "light");
  };
  const [logo, setlogo] = useState(
    "https://d2og5lryw1ajtt.cloudfront.net/img/mumtalikatilogred.png"
  );
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
  const logout = async () => {
    const updatedData = {
      deviceToken: browserID
    };
   
    // Make the API call to update the active session
    await PUTLogOut({ UserID: userId,updatedData}).unwrap();
    localStorage.clear();
    sessionStorage.clear();
    navigate(route.login);
  };
  const navigatedashboard = () => {
    navigate("admin/dashboard");
  };
  const [isPmsForm, setIsPmsForm] = useState(false);
  const toggleOpen = () => {
    setIsPmsForm(!isPmsForm);
  };
  const togglenotification = () => {
    setIsPmsForm(!isPmsForm);
  };

  const toggleclose = () => {
    setIsPmsForm(false);
  };
  return (
    <>
      {/* Header */}
      <div className="header" onClick={toggleExpandMenu}>
        {/* Logo */}
        <div className="header-left active ">
          <a className="logo logo-normal ">
            {themeSetting ? (
              <img src={logo} width={"140"} className="white-logo" alt="Logo" />
            ) : (
              <img src={logo} width={"140"} alt="Logo" />
            )}
          </a>
          <a className="logo-small">
            <img
              src="https://dmgx6hotgwaax.cloudfront.net/mumtalikati%20logo0021.png"
              alt="Logo"
            />
          </a>
          <Link id="toggle_btn" to="#" onClick={toggleMiniSidebar}>
            <i className="ti ti-arrow-bar-to-left" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={toggleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <ul className="nav user-menu">
            {/* Search */}

            <li className="nav-item nav-search-inputs me-auto">

              {/* <div className="top-nav-search">
                <Link to="#" className="responsive-search">
                  <i className="fa fa-search" />
                </Link>
                <form className="dropdown">
                  <div className="searchinputs" id="dropdownMenuClickable">
                    <input type="text" placeholder="Search" />
                    <div className="search-addon">
                      <button type="submit">
                        <i className="ti ti-command" />
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </li>
            <li className="nav-item nav-search-inputs me-auto">
              {/* <pre>{JSON.stringify(notification, null, 2)}</pre> */}
              <div className="scroll-netification">
                <Notificationscroll userNames={notification?.data} speed={20000} />
              </div>


              {/* <div className="top-nav-search">
         <Link to="#" className="responsive-search">
           <i className="fa fa-search" />
         </Link>
         <form className="dropdown">
           <div className="searchinputs" id="dropdownMenuClickable">
             <input type="text" placeholder="Search" />
             <div className="search-addon">
               <button type="submit">
                 <i className="ti ti-command" />
               </button>
             </div>
           </div>
         </form>
       </div> */}
            </li>
            {/* /Search */}
            {/* Nav List */}
            <li className="nav-item nav-list">

              <ul className="nav">
                <li className="dark-mode-list">
                  <Link
                    to="#"
                    className={`dark-mode-toggle ${themeSetting ? "" : "active"
                      }`}
                    id="dark-mode-toggle"
                  >
                    <i
                      className={`ti ti-sun light-mode ${themeSetting ? "" : "active"
                        }`}
                      onClick={LayoutLight}
                    >
                      {" "}
                    </i>
                    <i
                      className={`ti ti-moon dark-mode ${themeSetting ? "active" : ""
                        }`}
                      onClick={LayoutDark}
                    ></i>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={route.dashboard} className="btn btn-chart-pie">
                    <i className="ti ti-chart-pie" />
                  </Link>
                </li>
              </ul>
            </li>

            {/* /Notifications */}
            <li className="nav-item dropdown nav-item-box">
              <Dropdown className="notification-dropdown ">
                <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-profit nav-link">
                  {/* <Link to="#" className="nav-link" data-bs-toggle="dropdown"> */}
                  <i className="ti ti-bell" />
                  <span className="badge rounded-pill">13</span>
                  {/* </Link> */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <PmsNotification />
                </Dropdown.Menu>
              </Dropdown>
            </li>
            {/* Profile Dropdown */}
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-profit">
              <Box position="relative" display="inline-block">
                <Avatar
                  src={profileImage || undefined} // Use undefined to fall back if imageUrl is null
                  alt={"profite"}
                  style={{
                    backgroundColor: profileImage ? "transparent" : "#3f51b5",
                    color: profileImage ? "inherit" : "#fff",
                  }}
                >
                  {!profileImage && letterAvatar}
                  {/* Fallback to letter if no image */}
                </Avatar>
                {showSuccessDot && (
                  <Box
                    position="absolute"
                    bottom={0}
                    right={0}
                    width={10}
                    height={10}
                    borderRadius="50%"
                    bgcolor="green"
                    border="2px solid white"
                  />
                )}
              </Box>
              {/* <span className="user-info">
                    <span className="user-letter">
                      <img src={profileImage || profileimg} alt="Profile" width={"50px"} height={"40px"} style={{objectFit:"cover"}} />
                    </span>
                    <span className="badge badge-success rounded-pill" />
                  </span> */}

            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="profilename cursor-pointer">
                <div className="dropdown-item" onClick={navigatedashboard}>
                  <i className="ti ti-layout-2" /> Dashboard
                </div>
                <Link className="dropdown-item" to={route.profile}>
                  <i className="ti ti-user-pin" /> My Profile
                </Link>
                <div className="dropdown-item" onClick={logout}>
                  <i className="ti ti-lock" /> Logout
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          {/* <li className="nav-item dropdown has-arrow main-drop">
              <Link
                to="#"
                className="nav-link userset"
                data-bs-toggle="dropdown"
              >
                <span className="user-info">
                  <span className="user-letter">
                    <img src={profileImage || profileimg} alt="Profile" />
                  </span>
                  <span className="badge badge-success rounded-pill" />
                </span>
              </Link>
              <div className="dropdown-menu menu-drop-user">
                <div className="profilename">
                  <Link className="dropdown-item" to={route.dashboard}>
                    <i className="ti ti-layout-2" /> Dashboard
                  </Link>
                  <Link className="dropdown-item" to={route.profile}>
                    <i className="ti ti-user-pin" /> My Profile
                  </Link>
                  <div className="dropdown-item" onClick={logout}>
                    <i className="ti ti-lock" /> Logout
                  </div>
                </div>
              </div>
            </li> */}
          {/* /Profile Dropdown */}
        </ul>
      </div>
      {/* Mobile Menu */}
      <div className=" mobile-user-menu" onClick={logout}>
        <Link to="#" className="nav-link dropdown-toggle">
          <i
            className="fa fa-sign-out"
            data-toggle="tooltip"
            data-placement="right"
            title="logout"
            onClick={logout}
          />
        </Link>
      </div>
      {/* /Mobile Menu */}
    </div >
    </>
  );
};

export default Header;
