export interface ExpenseDashboard {
    currentMonthDate: string;
    yourExpense: number;
    raiseAmount: number;
    raisePercentage: number;
    raisePercentageDisplay: string;
    indicator: string;
  }
  export class ExpenseDashboardModel implements ExpenseDashboard {
    currentMonthDate: string;
    yourExpense: number;
    raiseAmount: number;
    raisePercentage: number;
    raisePercentageDisplay: string;
    indicator: string;
    constructor(data: ExpenseDashboard) {
      this.currentMonthDate = data.currentMonthDate || 'No Data';
      this.yourExpense = data.yourExpense || 0;
      this.raiseAmount = data.raiseAmount || 0;
      this.raisePercentage = data.raisePercentage || 0;
      this.raisePercentageDisplay = data.raisePercentageDisplay || '0%';
      this.indicator = data.indicator || 'pos';
    }
  }
  