import React, { useRef, useEffect, useState } from 'react'
import dayjs from "dayjs";
import { statusEnumMapUrl, unitStatusEnum, unitStatusEnumMap } from '../redux/RequestTypeEnum';
import { useGetAgRentTransactionsDetailQuery, useGetgeneratereceipQuery, usePutRentCollectionMutation } from '../redux/CommonApi';
import { HtmlString } from './HtmlString';
import { TransactionDetail } from './TransactionDetail';
import toastr from "toastr";
import { Link } from 'react-router-dom';
import { Loader } from '../shared/Loader';
import { NoData } from '../shared/NoData';
import html2pdf from 'html2pdf.js';
import { AgreementRentTransactionsDetail } from '../feature-module/Models/RentCollection/AgreementRentTransactionsDetail';
export const RentCollectionTenantCards = ({ data, isloadingRent }) => {
    const modalRef = useRef(null);
    const [isReopenpopup, setReopenpopup] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const handleReopenShow = () => setReopenpopup(false);
    const [isShowbutton, setIsShowbutton] = useState(true)
    const [isRejectionbutton, setIsRejectionbutton] = useState(false)
    const handleReopenClose = () => {
        setReopenpopup(false);
        setIsRejectionbutton(false)
        setIsShowbutton(true)
        setPaymentTypeID(null)
    }
    const [reason, setreason] = useState("");
    const [rentID, setrentID] = useState(null);
    const [status, setstatus] = useState(null);
    const [paymentTypeid, setPaymentTypeID] = useState(null);
    const handleClickOutside = (event) => {
        if (modalRef.current && event.target === modalRef.current) {
            setReopenpopup(false);
        }
    };

    useEffect(() => {
        if (isReopenpopup) {
            window.addEventListener("click", handleClickOutside);
        }

        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [isReopenpopup]);
    const profileimg =
        "https://img.freepik.com/premium-vector/traditional-arabic-man-head-icon-vector-avatar_55610-6301.jpg";
    const handleClick = (status, rentID, paymentTypeID) => {
        setstatus(status)
        if (status === unitStatusEnum.Approved || status === unitStatusEnum.Rejected) {
            setrentID(rentID)
            setReopenpopup(true)

        } else if (status === 8) {
            setrentID(rentID)
            setReopenpopup(true)
        }

    }
    const { data: recept, isLoading, error } = useGetgeneratereceipQuery({
        rentID: rentID || 0
    })
    const { data: agRentTrans, isLoading: loader, error: iserror } = useGetAgRentTransactionsDetailQuery({
        rentID: rentID || 0
    }) as { data: AgreementRentTransactionsDetail, isLoading: boolean, error: any };
    
    const [putRentCollection] = usePutRentCollectionMutation()
    const handleRejection = async () => {
        // setstatus(12)
        setIsRejectionbutton(true)
        setIsShowbutton(false)
        setIsShow(true)
    };

    const handleChange = (e) => {
        const text = e.target.value
        if (text === "") {
            setDescmess(true)
        } else {
            setDescmess(false)
            setreason(text)
        }

    }

    const handleApprove = async (statusId) => {
        debugger
        setstatus(8)
        setPaymentTypeID(agRentTrans?.paymentTypeID)
        if (agRentTrans?.paymentTypeID === 2 || agRentTrans?.paymentTypeID === 3) {
            setIsShowbutton(false)
        }
        setIsShow(true)
    }
    const [descMes, setDescmess] = React.useState(false)
    const handleRej = async () => {
        debugger
        try {
            const formdata = {
                "rentID": rentID,
                "chequeValueDate": null,
                "TransactionDate": null,
                "trxID": null,
                "status": 12,
                "feedback": reason
            }
            if (formdata.feedback === "") {
                setDescmess(true)
            } else {
                await putRentCollection({
                    updatedData: formdata,
                });
                setDescmess(false)
                handleReopenClose();
                setstatus(12)
                setIsShow(false)
                toastr.success('Transaction Rejected');
            }


        } catch (error) {
            toastr.error(error);
            console.error('Failed to update:', error);
        }
    }
    const downloadPdf = () => {
        const element = document.createElement('div');
        element.innerHTML = recept?.htmlSting;

        html2pdf()
            .set({
                margin: 1,
                filename: 'download.pdf',
                html2canvas: {
                    scale: 2, allowTaint: true,
                    useCORS: true,
                },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            })
            .from(element)
            .save();
    };
    if (isloadingRent) return <Loader />;

    return (
        <div className="row gx-2 gy-2">
            {data && data.length > 0 ? (
                data.map((list, index) => {
                    const statusInfo = statusEnumMapUrl[list.rentCollectionStatus] || null;
                    return (
                        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6 all_tenants" key={index}>
                            <div
                                className="card m-0 card-shadow-1 cursor-pointer"
                                onClick={() => handleClick(list.rentCollectionStatus, list.rentID, list.paymentTypeID)}
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center'>
                                            <div className='d-flex flex-column flex-sm-row gap-3  align-items-center'>
                                                <div className=" sm-user">
                                                    <img
                                                        src={profileimg}
                                                        className="no-gap"
                                                        style={{ width: '70px', height: '70px', borderRadius: '50%' }}
                                                        alt="Profile"
                                                    />
                                                </div>

                                                <div className="text-start text-truncate py-2">
                                                    <h5 className="m-0 pb-2 sm-user text-truncate">{list.tenantName}</h5>
                                                    <p className="sm-user">Unit ID: {list.unitID}</p>
                                                </div>
                                            </div>
                                            <div className=" text-center py-2">
                                                <p className="m-0 pb-2">{dayjs(list.dueDate).format('DD MMM YYYY')}</p>
                                                <h5 className="m-0">OMR {list.rentAmount}</h5>
                                            </div>
                                            <div className=" py-2">
                                                {/* <h5 className="text-center m-0 pb-2">Status</h5> */}
                                                <p
                                                    className={`text-center badge badge-pill badge-status ${statusInfo?.text === "Pending" ? "bg-warning" : statusInfo?.text === "Approved" ? "bg-success" : "bg-danger"}`}
                                                // style={{
                                                //     color: statusInfo?.text === "Pending" ? "orange" : statusInfo?.color,
                                                // }}


                                                >
                                                    {statusInfo?.text || 'Unknown Status'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="text-center">
                    <NoData />
                </div>
            )}

            <div

                className={isReopenpopup ? "toggle-popup sidebar-popup" : "toggle-popup"}


            >
                <div className={`sidebar-layout ${status === 8 ? 'w-25' : 'w-25'} w-sm-100`}>
                    <div className="">
                        <div className="sidebar-header">
                            <h6 className="page-title AwaitingApprovalPopup-h6 " style={{ flexGrow: "1" }}>
                                {status === 8 ? "Transaction Detail" : "Rent Receipt"}
                            </h6>

                            {status === 11 && <a type='button' className='sidebar-close ' onClick={downloadPdf} style={{ padding: '10px 20px', fontSize: '16px', color: "#a64041" }}><i className='fa fa-download'></i></a>}
                            {status === 12 && <a className='sidebar-close ' onClick={downloadPdf} style={{ padding: '10px 20px', fontSize: '16px', color: "#a64041" }}> <i className='fa fa-download'></i></a>}

                            <Link
                                to="#"
                                className="sidebar-close toggle-btn"
                                onClick={handleReopenClose}
                            >
                                <i className="ti ti-x" />
                            </Link>
                        </div>

                        {/* <div className="">
                            <button
                                style={{ background: "transparent", transform: "rotate(268deg)", fontSize: 'larger' }}
                                className="sidebar-close toggle-btn text-end bg-none border-0 icon-container"
                                onClick={handleReopenClose}
                            >
                                <i className="ti ti-chevrons-up" />
                            </button>
                            <span className='AwaitingApprovalPopup-text page-title'>{status === 8 ? "Transaction Detail" : "Rent Receipt"}</span>
                        </div> */}

                        {status === 11 || status === 12 ? (
                            <div>
                                {recept?.htmlSting ? (<>
                                    <HtmlString html={recept.htmlSting} isLoading={isLoading}
                                        error={error} handleRej={handleRej} isShow={isShow}
                                        handleChange={handleChange} status={status} paymentTypeid={paymentTypeid}
                                        putRentCollection={putRentCollection}
                                        rentID={rentID} setReopenpopup={setReopenpopup}
                                    />

                                </>

                                ) : (
                                    <div className='text-center'>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    </div>
                                )}
                            </div>
                        ) : status === 8 ? (
                            <div>
                                <TransactionDetail transaction={agRentTrans} handleRejection={handleRejection}
                                    handleApprove={handleApprove} isLoading={isLoading} error={error}
                                    html={recept?.htmlSting} rentID={rentID} setReopenpopup={setReopenpopup}
                                    status={status} paymentTypeid={paymentTypeid} isShowbutton={isShowbutton}
                                    handleChange={handleChange} handleRej={handleRej}
                                    isRejectionbutton={isRejectionbutton}
                                    setIsShowbutton={setIsShowbutton} loading={loader} descMes={descMes} />
                            </div>
                        ) : (
                            <div>

                                <h3>No Status Selected</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
