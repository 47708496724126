
export interface IRentCollectionResponse<T> {
  totalPending: number;
  totalCount: number;
  data: T[];
}
export class RentCollectionResponse<T> implements IRentCollectionResponse<T> {
  totalPending: number;
  totalCount: number;
  data: T[];
  constructor(data:RentCollectionResponse<T>) {
  this.totalPending = data.totalPending ?? 0;
  this.totalCount = data.totalCount ?? 0;
  this.data = data.data ?? [];
  }
}
