export interface IMaintenanceRequestBuildings {
  isBuilding: boolean;
  unitId: number;
  landLordId: number;
  unitName: string | null;
  propertyMasterID: number;
  propertyImageString: string;
  buildingName: string;
  buildingNumber: string;
  }
  export class MaintenanceRequestBuildings implements IMaintenanceRequestBuildings {
    isBuilding: boolean;
    unitId: number;
    landLordId: number;
    unitName: string | null;
    propertyMasterID: number;
    propertyImageString: string;
    buildingName: string;
    buildingNumber: string;
  constructor(data: MaintenanceRequestBuildings){
  this.isBuilding = data.isBuilding ?? false;
  this.unitId = data.unitId ?? 0;
  this.landLordId = data.landLordId ?? 0;
  this.unitName = data.unitName ?? '';
  this.propertyMasterID = data.propertyMasterID ?? 0;
  this.propertyImageString = data.propertyImageString ?? '';
  this.buildingName = data.buildingName ?? '';
  this.buildingNumber = data.buildingNumber ?? '';
  }
  }
