import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import dayjs from "dayjs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { StepIconProps, StepConnector } from '@mui/material';
import { unitStatusEnum } from '../../redux/RequestTypeEnum';
const getStatusName = (status) => {
  const statusNames = Object.keys(unitStatusEnum).find(
    (key) => unitStatusEnum[key] === status
  );
  if (status === unitStatusEnum.PENDING || status === unitStatusEnum.Create ){
    return "Create Token";
  }
  else if (status === unitStatusEnum.Approved){
    return "Accept Token";
  }
  else if (status === unitStatusEnum.Assign){
    return "Assigned Token";
  }
  else if (status === unitStatusEnum.In_Progress){
    return "In-Progress Token";
  }
  else if (status === unitStatusEnum.On_Hold){
    return "On Hold Token";
  }
  else {
  return statusNames ? statusNames.replace("_", " ") : "";
  }
};




const CustomStepIcon: React.FC<StepIconProps> = ({ active, completed }) => {
  return (
    <Box>
      {(
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'white' }} />
      ) }
    </Box>
  );
};


const DottedConnector = () => (
  <StepConnector
    sx={{
     
      '& .MuiStepConnector-line': {
        borderTopWidth: 2,
      
      },
    }}
  />
);

export default function VerticalLinearStepper({tokendetail}) {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<DottedConnector />}>
        {tokendetail.map((item, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Box display="flex" flexDirection="column">
          
              <Typography style={{fontFamily:"Noto Sans",fontSize:"13px"}} variant="body2">{`${getStatusName(item.status)}`}</Typography>
                <Typography style={{fontFamily:"Noto Sans",fontSize:"13px"}} variant="body2"> {`${item.name} (${item.userType})`}</Typography>
                <Typography style={{fontFamily:"Noto Sans",fontSize:"13px"}} variant="body2" color="textSecondary">
                  {dayjs(item.date).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
