export interface IJsonList {
  date: string;
  name: string;
  status: number;
  userTypeId: number;
  isCreatedBy: number;
  userType: string;
}

export class JsonList implements IJsonList {
  date: string;
  name: string;
  status: number;
  userTypeId: number;
  isCreatedBy: number;
  userType: string;

  constructor(data: IJsonList) {
    this.date = data.date;
    this.name = data.name;
    this.status = data.status;
    this.userTypeId = data.userTypeId;
    this.isCreatedBy = data.isCreatedBy;
    this.userType = data.userType;
  }
}