import React from "react";
import "./Skeleton.css"; // CSS for skeleton styles

const SkeletonInput: React.FC = () => {
  return (
    <div className="skeleton-input-container">
      <div className="skeleton skeleton-input"></div>
    </div>
  );
};

export default SkeletonInput;
