
export interface IPropertyMasterTransactions {
  subCategory: string;
  iconUrl: string;
  expenseDate: string;
  expenseAmount: number;
  expenseStatus: number;
  expenseID: number;
}
export class PropertyMasterTransactions implements IPropertyMasterTransactions {
  subCategory: string;
  iconUrl: string;
  expenseDate: string;
  expenseAmount: number;
  expenseStatus: number;
  expenseID: number;
  constructor(data: Partial<IPropertyMasterTransactions> = {}) {
    this.subCategory = data.subCategory ?? "";
    this.iconUrl = data.iconUrl ?? "";
    this.expenseDate = data.expenseDate ?? "";
    this.expenseAmount = data.expenseAmount ?? 0;
    this.expenseStatus = data.expenseStatus ?? 0;
    this.expenseID = data.expenseID ?? 0;
  }
}
