export interface ITransactionUpdate {
  TransactionDate?: string;
  chequeValueDate?: string;
  status: number;
  rentID: number;
  trxID?: number;
}

export class TransactionUpdate implements ITransactionUpdate {
  TransactionDate?: string;
  chequeValueDate?: string;
  status: number;
  rentID: number;
  trxID?: number;

  constructor(data: Partial<ITransactionUpdate>) {
    this.TransactionDate = data.TransactionDate ?? '';
    this.chequeValueDate = data.chequeValueDate ?? '';
    this.status = data.status ?? 0;
    this.rentID = data.rentID ?? 0;
    this.trxID = data.trxID ?? 0;
  }
}
