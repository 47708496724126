import { RecentMaintenanceRequest } from "./RecentMaintenanceRequest";
import { TenantDashboard } from "./TenantDashboard";

export interface IMaintenanceDashboardData {
  tenantDashboard: TenantDashboard[];
  recentMaintenanceRequests: RecentMaintenanceRequest[];
}

export class MaintenanceDashboardData implements IMaintenanceDashboardData {
  tenantDashboard: TenantDashboard[];
  recentMaintenanceRequests: RecentMaintenanceRequest[];

  constructor(data: IMaintenanceDashboardData) {
    this.tenantDashboard = data.tenantDashboard.map(
      (item) => new TenantDashboard(item)
    );
    this.recentMaintenanceRequests = data.recentMaintenanceRequests.map(
      (item) => new RecentMaintenanceRequest(item)
    );
  }
}