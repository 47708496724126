export interface IPropertyMaster {
  propertyMasterID: number;
  name: string;
  buildingNumber: string;
}
export class PropertyMaster implements IPropertyMaster {
  propertyMasterID: number;
  name: string;
  buildingNumber: string;

  constructor(data: PropertyMaster) {
    this.propertyMasterID = data.propertyMasterID ?? 0;
    this.name = data.name ?? "";
    this.buildingNumber = data.buildingNumber ?? "";
  }
}
