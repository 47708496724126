

export interface IMaintenanceRequestMain {
  id: number;
  propertyMasterID: number;
  propertyUnitID: number;
  maintenanceCategoryID: number;
  tenantID: number | null;
  corporateID: number | null;
  lanlordID: number;
  serviceProviderID: number | null;
  priorityId: number;
  description: string;
  status: number;
  isCreatedBy: number;
}
export class MaintenanceRequestMain implements IMaintenanceRequestMain { 
  id: number;
  propertyMasterID: number;
  propertyUnitID: number;
  maintenanceCategoryID: number;
  tenantID: number | null;
  corporateID: number | null;
  lanlordID: number;
  serviceProviderID: number | null;
  priorityId: number;
  description: string;
  status: number;
  isCreatedBy: number;
  constructor(data: Partial<MaintenanceRequestMain> ={} ){
this.id = data.id ?? 0;
this.propertyMasterID = data.propertyMasterID ?? 0;
this.propertyUnitID = data.propertyUnitID ?? 0;
this.maintenanceCategoryID = data.maintenanceCategoryID ?? 0;
this.tenantID = data.tenantID || null;
this.corporateID = data.corporateID || null;
this.lanlordID = data.lanlordID ?? 0;
this.serviceProviderID = data.serviceProviderID || null;
this.priorityId = data.priorityId ?? 0;
this.description = data.description ?? '';
this.status = data.status ?? 0;
this.isCreatedBy = data.isCreatedBy ?? 0;
  }
}

