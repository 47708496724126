import React, { useCallback, useEffect, useState } from "react";
import "./Table.css";

const InputTable = ({ onTotalUnitsChange, onUnitsChange ,setFloorLabels, floorLabels,categoryLabels,categoryCount,floors}) => {


  const [units, setUnits] = useState(
    Array(floors).fill("").map(() => Array(categoryCount).fill(""))
  );

  const handleUnitChange = useCallback((floorIndex, categoryIndex, value) => {
    
    
    
    setUnits((prevUnits) =>
      prevUnits.map((row, i) =>
        i === floorIndex
          ? row.map((cell, j) => (j === categoryIndex ? Number(value) : cell))
          : row
      )
    );
  }, []);

  const handleFloorLabelChange = useCallback((index, value) => {
    setFloorLabels((prevLabels) => {
      const updatedLabels = [...prevLabels];
      updatedLabels[index] = value;
      return updatedLabels;
    });
  }, []);

  useEffect(() => {
    if (onUnitsChange) {
      onUnitsChange(units);
    }
    const totalUnits = units.flat().reduce((sum, count) => sum + Number(count || 0), 0);
    // const totalUnits = units.flat().reduce((sum, count) => sum + count, 0);
    if (onTotalUnitsChange) {
      onTotalUnitsChange(totalUnits);
    }
  }, [units, floorLabels, onUnitsChange, categoryLabels,onTotalUnitsChange]);

  return (
    <div className="table-responsive">
      <table className="styled-table">
        <thead>
          <tr className="headhw ">
            <th className="floor-label rounded-1">Floors</th>
            {Array.from({ length: categoryCount }).map((_, index) => (
              <th className="rounded-1" key={index}>{categoryLabels[index] || `${index + 1}BHK`}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: floors }).map((_, floorIndex) => (
            <tr key={floorIndex}>
              <td className="floor-label rounded-1">
                <input
                  type="text"
                  value={floorLabels[floorIndex]}
                  onChange={(e) =>
                    handleFloorLabelChange(floorIndex, e.target.value)
                  }
                  className="floor-input floor-label"
                  
                />
              </td>
              {Array.from({ length: categoryCount }).map((_, categoryIndex) => (
                <td key={categoryIndex} className="rounded-1">
                  <input
                    type="number"
                    value={units[floorIndex][categoryIndex]}
                   placeholder="0"
                    onChange={(e) =>
                      handleUnitChange(floorIndex, categoryIndex, e.target.value)
                    }
                    onKeyPress={(e) => {
                      if (e.key === '-' || e.key === '+' || e.key === '.') {
                        e.preventDefault(); // Prevents entering +, -, and . characters
                      }
                    }}
                  />
                  
                </td>
              ))}
            </tr>
          ))}

          <tr className="count-row">
            <td className="rounded-1">Count</td>
            {Array.from({ length: categoryCount }).map((_, categoryIndex) => (
              <td className="rounded-1" key={categoryIndex}>
                {units.reduce(
                  (total, floorUnits) =>
                    total + Number(floorUnits[categoryIndex]),
                  0
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};


export default InputTable;
