import React, { useEffect, useState } from 'react'
import { useGetRentCollectionDashboardQuery, useGetsetupRentFrequencyQuery } from '../redux/CommonApi'
import { Dropdown } from 'react-bootstrap';
import { priorityEnum } from '../redux/RequestTypeEnum';
import { Link } from 'react-router-dom';
import { RentDashboard } from '../feature-module/Models/RentCollection/RentDashboard';
import { RentalFrequency } from '../feature-module/Models/RentCollection/RentalFrequency';

export const RentCollectionMaincard = () => {
  const userId = sessionStorage.getItem("userId");
  const [feqId, setFeqID] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState("View All");

  // Fetch rent frequency data
  const { data: rentFeq, isLoading: loading, error: erro } = useGetsetupRentFrequencyQuery() as {data:RentalFrequency[], isLoading:boolean, error:any};

  // Fetch rent collection data, dependent on `feqId`
  const { data: rentCollection, isLoading, error } = useGetRentCollectionDashboardQuery({
    userID: +userId,
    rentalFrequencyID: feqId || 5,
  }) as { 
    data: RentDashboard | null;  
    error: any; 
    isLoading: boolean; 
  };

  const handlePrioritySelectAll = () => {
    setFeqID(null);
    setSelectedPriority("View All");
  };

  const handlePrioritySelect = (id, desc) => {
    setFeqID(id);
    setSelectedPriority(desc);
  };
  return (
    <div className='card'>
      <div className="card-body">
        <div className='row'>
          <div className="col-md-6">
            <h5 className="card-title">Collection of Rent</h5>
          </div>
          <div className="col-md-6 text-end">
            <Link
              to={"/admin/addrent"}
              type="button"
              className="btn btn-danger btn-radius"
              id="middlecenterToastBtn"
            // onClick={() => handleRej()}
            >
              <i className='ti ti-square-rounded-plus'></i> Add Rent
            </Link>
          </div>
        </div>
        <p>Outstanding amount</p>
        <div className='row'>
          <div className="col-md-6">
            <h5 className="card-title">OMR {rentCollection?.totalOutstandingAmount.toFixed(1)}</h5>
          </div>
          <div className="col-md-6 text-end">
           
         <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                style={{ borderRadius: "60px" }}
              >
                {selectedPriority}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  borderRadius: "14px",
                  boxShadow: "-2px 4px 4px 0px #00000026",
                }}
              >
                <div className="profilename">
                  <Dropdown.Item
                    className="dropdown-item"
                    onClick={() => handlePrioritySelectAll()}
                  // style={{
                  //   color: "#147AD6",
                  //   borderBottom: "0.3px solid #5A5E7C66",
                  // }}
                  >
                    All
                  </Dropdown.Item>
                  {rentFeq?.map((feq, index) => (
                    <Dropdown.Item
                      key={index}
                      className="dropdown-item"
                      // to="#"

                      onClick={() => handlePrioritySelect(feq.rentalFrequencyType, feq.desc)}

                    >
                      {feq.desc}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-4 RentCollectionMaincard-sm">
            <div className="rent-colection-card" style={{ background: "#6198EA" }}>
              <div className="">
                <h5 className="card-title">Collected</h5>
                <p>{rentCollection?.rentReceivedPercentage} %</p>

              </div>
              <div className="pt-2">
                <p>OMR {rentCollection?.totalRentReceived.toFixed(1)}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 RentCollectionMaincard-sm">
            <div className="rent-colection-card" style={{ background: "#345C66 " }}>
              <div className="">
                <h5 className="card-title">Pending</h5>
                <p>{rentCollection?.rentPendingPercentage} %</p>

              </div>
              <div className="pt-2">
                <p>OMR {rentCollection?.totalpending.toFixed(1)}</p>
              </div>
            </div>
          </div>
          <Link to={"/admin/rentCollectiontenant"} className="col-md-4">
            <div className="rent-colection-card" style={{ background: "#DF9F3E " }}>
              <div className="">
                <h5 className="card-title">Rent <br /> Transactions</h5>
              </div>
              <div className="pt-2">
                <Link to={"/admin/rentCollectiontenant"} className='border-0 card-shadow-1 px-4' style={{ background: 'transparent' }}>  <i className='fas fa-angle-right text-white'></i> </Link>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
