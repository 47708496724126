export interface IAgreementPropertyUnit {
  propertyUnitId: string;
  unitCategoryID: number;
}
export class AgreementPropertyUnit implements IAgreementPropertyUnit {
  propertyUnitId: string;
  unitCategoryID: number;

  constructor(data: AgreementPropertyUnit) {
   this.propertyUnitId = data.propertyUnitId ?? "";
    this.unitCategoryID = data.unitCategoryID ?? 0;
  }
}
