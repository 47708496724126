import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useGetOwnerDashBoardQuery } from "../../../redux/CommonApi";
import { OwnerDashBoard } from "../../Models/Pms/OwnerDashBoard";

const Occupied = () => {
  const [series, setSeries] = useState([0, 0]);
  const [labels, setLabels] = useState(["Occupied", "Vacant"]);
  const colors = ["#4c72ff", "#ffcda2"];
  const chartRef = useRef(null);
  const { data, error, isLoading } = useGetOwnerDashBoardQuery({
    userID: +sessionStorage.getItem("userId"),
  }) as {data:OwnerDashBoard, error:any, isLoading:boolean};

  useEffect(() => {
    if (data) {
      const occupied = data.propertiesOccupied;
      const vacant = data.propertyListForRent;
      const sortedData = [
        { label: "Occupied", value: occupied },
        { label: "Vacant", value: vacant },
      ].sort((a, b) => b.value - a.value);

      // Set sorted series and labels
      setSeries(sortedData.map((item) => item.value));
      setLabels(sortedData.map((item) => item.label));
    }
  }, [data]);
  const total = series.reduce((acc, value) => acc + value, 0);
  const percentages = series.map((value) =>
    total > 0 ? ((value / total) * 100).toFixed(2) : "0.00"
  );
  const donutChart: ApexOptions = {
    chart: {
      height: 350,
      type: "donut",
      toolbar: {
        show: true,
      },
    },
    series: series,
    labels: labels,
    colors: colors,
    dataLabels: {
      enabled: false,
      formatter: function (val, opts) {
        return `${percentages[opts.seriesIndex]}%`;
      },
    },
    legend: {
      show: true,
      position: "bottom",
      formatter: function (label, opts) {
        return `
          <div class=" ">
           
              <div class=" " >${label}
              <span>(${series[opts.seriesIndex]})</span>
           </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "60%", // Set the width of the donut
          background: "transparent", // Ensure the background of the donut is transparent
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
            },
            value: {
              show: true,
              fontSize: "16px",
              // Change this to your desired color
            },
          },
        },
      },
    },
    stroke: {
      width: 2,
      // colors: ['#fff'],
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 1,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [10, 100],
        colorStops: [
          [
            { offset: 10, color: "#bed9ff", opacity: 1 },
            { offset: 100, color: "#4c72ff", opacity: 1 },
          ],
          [{ offset: 100, color: "#ffcda2", opacity: 1 }],
        ],
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <>
      <div className="card cardreq">
        <div className="card-header d-flex justify-content-between">
          <h6 className="card-title">Total Units {data?.listedProperty}</h6>
          <p className="text-primary">See all Units</p>
        </div>
        <div className="card-body staff-card">
          <div
            id="donut-chart"
            className="text-avatar position-relative donut-chart"
          >
            <ReactApexChart
              ref={chartRef}
              options={donutChart}
              series={series}
              type="donut"
              height={350}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Occupied;
