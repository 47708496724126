export interface IImageUrl {
  status: number;
  url: string;
  uniqueIdentifier: number;
}

export class ImageUrl implements IImageUrl {
  status: number;
  url: string;
  uniqueIdentifier: number;

  constructor(data: IImageUrl) {
    this.status = data.status;
    this.url = data.url;
    this.uniqueIdentifier = data.uniqueIdentifier;
  }
}