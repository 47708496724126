import React, { useState, useEffect } from "react";
import {
  useGetTenancyRequestQuery,
  useTenancyRequestTotalCountQuery,
} from "../../../redux/CommonApi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles"; // Ensure correct import for MUI v4
import Stack from "@mui/material/Stack";
import PropertyCard from "./PropertyCard"; // Import the common card component
import { RequestTypeEnum } from "../../../redux/RequestTypeEnum";
import "../../../style/scss/tenantdashboard.scss";
import Nodata from "../Nodata";
import { useDispatch } from "react-redux";
import { setAggrementId } from "../../../redux/Commonslice";
import CustomLoading from "../CustomLoading";
import { StackPagination } from "../../../shared/StackPagination";
const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background:
      "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#9E2A2B",
    },
    "& .MuiPaginationItem-previousNext:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
}));

const TenancyRequest = ({ setTenenacyRequestDetail, Cardstatus,setReopenpopup }) => {
  const dispatch = useDispatch();
  const classes = useStyles(); // Correct usage of makeStyles hook
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
  const landlordId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState(6);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const {
    data: tenancyRequest = [],
    error: tenancyError,
    isLoading: isTenancyLoading,
  } = useGetTenancyRequestQuery({
    userID: landlordId,
    pageNumber: pageNumber,
    rowNumber: rowNumbers,
    requestTypeID: RequestTypeEnum.TENANCY_REQUEST,
  });
  const {
    data: tenancyRequestCount = 0,
    error: countError,
    isLoading: isCountLoading,
  } = useTenancyRequestTotalCountQuery({
    userID: landlordId,
    requestTypeID: RequestTypeEnum.TENANCY_REQUEST,
  });
  if (isTenancyLoading || isCountLoading) return <CustomLoading/>;
  if (tenancyError || countError) return <p>Error fetching data.</p>;

  const onCardClick = (agreementId) => {
    
    dispatch(setAggrementId(agreementId)); 
    setTenenacyRequestDetail(agreementId); 
    setReopenpopup(true)
  };


  return (
    <>
    <div className="   mb-0">
    <div className="card-body pb-5">
      <div className="row ">
        <div className="col-12 ">
    <div className="row pt-5">
      
      {tenancyRequest.length > 0 ? (
        tenancyRequest.map((item, index) => (
          <PropertyCard
          key={index}
          item={item}
          fallbackImage={fallbackImage}
          profileimg={profileimg}
          onCardClick={() => onCardClick(item.reqAgID)}
          Cardstatus={Cardstatus}
          activesubIndex={null}
          />
          
        ))
      ) : (
      <Nodata/>
      )}

    </div>

   
    </div>
    </div>
    </div>
    </div>
    {tenancyRequestCount > 0 && (
     <div className="row row1  fixed-bottom1" >
     <div className="d-flex justify-content-center">
       <StackPagination tenancyRequestCount={tenancyRequestCount} rowNumbers={rowNumbers}pageNumber={pageNumber} handlePageChange={handlePageChange}/>
       
     </div>
     </div>
     )}
     </>
  );
};

export default TenancyRequest;
