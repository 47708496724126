import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: false,
  activityTogglePopup: false,
  activityTogglePopupTwo: false,
  toggleAddCampaign: false,
  themeSettings: false,
  modalTitle: "",
  addTogglePopupTwo: false,
  headerCollapse: false,
  mobileSidebar: false,
  miniSidebar: false,
  expandMenu: false,
  id: null,
  loading: false,
  notification: null,
  propertyMaster: null,
  propertyMasterFeature: null,
  propertyMasterImages: null,
  propertyMasterUnitFeature: null,
  // propertyMasterUniteImages: null,
  propertyId: null,
  aggrementId: null,
  propertyMasterUniteImages: {},
  tenantImages: null,
  isRenewAgreement: null,
  RentPropertyMasterId: null,
  isDarkTheme: localStorage.getItem('layoutThemeColors') === 'light',
  ReAssignToNewTechnician: null,
  ReAssignToNewTechnicianImages: null,
  ExpensePropertyMasterId: null,
  buidIndex: null
};
const commonSlice = createSlice({
  name: "CRMS",
  initialState,

  reducers: {
    setDarkMode: (state, { payload }) => {
      state.darkMode = payload;
    },
    notificationData: (state, action) => {
      state.notification = [...action.payload];
    },
    setActivityTogglePopup: (state, { payload }) => {
      state.activityTogglePopup = payload;
    },
    setAddTogglePopupTwo: (state, { payload }) => {
      state.addTogglePopupTwo = payload;
    },
    setActivityTogglePopupTwo: (state, { payload }) => {
      state.activityTogglePopupTwo = payload;
      state.id = payload.id;
    },
    setToggleAddCampaign: (state, { payload }) => {
      state.toggleAddCampaign = payload;
    },
    setThemeSettings: (state, action) => {
      state.themeSettings = action.payload;
    },
    setModalTitle: (state, { payload }) => {
      state.modalTitle = payload;
    },
    setHeaderCollapse: (state, action) => {
      state.headerCollapse = action.payload;
    },
    setMobileSidebar: (state, action) => {
      state.mobileSidebar = action.payload;
    },
    setMiniSidebar: (state, action) => {
      state.miniSidebar = action.payload;
    },
    setExpandMenu: (state, action) => {
      state.expandMenu = action.payload;
    },
    setPropertyMaster: (state, action) => {
      state.propertyMaster = action.payload;
    },
    setPropertyMasterFeature: (state, action) => {
      state.propertyMasterFeature = action.payload;
    },
    // setPropertyMasterUnitFeature: (state, action) => {
    //   if (action.payload === null) {
    //     state.propertyMasterUnitFeature = null;
    //     return;
    //   }
    //   const { unitCategoryID, features } = action.payload;

    //   // Ensure propertyMasterUnitFeature is initialized as an object
    //   if (!state.propertyMasterUnitFeature) {
    //     state.propertyMasterUnitFeature = {};
    //   }

    //   // Ensure the specific unitCategoryID is initialized
    //   if (!state.propertyMasterUnitFeature[unitCategoryID]) {
    //     state.propertyMasterUnitFeature[unitCategoryID] = [];
    //   }

    //   // Update the features for the given unitCategoryID
    //   state.propertyMasterUnitFeature[unitCategoryID] = features;
    // },
    setPropertyMasterUnitFeature: (state, action) => {
      if (action.payload === null) {
        state.propertyMasterUnitFeature = null;
        return;
      }
      const {selectedFeaturesByIndex, unitId, features } = action.payload;

      // Ensure propertyMasterUnitFeature is initialized as an object
      if (!state.propertyMasterUnitFeature) {
        state.propertyMasterUnitFeature = {};
      }

      // Ensure the specific unitCategoryID is initialized
      if (!state.propertyMasterUnitFeature[unitId]) {
        state.propertyMasterUnitFeature[selectedFeaturesByIndex] = [];
      }

      // Update the features for the given unitCategoryID
      state.propertyMasterUnitFeature[unitId] = features;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setBuidIndex: (state, action) => {
      state.buidIndex = action.payload;
    },
    setAggrementId: (state, action) => {
      state.aggrementId = action.payload;
    },
    setisRenewAgreement: (state, action) => {
      state.isRenewAgreement = action.payload;
    },
    setPropertyMasterImages: (state, action) => {
      state.propertyMasterImages = action.payload;
    },
    setTenantImages: (state, action) => {
      state.tenantImages = action.payload;
    },
    setRentPropertyMasterId: (state, action) => {
      state.RentPropertyMasterId = action.payload;
    },

    // setPropertyMasterUniteImages: (state, action) => {
    //   state.propertyMasterUniteImages = action.payload;
    // },
    setPropertyMasterUniteImages: (state, action) => {

      if (action.payload === null) {
        state.propertyMasterUniteImages = null;
        return;

      }
      const {index, UnitcategoryId, images } = action.payload;
      debugger
      if (!state.propertyMasterUniteImages) {
        state.propertyMasterUniteImages = {};  // Initialize as an empty object if it's undefined or null
      }
      if (!state.propertyMasterUniteImages[UnitcategoryId]) {
        state.propertyMasterUniteImages[index] = [];
      }
      state.propertyMasterUniteImages[index] = images;
    },
    removeImage: (state, action) => {
      const { UnitcategoryId, imageIndex } = action.payload;

      if (state.propertyMasterUniteImages[UnitcategoryId]) {
        state.propertyMasterUniteImages[UnitcategoryId].splice(imageIndex, 1);
      }
    },
    removeImagesByCategoryId: (state, action) => {
      if (!action.payload || action.payload.categoryId === null || action.payload.categoryId === undefined) {
        state.propertyMasterUniteImages = null;
        return;
      }

      const { categoryId } = action.payload;
      if (categoryId in state.propertyMasterUniteImages) {
        delete state.propertyMasterUniteImages[categoryId];
      }
    },
    // removeImagesByCategoryId: (state, action) => {
    //   if(action.payload === null){
    //     state.propertyMasterUniteImages = null; 
    //     return;

    //   }

    //   const { categoryId } = action.payload;
    //   delete state.propertyMasterUniteImages[categoryId];
    // },
    startLoading: (state) => {
      state.loading = true;
    },
    Loading: (state) => {
      state.loading = false;
    },
    toggleTheme: (state) => {
      state.isDarkTheme = !state.isDarkTheme;
      const newTheme = state.isDarkTheme ? 'light' : 'dark';
      localStorage.setItem('layoutThemeColors', newTheme);
    },
    setReAssignToNewTechnician: (state, action) => {
      state.ReAssignToNewTechnician = action.payload;
    },
    setReAssignToNewTechnicianImages: (state, action) => {
      state.ReAssignToNewTechnicianImages = action.payload;
    },
    setExpensePropertyMasterId: (state, action) => {
      state.ExpensePropertyMasterId = action.payload;
    }
  },
});


export const { setDarkMode, setToggleAddCampaign, setAddTogglePopupTwo, setPropertyMaster, setPropertyMasterFeature, setPropertyMasterImages, setPropertyMasterUnitFeature, setPropertyMasterUniteImages, removeImage, removeImagesByCategoryId, setPropertyId, setBuidIndex,setAggrementId, setisRenewAgreement, setTenantImages, setRentPropertyMasterId, toggleTheme, setReAssignToNewTechnician, setReAssignToNewTechnicianImages, setExpensePropertyMasterId } =

  commonSlice.actions;
export const {
  setActivityTogglePopup,
  setHeaderCollapse,
  setMobileSidebar,
  setMiniSidebar,
  notificationData,
} = commonSlice.actions;
export const { startLoading } = commonSlice.actions;
export const { Loading } = commonSlice.actions;
export const { setActivityTogglePopupTwo } = commonSlice.actions;
export const { setThemeSettings } = commonSlice.actions;
export const { setExpandMenu } = commonSlice.actions;
export const { setModalTitle } = commonSlice.actions;
export default commonSlice.reducer;
export const setToogleHeader = (payload: any) => ({
  type: "toggle_header",
  payload,
});
