import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import frame from '../../../../public/assets/img/Frame.png'
import CollapseHeader from "../../../core/common/collapse-header";
import { all_routes } from "../../../router/all_routes";
import { useAgreementAttachmentQuery, useCurrentAgreementDetailQuery, useGetAgreementExpensebyAgIDQuery, useGetAuthenticateUserMutation, useGetChequesByAgreementIDAndChequeTypeIDQuery, useGetTenancyRequestDetailQuery, useGetUtilitiesbyAgQuery, usePDFViewQuery, useRequestAggreementAttachmentQuery, useUpdateRequestAgreementMutation } from "../../../redux/CommonApi";
import { ChequeTypeEnum, unitStatusEnum } from "../../../redux/RequestTypeEnum";
import { Button, Toast } from 'react-bootstrap';
import terminateagg from '../../../../public/assets/img/Tenanticon/terminateaggrement.svg'
import toastr from "toastr";
import dayjs from 'dayjs';
import plug from '../../../../public/assets/img/Tenanticon/plug.png'
import warningimg from '../../../../public/assets/img/Tenanticon/warningimg.svg'
import CustomLoading from "../CustomLoading";
import { NoData } from "../../../shared/NoData";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../shared/Loader";
import { setAggrementId } from "../../../redux/Commonslice";

const route = all_routes;

const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-OM", {
        style: "currency",
        currency: "OMR",
        minimumFractionDigits: 1, // Ensures one decimal place
        maximumFractionDigits: 1, // Ensures no extra decimals
    });
    return formatter.format(value);
};

const AgreementDocument = ({ AgreementDocDetail, Aggrementoption, setgoback, setRentHistory, setAgreementDocDetail, setVerifyTerminate, Cardstatus }) => {
    const [showAttachments, setShowAttachments] = useState(false)
    const [showimag, setShowimag] = useState(false)
    const agreementId = useSelector((state: any) => state.CRMS.aggrementId) ?? null;
    const dispatch = useDispatch();
    const {
        data: tenancyRequestDetail,
        error,
        isLoading: CurrentAgreementLoading,
    } = useCurrentAgreementDetailQuery({
        requestAgID: AgreementDocDetail,
        option: Aggrementoption,// Dynamically set option based on statusMapping
    });
 

    const {
        data: Expense,
    } = useGetAgreementExpensebyAgIDQuery({
        requestAgID: AgreementDocDetail,
    });
    const {
        data: pdf,
    } = usePDFViewQuery({
        requestAgID: AgreementDocDetail,
    });
    const {
        data: AggAttachemnt,
    } = useRequestAggreementAttachmentQuery({
        requestAgID: AgreementDocDetail,
    });
    const {
        data: attachments,
    } = useAgreementAttachmentQuery({
        requestAgID: AgreementDocDetail,
    });
   
    const {
        data: cheque,
    } = useGetChequesByAgreementIDAndChequeTypeIDQuery({
        AgID: AgreementDocDetail,
        cheqId: ChequeTypeEnum.Rental,
    });
    const {
        data: utilities,
    } = useGetUtilitiesbyAgQuery({
        AgID: AgreementDocDetail,
    });
    // if (CurrentAgreementLoading) return <CustomLoading />;
    const handleDownloadAll = () => {
        
        if (attachments && attachments.length > 0) {
            attachments.forEach((attachment, index) => {
                if (attachment?.attachmentString) {
                    const link = document.createElement("a");
                    link.href = attachment.attachmentString; // Use the imgUrl from the API response
                    document.body.appendChild(link);
                    link.click();
                    link.target = "_blank";
                    document.body.removeChild(link);
                }
            });
        } else {
            toastr.error("No attachments found");
        }
    };


    const handleDownload = () => {
        if (pdf?.imgUrl) {
            const link = document.createElement("a");
            link.href = pdf.imgUrl; // Use the imgUrl from the API response
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            toastr.error("No PDF found");
        }
    };
    const [showMiddleCenterToast, setShowMiddleCenterToast] = useState(false);
    const [shownegotiationToast, setShowNegotiationToast] = useState(false);
    const [showApproveCenterToast, setShowApproveCenterToast] = useState(false);
    const [showRejectToast, setShowRejectToast] = useState(false);
    const handleMiddleCenterButtonClick = () => {
        setShowMiddleCenterToast(true);
    };

    const handleMiddleCenterToastClose = () => {
        setShowMiddleCenterToast(false);
    };
    const handleApproveCenterButtonClick = () => {
        setShowApproveCenterToast(true);
    };

    const handleApproveCenterToastClose = () => {
        setShowApproveCenterToast(false);
    };
   
    const handleNegotiationClick = () => {
        setShowRejectToast(false);
        setShowNegotiationToast(true);
        dispatch(setAggrementId(tenancyRequestDetail?.aggID)) 
        setApproveReq((prevState) => ({
            ...prevState,
            feedback: "", // Clear feedback
        }));
    };

    const handleNegotiationClose = () => {
        setShowNegotiationToast(false);
    };
    const handleRejection = () => {
        setShowNegotiationToast(false);
        setShowRejectToast(true);
        dispatch(setAggrementId(tenancyRequestDetail?.aggID)) 
        setApproveReq((prevState) => ({
            ...prevState,
            feedback: "", // Clear feedback
        }));
    };

    const handleRejectionClose = () => {
        setShowRejectToast(false);
    };
    const handleBackClick = () => {
        setgoback(false);
        setAgreementDocDetail(null);
    };
    const handleRentHistory = () => {
        setAgreementDocDetail(null);
        setRentHistory(1); // Activate the RentHistory component
    };

    const handleTerminate = () => {
        setAgreementDocDetail(null);
        setVerifyTerminate(1);
        // Activate the RentHistory component
    };
    const [updateRequestAgreement] = useUpdateRequestAgreementMutation();
    const [approveReq, setApproveReq] = useState({
        reqAgID: tenancyRequestDetail?.aggID,
        requestTypeID: tenancyRequestDetail?.requestTypeID,
        isApprovedByTenant: true,
        isApprovedByLandLord: true,
        feedback: "",
        proposedRent:null,
        status: 0
    });
    const handleChange = (e) => {
        const { name, value } = e.target;

        setApproveReq((prevValues) => ({
            ...prevValues,
            [name]: name === 'proposedRent' ? Number(value) : value,  // Convert proposedRent to a number
        }));
    };

    const handleApprove = async (statusId) => {
        dispatch(setAggrementId(tenancyRequestDetail?.aggID)) 
        let updatedData;
        let fullFeedback = '';
        if (tenancyRequestDetail.feedback) {
            fullFeedback = `${tenancyRequestDetail.feedback}, Landlord: ${approveReq.feedback}`;
        } else {
            fullFeedback = `Landlord: ${approveReq.feedback}`;
        }
        if (statusId === 1) {
            updatedData = {
                ...approveReq,
                reqAgID: tenancyRequestDetail?.aggID,
                requestTypeID: 2,
                proposedRent: tenancyRequestDetail.proposeRent,
                isApprovedByTenant: true,
                isApprovedByLandLord: true,
                status: unitStatusEnum.Approved,
            };
        } else if (statusId === 2) {
            
            updatedData = {
                ...approveReq,
                reqAgID: agreementId,
                requestTypeID: 2,
                proposedRent: tenancyRequestDetail.proposeRent,
                isApprovedByTenant: tenancyRequestDetail.isApprovedByTenant,
                isApprovedByLandLord: false,
                status: unitStatusEnum.Rejected,
                feedback: fullFeedback,
            };
        } else if (statusId === 3) {
            updatedData = {
                ...approveReq,
                reqAgID: agreementId,
                requestTypeID: 2,
                isApprovedByTenant: false,
                isApprovedByLandLord: true,
                status: tenancyRequestDetail.status,
                feedback: fullFeedback,
            };
        }

        // Update the approveReq state
        setApproveReq(updatedData);

        try {
            
            // Send the updated data to the API via the PUT call
            const result = await updateRequestAgreement({
                Agid: AgreementDocDetail,
                updatedData,
            }).unwrap();
            if (statusId === 1) {
                toastr.success("Approval request sent successfully");
                window.location.reload();
            }
            else if (statusId === 2) {
                
                toastr.success("Rejection request sent successfully");
                window.location.reload();
            }
            else if (statusId === 3) {
                toastr.success("Request Approved with condition sent successfully");
                window.location.reload();
            }
            // window.location.reload(); 
        } catch (error) {
            toastr.error("Failed to send approval request", error);
            return; // Stop execution if there's an error
        }
    };
    useEffect(() => {
        // Scroll to the top of the page when this component is rendered
        window.scrollTo(0, 0);
    }, []);
    const openImage = () => {
        
        setShowimag(true)
    }
    return (
        <>
            <div className="d-flex gap-2 align-items-center gap mb-3 agreementdoc">
                <i
                    className="ti ti-arrow-narrow-left"
                    onClick={handleBackClick}
                    style={{
                        background: '#7E7E7E',
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        fontSize: "30px"
                    }}

                />

                <h4 className="py-2 page-title">Agreement Documents</h4>
            </div>
            {tenancyRequestDetail ? (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card  agreementdoc">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="tenantbold page-title">Agreement Details</td>
                                                    {Cardstatus === 2 && (
                                                        <td className="page-title" style={{
                                                            background:
                                                                "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                                                            WebkitBackgroundClip: "text",
                                                            WebkitTextFillColor: "transparent",
                                                            backgroundClip: "text",
                                                        }}
                                                            onClick={handleRentHistory}>Rent History
                                                            <i
                                                                className="ti ti-chevron-right page-title"
                                                                style={{
                                                                    background:
                                                                        "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                                                                    WebkitBackgroundClip: "text",
                                                                    WebkitTextFillColor: "transparent",
                                                                    backgroundClip: "text",
                                                                    fontSize: "15px"
                                                                }}
                                                            />
                                                        </td>
                                                    )}
                                                </tr>
                                                {tenancyRequestDetail?.addressStr && (
                                                    <tr>

                                                        <td className="tenantbold page-title"> <i
                                                            className="ti ti-map-pin-filled page-title"
                                                            style={{
                                                                background:
                                                                    "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
                                                                WebkitBackgroundClip: "text",
                                                                WebkitTextFillColor: "transparent",
                                                                backgroundClip: "text",
                                                            }}
                                                        />{" "}{tenancyRequestDetail.addressStr}</td>
                                                        <td></td>
                                                    </tr>
                                                )}

                                                {tenancyRequestDetail?.buildingType && (
                                                    <tr>
                                                        <td className="page-title">Type</td>
                                                        <td className="page-title">{tenancyRequestDetail.buildingType}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.buildingNumber && (
                                                    <tr>
                                                        <td className="page-title">Building ID</td>
                                                        <td className="page-title">{tenancyRequestDetail.buildingNumber}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.propertyUnitStatusName && (
                                                    <tr>
                                                        <td className="page-title">Occupancy Status</td>
                                                        <td className="page-title">{tenancyRequestDetail.propertyUnitStatusName}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.aggUnitID && (
                                                    <tr>
                                                        <td className="page-title">Unit ID</td>
                                                        <td className="page-title">{tenancyRequestDetail.aggUnitID}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.properyUnitName && (
                                                    <tr>
                                                        <td className="page-title">Unit Category</td>
                                                        <td className="page-title">{tenancyRequestDetail.properyUnitName}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.floorNumber && (
                                                    <tr>
                                                        <td className="page-title">Floor #</td>
                                                        <td className="page-title">{tenancyRequestDetail.floorNumber}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.rentAmount && (
                                                    <tr>
                                                        <td className="page-title">Rent</td>
                                                        <td className="page-title">{formatCurrency(tenancyRequestDetail.rentAmount)}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.rentFrequency && (
                                                    <tr>
                                                        <td className="page-title">Rent Frequency</td>
                                                        <td className="page-title">{tenancyRequestDetail.rentFrequency}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.rentMode && (
                                                    <tr>
                                                        <td className="page-title">Rent Mode</td>
                                                        <td className="page-title">{tenancyRequestDetail.rentMode}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.terms && (
                                                    <tr>
                                                        <td className="page-title">Terms</td>
                                                        <td className="page-title">{tenancyRequestDetail.terms}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.aggStartDate && (
                                                    <tr>
                                                        <td className="page-title">Agreement Start Date</td>
                                                        <td className="page-title">{dayjs(tenancyRequestDetail.aggStartDate).format('DD-MM-YYYY')}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.aggEndDate && (
                                                    <tr>
                                                        <td className="page-title">Agreement End Date</td>
                                                        <td className="page-title">{dayjs(tenancyRequestDetail.aggEndDate).format('DD-MM-YYYY')}</td>
                                                    </tr>
                                                )}
                                                {tenancyRequestDetail?.advancedDeposit != null ? (
                                                    <tr>
                                                        <td className="page-title">Security Deposit</td>
                                                        <td className="page-title">{formatCurrency(tenancyRequestDetail?.advancedDeposit)}</td>
                                                    </tr>
                                                ) : (
                                                    <></>
                                                )}
                                                {cheque?.length > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="tenantbold py-2 page-title">Advance Rental cheque</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="page-title">No.</td>
                                                            <td className="page-title">Bank Name</td>
                                                            <td className="page-title">Cheque No</td>
                                                            <td className="page-title">Amount</td>
                                                            <td className="page-title">Due Date</td> {/* Format the date */}
                                                        </tr>

                                                        {cheque?.map((cheque, index) => (
                                                            <tr key={cheque.id} className="thead-light">
                                                                <td className="page-title">{index + 1}</td>
                                                                <td className="page-title">{cheque.bank?.desc}</td>
                                                                <td className="page-title">{cheque.chequeNumber}</td>
                                                                <td className="page-title">{cheque.amount}</td>
                                                                <td className="page-title">{new Date(cheque.dueDate).toLocaleDateString()}</td> {/* Format the date */}
                                                            </tr>
                                                        ))}

                                                    </>
                                                )}

                                                {Expense?.length > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="tenantbold py-2 page-tile">Bills will pay</td>
                                                            <td></td>
                                                        </tr>
                                                        {Expense.map((expense) => (
                                                            <tr key={expense.id}>
                                                                <td className="page-title">{expense.expenseType?.desc}</td>
                                                                <td className="page-title">{expense.userType?.desc}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                                {utilities?.length > 0 && (
                                                    <>
                                                        <tr>
                                                            <td className="tenantbold py-2 page-title">Utilities bills details</td>
                                                            <td>{''}</td>
                                                        </tr>
                                                        {utilities.map((utilities) => (
                                                            <>
                                                                <tr key={utilities.utilityTypeID}>
                                                                    <td className="d-flex gap-2 align-items-center page-title">
                                                                        <img
                                                                            src={plug}
                                                                            className="no-gap"
                                                                            alt="Profile"
                                                                            style={{ height: "2rem" }}
                                                                        />
                                                                        {utilities.utilityType?.desc}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td className="page-title">Meter ID</td>
                                                                    <td className="page-title">{utilities.meterID}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="page-title">Meter Reading</td>
                                                                    <td className="page-title">{utilities.meterReading}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="page-title">Meter Reading Date</td>
                                                                    <td className="page-title">{dayjs(utilities.meterReadingDate).format('DD-MM-YYYY')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="page-title">Outstanding Amount</td>
                                                                    <td className="page-title">{formatCurrency(utilities.outstandingAmount)}</td>
                                                                </tr>

                                                            </>
                                                        ))}
                                                    </>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card  agreementdoc coporatedetail">
                                <div className="card-body">

                                    <div className="img-tag  d-flex align-items-center gap-2 pb-4">
                                        <img
                                            src={tenancyRequestDetail?.userImage || profileimg}
                                            className="no-gap"
                                            alt="Profile"
                                            style={{ height: "5rem" }}
                                        />
                                        <p className="page-title">
                                            Tenant
                                            <br />
                                            <span className="page-title">{tenancyRequestDetail?.tenantName}</span>
                                        </p>
                                    </div>
                                    {tenancyRequestDetail?.corporateDetails?.length > 0 && (
                                        <>
                                            <div className=" pb-2 tenantbold page-title">Agreement Documents</div>
                                            <div className=" pb-2 tenantbold page-title">Corporate & Representative Details</div>

                                            <div className="table-responsive">
                                                <table className="table table-borderless mb-5">
                                                    <tbody>
                                                        {tenancyRequestDetail?.corporateDetails?.companyName && (
                                                            <tr>
                                                                <td className="page-title">Company Name</td>
                                                                <td className="page-title">{tenancyRequestDetail.corporateDetails.companyName}</td>
                                                            </tr>
                                                        )}
                                                        {tenancyRequestDetail?.corporateID && (
                                                            <tr>
                                                                <td className="page-title">Corporate ID</td>
                                                                <td className="page-title">{tenancyRequestDetail.corporateID}</td>
                                                            </tr>
                                                        )}
                                                        {tenancyRequestDetail?.representativeName && (
                                                            <tr>
                                                                <td className="page-title">Representative Name</td>
                                                                <td className="page-title">{tenancyRequestDetail.representativeName}</td>
                                                            </tr>
                                                        )}
                                                        {tenancyRequestDetail?.representativeCell && (
                                                            <tr>
                                                                <td className="page-title">Representative Cell</td>
                                                                <td className="page-title">{tenancyRequestDetail.representativeCell}</td>
                                                            </tr>
                                                        )}
                                                        {tenancyRequestDetail?.authorizedSignatoryID && (
                                                            <tr>
                                                                <td className="page-title">Authorized Signatory ID</td>
                                                                <td className="page-title">{tenancyRequestDetail.authorizedSignatoryID}</td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}
                                    {tenancyRequestDetail?.status === unitStatusEnum.TERMINATE || tenancyRequestDetail?.status === unitStatusEnum.PENDING ? (
                                        <>
                                            <div className="card  agreementdoc downloadcard">
                                                <div className="card-body">
                                                    <div className="img-tag  d-flex  gap-2 pb-2">
                                                        <img
                                                            src={frame}
                                                            className="no-gap img-fluid"
                                                            alt="Profile"
                                                            style={{ height: "2rem" }}
                                                        />
                                                        <div className="ps-3 page-title">
                                                            View Attachment
                                                            <p className="page-title">Check soft copy Of Agreement</p>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btndangerbackground page-title"
                                                                onClick={
                                                                    tenancyRequestDetail?.status === unitStatusEnum.TERMINATE
                                                                        ? openImage
                                                                        : () => setShowAttachments(!showAttachments)
                                                                }
                                                            >
                                                                View
                                                            </button>

                                                            {showAttachments && (
                                                                <div className="d-flex gap-4 mt-2 flex-wrap">
                                                                    {AggAttachemnt?.length > 0 ? (
                                                                        AggAttachemnt.map((attachment) => (
                                                                            <div key={attachment.id} className="img-tag">
                                                                                <a href={attachment?.attachmentString} target="_blank" rel="noopener noreferrer">
                                                                                    <img
                                                                                        src={attachment.attachmentString}
                                                                                        alt={attachment.fileName}
                                                                                        style={{
                                                                                            width: '235px',
                                                                                            borderRadius: '10px',
                                                                                            objectFit: 'cover',
                                                                                            aspectRatio: '1/2',
                                                                                        }}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <p className="page-title text-danger">No Attachments Available</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    {
                                                        showimag && (
                                                            <div>{
                                                                attachments?.map((img, index) => (
                                                                    <a key={index} href={img?.attachmentString} target="_blank" rel="">{img?.attachmentString}</a>

                                                                ))}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {Cardstatus == 6 && (
                                                <div className="pb-4">
                                                    <div className="  d-flex align-items-center gap-2 pb-2">

                                                        <img
                                                            src={terminateagg}
                                                            className="no-gap no-terment"
                                                            alt="Profile"
                                                            style={{ height: "2rem" }}
                                                        />
                                                        <h5 style={{
                                                            background:
                                                                "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
                                                            WebkitBackgroundClip: "text",
                                                            WebkitTextFillColor: "transparent",
                                                            backgroundClip: "text",
                                                            fontSize: "15px",
                                                        }} className="page-title">Agreement has been teminated  </h5>
                                                    </div>
                                                </div>
                                            )}

                                        </>

                                    ) : (
                                        <>                        <div className="card  agreementdoc downloadcard">
                                            <div className="card-body">
                                                <div className="img-tag  d-flex  gap-2 pb-2">
                                                    <img
                                                        src={frame}
                                                        className="no-gap"
                                                        alt="Profile"
                                                        style={{ height: "5rem" }}
                                                    />
                                                    <div className="ps-3 page-title">
                                                        Download Attachment
                                                        <p className="page-title">Check soft copy Of Agreement</p>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btndangerbackground page-title"
                                                            onClick={handleDownloadAll}
                                                        >
                                                            Download
                                                        </button>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                            {/* <div className="justify-content-end d-flex agreementdoc gap-2 pb-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btndangerbackground"
                                                    id="middlecenterToastBtn"
                                                    onClick={handleMiddleCenterButtonClick}
                                                >
                                                    Terminate agreement
                                                </button>

                                                <button
                                                    type="button"
                                                    className="btn  addfeature-btn"
                                                    onClick={handleDownload}
                                                >
                                                    Download agreement
                                                </button>
                                            </div> */}
                                        </>
                                    )}
                                    {Cardstatus == 2 && (
                                        <div className="justify-content-end d-flex agreementdoc gap-2 pb-3">
                                            <button
                                                type="button"
                                                className="btn btn-danger btndangerbackground page-title"
                                                id="middlecenterToastBtn"
                                                onClick={handleMiddleCenterButtonClick}
                                            >
                                                Terminate agreement
                                            </button>

                                            <button
                                                type="button"
                                                className="btn  addfeature-btn page-title"
                                                onClick={handleDownload}
                                            >
                                                Download agreement
                                            </button>
                                        </div>
                                    )}
                                    {tenancyRequestDetail?.isApprovedByTenant === true && tenancyRequestDetail?.isApprovedByLandLord === false && (
                                        <div className="justify-content-end d-flex agreementdoc gap-2 pb-3">
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-radius page-title"
                                                id="middlecenterToastBtn"
                                                onClick={handleRejection}
                                            >REJECT
                                            </button>

                                            <button
                                                type="button"
                                                className="btn  addfeature-btn page-title"
                                                onClick={handleNegotiationClick}
                                            >
                                                APPROVE WITH CONDITION
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-radius page-title"
                                                id="middlecenterToastBtn"
                                                onClick={() => handleApprove(1)}
                                            >
                                                APPROVE
                                            </button>
                                        </div>
                                    )

                                    }
                                    {/* {tenancyRequestDetail?.isApprovedByLandLord === false && tenancyRequestDetail?.isApprovedByTenant === true ? (
                                        <div className="justify-content-end d-flex agreementdoc gap-2 pb-3">
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-radius "
                                                id="middlecenterToastBtn"
                                                onClick={handleRejection}
                                            >REJECT
                                            </button>

                                            <button
                                                type="button"
                                                className="btn  addfeature-btn"
                                                onClick={handleNegotiationClick}
                                            >
                                                APPROVE WITH CONDITION
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-radius"
                                                id="middlecenterToastBtn"
                                                onClick={() => handleApprove(1)}
                                            >
                                                APPROVE
                                            </button>
                                        </div>
                                    ) :
                                        (
                                            <div className="justify-content-end d-flex agreementdoc gap-2 pb-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btndangerbackground"
                                                    id="middlecenterToastBtn"
                                                    onClick={handleMiddleCenterButtonClick}
                                                >
                                                    Terminate agreement
                                                </button>

                                                <button
                                                    type="button"
                                                    className="btn  addfeature-btn"
                                                    onClick={handleDownload}
                                                >
                                                    Download agreement
                                                </button>
                                            </div>
                                        )} */}
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="toast-container position-fixed top-50 start-50 translate-middle">
                        <Toast
                            show={shownegotiationToast}
                            onClose={handleNegotiationClose}
                            id="middlecenter-Toast"
                            className="colored-toast bg-white terminateToast negotiate-popup"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div className="d-flex align-items-center py-4 justify-content-between" >
                                <h5 className="page-title">Negotiate</h5>
                                <div className="img-tag d-flex align-items-center gap-2 pb-4">
                                    <i className="ti ti-x page-title" style={{
                                        background: '#A64041',
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        fontSize: "30px",
                                    }}
                                        onClick={handleNegotiationClose} />
                                </div>
                            </div>
                            {/* <Toast.Body><p>Are you sure you want to terminate agreement against building ID ({tenancyRequestDetail.buildingNumber}) and unit ID ({tenancyRequestDetail.aggUnitID}) </p></Toast.Body> */}
                            <form>
                                <div className="row">
                                    {/* <div className="col-md-12">
                                        <div className="form-wrap">
                                            <label className="col-form-label page-title">Proposed Rent</label>
                                            <input
                                                className="form-control negotiate-form page-title"
                                                name="proposedRent"
                                                value={approveReq.proposedRent}
                                                onChange={handleChange}
                                                placeholder="OMR 115"
                                    
                                            />
               
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="form-wrap">
                                            <label className="col-form-label page-title">Feedback</label>
                                            <textarea
                                                rows={5}
                                                cols={5}
                                                className="form-control tenant-textarea"
                                                placeholder="Description"
                                                name="feedback"
                                                value={approveReq.feedback}
                                                onChange={handleChange}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end gap-2 pb-4 pt-3 ">

                                    <button
                                        type="button"
                                        className="btn  btn-danger page-title "
                                        onClick={() => handleApprove(3)}
                                    >
                                        SEND TO TENANT
                                    </button>
                                </div>
                            </form>

                        </Toast>
                    </div>
                    <div className="toast-container position-fixed top-50 start-50 translate-middle">
                        <Toast
                            show={showRejectToast}
                            onClose={handleRejectionClose}
                            id="middlecenter-Toast"
                            className="colored-toast bg-white terminateToast negotiate-popup"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div className="d-flex align-items-center py-4 justify-content-between" >
                                <h5 className="page-title">Rejection Reason</h5>
                                <div className="img-tag d-flex align-items-center justify-content-between gap-2 pb-4">

                                    <i className="ti ti-x page-title" style={{
                                        background: '#A64041',
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        fontSize: "25px",
                                    }}
                                        onClick={handleRejectionClose} />
                                </div>
                            </div>
                            {/* <Toast.Body><p>Are you sure you want to terminate agreement against building ID ({tenancyRequestDetail.buildingNumber}) and unit ID ({tenancyRequestDetail.aggUnitID}) </p></Toast.Body> */}
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-wrap">
                                            <label className="col-form-label page-title">Reason of Rejection</label>
                                            <textarea
                                                rows={5}
                                                cols={5}
                                                className="form-control tenant-textarea"
                                                placeholder="Description"
                                                name="feedback"
                                                value={approveReq.feedback}
                                                onChange={handleChange}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end gap-2 pb-4 pt-3 ">

                                    <button
                                        type="button"
                                        className="btn  btn-danger  page-title"
                                        onClick={() => handleApprove(2)}
                                    >
                                        SEND TO TENANT
                                    </button>
                                </div>
                            </form>

                        </Toast>
                    </div>
                </>

            ) : (
                <Loader />
            )}
            <div className="toast-container position-fixed top-50 start-50 translate-middle">
                <Toast
                    show={showMiddleCenterToast}
                    onClose={handleMiddleCenterToastClose}
                    id="middlecenter-Toast"
                    className="colored-toast bg-white terminateToast"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <div className="img-tag d-flex align-items-center  pt-4 justify-content-center ">
                        <img
                            src={warningimg}
                            className="no-gap"
                            alt="Profile"
                            style={{ height: "5rem" }}
                        />

                    </div>
                    <h5 className=" d-flex align-items-center py-4 justify-content-center">Warning!</h5>
                    <Toast.Body><p>You are still in notice period Do you really want to terminate the Agreement</p></Toast.Body>
                    <div className="d-flex justify-content-center gap-2 pb-4 pt-3 ">
                        <button
                            type="button"
                            className="btn addfeature-btn "
                            id="middlecenterToastBtn"
                            onClick={handleMiddleCenterToastClose}
                        >
                            No
                        </button>

                        <button
                            type="button"
                            className="btn  btn-danger  "
                            onClick={handleTerminate}
                        >
                            Yes
                        </button>
                    </div>
                </Toast>
            </div>

        </>
    );
};

export default AgreementDocument;
