import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyMasterUniteImages,removeImage  } from "../../../redux/Commonslice";

const MultipleImageUpload = ({ UnitcategoryId,index }) => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const propertyMasterUniteImages = useSelector(
    (state: any) => state.CRMS.propertyMasterUniteImages
  );

  const uploadedImages = useSelector(
    (state: any) => state.CRMS.propertyMasterUniteImages?.[index] ?? []
  );
  const filterdata=uploadedImages.filter((item)=>item.index===index)
 console.log("uploadedImages",uploadedImages)
  
  const alluploadedImages = useSelector(
    (state: any) => state.CRMS.propertyMasterUniteImages ?? []
  );

  

  const [singleFile, setSingleFile] = useState([]);

  const uploadSingleFiles = (e) => {
    
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      if (file.size > 5 * 1024 * 1024) {
        setError("Each file must be less than 5MB");
        setTimeout(() => {
          setError("");
        }, 5000);
        continue;
      }

      
      setError("");

      reader.onload = (event) => {
        const result = event.target.result;
        let byteArray;

        if (typeof result !== "string") {
          byteArray = new Uint8Array(result);
        }

        const newImage = {
          propertyMasterID: 1,
          image: byteArray ? Array.from(byteArray) : null,
          url: URL.createObjectURL(file),
          name: file.name,
          index:index,
          propertUnitCategoryID: UnitcategoryId,
        };

       
        const updatedImages = [...uploadedImages, newImage];

        
        dispatch(setPropertyMasterUniteImages({ index, UnitcategoryId, images: updatedImages }));
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handleRemoveImage = (index) => {
  
    dispatch(removeImage({ UnitcategoryId, imageIndex: index }));
  };

  return (
    <>
      <div className="container-fluid" style={{paddingTop: "20px"}}>
      {/* <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {JSON.stringify(uploadedImages, null, 2)}
          </pre> */}
      
        <form>
          <div className="form-group multi-preview">
            <div className="image-grid">
             
              {filterdata.length  &&
                filterdata.map((image, idx) => (
                  <div key={idx} className="image-wrapper-unit">
                    <img
                      src={image.url}
                      alt={image.name}
                      className="uploaded-image"
                      style={{aspectRatio:"3/2",width:"50px",height:"50px"}}
                    />
                    <span
                      className="remove_img d-flex justify-content-center align-items-center"
                      onClick={() => handleRemoveImage(idx)}
                    >
                      X
                    </span>
                  </div>
                ))}
            </div>

            <div className="upload-wrapper" style={{ position: "relative"}}>
              <div className="upload-btn-wrapper">
                <button
                  type="button"
                  className="btn btn-danger btndangerbackground"
                  disabled={filterdata.length === 10}
                >
                  Upload Images
                </button>
                <input
                  type="file"
                  name="myfile"
                  onChange={uploadSingleFiles}
                  multiple
                  disabled={filterdata.length === 10}
                />
              </div>
              {error && <p style={{ color: "red", position: "absolute", top:"45px" }}>{error}</p>}
            </div>
           
          </div>
        </form>
      </div>
      <div className="row">
        <div className="text-end" style={{ paddingRight: "20px" }}>
          <i className="fa fa-picture-o fafaicon" aria-hidden="true">
            <span className="fafaicon ps-2">{filterdata.length}</span>
          </i>
        </div>
      </div>
    </>
  );
};

export default MultipleImageUpload;
