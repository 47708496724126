import React, { useState } from "react";
import Incomeoverview from "./Incomeoverview";
const PMSCharts = () => {
  return (
    <>
      <Incomeoverview />
    </>
  );
};

export default PMSCharts;
