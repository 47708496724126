import React, { useState } from "react";
import MultipleImageUpload from "./MultipleImageUpload";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import Chaveron from "./Chaveron";
import { UnitCategoryEnum } from "../../../redux/RequestTypeEnum";
import FeatureModal from "./FeatureModal";
import { useGetPropertyFeaturesQuery } from "../../../redux/CommonApi";
import { Modal } from "bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyId, setPropertyMasterUnitFeature } from "../../../redux/Commonslice";
import FeatureModalBuiding from "../FeatureModalBuilding";
import { setBuidIndex } from "../../../redux/Commonslice";
interface Feature {
  featureTypesID: number;
  desc: string;
  id: number;
  status: number;
}
enum FeatureType {
  MainFeatures = 1,
  Utilities = 2,
  Facing = 3,
}

type GroupedFeatures = { [key: string]: Feature[] };
const UnitCategoryCard = ({ heading, values, onFormChange, errors, onBlur, index }) => {
  const [selectedFeaturesByIndex, setSelectedFeaturesByIndex] = useState();
  const [addfeatureform, setAddfeatureform] = useState<{ [unitCategoryID: number]: any[] }>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentCardType, setCurrentCardType] = useState<string | null>(null);
  const unit = useSelector((state: any) => state.CRMS.propertyId);
  const [unitId, setUnitId] = useState<number | null>(null);
  const [selectedFeatures, setSelectedFeatures] = useState<{
    [key: string]: number[];
  }>({
    'Studio': [],
    '1BHK': [],
    '2BHK': [],
    '3BHK': [],
    '4BHK': [],
    '5BHK': [],
    'Shop': [],
    'Office': [],
    'WareHouse': [],
  });

  const dispatch = useDispatch();
  const { data: FeatureData } = useGetPropertyFeaturesQuery({});
  const getFeatureTypeName = (id: number) => {
    switch (id) {
      case FeatureType.MainFeatures:
        return "Main Features";
      case FeatureType.Utilities:
        return "Utilities";
      case FeatureType.Facing:
        return "Facing";
      default:
        return "Unknown";
    }
  };

  const groupedFeatures: GroupedFeatures = FeatureData?.reduce((acc, feature) => {
    const featureTypeName = getFeatureTypeName(feature.featureTypesID);
    acc[featureTypeName] = acc[featureTypeName] || [];
    acc[featureTypeName].push(feature);
    return acc;
  }, {} as GroupedFeatures);
  const uploadedFeature = useSelector((state: any) => {
    if (unitId && state.CRMS?.propertyMasterUnitFeature) {
      return state.CRMS.propertyMasterUnitFeature[unit] ?? null;
    }
    return null;
  });
  const handleFeatureChange = (
    cardType: string,
    featureId: number,
    featureTypesID: number
  ) => {
    setAddfeatureform((prevForm) => {
      if (Array.isArray(prevForm)) {
        // Check if the feature already exists in the form for the current category
        if (prevForm.some((item) => item.featureID === featureId && item.propertyUnitCategoryID === unitId)) {
          // Remove feature from the form if unchecked
          return prevForm.filter((item) => !(item.featureID === featureId && item.propertyUnitCategoryID === unitId));
        } else {
          // Add new feature to the form
          return [
            ...prevForm,
            {
              propertyMasterID: 0,
              propertyUnitCategoryID: unitId,
              featureID: featureId,
              propertyTypes: 1,
              index: selectedFeaturesByIndex
            },
          ];
        }
      } else {
        return [
          {
            propertyMasterID: 0,
            propertyUnitCategoryID: unitId,
            featureID: featureId,
            propertyTypes: 1,
            index: selectedFeaturesByIndex
          },
        ];
      }
    });
    const feature = {
      propertyMasterID: 0,
      propertyUnitCategoryID: unitId,
      featureID: featureId,
      propertyTypes: 1,
      index: selectedFeaturesByIndex
    };

    const updatedFeatures = [...(uploadedFeature || []), feature];
    dispatch(setPropertyMasterUnitFeature({ selectedFeaturesByIndex, unitId, features: updatedFeatures }));
    setSelectedFeatures((prevState) => {
      const selectedFeaturesForCard = prevState[cardType];
      const isAlreadySelected = selectedFeaturesForCard.includes(featureId);

      return {
        ...prevState,
        [cardType]: isAlreadySelected
          ? selectedFeaturesForCard.filter((id) => id !== featureId)
          : [...selectedFeaturesForCard, featureId],
      };
    });
  };
  const handleInputChange = (field, newValue) => {


    if (!isNaN(newValue) && newValue !== "") {
      newValue = String(Number(newValue));
    }
    const updatedValues = {
      ...values,
      [field]: newValue,
    };
    onFormChange(updatedValues);
    // if (field === 'rent' || field === 'area') {
    //   onBlur();
    // }

  };
  const handleIncrement = (field, max = 9999) => {
    if (values && values[field] !== undefined) {
      handleInputChange(field, Math.min(parseInt(values[field] || 0, 10) + 1, max));
    } else {
      console.error(`Field ${field} is undefined in values`);
    }
  };

  const handleSave = (cardType: string) => {
    const savedFeatures = FeatureData.filter((f) =>
      selectedFeatures[cardType].includes(f.id)
    );
    closeModal()
    console.log(`Saved features for ${cardType}:`, savedFeatures);
    // alert(`Saved features for ${cardType}: ${JSON.stringify(savedFeatures, null, 2)}`);
  };
  const closeModal = () => {
    setModalOpen(false);
    setCurrentCardType(null);
  };
  // const handleIncrement = (field, max = 9999) => {
  //   handleInputChange(field, Math.min(parseInt(values[field] || 0, 10) + 1, max));
  // };
  // const handleDecrement = (field, min = 0) => {
  //   handleInputChange(field, Math.max(values[field] - 1, min));
  // };
  const handleDecrement = (field, min = 0) => {
    if (values && values[field] !== undefined) {
      handleInputChange(field, Math.max(values[field] - 1, min));
    } else {
      console.error(`Field ${field} is undefined in values`);
    }
  };

  const addFeature = (e, heading, idx) => {
    // e.preventDefault();
    dispatch(setBuidIndex(idx))
    setSelectedFeaturesByIndex(idx)
    setCurrentCardType(heading);
    setModalOpen(true);
    
    const selectedUnitId = UnitCategoryEnum[heading];
    if (selectedUnitId) {
      setUnitId(selectedUnitId);
      dispatch(setPropertyId(selectedUnitId))
      // const modalElement = document.getElementById("exampleModalScrollable3");
      // if (modalElement) {
      //   const modal = new Modal(modalElement);
      //   modal.show();
      // }
    } else {
      console.error(`No unit category found for ${heading}`);
    }
  };

  return (
    <div className="card unitcategoryCard">
      <div className="card-body">
        <div className="col-lg-3 col-md-4 align-items-center">
          <h4 className="totalunit-h4 py-2 fafaicon"> {heading === 0 ? "Studio" : `${heading}`}</h4>
        </div>
        <div className="row">
          {heading && heading !== "Shop" ? (<>  <div className="col-lg-4 col-md-4 col-sm-12 ">

            <div className="fheadingorm-wrap" style={{ position: "relative" }}>
              <label className="col-form-label">
                {heading && (heading === "Studio" || heading === "Office" || heading === "WareHouse") ? "Room" : "Bedrooms"}  <span className="text-danger"></span>
              </label>
              <input
                className="form-control"
                type="number"
                value={values?.bedrooms}
                placeholder="0"
                onChange={(e) =>
                  handleInputChange("bedrooms", Number(e.target.value))
                }
                onKeyPress={(e) => {
                  if (e.key === '-' || e.key === '+' || e.key === '.') {
                    e.preventDefault(); // Prevents entering +, -, and . characters
                  }
                }}
                aria-describedby="number"
                style={{ paddingRight: "30px" }}
              />
              <Chaveron
                onIncrement={() => handleIncrement("bedrooms")}
                onDecrement={() => handleDecrement("bedrooms")}
              />
            </div>
          </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <div className="form-wrap" style={{ position: "relative" }}>
                <label className="col-form-label">
                  Bathrooms<span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={values?.bathrooms}
                  placeholder="0"
                  onChange={(e) =>
                    handleInputChange("bathrooms", Number(e.target.value))
                  }
                  onKeyPress={(e) => {
                    if (e.key === '-' || e.key === '+' || e.key === '.') {
                      e.preventDefault(); // Prevents entering +, -, and . characters
                    }
                  }}
                  aria-describedby="number"
                />
                <Chaveron
                  onIncrement={() => handleIncrement("bathrooms")}
                  onDecrement={() => handleDecrement("bathrooms")}
                />
              </div>
            </div></>) : (<></>)}
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div className="form-wrap" style={{ position: "relative" }}>
              <label className="col-form-label">
                Area (SQM) <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                value={values?.area}
                placeholder="0"
                onChange={(e) =>
                  handleInputChange("area", Number(e.target.value))
                }

                aria-describedby="number"
                onKeyPress={(e) => {
                  if (e.key === '-' || e.key === '+' || e.key === '.') {
                    e.preventDefault(); // Prevents entering +, -, and . characters
                  }
                }}
              />

            </div>
            {errors.area && <div className="text-danger">{errors.area}</div>}
          </div>

          {heading && heading !== "Shop" ? (<>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="form-wrap" style={{ position: "relative" }}>
                <label className="col-form-label">
                  Kitchen <span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={values?.kitchen}
                  placeholder="0"
                  onChange={(e) =>
                    handleInputChange("kitchen", Number(e.target.value))
                  }
                  onKeyPress={(e) => {
                    if (e.key === '-' || e.key === '+' || e.key === '.') {
                      e.preventDefault(); // Prevents entering +, -, and . characters
                    }
                  }}
                  aria-describedby="number"
                />
                <Chaveron
                  onIncrement={() => handleIncrement("kitchen")}
                  onDecrement={() => handleDecrement("kitchen")}
                />
              </div>
            </div>
            {heading && heading !== "Studio" && heading !== "WareHouse" && (<div className="col-lg-4 col-md-4 col-sm-12">
              <div className="form-wrap" style={{ position: "relative" }}>
                <label className="col-form-label">
                  Hall <span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={values?.hall}
                  placeholder="0"
                  onChange={(e) =>
                    handleInputChange("hall", Number(e.target.value))
                  }
                  onKeyPress={(e) => {
                    if (e.key === '-' || e.key === '+' || e.key === '.') {
                      e.preventDefault(); // Prevents entering +, -, and . characters
                    }
                  }}
                  aria-describedby="number"
                />
                <Chaveron
                  onIncrement={() => handleIncrement("hall")}
                  onDecrement={() => handleDecrement("hall")}
                />
              </div>
            </div>)}</>) : (<></>)}




          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="form-wrap" style={{ position: "relative" }}>
              <label className="col-form-label">
                Rent (OMR) <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                value={values?.rent}
                placeholder="0"
                onChange={(e) =>
                  handleInputChange("rent", Number(e.target.value))
                }

                aria-describedby="number"
                onKeyPress={(e) => {
                  if (e.key === '-' || e.key === '+' || e.key === '.') {
                    e.preventDefault(); // Prevents entering +, -, and . characters
                  }
                }}
              />

              {/* <Chaveron
                onIncrement={() => handleIncrement("rent")}
                onDecrement={() => handleDecrement("rent")}
              /> */}
            </div>
            {errors.rent && <div className="text-danger">{errors.rent}</div>}
          </div>
        </div>
        <div className="row">
          <div className="card ">
            <div className="row">
              <MultipleImageUpload UnitcategoryId={UnitCategoryEnum[heading]} index={index} />
            </div>
          </div>
        </div>
        <div className="text-end">
          <div>
            {/* Add Features button */}
            <a
              className="btn addfeature-btn"
              type="button"
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModalScrollable3"
              onClick={(e) => addFeature(e, heading, index)}
            >
              <i className="fa fa-add" aria-hidden="true">
                {" "}
                <span className="fafaicon"></span>
              </i>
              Add Features
            </a>
            {/* {<FeatureModalBuiding unitCategoryID={unitId} FeatureData={FeatureData} index={index} />} */}
            {modalOpen && currentCardType &&
              <div
                style={{
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '5px',
                    width: '500px',
                    maxHeight: '700px',
                    overflowY: 'auto',
                  }}
                >
                  <div className="modal-content modal-padding">
                    <div className="addproperty-modal-header justify-content-between modal-header px-3 py-2">
                      <h4 className="modal-title modal-text " id="staticBackdropLabel3">
                        Property Features
                      </h4>
                      <button type="button" onClick={closeModal} className="btn-close modal-text" aria-label="Close" />
                    </div>
                    <div className="modal-body px-3">
                      {Object.entries(groupedFeatures || {}).map(([featureTypeName, features]) => (
                        <div key={featureTypeName}>
                          <h4 className='text-start'>{featureTypeName}</h4>
                          <div className="row py-3 text-start">
                            {features && features.map((feature) => (
                              <div key={feature.id} className="form-check form-check-lg modal-checkbox align-items-center col-md-6 col-sm-6 col-lg-6 py-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`checkbox-${feature.id}`}
                                  checked={selectedFeatures[currentCardType].includes(feature.id)}

                                  onChange={() =>
                                    handleFeatureChange(currentCardType, feature.id, feature.featureTypesID)
                                  }
                                />
                                <label className="form-check-label modal-p" htmlFor={`checkbox-${feature.id}`}>
                                  {feature.desc}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                      <div className=" pb-4 d-flex justify-content-between">
                        <button type="button" className="btn next-btn" onClick={() => handleSave(currentCardType)}>
                          Save
                        </button>
                        <button onClick={closeModal} className="btn next-btn"  >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <FeatureModalBuiding unitCategoryID={unitId} FeatureData={FeatureData} index={index} currentCardType={currentCardType}/> */}
                </div>
              </div>
            }
            {/* {unitId && <FeatureModal unitCategoryID={unitId} FeatureData={FeatureData} idx={selectedFeaturesByIndex} />} */}

          </div>
          {/* <FeatureModal unitCategoryID={UnitCategoryEnum[heading]} FeatureData={FeatureData} /> */}
          {/* <Link to="#" className="btn addfeature-btn p-2" type="button">
            <i className="fa fa-add" aria-hidden="true">
              {" "}
              <span className="fafaicon"></span>
            </i>

            <span className="ms-2">ADD FEATURES</span>
          </Link> */}
        </div>
      </div>
    </div>


  );
};
export default UnitCategoryCard;
