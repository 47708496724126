import React, { useEffect, useState, useRef } from "react";
import { useGetRequestAgreementQuery, useGetTenantDashBoardQuery } from "../../../redux/CommonApi";
import ExpiredAggrements from "./ExpiredAggrements";
import TenancyRequest from "./TenancyRequest";
import ExpiredAggrementsIn30Days from "./ExpiredAggrementsIn30Days";
import AgreementRenewRequest from "./AgreementRenewRequest";
import TerminatedAgreement from "./TerminatedAgreement";
import "../../../style/scss/tenantdashboard.scss";
import Negotiation from "./Negotiation";
import { NegotiationTypeEnum, unitStatusEnum } from "../../../redux/RequestTypeEnum";
import CurrentAgreement from "./CurrentAgreement";
import AgreementDocument from "./AgreementDocument";
import Rent from "./Rent";
import RentDetail from "./RentDetail";
import VerifyTerminate from "./VerifyTerminate";
import ProceedAgreement from "./ProceedAgreement";
import ExpireAggDetail from "./ExpireAggDetail";
import { AwaitingApprovalPopup } from "./AwaitingApprovalPopup";
import { useSelector } from "react-redux";
import { Loader } from "../../../shared/Loader";

const TenantDashboard = () => {
  const userId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetTenantDashBoardQuery({
    userID: userId,
  });
  const modalRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [negotiationItem, setNegotiationItem] = useState(null);
  const [activeIndex, setActiveIndex] = useState("Tenancy Requests");
  const [cardOpen, setcardOpen] = useState(false);
  const [activesubIndex, setActivesubIndex] = useState("Agreements");
  const [TenenacyRequestDetail, setTenenacyRequestDetail] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [RentHistory, setRentHistory] = useState(null);
  const [RentHistoryDetail, setRentHistoryDetail] = useState(null);
  const [verifyTerminate, setVerifyTerminate] = useState(null);
  const [proceedAgg, setProceedAgg] = useState(null);
  const [expireDetail, setExpireDetail] = useState(null);
  const [agreementExpire30Detail, setAgreementExpire30Detail] = useState(null);
  const [selectedPropertyUnit, setSelectedPropertyUnit] = useState(null);
  const [pageNumber1, setPageNumber1] = useState(1);
  const [AgreementDocDetail, setAgreementDocDetail] = useState(null);
  const [Aggrementoption, setAggrementoption] = useState(null);
  const CLOUND_FRONT_BASE_URL = "https://d2og5lryw1ajtt.cloudfront.net/";
  const [goBack, setgoback] = useState(true);
  const [isReopenpopup, setReopenpopup] = useState(false);
  const handleReopenShow = () => setReopenpopup(false);
  const handleReopenClose = () => setReopenpopup(false);
  var modal = document.getElementById("toggle-popup");
  const handleClickOutside = (event) => {
    if (modalRef.current && event.target === modalRef.current) {
      setReopenpopup(false);
    }
  };
  const isDarkTheme = useSelector((state: any) => state?.CRMS.isDarkTheme);
  useEffect(() => {
    if (isReopenpopup) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isReopenpopup]);
  //  useEffect(() => {
  //   // Scroll to the top of the page when this component is rendered
  //   window.scrollTo(0, 0);
  // }, []); 
  useEffect(() => {
    if (data && Array.isArray(data)) {
      const negotiation = data.find((item) => item.title === "Negotiation");
      const filtered = data.filter((item) => item.title !== "Negotiation");
      setNegotiationItem(negotiation);
      setFilteredData(filtered);
    }
  }, [data]);

  const statusMapping = {
    "Tenancy Requests": 1,
    "Current Agreements": 2,
    "Agreement Expiring In 30 Days": 3,
    "Expired Agreements": 4,
    "Agreement Renewal Requests": 5,
    "Terminated Agreements": 6,
    Negotiation: 7,
  };

  const handleActiveTabs = (TabId) => {
    setActiveIndex(TabId);
    setcardOpen(true);
    // setActiveIndex((prevIndex) => (prevIndex === TabId ? null : TabId));
    setActivesubIndex("Agreements");
    setSelectedProperty(null);
    setSelectedPropertyUnit(null);
    setTenenacyRequestDetail(null);
    setAgreementDocDetail(null);
    setRentHistory(null);
    setRentHistoryDetail(null);
    setVerifyTerminate(null);
    setProceedAgg(null);
    setExpireDetail(null);
    setAgreementExpire30Detail(null);
    setAggrementoption(null)
    // setgoback(false);
  };

  const handleSubIndexChange = (subIndex) => {
    setActivesubIndex(subIndex);
    setPageNumber1(1);
    setTenenacyRequestDetail(null);
    setAgreementDocDetail(null);
    setRentHistory(null);
    setRentHistoryDetail(null);
    setVerifyTerminate(null);
    setProceedAgg(null);
    setExpireDetail(null);
    setAgreementExpire30Detail(null);
    setAggrementoption(null);
    // setgoback(false);
  };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Add a timer to slowly set cardOpen to true
  //     setTimeout(() => {
  //       setcardOpen(true);
  //     }, 500); // Adjust the timer for smoother transition, 500ms delay
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  const negotiationData = {
    Agreements: NegotiationTypeEnum.LandlordRequest,
    "Tenancy Requests": NegotiationTypeEnum.TenancyRequest,
    "Renewal Requests": NegotiationTypeEnum.RenewableRequest,
    "Admin Requests": NegotiationTypeEnum.ChangeRequest,
  };

  const categories = Object.keys(negotiationData);
  if (isLoading) return <Loader/>;
  if (error) return <p>Error loading data</p>;

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className={`card  ${cardOpen ? "open" : ""}`} style={{ boxShadow: '0px 4px 19px 0px #00000033' }} >
              <div className="card-body">
                <div className="row" >
                  {cardOpen ? (
                    <div className="col-12 ">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className={`campaign-box-block campaign-box propertycard bg-tenant`}
                            style={{ color: "white" }}
                          >
                            <div>
                              <div className="d-flex justify-content-center align-items-center gap-3">
                                <img
                                  className="filter-white"
                                  style={{
                                    filter: "brightness(0) invert(100%)",
                                    width: "25px",
                                    height: "25px",
                                  }}
                                  src={`${CLOUND_FRONT_BASE_URL}${activeIndex === "Negotiation"
                                    ? negotiationItem.asset
                                    : filteredData.find((item) => item.title === activeIndex)?.asset
                                    }`}
                                />
                                <h6 className="" style={{ width: "100%", paddingBottom: "0px" }}>
                                  {activeIndex === "Negotiation"
                                    ? negotiationItem.title
                                    : filteredData.find((item) => item.title === activeIndex)?.title}
                                  <span className="count-title">
                                    {activeIndex === "Negotiation"
                                      ? negotiationItem.count
                                      : filteredData.find((item) => item.title === activeIndex)?.count}
                                  </span>
                                </h6>
                                <h2>
                                  <i
                                    className="ti ti-menu-2"
                                    onClick={() => setcardOpen(false)} // Collapse view and show all cards
                                    style={{
                                      cursor: "pointer",

                                      transition: "color 0.3s ease", // Smooth color transition
                                    }}
                                  />
                                </h2>
                              </div>
                              <div className="d-flex justify-content-end">

                              </div>
                            </div>
                          </div>
                        </div>
                      </div></div>
                  ) : (
                    <div className="col-12 col-lg-9">
                      <div className="row">
                        {filteredData.map((item, index) => (
                          <div
                            key={index}
                            className={`col-12 col-lg-4 ${activeIndex && activeIndex !== item.title ? "collapsed" : "expanded"
                              }`}

                          >
                            <div
                              className={`campaign-box-block campaign-box propertycard  ${activeIndex === item.title ? "bg-tenant" : "bg-tenant-light"
                                }`}
                              onClick={() => handleActiveTabs(item.title)} // Set active index on click
                              style={{
                                color: activeIndex === item.title ? "white" : "",
                                transition: "color 0.3s ease", // Smooth color transition
                              }}
                            >
                              <div>
                                <div className="d-flex justify-content-center align-items-center gap-3">
                                  <img
                                    className="filter-white"
                                    style={{
                                      filter: isDarkTheme
                                        ? "brightness(0) invert(100%)" // White image for dark theme
                                        : activeIndex === item.title
                                          ? "brightness(0) invert(100% ) invert(0%)" // Active state in light theme
                                          : "brightness(0) saturate(100%) invert(0%)", // Default state in light theme
                                      width: "25px",
                                      height: "25px",
                                    }}
                                    src={`${CLOUND_FRONT_BASE_URL}${item.asset}`}
                                    alt={item.title}
                                  />
                                  <h6 className="text-truncate page-title" style={{ fontSize: "1rem", width: "100%" }}>
                                    {item.title}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h2 className="page-title">{item.count}</h2>
                                  <h2>
                                    <i className="ti ti-arrow-right page-title" />
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* Right Section for Negotiation Card */}
                  {!cardOpen && (
                    <div className="col-12 col-lg-3 mb-3">
                      <div
                        className={`card tm-h propertycard ${activeIndex === "Negotiation" ? "tenantdashboard-card" : "tenantdashboard-light"
                          }`}
                        onClick={() => handleActiveTabs("Negotiation")} // Toggle the "Negotiation" card
                        style={{
                          color: activeIndex === "Negotiation" ? "white" : "",
                        }}
                      >
                        {negotiationItem && (
                          <div className="card-body text-center align-content-center">
                            <div className="mb-2">
                              <img
                                className="filter-white"
                                style={{
                                  filter:isDarkTheme
                                  ? "brightness(0) invert(100%)" :
                                    activeIndex === "Negotiation"
                                      ? "brightness(0) invert(100%)"
                                      : "brightness(0) saturate(100%) invert(0%)",
                                }}
                                src={`${CLOUND_FRONT_BASE_URL}${negotiationItem.asset}`}
                              />
                              <h6 className="text-truncate page-title">{negotiationItem.title}</h6>
                            </div>
                            <h2 className="page-title">{negotiationItem.count}</h2>
                            <h2>
                              <i className="ti ti-arrow-right page-title" />
                            </h2>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row align-items-baseline mb-3">
              <div className=" col-lg ">
                {/* <h4 className="totalunit-h4 ps-2">{activeIndex}</h4> */}
              </div>

              {activeIndex === "Negotiation" ? (<>
                <div className="mb-5 position-relative">
                  <div className="position-absolute d-flex pt-3">
                  {activeIndex || "N/A"}
                  </div>
                  {/* translate-middle-x */}
                  <div className="d-none py-2 d-xl-flex align-items-center position-absolute end-0  rounded-pill bg-white bg-gradient border border-white px-3 text-sm fw-medium text-dark shadow-lg">
                    {categories.map((category, index) => (
                      <a key={index} type="button"
                       onClick={() => handleSubIndexChange(category)} 
                       className={`btn btn-link px-3 py-2 rounded-pill ${activesubIndex === category ? "bg-activetab" : "bg-inactive-tab"}`} 
                      style={{
                        // color:
                        // activesubIndex === category
                        // ? "bg-activetab"
                        // : "black",
                        // background: activeIndex === category ? "#9e2a2b" : "",
                        textDecoration: "none",
                        cursor:"pointer"

                      }}
                        // onMouseEnter={(e) => {
                        //   e.currentTarget.style.backgroundColor = "#9e2a2b";
                        //   e.currentTarget.style.color = "white";
                        
                        // }}
                        // onMouseLeave={(e) => {
                        //   if (activeIndex !== category) {
                        //     e.currentTarget.style.backgroundColor = "";
                        //     e.currentTarget.style.color = "white"
                        //   }
                        // }}
                      >
                        
                        {category}
                      </a>
                    ))}
                  </div>
                </div>
                {/* <div className=" col-lg ">
                  <div className="card card-shadow">
                    <div className="card-body tab-breadcrums align-content-center wrapper-nav">
                      <div className="button-group d-flex flex-wrap ms-auto">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb breadcrumb-line breadcrumb-li">
                            {categories.map((category, index) => (
                              <React.Fragment key={index}>
                                <li
                                  className={`breadcrumb-item`}
                                  onClick={() => handleSubIndexChange(category)}
                                  style={{
                                    color:
                                      activesubIndex === category
                                        ? "$white"
                                        : "",
                                  }}
                                  aria-current="page"
                                >
                                  <span
                                    className={`${activesubIndex === category
                                      ? "tenant-breadcrumb"
                                      : ""
                                      }`}
                                  >
                                    <span className="ps-2">{category}ddd</span>
                                  </span>
                                </li>
                           
                              </React.Fragment>
                            ))}
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div> */}
                </> ) : null}
            </div>
            <div
              ref={modalRef}
              className={isReopenpopup ? "toggle-popup sidebar-popup" : "toggle-popup"}

              style={{ display: isReopenpopup ? "block" : "none" }}
            >
              <AwaitingApprovalPopup handleReopenClose={handleReopenClose} TenenacyRequestDetail={TenenacyRequestDetail}
                setProceedAgg={setProceedAgg}
                setgoback={setgoback}
                setTenenacyRequestDetail={setTenenacyRequestDetail} />
            </div>


            {AgreementDocDetail ?
              (
                <AgreementDocument AgreementDocDetail={AgreementDocDetail}
                  setgoback={setgoback}
                  setRentHistory={setRentHistory}
                  setAgreementDocDetail={setAgreementDocDetail}
                  setVerifyTerminate={setVerifyTerminate}
                  Aggrementoption={Aggrementoption}
                  Cardstatus={statusMapping[activeIndex]}

                />
                // <TenantMasterCards/>
              ) :
              RentHistory === 1 ?
                (
                  <Rent
                    setRentHistory={setRentHistory}
                    setRentHistoryDetail={setRentHistoryDetail}
                    setAgreementDocDetail={setAgreementDocDetail}
                    setgoback={setgoback}

                  />

                ) :
                RentHistoryDetail === 1 ?
                  (
                    <RentDetail />

                  ) :
                  verifyTerminate === 1 ?
                    (
                      <VerifyTerminate />

                    ) :
                    proceedAgg === unitStatusEnum.In_Progress ?
                      (
                        <ProceedAgreement
                          setProceedAgg={setProceedAgg}
                         
                          setgoback={setgoback}
                          RequestAgreementID={TenenacyRequestDetail}
                          showProceed={false}
                          setShowProceed={false}
                        />

                      ) :

                      expireDetail ?
                        (
                          <ExpireAggDetail expireDetail={expireDetail} />

                        ) :
                        agreementExpire30Detail ?
                          (
                            <ExpireAggDetail expireDetail={agreementExpire30Detail} />

                          ) :

                          activeIndex === "Tenancy Requests" ? (
                            <TenancyRequest
                              setTenenacyRequestDetail={setTenenacyRequestDetail}
                              Cardstatus={statusMapping[activeIndex]}
                              setReopenpopup={setReopenpopup}

                            />
                          ) : activeIndex === "Agreement Expiring In 30 Days" ? (
                            <ExpiredAggrementsIn30Days
                              Cardstatus={statusMapping[activeIndex]}
                              setAgreementExpire30Detail={setAgreementExpire30Detail}
                            />
                          )
                            : activeIndex === "Expired Agreements" ? (
                              <ExpiredAggrements
                                status={statusMapping[activeIndex]}
                                setExpireDetail={setExpireDetail}
                                setSelectedProperty={setSelectedProperty}
                                Cardstatus={statusMapping[activeIndex]}
                              />
                            ) : activeIndex === "Agreement Renewal Requests" ? (

                              <AgreementRenewRequest
                                setTenenacyRequestDetail={setTenenacyRequestDetail}
                                Cardstatus={statusMapping[activeIndex]}
                                setReopenpopup={setReopenpopup} />
                            ) : activeIndex === "Terminated Agreements" ? (
                              <TerminatedAgreement
                                setAgreementDocDetail={setAgreementDocDetail}
                                Cardstatus={statusMapping[activeIndex]}
                                setAggrementoption={setAggrementoption}
                              />
                            ) : activeIndex === "Negotiation" ? (
                              <Negotiation
                                negotiationType={negotiationData[activesubIndex]}
                                setPageNumber1={setPageNumber1}
                                pageNumber1={pageNumber1}
                                setTenenacyRequestDetail={setTenenacyRequestDetail}
                                setReopenpopup={setReopenpopup}
                                setAggrementoption={setAggrementoption}
                                setAgreementDocDetail={setAgreementDocDetail}
                                Cardstatus={statusMapping[activeIndex]}
                                activesubIndex={activesubIndex}
                              />
                            ) : activeIndex === "Current Agreements" ? (
                              <CurrentAgreement
                                setAgreementDocDetail={setAgreementDocDetail}
                                Cardstatus={statusMapping[activeIndex]}
                                setAggrementoption={setAggrementoption} />


                            ) : (
                              <></>
                            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantDashboard;
