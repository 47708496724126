import React, { useEffect, useState } from 'react';

const TenantSearchBar = ({ CurrentAgreementByUser, setFilteredData }) => {
  const [searchTerm, setSearchTerm] = useState("");
// console.log(CurrentAgreementByUser, "CurrentAgreementByUser")
  // Function to handle search/filter
  useEffect(() => {
    if (Array.isArray(CurrentAgreementByUser)) {
      const filtered = CurrentAgreementByUser.filter((items) => {
        const tenantName = items.tenantName?.toLowerCase() || ""; // Check if defined, fallback to empty string
        const propertyMasterName = items.propertyMasterName?.toLowerCase() || ""; // Check if defined, fallback to empty string
        return (
            tenantName.includes(searchTerm.toLowerCase()) ||
            propertyMasterName.includes(searchTerm.toLowerCase())
        );
      });
  
      setFilteredData(filtered);
    }
  }, [searchTerm, CurrentAgreementByUser]);
  return (
          <div className="row">
            <div className="col-md-12 ">
              <div className="form-wrap icon-form">
                
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Tenant Or Building Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <p className="form-icon">
                  <i className="ti ti-search" />
                </p>
              </div>
            </div>
          </div>
  );
};
export default TenantSearchBar;
