import React, { ReactNode } from "react";

const MaintenanceUnitCard = ({
  data,
  handleCardunitClick,
  propertyMasterID,
  buildingNumber,
  setShowUnit,
  setShowMaintenanceCard,
  setPropertyMasterID
}) => {
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const [filteredData, setFilteredData] = React.useState([]);
  const [tenantPropertyUnitsMaintenace, settenantPropertyUnitsMaintenace] =
    React.useState([]);

  const backclick = () => {
    propertyMasterID = null;
    setShowUnit(false),
    setShowMaintenanceCard(true)
  };
  const unitbackclick = () => {
    setPropertyMasterID(null);
    setShowUnit(true),
    setShowMaintenanceCard(false)
  };
  const [searchTerm, setSearchTerm] = React.useState("");
  React.useEffect(() => {
    if (propertyMasterID === null) {
      const filtered = data?.filter((items) =>
        items.buildingNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      const tenant = data?.filter(
        (item) =>
          item.unitID
            ?.toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.caption?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      settenantPropertyUnitsMaintenace(tenant);
    }
  }, [searchTerm, data]);
  return (
    <>
      <div className="row pb-2">
        <div className="align-items-center col-md-9 d-flex justify-content-start">
          {propertyMasterID === null ? (
            <>
              <i
                className="ti ti-arrow-narrow-left"
                onClick={backclick}
                style={{
                  background: '#7E7E7E',
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  fontSize: "30px",
                  cursor:"pointer"
                }}

              />  <p className="fw-bolder page-title">Select Building</p>
            </>
          ) : (
            <>
              <p className="fw-bolder page-title">
              <i
                className="ti ti-arrow-narrow-left"
                onClick={unitbackclick}
                style={{
                  background: '#7E7E7E',
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  fontSize: "30px"
                }}

              /> Select Unit Of Building ({buildingNumber})
              </p>
     
            </>
          )}
        </div>
        <div className="col-md-3 text-end">
          <div className="form-wrap icon-form">
            <span className="form-icon">
              <i className="ti ti-search" />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      {propertyMasterID === null ? (
        <div className="row">
          {filteredData &&
            filteredData.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-3 col-sm-4">
                <div
                  className="card main-card card-unit-buid"
                  onClick={() =>
                    handleCardunitClick(
                      item.propertyMasterID,
                      item.buildingNumber
                    )
                  }
                >
                  <div className="card-body">
                    <img
                      className="card-img-top M-Unitcard"
                      src={item.propertyImageString || fallbackImage}
                      alt="Card image cap"
                    />
                    <p className="card-text text-center m-0 py-2 fw-semibold page-title">
                      Building ID: {item.buildingNumber}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="row">
          {tenantPropertyUnitsMaintenace &&
            tenantPropertyUnitsMaintenace.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-3 col-sm-4">
                <div
                  className="card main-card card-unit-buid"
                  onClick={() =>
                    handleCardunitClick(item.propertyMasterID, item.unitID)
                  }
                >
                  <div className="card-body">
                    <img
                      className="card-img-top M-Unitcard"
                      src={item.imageUrl || fallbackImage}
                      alt="Card image cap"
                    />
                    {/* <p className="card-text text-center m-0 py-2 fw-semibold">Unit ID: {item.unitID}</p> */}
                    <p className="card-text text-center m-0 py-2 fw-semibold page-title">
                      {item.unitID === -1
                        ? `Building ID: ${buildingNumber}`
                        : `Unit ID: ${item.unitID}`}
                    </p>
                    {item.caption && item.caption !== "0" && (
                      <p className="card-text text-center p-0 fw-semibold page-title">
                        caption: {item.caption}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default MaintenanceUnitCard;
