import api from "../../src/Interceptor";

export const getAllStaffManagement = async (UserID, pageNumber, rowNumbers) => {
  try {
    const response = await api.get(
      `/Staff/AllLandlordStaffManagement?UserID=${UserID}&PageNumber=${pageNumber}&RowNumber=${rowNumbers}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching GetAllStaffManagement:", error);
    throw error;
  }
};

export const updateStatus = async (staff, UserID) => {
  try {
    const response = await api.post("/Staff/AssignSecuritygroupToStaff", {
      landlordStaffId: staff.landlordStaffId,
      status: staff.status === 1 ? 8 : 1,
      landlordId: UserID,
    });
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};
export const createLandlordStaff = async (postData, setFormData) => {
  try {
    const response = await api.post("/Staff", postData);
    if (response.status === 200) {
      setFormData({
        username: "",
        id_number: "",
        cell_number: "",
        role: "",
        activeUser: false,
        imageUrls: "",
      });
    } else {
      console.error("Failed to post staff data:", response.statusText);
    }
    return response.data;
  } catch (error) {
    console.error("Error posting staff data:", error);
    throw error;
  }
};

export const getAllRoles = async () => {
  try {
    const response = await api.get(`/Setup/GetRoleTypes`);
    return response.data;
  } catch (error) {
    console.error("Error fetching roles:", error);
  }
};

export const StaffSearchByPhoneNumber = async (PhoneNumber) => {
  try {
    const response = await api.get(
      `/Staff/SearchStaffByPhoneNumber?PhoneNumber=${PhoneNumber}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching roles:", error);
  }
};

export const imageUpload = async (imageData) => {
  try {
    const response = await api.post("/userDocument", imageData);
    if (response.status == 200) {
    } else {
      console.error("Failed to upload image:", response.statusText);
    }
  } catch (error) {
    console.error("Error uploading image:", error);
  }
};

export const GetstaffDetailById = async (StaffId) => {
  try {
    const landlordId = sessionStorage.getItem("userId");
    const response = await api.get(
      `Staff/StaffDetailByStaffId?StaffId=${StaffId}&LandlordId=${landlordId}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching roles:", error);
  }
};

export const UpdateLandlordStaff = async (UpdateStaffRequest) => {
  try {
    const response = await api.put("/Staff/UpdateStaff", UpdateStaffRequest);

    return response;
  } catch (error) {
    console.error("Error posting staff data:", error);
  }
};
export const SearchByPhoneNumber = async (value) => {
  try {
    const response = await api.get(
      `Staff/SearchStaffByPhoneNumber?PhoneNumber=${value}`
    );
    if (response.status === 200) {
    } else {
      console.error("Failed to post staff data:", response.statusText);
    }
    return response;
  } catch (error) {
    console.error("Error posting staff data:", error);
    throw error;
  }
};
