export const NoData = () => {
    return (
        <div
        className="card d-flex align-items-center justify-content-center"
        style={{ padding: "40px 0", border: "none" }}
      >
        <div className="card-body">
          <img
            src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
            alt="No data found"
          />
          <h3 className="text-center">No Data Found</h3>
        </div>
      </div>
    )
}