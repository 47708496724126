import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { LineChart } from '@mui/x-charts/LineChart';
import { useGetIncomeOverviewQuery } from "../../../redux/CommonApi";
const OccupancyChart = () => {
  const landlordId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetIncomeOverviewQuery(landlordId);
  const options: ApexOptions = {
    series: [
      {
        data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
      },
    ],
    colors: ["#15CE2C"],
    chart: {
      type: "line",
      width: 80,
      height: 55,

      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (series) {
            return "";
          },
        },
      },
      marker: {
        show: true,
      },
    },
  };
  const containerRef = React.useRef(null);
  const [chartSize, setChartSize] = React.useState({ width: 400, height: 300 });

  React.useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setChartSize({ width, height: width * 0.75 }); // Maintain aspect ratio
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);
  return (
    <div className="card cardreq ">
      <div className="card-header d-flex justify-content-between">
        <h6 className="card-title">Occupancy</h6>
      </div>
      <div className="card-body">
        <div className="position-relative text-avatar row occupancy">
          <div className="align-items-center col-md-6 col-sm-6 d-flex justify-content-center text-center">
            <div>
              <h1 className="chart-g">+{data.occupancyPercentage}%</h1>
              <div className="pt-2">
              <p>Last Updated 2 Min ago</p>
            </div>
            </div>
         
          </div>
          <div className="col-md-6 col-sm-6 text-center" ref={containerRef}>
          <LineChart
        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
        series={[{ data: [2, 5.5, 2, 8.5, 1.5, 5] }]}
        width={chartSize.width}
        height={chartSize.height}
      />
            {/* <div id="chart-1">
              <ReactApexChart
                options={options}
                series={options.series}
                type="line"
                height={55}
                width={100}
              />
            </div> */}
            <div>
              <h4>Monthly Trends</h4>
              <p>( Last 6 Months )</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccupancyChart;
