import React, { useState } from 'react'
import { Loader } from '../shared/Loader';
import { Controller, useForm } from 'react-hook-form';
import { styled, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { usePutRentCollectionMutation } from '../redux/CommonApi';
import html2pdf from 'html2pdf.js';
export const HtmlString = ({ html, isLoading, error, handleRej, isShow, handleChange, status, paymentTypeid, putRentCollection, rentID,setReopenpopup }) => {

    // const [loader, setloader] = useState(false);
    if (isLoading) return <Loader />;
    if (error) return <div>Error loading receipt</div>;
    // const [putRentCollections] = usePutRentCollectionMutation()
    // const CustomLocalizationProvider = styled(LocalizationProvider)(
    //     ({ theme }) => ({
    //         display: "flex !important",
    //     })
    // );
    const downloadPdf = () => {
        const element = document.createElement('div');
        element.innerHTML = html;
    
        html2pdf()
          .set({
            margin: 1,
            filename: 'download.pdf',
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
          })
          .from(element)
          .save();
      };
    // const CustomDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
    //     fontFamily: "Arial, sans-serif",
    //     color: "#333",
    //     display: "flex !important",
    //     height: "0.4375em !important",
    // }));
    // const {
    //     register,
    //     control,
    //     reset,
    //     formState: { errors, isSubmitting },
    //     handleSubmit,
    //     setValue,
    //     getValues,
    //     watch,
    // } = useForm({
    //     defaultValues: {
    //         status: 11,
    //         chequeValueDate: dayjs().toISOString(),
    //         TransactionDate: dayjs().toISOString(),
    //         trxID: null,
    //         rentID: rentID,
    //     }
    // });
    // const onSubmit = async (data) => {
    //     data.rentID = rentID;
    //     setloader(true);
    //     try {
    //         await putRentCollections({
    //             updatedData: data,
    //         });
    //         setloader(false);
    //         setReopenpopup(false)
    //     } catch (error) {
    //         console.error("error", error);
    //         setloader(false);
    //         setReopenpopup(false)
    //     }
    // }
    return (
        <div>

            <div
                style={{
                    backgroundColor: "#ffffff",
                    padding: "20px",
                    borderRadius: "5px",
                    overflowX: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: html }}
            />
            {/* <div className='d-flex justify-content-end'>
            {status===11  && <button className='btn text-end' onClick={downloadPdf} style={{ padding: '10px 20px', fontSize: '16px' }}><i className='fa fa-download'></i></button>}
            {status===12  && <button className='btn text-end' onClick={downloadPdf} style={{ padding: '10px 20px', fontSize: '16px' }}> <i className='fa fa-download'></i></button>}
            </div> */}
           

            {/* {status === 12 && (
                <>
                    <div>
                        <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Reason of Rejection</h6>
                        <div className="px-3 pt-2">
                            <textarea
                                rows={2}
                                cols={2}
                                className="form-control form-textarea"
                                placeholder="Write about Request...."
                                name="description"
                                onChange={handleChange}

                            />
                        </div>
                    </div>

                    <div className='text-end py-4'>
                        <button
                            type="button"
                            className="btn btn-danger btn-radius"
                            id="middlecenterToastBtn"
                            onClick={() => handleRej()}
                        >
                            Submit
                        </button>
                    </div>
                </>
            )} */}
            {/* paymentTypeid===3 is Type Direct transfer */}
            {/* {status === 11 && (
                <>
                    {paymentTypeid === 3 && (<>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Reconaciliation Details</h6>
                            <div className="px-3 pt-2">
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Transaction ID</label>
                                        <input
                                            className="form-control addproperty-form"
                                            {...register("trxID", {
                                                required: "is required",
                                                onChange: (e) => setValue("trxID", e.target.value),
                                            })}
                                        />
                                        {errors.trxID && (
                                            <p style={{ color: "#9E2A2B" }}>
                                                {errors.trxID.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Transaction Date</label>
                                        <div className="form-control addproperty-form duedate-form">
                                            <Controller
                                                name="TransactionDate"
                                                control={control}
                                                rules={{
                                                    required: "Transaction Date is required",
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <CustomLocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <CustomDesktopDatePicker
                                                            value={field.value ? dayjs(field.value) : null} // Ensure value is a Dayjs object
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    field.onChange(date.toISOString()); // Update the form value as an ISO string
                                                                }
                                                            }}
                                                            format="DD-MM-YYYY" // Set the display format
                                                        >
                                                            <TextField
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error?.message}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                        </CustomDesktopDatePicker>
                                                    </CustomLocalizationProvider>
                                                )}
                                            />

                                        </div>
                                    </div>

                                </div>
                                <div className='text-end py-4'>
                                    <button
                                        type="submit"
                                        className="btn btn-danger btn-radius"
                                        id="middlecenterToastBtn"
                                        disabled={loader}
                                    >
                                        {loader ? (
                                            <span className="spinner-border spinner-border-sm mr-1 "></span>
                                        ) : null}
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>)}

                </>
            )} */}
            {/* paymentTypeid===2 is Type Cheque */}
            {/* {status === 11 && paymentTypeid === 2 && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Reconaciliation Details</h6>
                        <div className="px-3 pt-2">
                            <div className="col-md-12">
                                <div className="form-wrap">
                                    <label className="col-form-label">Transaction Date</label>
                                    <div className="form-control addproperty-form duedate-form">
                                        <Controller
                                            name="TransactionDate"
                                            control={control}
                                            rules={{
                                                required: "Transaction Date is required",
                                            }}
                                            render={({ field, fieldState }) => (
                                                <CustomLocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDesktopDatePicker
                                                        value={field.value ? dayjs(field.value) : null} // Ensure value is a Dayjs object
                                                        onChange={(date) => {
                                                            if (date) {
                                                                field.onChange(date.toISOString()); // Update the form value as an ISO string
                                                            }
                                                        }}
                                                        format="DD-MM-YYYY" // Set the display format
                                                    >
                                                        <TextField
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                    </CustomDesktopDatePicker>
                                                </CustomLocalizationProvider>
                                            )}
                                        />

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="form-wrap">
                                    <label className="col-form-label">Cheque Value Date</label>
                                    <div className="form-control addproperty-form duedate-form">
                                        <Controller
                                            name="chequeValueDate"
                                            control={control}
                                            rules={{
                                                required: "cheque Value Date Date is required",
                                            }}
                                            render={({ field, fieldState }) => (
                                                <CustomLocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <CustomDesktopDatePicker
                                                        value={field.value ? dayjs(field.value) : null} // Ensure value is a Dayjs object
                                                        onChange={(date) => {
                                                            if (date) {
                                                                field.onChange(date.toISOString()); // Update the form value as an ISO string
                                                            }
                                                        }}
                                                        format="DD-MM-YYYY" // Set the display format
                                                    >
                                                        <TextField
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            fullWidth
                                                            variant="outlined"
                                                        />
                                                    </CustomDesktopDatePicker>
                                                </CustomLocalizationProvider>
                                            )}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className='text-end py-4'>
                                <button
                                    type="submit"
                                    className="btn btn-danger btn-radius"
                                    id="middlecenterToastBtn"
                                    disabled={loader}
                                >
                                        {loader ? (
                                            <span className="spinner-border spinner-border-sm mr-1 "></span>
                                        ) : null}
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </>
            )} */}
        </div>
    )
}
