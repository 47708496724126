import React, { useRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import {
  priorityEnum,
  statusEnumMapUrl,
  StatusInfo,
  unitStatusEnum,
  unitStatusEnumMap,
} from "../../redux/RequestTypeEnum";
import {
  useExpenseTransactionDetailsQuery,
  useExpenseTransactionsByPropertyMasterQuery,
  useExpenseUpdateRequestMutation,
  useGetExpensePropertyMasterTransactionsQuery,
} from "../../redux/CommonApi";
import toastr from "toastr";
import { Link } from "react-router-dom";
import { Loader } from "../../shared/Loader";
import { NoData } from "../../shared/NoData";
import { StackPagination } from "../../shared/StackPagination";
import { useSelector } from "react-redux";
import Approved from "../../../public/assets/img/stamps/Approved.svg";
import Rejected from "../../../public/assets/img/stamps/Rejected.svg";
import { ResponseMessage } from "../Models/GenericModel/ResponseMessage";
import { PropertyMasterTransactions } from "../Models/Expense/PropertyMasterTransactions";
import { ExpenseDetail } from "../Models/Expense/ExpenseDetail";
import { UpdateExpenseStatus } from "../Models/Expense/UpdateExpenseStatus";

export const ExpenseTransactions = () => {
  const propertyMasterID =
    useSelector((state: any) => state.CRMS.ExpensePropertyMasterId) ?? null;
  const landlordId = sessionStorage.getItem("userId");
  const [isTransactionDetail, setTransactionDetail] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState(18);
  const [selectedPriority, setSelectedPriority] = React.useState("View All");
  const [loader, setloader] = useState(false);
  const [expenseID, setexpenseID] = useState(null);
  const [showRejectInput, setShowRejectInput] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");
  const filteredtransactionsStatus = {
    "View All": "",
    Pending: 8,
    Approved: 11,
    Rejected: 12,
  };

  const handleReopenClose = () => {
    setTransactionDetail(false);
    setShowRejectInput(false);
  };
  const isPropertyMasterValid =
    propertyMasterID !== undefined && propertyMasterID > 0;

  const {
    data: transactions,
    isLoading,
    error,
  } = useGetExpensePropertyMasterTransactionsQuery(
    {
      landLordID: +landlordId,
      pageNumber: pageNumber,
      rowNumbers: rowNumbers,
    },
    { skip: isPropertyMasterValid }
  ) as { data: ResponseMessage<PropertyMasterTransactions>, error: any, isLoading: boolean };

  const {
    data: ProperyMastertransactions,
    isLoading: isLoadingProperyMastertransactions,
    error: errorProperyMastertransactions,
  } = useExpenseTransactionsByPropertyMasterQuery({
    propertyMasterID: propertyMasterID,
    landLordID: +landlordId,
    pageNumber: pageNumber,
    rowNumbers: rowNumbers,
  },
  { skip: propertyMasterID === null }
) as { data: ResponseMessage<PropertyMasterTransactions>, error: any, isLoading: boolean };;

  const displayData = isPropertyMasterValid
    ? ProperyMastertransactions?.data
    : transactions?.data;

  const displayCount = isPropertyMasterValid
    ? ProperyMastertransactions?.totalCount
    : transactions?.totalCount;

  const isload = isPropertyMasterValid
    ? isLoadingProperyMastertransactions
    : isLoading;

  const handleStatusSelect = (
    status: keyof typeof filteredtransactionsStatus
  ) => {
    setSelectedPriority(status);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
  };
  // const filteredTransactions =
  //   displayData?.filter((transaction) =>
  //     transaction.subCategory.toLowerCase().includes(searchTerm.toLowerCase())
  //   ) || [];
  const filteredTransactions =
    displayData?.filter(
      (transaction) =>
        transaction?.subCategory
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) &&
        (selectedPriority === "View All" ||
          transaction?.expenseStatus ===
            filteredtransactionsStatus[selectedPriority])
    ) || [];

  const transactionClick = (event, id) => {
    event.preventDefault();
    setexpenseID(id);
    setTransactionDetail(!isTransactionDetail);
  };

  const { 
    data: transactionDetail, 
    isLoading: isLoadingTransactionDetails, 
    error: transactionDetailError 
  } = useExpenseTransactionDetailsQuery(
    { expenseID },
    { skip: expenseID === null }
  ) as { 
    data: ResponseMessage<ExpenseDetail>; 
    error: any; 
    isLoading: boolean; 
  };
  
  
  const [updateExpenseTransaction] = useExpenseUpdateRequestMutation();

  const handleAction = async (status) => {
    setloader(true);
    const expenseId = expenseID;

    // const actionObject = {
    //   expenseId,
    //   status,
    //   feedback,
    // };

    const actionObject = new UpdateExpenseStatus(expenseId, status, feedback);
    const result = await updateExpenseTransaction(actionObject); 
    setTransactionDetail(false);
    setShowRejectInput(false);
    toastr.success(
      `Request ${
        status === unitStatusEnum.Approved ? "Approved" : "Rejected"
      } successfully`
    );
    setloader(false);
    setFeedback("");
  };

  if (isload) return <Loader />;

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="card pb-5">
          <div className="card-body">
            <div className="row pb-2">
              <div className="d-flex justify-content-between ">
                <h5 className="card-title">Transactions</h5>
                <div className="col-md-6 text-end">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                     className="rounded-5"
                    >
                      {selectedPriority}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                     className="drop-down-menu"
                    >
                      <div className="profilename">
                        {Object.keys(filteredtransactionsStatus).map(
                          (statusKey) => (
                            <Dropdown.Item
                              key={statusKey}
                              className={`dropdown-item ${
                                statusKey === "Pending"
                                  ? "status-pending"
                                  : statusKey === "Approved"
                                  ? "status-approved"
                                  : statusKey === "View All"
                                  ? "status-view-all"
                                  : "status-default"
                              }`} 
                              onClick={() =>
                                handleStatusSelect(
                                  statusKey as keyof typeof filteredtransactionsStatus
                                )
                              }
                            >
                              {statusKey}
                            </Dropdown.Item>
                          )
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-md-9 "></div>
              
              <div className="col-md-3 text-end">
                <div className="form-wrap icon-form">
                  <span className="form-icon">
                    <i className="ti ti-search" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search transactions"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            
            </div>

            <div className="row gx-3 gy-3">
              {filteredTransactions && filteredTransactions.length > 0 ? (
                filteredTransactions.map((list, index) => {
                  const statusInfo =
                    statusEnumMapUrl[list.expenseStatus] || null;
                  return (
                    <div 
                      className="col-md-12 col-lg-12 col-xl-6 col-xxl-4 all_tenants cursor-pointer"
                      key={index}
                      onClick={(e) => transactionClick(e, list.expenseID)}
                    >
                      <div
                        className="card m-0 card-shadow-1"
                        // onClick={() => handleClick(list.rentCollectionStatus, list.rentID, list.paymentTypeID)}
                      >
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                              <div className="d-flex flex-column flex-sm-row gap-3 align-items-center">
                                <div className=" sm-user">
                                  <div className="transaction-image-wrapper">
                                    <img
                                      src={list.iconUrl}
                                      alt={list.iconUrl}
                                    />
                                  </div>
                                </div>

                                <div className="text-start py-2 text-truncate">
                                  <h5 className="m-0 pb-2 sm-user text-truncate">
                                    {list.subCategory}
                                  </h5>
                                  <p className="sm-user">
                                    {" "}
                                    {dayjs(list.expenseDate).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className=" text-center py-2">
                                <h5 className="m-0 pb-2 sm-user">Amount</h5>
                                <h6 className="m-0">
                                  OMR {list.expenseAmount.toFixed(1)}
                                </h6>
                              </div>
                              {/* <div className="col-sm-1 py-1 text-end"></div> */}
                              <div className=" py-2 text-end">
                                {/* <h5 className="text-center m-0 pb-2">Status</h5> */}
                                <p
                                  // className="text-center"
                                  // style={{
                                  //   color:
                                  //     statusInfo?.text === "Pending"
                                  //       ? "orange"
                                  //       : statusInfo?.color,
                                  // }}
                                  className={`text-center badge badge-pill badge-status ${statusInfo?.text === "Pending" ? "bg-warning" : statusInfo?.text === "Approved" ? "bg-success" : "bg-danger"}`}

                                >
                                  {statusInfo?.text || "Unknown Status"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <NoData />
                </div>
              )}
            </div>
          </div>
        </div>

        {(displayCount > 0 ) && (
          <div className="row row1 fixed-bottom1">
            <div className="d-flex justify-content-center">
              <StackPagination
                tenancyRequestCount={displayCount}
                rowNumbers={rowNumbers}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        )}

        <div
          className={
            isTransactionDetail ? "toggle-popup sidebar-popup" : "toggle-popup"
          }
        >
          <div className="sidebar-layout w-md-25 w-lg-25 w-sm-100">
            <div className="sidebar-header">
              <h4>Transaction Detail</h4>
              <Link
                to="#"
                className="sidebar-close toggle-btn"
                onClick={handleReopenClose}
              >
                <i className="ti ti-x" />
              </Link>
            </div>

            <div className="maintenance_Reopen pt-0 p-4">
              <div className="row mt-4">
                <div className="align-items-center d-flex justify-content-between">
                  <div className="align-items-center d-flex">
                    <div className="transaction-image-wrapper me-3">
                      <img
                        className="rounded-circle"
                        src={transactionDetail?.data?.[0].iconUrl}
                        alt="Transaction Icon"
                        style={{
                          width: "41px",
                          height: "41px",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    <div className="text-start">
                      <p className="m-0 pb-1 text-dark fw-bold">
                        {transactionDetail?.data?.[0].category}
                      </p>
                      <p className="text-muted mb-0 small">
                        {transactionDetail?.data?.[0].subCategory}
                      </p>
                    </div>
                  </div>
                  <div className="text-end py-2">
                    <p className="text-muted mb-0 small">
                      {dayjs(transactionDetail?.data?.[0].expenseDate).format(
                        "DD MMM YYYY"
                      )}
                    </p>
                    <p className="text-danger mb-0 small">
                      OMR {transactionDetail?.data?.[0].amount.toFixed(1)}
                    </p>
                  </div>
                </div>

                <div className="popupdetail p-0 mt-2">
                  <div className="p-3 pb-0">
                    <h6 className="text-dark fw-bold expenseDetail-outline mb-2">
                      Expense against by
                    </h6>
                    <div className="expenseDetail-outline mb-2">
                      <p className="d-flex align-items-center gap-2">
                        <span
                          className="success-circle custom-circle-green"
                          
                        ></span>
                        <span className="fw-bold">Building ID: </span>{" "}
                        <span className="text-muted">
                          {" "}
                          {transactionDetail?.data?.[0].buildingID}
                        </span>
                      </p>
                    </div>
                    {transactionDetail?.data?.[0].unitID ? (
                    <div className="expenseDetail-outline mb-2">
                      <p className="d-flex align-items-center gap-2">
                        <span
                          className="success-circle custom-circle-orange"
                          
                        ></span>
                        <span className="fw-bold">Unit ID: </span>{" "}
                        <span className="text-muted">
                          {" "}
                          {transactionDetail?.data?.[0].unitID}{" "}
                        </span>
                      </p>
                    </div>
                  ): null}
                    <div className="expenseDetail-outline mb-2">
                      <p>
                        <span className="fw-bold">Receipt No: </span>
                        {transactionDetail?.data?.[0].receiptNo}
                      </p>
                    </div>
                    {transactionDetail?.data?.[0].expenseDetail && (
                    <div className="expenseDetail-outline mb-2"
                    style={{height:"auto"}}>
                      <p>
                        <span className="fw-bold">Expense Detail: </span>
                        {transactionDetail?.data?.[0].expenseDetail}
                      </p>
                    </div>
                    )}

                   
                      {transactionDetail?.data?.[0].status ===
                        unitStatusEnum.Rejected && (
                        <>
                         <div className="expenseDetail-outline mb-2"
                         style={{height:"auto"}}>
                          {" "}
                          <p>
                            <span className="fw-bold">Rejection Review </span>{" "}
                            {transactionDetail?.data?.[0].feedback}
                          </p>
                          </div>
                        </>
                      )}
                 
                   
                      {(transactionDetail?.data?.[0].status ===
                        unitStatusEnum.Approved ||
                        transactionDetail?.data?.[0].status ===
                          unitStatusEnum.Rejected) && (
                        <>
                         <div className="expenseDetail-outline mb-2">
                          <p>
                            <span className="fw-bold">
                              {" "}
                              {transactionDetail?.data?.[0].status ===
                              unitStatusEnum.Approved
                                ? "Approved by: "
                                : transactionDetail?.data?.[0].status ===
                                  unitStatusEnum.Rejected
                                ? "Rejected by: "
                                : ""}
                            </span>
                            {transactionDetail?.data?.[0].updatedBy}
                          </p>
                          </div>
                          <div className="expenseDetail-outline mb-2">
                          <p>
                            <span className="fw-bold">
                              {transactionDetail?.data?.[0].status ===
                              unitStatusEnum.Approved
                                ? "Approved Date: "
                                : transactionDetail?.data?.[0].status ===
                                  unitStatusEnum.Rejected
                                ? "Rejected Date: "
                                : ""}{" "}
                            </span>
                            {dayjs(
                              transactionDetail?.data?.[0].updatedTime
                            ).format("DD MMM YYYY")}
                          </p>
                          </div>
                        </>
                      )}
                    
                  
                      {showRejectInput && (
                          <div className=" ">
                             <p className="m-0 ps-2 mb-2">
                        <span className="fw-bold">Reason of Rejection </span>
                        
                      </p>
                        <div className="">
                          <textarea
                            className="form-control mb-2 shadow rounded-3"
                            placeholder="Enter rejection feedback..."
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                          ></textarea>
                          <button
                            className="btn btn-danger w-100 rounded-5 p-3 border-0"
                            onClick={() => handleAction(unitStatusEnum.Rejected)}
                            disabled={!feedback.trim() || loader}
                          >
                            {loader ? (
                              <>
                                <i className="fa fa-spinner fa-spin text-black"></i>
                              </>
                            ) : null}
                            Submit
                          </button>
                        </div>
                        </div>
                      )}
                 

                    {transactionDetail?.data?.[0].status === 8 &&
                    showRejectInput === false ? (
                      <>
                        <div className="d-flex justify-content-center pt-2">
                          <button
                            disabled={loader}
                            className="btn btn-success w-100 rounded-5 p-3 border-0 opacity-75"
                            onClick={() => handleAction(unitStatusEnum.Approved)}
                          >
                            {loader ? (
                              <>
                                <i className="fa fa-spinner fa-spin text-black"></i>
                              </>
                            ) : null}
                            Approve
                          </button>
                        </div>

                        <div className="d-flex justify-content-center pt-2">
                          <button
                            className="btn btn-primary w-100 rounded-5 p-3 border-0 opacity-75"
                            onClick={() => setShowRejectInput(true)}
                          >
                            Reject
                          </button>
                        </div>
                      </>
                    ) : !showRejectInput ? (
                      <>
                        <div className="text-center mt-4">
                          <img
                            style={{ width: "108px" }}
                            src={
                              transactionDetail?.data?.[0].status ===
                              unitStatusEnum.Approved
                                ? Approved
                                : transactionDetail?.data?.[0].status ===
                                  unitStatusEnum.Rejected
                                ? Rejected
                                : null
                            }
                            alt="default"
                          />
                        </div>
                      </>
                    ) : null}

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
