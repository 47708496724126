import React from "react";
import { Link } from "react-router-dom";
import {
  useGetLandlordBuildingUnitsQuery,
  useGetOwnerPropertyMasterCountQuery,
  useGetOwnerPropertyMasterQuery,
} from "../../redux/CommonApi";
import { StackPagination } from "../../shared/StackPagination";
import { Loader } from "../../shared/Loader";
import { NoData } from "../../shared/NoData";
import "../../style/scss/expense.scss";
import { OwnerPropertyMaster } from "../Models/Pms/OwnerPropertyMaster";

const ExpenseBuildingPopup = ({
  selectedBuildings,
  setSelectedBuildings,
  setpropertyMasterID,
  propertyMasterID,
  setBuildingPopUp,
  setSaveSelectedUnits,
  saveSelectedUnits,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowNumber, setrowNumber] = React.useState(10);
  const [buildingNumber, setBuildingNumber] = React.useState(null);
  const [tempSelectedBuildings, setTempSelectedBuildings] =
    React.useState(selectedBuildings);
    console.log("lg",selectedBuildings)
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";

  const isPropertyMasterValid =
    propertyMasterID !== undefined && propertyMasterID > 0;

  const { data: propertymasterdata, isLoading: propertymasterisLoading } =
    useGetOwnerPropertyMasterQuery(
      {
        pageNumber: pageNumber,
        rowNumber: rowNumber,
        userID: +sessionStorage.getItem("userId"),
        Status: 1,
      },
      { skip: isPropertyMasterValid }
    )as {data:OwnerPropertyMaster[], error:any, isLoading:boolean};
  const { data: propertymasterCount, isLoading: propertymasterCountisLoading } =
    useGetOwnerPropertyMasterCountQuery(
      {
        userID: +sessionStorage.getItem("userId"),
        Status: 1,
      },
      { skip: isPropertyMasterValid }
    );

  const { data: BuildingUnits, isLoading: BuildingUnitsisLoading } =
    useGetLandlordBuildingUnitsQuery(
      {
        propertyMasterID: propertyMasterID,
      },
      { skip: !isPropertyMasterValid }
    );

  const handlePropertyClick = (item) => {
    setpropertyMasterID(item.propertyMasterID);
    setBuildingNumber(item.buildingNumber)
    setTempSelectedBuildings((prev) => {
      if (!prev.some((building) => building.propertyMasterID === item.propertyMasterID)) {
        return [...prev, { propertyMasterID: item.propertyMasterID, buildingNumber: item.buildingNumber, units: [] }];
      }
      return prev;
    });
  };

  const handleUnitClick = (unitID, imageUrl) => {
    setTempSelectedBuildings((prev) => {
      const buildingIndex = prev.findIndex(
        (building) => building.propertyMasterID === propertyMasterID
      );
      if (buildingIndex === -1 && propertyMasterID !== 0) {
        return [...prev, { propertyMasterID, units: [{ unitID, imageUrl }] }];
      }

      const updatedBuildings = [...prev];
      if (buildingIndex !== -1) {
        const building = updatedBuildings[buildingIndex];
        const unitExists = building.units.some(
          (unit) => unit.unitID === unitID
        );

        if (unitExists) {
          building.units = building.units.filter(
            (unit) => unit.unitID !== unitID
          );
        } else {
          building.units.push({ unitID, imageUrl });
        }
      }

      return updatedBuildings.filter((building) => building.units.length > 0);
    });
  };

  const handleBuildingPopupClose = () => {
    const buildingHasNoUnits = selectedBuildings.some(
      (building) =>
        building.propertyMasterID === propertyMasterID &&
        building.units.length === 0
    );

    if (buildingHasNoUnits) {
      setSelectedBuildings((prev) =>
        prev.filter(
          (building) => building.propertyMasterID !== propertyMasterID
        )
      );
    }
    setpropertyMasterID(0);

    setBuildingPopUp(false);
  };

  const isActiveBuilding = (propertyMasterID) =>
    tempSelectedBuildings.some((b) => b.propertyMasterID === propertyMasterID);

  const isActiveUnit = (unitID) =>
    tempSelectedBuildings.some(
      (b) =>
        b.propertyMasterID === propertyMasterID &&
        b.units.some((unit) => unit.unitID === unitID)
    );

  // const SaveClick = (e) => {
  //   e.preventDefault();

  //   setSelectedBuildings((prev) => {
  //     const updatedBuildings = prev
  //       .map((building) => {
  //         if (building.propertyMasterID === propertyMasterID && building.units.length === 0) {
  //           return null;
  //         }
  //         return building;
  //       })
  //       .filter((building) => building !== null);

  //     return updatedBuildings;
  //   });

  //   setSaveSelectedUnits(selectedBuildings)
  //   setpropertyMasterID(0);
  //   setSearchTerm("");
  // };
  const SaveClick = (e) => {
    e.preventDefault();
    debugger
    // Update tempSelectedBuildings and directly use the updated value
    const updatedBuildings = tempSelectedBuildings
    .map((building) => {
      if (building.propertyMasterID === propertyMasterID && building.units.length === 0) {
        debugger;
        return null; // Mark buildings with no units for removal
      }
      debugger;
      return building; // Keep the building otherwise
    })
    .filter((building) => building !== null); // Remove null buildings

  // Update state with the updated buildings
  setTempSelectedBuildings(updatedBuildings);
  setSelectedBuildings(updatedBuildings);
  setSaveSelectedUnits(updatedBuildings);

  // Reset other values
  setpropertyMasterID(0);
  setSearchTerm("");
    // setTempSelectedBuildings((prev) => {
    //   const updatedBuildings = prev
    //     .map((building) => {
    //       if (building.propertyMasterID === propertyMasterID && building.units.length === 0) {
    //         debugger
    //         return null;
    //       }
    //       debugger
    //       return building;
    //     })
    //     .filter((building) => building !== null);

    //   return updatedBuildings;
    // });
    // setSelectedBuildings(tempSelectedBuildings);
    // setSaveSelectedUnits(tempSelectedBuildings);
    
    // setpropertyMasterID(0);
    // setSearchTerm("");
  };
  // const SaveClick = React.useCallback((e) => {
  //   e.preventDefault();
  //   debugger
  //   setTempSelectedBuildings((prev) => {
  //     const updatedBuildings = prev
  //       .map((building) => {
  //         if (building.propertyMasterID === propertyMasterID && building.units.length === 0) {
  //           debugger
  //           return null;
  //         }
  //         debugger
  //         return building;
  //       })
  //       .filter((building) => building !== null);

  //     return updatedBuildings;
  //   });
  //   setSelectedBuildings(tempSelectedBuildings);
  // }, [tempSelectedBuildings]);


  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
  };
  const filteredPropertyMasterData = propertymasterdata?.filter(
    (item) =>
      item.buildingNumber.toString().includes(searchTerm) ||
      item.propertyName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredBuildingUnits = BuildingUnits?.data?.filter(
    (item) =>
      item.unitID.toString().includes(searchTerm) ||
      item.unitName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (propertymasterisLoading || BuildingUnitsisLoading) return <Loader />;

  return (
    <div className="sidebar-layout w-md-25 w-lg-25 w-sm-100">
      <div className="sidebar-header border-0">
        <h4>{propertyMasterID === 0 ? "Select Building" : "Select Units"}</h4>
        {propertyMasterID === 0 && (
          <Link
            to="#"
            className="sidebar-close toggle-btn"
            onClick={handleBuildingPopupClose}
          >
            <i className="ti ti-x" />
          </Link>
        )}
      </div>
      <div className="maintenance_Reopen pt-0 p-4">
        <div className="row">
          <div className="col-md-12 text-end">
            <div className="form-wrap icon-form">
              <span className="form-icon">
                <i className="ti ti-search" />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={
                  propertyMasterID === 0 ? "Search Buildings" : "Search Units"
                }
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          {propertyMasterID === 0 && (
            <div className="col-md-12 text-end">
              <div className="form-wrap ">
                <h6 className="text-muted">
                  {selectedBuildings.length} Selected
                </h6>
              </div>
            </div>
          )}
        </div>
        {propertyMasterID === 0 ? (
          <div className="row g-3">
            {filteredPropertyMasterData?.map((item) => (
              <div
                key={item.propertyMasterID}
                className="col-6 col-lg-6 col-md-6 col-sm-6"
                onClick={() => handlePropertyClick(item)}
              >
                <div
                  className={`card1 ${isActiveBuilding(item.propertyMasterID)
                      ? "active-card1 "
                      : ""
                    }`}
                >
                  <div className="card-body pt-2 text-center ">
                    <img
                      className="expense-building-img"
                      src={item.imageString || fallbackImage}
                      alt="Card"
                    />
                    <p className="card-text text-center m-0 py-2 fw-semibold page-title">
                      Building ID: {item.buildingNumber}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {/* <i
              className="ti ti-arrow-narrow-left back-button-building cursor-pointer"
              onClick={handleBackClick}
              
            ></i> */}
            <div className="row g-3">
              {filteredBuildingUnits?.map((item) => (
                <div
                  key={item.unitID}
                  className="col-6 col-sm-6 col-lg-6 col-md-6"
                  onClick={() => handleUnitClick(item.unitID, item.imageUrl)}
                >
                  <div
                    className={`card1 ${isActiveUnit(item.unitID) ? "active-card1" : ""
                      }`}
                  >
                    <div className="card-body pt-2 text-center">
                      <img
                        src={item.imageUrl || fallbackImage}
                        alt="Card image cap"
                        className="expense-building-img"
                      />
                      {item.unitID === -1 ? (
                        <p className="card-text text-center m-0 py-2 fw-semibold page-title">
                          Building ID: {buildingNumber}
                        </p>
                      ) : (
                        <>
                          <p className="card-text text-center m-0 py-2 fw-semibold page-title">
                            Unit ID: {item.unitID}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-3 fixed-Save px-2">
              <button className="btn btn btn-primary px-5 " onClick={SaveClick}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
      {propertyMasterID === 0 &&
        propertymasterCount > 10 &&
        filteredPropertyMasterData.length > 0 && (
          <div className="sidebar-pagination-container">
            <div className="d-flex justify-content-center py-2">
              <StackPagination
                tenancyRequestCount={propertymasterCount}
                rowNumbers={10}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default React.memo(ExpenseBuildingPopup);
