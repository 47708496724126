import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import dayjs from "dayjs";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { json } from "stream/consumers";
import {
  useGetAgreementDetailQuery,
  useGetTransactionByAgIDQuery,
} from "../redux/CommonApi";
import { AgreementDetail } from "../feature-module/Models/RentCollection/AgreementDetail";
import { AgreementTransactionsDetail } from "../feature-module/Models/RentCollection/AgreementTransactionsDetail";
const RentDetailPopup = ({ handleReopenClose }) => {
  const { data, isLoading, error } = useGetAgreementDetailQuery({
    AgID: +sessionStorage.getItem("aggrementId"),
  }) as {data:AgreementDetail,isLoading:boolean,error:any};

  const { data: agreementTransactionDetail } = useGetTransactionByAgIDQuery({
    AgID: +sessionStorage.getItem("aggrementId"),
  }) as {data:AgreementTransactionsDetail[],isLoading:boolean,error:any};
  const tbodyRef = useRef(null);

  useEffect(() => {
    if (
      Array.isArray(agreementTransactionDetail) &&
      agreementTransactionDetail.length > 3 &&
      tbodyRef.current
    ) {
      tbodyRef.current.scrollTop = tbodyRef.current.scrollHeight;
    }
  }, [agreementTransactionDetail]);

  return (
    <>
      <div className="sidebar-layout w-md-25 w-lg1-25 w-xxl-25 w-sm-100">
        <>
          <div className="sidebar-header">
            <h6 className="page-title AwaitingApprovalPopup-h6 ">
              Rent History Detail
            </h6>

            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={handleReopenClose}
            >
              <i className="ti ti-x" />
            </Link>
          </div>

          <div className="row p-2 pb-2 ">
            <div className="px-3 pt-2">
              <div className=" AwaitingApprovalPopup-outline">
                <div className="col-sm-6 ">
                  <p className="page-title">Landlord ID </p>
                </div>
                <div className="col-sm-6 text-end ">
                  <p className="page-title">{data?.landLordID}</p>
                </div>
              </div>
            </div>

            <div className="px-3 pt-2">
              <div className=" AwaitingApprovalPopup-outline">
                <div className="col-sm-6 ">
                  <p className="page-title">Tenant ID</p>
                </div>
                <div className="col-sm-6 text-end ">
                  <p className="page-title">{data?.tenantID}</p>
                </div>
              </div>
            </div>

            <div className="px-3 pt-2">
              <div className=" AwaitingApprovalPopup-outline">
                <div className="col-sm-6 ">
                  <p className="page-title">Unit ID</p>
                </div>
                <div className="col-sm-6 text-end ">
                  <p className="page-title">{data?.rentalUnitID} </p>
                </div>
              </div>
            </div>

            <div className="px-3 pt-2">
              <div className=" AwaitingApprovalPopup-outline">
                <div className="col-sm-6 ">
                  <p className="page-title ">Rent</p>
                </div>
                <div className="col-sm-6 text-end ">
                  <p className="page-title">{data?.rentAmount.toFixed(1)} </p>
                </div>
              </div>
            </div>

            <div className="px-3 pt-2">
              <div className=" AwaitingApprovalPopup-outline">
                <div className="col-sm-6">
                  <p className="page-title ">Security deposit</p>
                </div>
                <div className="col-sm-6 text-end ">
                  <p className="page-title">{data?.advanceAmount.toFixed(1)} </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-4 pb-2">
            <div
              className="card shadow p-1"
              style={{ height: "170px", overflowY: "auto" }}
            >
              <h6 className="page-title AwaitingApprovalPopup-h6 px-3 p-2">
                Rent History
              </h6>
              <div className="table-responsive px-3 pb-2">
                <table className="table table-responsive">
                  <thead style={{ position: "sticky", top: "0px" }}>
                    <tr className="tr-border-n">
                      <th>Date</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody
                    ref={tbodyRef}
                    style={{ maxHeight: "200px", overflowY: "auto",cursor:"pointer",overflow:"auto" }}
                  >
                    {agreementTransactionDetail &&
                      agreementTransactionDetail.map((item) => (
                        <tr className="tr-border-n" key={item.id}>
                          <td key={item.id}>
                            {dayjs(item.amountReceivedDate).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>{item.paymentType.desc}</td>
                          <td>{item.status === 11 ? "Paid" : "Pending"}</td>
                          <td>OMR<span className="ps-2">{item.rentAmount.toFixed(1)}</span></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row px-4">
            <p>*The security Deposit is refundable</p>
            <div className="card shadow p-1">
              <div className="px-3 pt-2">
                <div className=" AwaitingApprovalPopup-outline">
                  <div className="col-sm-6 ">
                    <p className="page-title ">Term</p>
                  </div>
                  <div className="col-sm-6 text-end ">
                    <span className=" badge badge-pill badge-status bg-danger-subtle text-black p-2">
                      {data?.terms} months
                    </span>
                  </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                  <div className="d-flex flex-column align-items-center ">
                    <span className="border border-danger d-flex align-items-center page-title justify-content-center px-3 py-2 rounded-3">
                      {dayjs(data?.aggStartDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  <div className="flex-grow-1 border-top border-danger "></div>
                  <div className="d-flex flex-column align-items-center ">
                    <span className="border border-danger d-flex align-items-center page-title justify-content-center px-3 py-2 rounded-3">
                      {dayjs(data?.aggEndDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="mt-2 text-black text-center  ps-4">
                    Move-in
                  </h6>
                  <h6 className="mt-2 text-black text-center  me-3">
                    Move-out
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default RentDetailPopup;
