
export interface IAgreementRentHistory  {
  agreementID: number;
  rentMonthName: string;
  rentRecDate: string; 
  rentReceived: number;
  rentyear: string;
  rownumberId: number;
  totalCount: number | null;
  }
  export class AgreementRentHistory implements IAgreementRentHistory {
    agreementID: number;
    rentMonthName: string;
    rentRecDate: string; 
    rentReceived: number;
    rentyear: string;
    rownumberId: number;
    totalCount: number | null;

    constructor(data: AgreementRentHistory) {
     this.agreementID = data.agreementID ?? 0;
      this.rentMonthName = data.rentMonthName ?? "";
      this.rentRecDate = data.rentRecDate ?? "";
      this.rentReceived = data.rentReceived ?? 0;
      this.rentyear = data.rentyear ?? "";
      this.rownumberId = data.rownumberId ?? 0;
      this.totalCount = data.totalCount ?? 0;

    }
  }
  