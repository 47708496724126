import React from "react";
import { Link } from "react-router-dom";
import { useGetuserSmartNotificationQuery } from "../../../redux/CommonApi";

const SubNotificationTabs = ({ subTypes, activeSubTab, setActiveSubTab }) => {

  const userId = sessionStorage.getItem("userId");

  // const { data, error: incomingError, isLoading: incomingLoading } = useGetuserSmartNotificationQuery({ userID: userId, requestType: subTypes.id });

  return (
    <>
    <ul className="nav nav-tabs nav-tabs-bottom pt-3">
      {subTypes.map((subType) => (
        <li className="nav-item" key={subType.id}>
          <Link
            className={`nav-link ${
              activeSubTab === subType.subTypeName ? "active" : ""
            }`}
            to={`#bottom-tab${subType.id}`}
            onClick={(event) => setActiveSubTab(subType.subTypeName, event)}
          >
            {subType.subTypeName}
          </Link>
        </li>
      ))}
    </ul>


</>
  );
};

export default SubNotificationTabs;
