import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setThemeSettings } from "../../redux/Commonslice";
import img1 from "../../../public/assets/img/theme/theme-04.png";
import img2 from "../../../public/assets/img/theme/theme-05.png";
import blue from "../../../public/assets/img/theme/theme-07.png";
import green from "../../../public/assets/img/theme/theme-08.png";
import dark from "../../../public/assets/img/theme/theme-06.png";
import sidebar1 from "../../../public/assets/img/theme/theme-09.png";
import sidebar2 from "../../../public/assets/img/theme/theme-10.png";
import sidebar3 from "../../../public/assets/img/theme/theme-11.png";
import sidebar4 from "../../../public/assets/img/theme/theme-12.png";

const ThemeSettings = () => {
  const dispatch = useDispatch();
  const themeOpen = useSelector((state: any) => state.CRMS.themeSettings);

  const [layoutTheme, setLayoutTheme] = useState(
    localStorage.getItem("dataTheme") || "light"
  );
  const [layoutColor, setLayoutColor] = useState(
    localStorage.getItem("dataColor") || "red"
  );
  const [layoutBar, setLayoutBar] = useState(
    localStorage.getItem("dataSidebar") || "light"
  );

  const themeDark = () => {
    localStorage.setItem("dataTheme", "dark");
    setLayoutTheme("dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const themeLight = () => {
    localStorage.setItem("dataTheme", "light");
    setLayoutTheme("light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  const colorRed = () => {
    localStorage.setItem("dataColor", "red");
    setLayoutColor("red");
    document.documentElement.setAttribute("data-color", "red");
  };

  const colorYellow = () => {
    localStorage.setItem("dataColor", "yellow");
    setLayoutColor("yellow");
    document.documentElement.setAttribute("data-color", "yellow");
  };

  const colorBlue = () => {
    localStorage.setItem("dataColor", "blue");
    setLayoutColor("blue");
    document.documentElement.setAttribute("data-color", "blue");
  };

  const colorGreen = () => {
    localStorage.setItem("dataColor", "green");
    setLayoutColor("green");
    document.documentElement.setAttribute("data-color", "green");
  };

  const sidebarLight = () => {
    localStorage.setItem("dataSidebar", "light");
    setLayoutBar("light");
    document.documentElement.setAttribute("data-sidebar", "light");
  };

  const sidebarDark = () => {
    localStorage.setItem("dataSidebar", "dark");
    setLayoutBar("dark");
    document.documentElement.setAttribute("data-sidebar", "dark");
  };

  const sidebarBlue = () => {
    localStorage.setItem("dataSidebar", "blue");
    setLayoutBar("blue");
    document.documentElement.setAttribute("data-sidebar", "blue");
  };

  const sidebarGreen = () => {
    localStorage.setItem("dataSidebar", "green");
    setLayoutBar("green");
    document.documentElement.setAttribute("data-sidebar", "green");
  };

  const sidebarBgOne = () => {
    localStorage.setItem("dataSidebar", "sidebarbg1");
    setLayoutBar("sidebarbg1");
    document.documentElement.setAttribute("data-sidebar", "sidebarbg1");
  };

  const sidebarBgTwo = () => {
    localStorage.setItem("dataSidebar", "sidebarbg2");
    setLayoutBar("sidebarbg2");
    document.documentElement.setAttribute("data-sidebar", "sidebarbg2");
  };

  const sidebarBgThree = () => {
    localStorage.setItem("dataSidebar", "sidebarbg3");
    setLayoutBar("sidebarbg3");
    document.documentElement.setAttribute("data-sidebar", "sidebarbg3");
  };

  const sidebarBgFour = () => {
    localStorage.setItem("dataSidebar", "sidebarbg4");
    setLayoutBar("sidebarbg4");
    document.documentElement.setAttribute("data-sidebar", "sidebarbg4");
  };

  const resetData = () => {
    localStorage.setItem("dataTheme", "light");
    localStorage.setItem("dataColor", "red");
    localStorage.setItem("dataSidebar", "light");
    setLayoutTheme("light");
    setLayoutColor("red");
    setLayoutBar("light");
    document.documentElement.setAttribute("data-theme", "light");
    document.documentElement.setAttribute("data-color", "red");
    document.documentElement.setAttribute("data-sidebar", "light");
  };

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", layoutTheme);
    document.documentElement.setAttribute("data-color", layoutColor);
    document.documentElement.setAttribute("data-sidebar", layoutBar);
  }, [layoutTheme, layoutColor, layoutBar]);

  return (
    <div>
      <div className="sidebar-contact">
        <div
          className="toggle-theme"
          onClick={() => dispatch(setThemeSettings(!themeOpen))}
        >
          <i className="fa fa-cog fa-w-16 fa-spin" />
        </div>
      </div>
      <div className={`sidebar-themesettings ${themeOpen ? "open" : ""}`}>
        <div className="themesettings-header">
          <h4>Theme Customizer</h4>
          <Link
            to="#"
            id="theme-settings"
            onClick={() => dispatch(setThemeSettings(!themeOpen))}
          >
            <i className="ti ti-x" />
          </Link>
        </div>
        <div className="themesettings-inner">
          <div className="themesettings-content">
            <h6>Layout</h6>
            <div className="row">
              <div className="col-lg-6" onClick={themeLight}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="theme"
                    id="lightTheme"
                    defaultValue="light"
                    readOnly
                    checked={layoutTheme === "light"}
                  />
                  <label htmlFor="lightTheme" style={{ display: "flow" }}>
                    <img src={img1} alt="img" />
                    <span className="w-100">
                      <span>Light</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6" onClick={themeDark}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="theme"
                    id="darkTheme"
                    defaultValue="dark"
                    readOnly
                    checked={layoutTheme === "dark"}
                  />
                  <label htmlFor="darkTheme" style={{ display: "flow" }}>
                    <img src={img2} alt="img" />
                    <span className="w-100">
                      <span>Dark</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="themesettings-content">
            <h6>Colors</h6>
            <div className="row">
              <div className="col-lg-12">
                <div className="theme-colorsset">
                  <ul>
                    <li>
                      <div className="input-themeselects" onClick={colorRed}>
                        <input
                          type="radio"
                          name="color"
                          id="redColor"
                          defaultValue="red"
                          readOnly
                          checked={layoutColor === "red"}
                        />
                        <label htmlFor="redColor" className="red-clr" />
                      </div>
                    </li>
                    <li>
                      <div className="input-themeselects" onClick={colorYellow}>
                        <input
                          type="radio"
                          name="color"
                          id="yellowColor"
                          defaultValue="yellow"
                          readOnly
                          checked={layoutColor === "yellow"}
                        />
                        <label htmlFor="yellowColor" className="yellow-clr" />
                      </div>
                    </li>
                    <li>
                      <div className="input-themeselects" onClick={colorBlue}>
                        <input
                          type="radio"
                          name="color"
                          id="blueColor"
                          defaultValue="blue"
                          readOnly
                          checked={layoutColor === "blue"}
                        />
                        <label htmlFor="blueColor" className="blue-clr" />
                      </div>
                    </li>
                    <li>
                      <div className="input-themeselects" onClick={colorGreen}>
                        <input
                          type="radio"
                          name="color"
                          id="greenColor"
                          defaultValue="green"
                          readOnly
                          checked={layoutColor === "green"}
                        />
                        <label htmlFor="greenColor" className="green-clr" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="themesettings-content">
            <h6>Sidebar</h6>
            <div className="row">
              <div className="col-lg-6" onClick={sidebarLight}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="lightSidebar"
                    defaultValue="light"
                    readOnly
                    checked={layoutBar === "light"}
                  />
                  <label htmlFor="lightSidebar" style={{ display: "flow" }}>
                    <img src={img1} alt="img" />
                    <span className="w-100">
                      <span>Light</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6" onClick={sidebarDark}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="darkSidebar"
                    defaultValue="dark"
                    readOnly
                    checked={layoutBar === "dark"}
                  />
                  <label htmlFor="darkSidebar" style={{ display: "flow" }}>
                    <img src={dark} alt="img" />
                    <span className="w-100">
                      <span>Dark</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6" onClick={sidebarBlue}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="blueSidebar"
                    defaultValue="blue"
                    checked={layoutBar === "blue"}
                    readOnly
                  />
                  <label htmlFor="blueSidebar" style={{ display: "flow" }}>
                    <img src={blue} alt="img" />
                    <span className="w-100">
                      <span>Blue</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6" onClick={sidebarGreen}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="greenSidebar"
                    defaultValue="green"
                    readOnly
                    checked={layoutBar === "green"}
                  />
                  <label htmlFor="greenSidebar" style={{ display: "flow" }}>
                    <img src={green} alt="img" />
                    <span className="w-100">
                      <span>Green</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="themesettings-content">
            <h6>Sidebar Background</h6>
            <div className="row">
              <div className="col-lg-6" onClick={sidebarBgOne}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="sidebarBg1"
                    defaultValue="sidebarbg1"
                    readOnly
                    checked={layoutBar === "sidebarbg1"}
                  />
                  <label htmlFor="sidebarBg1 " style={{ display: "flow" }}>
                    <img src={sidebar1} alt="img" />
                    <span className="w-100">
                      <span>Sidebar Bg 1</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6" onClick={sidebarBgTwo}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="sidebarBg2"
                    defaultValue="sidebarbg2"
                    readOnly
                    checked={layoutBar === "sidebarbg2"}
                  />
                  <label htmlFor="sidebarBg2" style={{ display: "flow" }}>
                    <img src={sidebar2} alt="img" />
                    <span className="w-100">
                      <span>Sidebar Bg 2</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6" onClick={sidebarBgThree}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="sidebarBg3"
                    defaultValue="sidebarbg3"
                    readOnly
                    checked={layoutBar === "sidebarbg3"}
                  />
                  <label htmlFor="sidebarBg3" style={{ display: "flow" }}>
                    <img src={sidebar3} alt="img" />
                    <span className="w-100">
                      <span>Sidebar Bg 3</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6" onClick={sidebarBgFour}>
                <div className="input-themeselect">
                  <input
                    type="radio"
                    name="sidebar"
                    id="sidebarBg4"
                    defaultValue="sidebarbg4"
                    readOnly
                    checked={layoutBar === "sidebarbg4"}
                  />
                  <label htmlFor="sidebarBg4" style={{ display: "flow" }}>
                    <img src={sidebar4} alt="img" />
                    <span className="w-100">
                      <span>Sidebar Bg 4</span>
                      <span className="checkboxs-theme" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="themesettings-footer">
            <ul>
              <li>
                <Link
                  to="#"
                  onClick={() => dispatch(setThemeSettings(!themeOpen))}
                  className="btn btn-cancel close-theme"
                >
                  Cancel
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  id="resetButton"
                  className="btn btn-reset"
                  onClick={resetData}
                >
                  Reset
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
