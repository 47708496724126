import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { getAllStaffManagement } from "../../Services/StaffServices";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core/styles";
import {
  AllSecurityGroups,
  AssignSecurityGrouptostaff,
  ChangeSecurityGroupStatus,
  getSecurityGroupStaff,
  RemoveUserFromSecurityGroup,
} from "../../Services/SecurityGroupServices";
import Chip from "@mui/material/Chip";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { startLoading, Loading } from "../../redux/Commonslice";
const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background: "#E41F07 !important",
    },
  },
}));

const SecurityGroupStaff = ({ staffObject }) => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [previousSelectedRow, setPreviousSelectedRow] = useState<number | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({ data: [], totalCount: null });
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState<number>(6);
  const [data, setData] = useState([]);
  const [SecurityGroupStaffs, setSecurityGroupStaff] = useState([]);
  const [dialogData, setDialogData] = useState(null);
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const UserID = sessionStorage.getItem("userId");
  const defaultimg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  useEffect(() => {
    if (staffObject && staffObject !== undefined) {
      staffofsecuritygroup(staffObject, pageNumber, rowNumbers);
    }
  }, [staffObject]);

  const handleClick = (staff) => {
    setDialogData(staff);
    setOpen(true);
  };
  const dispatch = useDispatch();

  const handleClose = (confirmed) => {
    if (confirmed) {
      RemoveUserFromSecurityGroup(dialogData).then(() => {
        const updatedstff = SecurityGroupStaffs.filter(
          (staff) => staff.staffId !== dialogData.staffId
        );
        setSecurityGroupStaff(updatedstff);
      });
      toastr.success("staff removed From security group");
      setOpen(false);
    }
    setOpen(false);
  };
  const staffofsecuritygroup = (staffObject, pageNumber, rowNumbers) => {
    const UserID = sessionStorage.getItem("userId");
    getSecurityGroupStaff(staffObject, UserID, pageNumber, rowNumbers).then(
      (resData) => {
        dispatch(startLoading());
        if (resData) {
          setSecurityGroupStaff(resData.data);
          setFormData(resData);
          dispatch(Loading());
          setLoading(false);
        }
      }
    );
  };

  const tableHeader = ["Staff Name", "Status", "Action"];
  const handlePageChange = (event, value) => {
    setPageNumber(value);
    staffofsecuritygroup(staffObject, value, rowNumbers);
  };

  return (
    <>
      <div
        className="modal custom-modal fade"
        id="StaffInSecurityGroup"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog1 modal-dialog-centered">
          <div className="modal-content">
            <Link
              to="#"
              className="sidebar-close toggle-btn text-end"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </Link>
            <div className="modal-body">
              {loading && (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="loading-spinners"></div>
                </div>
              )}
              {!loading && SecurityGroupStaffs.length === 0 && (<>
             
                <div
                      className="card d-flex align-items-center justify-content-center"
                      style={{ padding: "40px 0", border: "none" }}
                    >
                      <div className="card-body">
                        <img
                          src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                          alt="No data found"
                        />
                        <h3 className="text-center">No Data Found</h3>
                      </div>
                    </div>
                </> )}
              <div className="row">
                {SecurityGroupStaffs.length > 0 && !loading && (
                  <div className="col-md-12">
                    <div className="card main-card">
                      <div className="card-body">
                        <div className="col-sm-12 table-responsive">
                          <Table responsive hover>
                            <thead className="">
                              <tr>
                                {tableHeader.map((header, index) => (
                                  <th
                                    className="p-4"
                                    style={{
                                      position: "relative",
                                    }}
                                    key={index}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {SecurityGroupStaffs &&
                                SecurityGroupStaffs.map((staff, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={staff.documentPath || defaultimg}
                                          alt=""
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                          }}
                                          className="rounded-circle"
                                        />
                                        <div className="ms-3 text-truncate">
                                          <p className="staff-txt mb-1 text-capitalize text-truncate">
                                            {staff.name}
                                          </p>
                                          {/* <p className="staff-subtext mb-1 text-capitalize text-truncate">
                                            {staff.staffId}
                                          </p> */}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="">
                                      {staff.status === 1 && (
                                        <span className="badge badge-pill badge-status bg-success">
                                          Active
                                        </span>
                                      )}
                                      {staff.status === 8 && (
                                        <span className="badge badge-pill badge-status bg-warning">
                                          Pending
                                        </span>
                                      )}
                                      {staff.status === 2 && (
                                        <span className="badge badge-pill badge-status bg-danger">
                                          Inactive
                                        </span>
                                      )}
                                      {staff.status === 12 && (
                                        <span className="badge badge-pill badge-status bg-info">
                                          Rejected
                                        </span>
                                      )}
                                    </td>

                                    <td onClick={() => handleClick(staff)}>
                                      <span className="badge badge-pill badge-status bg-danger">
                                        Remove
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Stack spacing={2} mt={3}>
                            <Pagination
                              classes={{ ul: classes.ul }}
                              count={Math.ceil(
                                parseInt(formData.totalCount, 10) /
                                  parseInt(rowNumbers.toString(), 10)
                              )}
                              page={pageNumber}
                              onChange={handlePageChange}
                            />
                            {/* <div className="text-center">{`${pageNumber} – ${
                              pageNumber * rowNumbers
                            } of ${formData.totalCount} Staff available`}</div> */}
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal-wrapper">
        <Modal
          size="lg"
          show={open}
          onHide={() => handleClose(false)}
          animation={false}
          centered
          dialogClassName="centered-modal custom-modal-bg1 w-25 w-sm-100 "
        >
          <div className="modal-content-wrapper ">
          <div className="text-center">
            <img src="https://d38tibxba17kdz.cloudfront.net/StaffManagementImages/Remove.svg" />
          </div>
          <div className="text-center">{"Confirmation!"}</div>
            <Modal.Body>
              <div className="row pt-3">
              {dialogData && (
              <>
                <div className="text-center page-title">
                  Are you sure you want to take this user out of <br /> this
                  security group?
                </div>
              </>
            )}
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center align-items-center">
              <div className="pt-2">
              
               <button
            type="submit"
            className="border btn btn-secondary me-2 px-5"
            onClick={() => handleClose(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary px-5"
            onClick={() => handleClose(true)}
          >
            Remove
          </button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>

      {/* <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="text-center">
            <img src="https://d38tibxba17kdz.cloudfront.net/StaffManagementImages/Remove.svg" />
          </div>
          <div className="text-center">{"Confirmation!"}</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogData && (
              <>
                <div className="text-center">
                  Are you sure you want to take this user out of <br /> this
                  security group?
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type="submit"
            className="btn btn-secondary px-5 border"
            onClick={() => handleClose(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary px-5"
            onClick={() => handleClose(true)}
          >
            Remove
          </button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default SecurityGroupStaff;
