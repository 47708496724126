import React, { useRef, useState } from "react";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import {
  useGetAgPropertyMasterQuery,
  useGetAgPropertyUnitQuery,
  useGetBankQuery,
  useGetPaymentTypesQuery,
  useGetUserPendingLiabilitiesByAgIdQuery,
  useGetUserPendingLiabilitiesQuery,
  usePostRentCollectionMutation,
} from "../redux/CommonApi";
import { Controller, useForm } from "react-hook-form";
import { CustomAutocomplete } from "./../feature-module/pms/AddProperty/CustomAutocomplete";
import { Link, useNavigate } from "react-router-dom";
import {
  idToTextEnum,
  totalunitEnum,
  unitStatusEnumMap,
  unitStatusEnumRENTMap,
  unitStatusEnumRentColection,
} from "../redux/RequestTypeEnum";
import { Form, Toast } from "react-bootstrap";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import toastr from "toastr";
import { InputAdornment } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SkeletonInput from "./SkeletonInput";
import { PropertyMaster } from "../feature-module/Models/RentCollection/PropertyMaster";
import { AgreementPropertyUnit } from "../feature-module/Models/RentCollection/AgreementPropertyUnit";
import { PaymentType } from "../feature-module/Models/RentCollection/PaymentType";
import { UserPendingLiabilitie } from "../feature-module/Models/RentCollection/UserPendingLiabilitie";
import { Bank } from "../feature-module/Models/RentCollection/Bank";
interface Area {
  propertyMasterID: number;
  name: string;
  buildingNumber: string;
}
interface Unit {
  unitCategoryID: number;
  propertyUnitId: string;
}
export const AddRent = () => {
  const userId = sessionStorage.getItem("userId");
  const [propertyMasterid, setpropertyMasterID] = useState(null);
  const [rentalid, setrentalid] = useState(null);
  const [aggid, setaggid] = useState(null);
  const navigate = useNavigate();
  const [bankid, setbankid] = useState(null);
  const [paymentTypeid, setpaymentTypeid] = useState(1);
  const [isReopenpopup, setReopenpopup] = useState(false);
  const handleReopenShow = () => setReopenpopup(false);
  const [loader, setloader] = useState(false);
  const handleReopenClose = () => {
    reset();
    setReopenpopup(false);
    setOverallOutstanding(overallOutstandingGenericVal);
  };
  const [dataList, setDataList] = useState(null);
  const [bankObj, setBankObj] = useState(null);
  const [showchequetoast, setShowchequetoast] = useState(false);
  const [chequevalidated, setchequeValidated] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [paymentDetails, setpaymentDetails] = useState(null);
  const [overAllOutStanding, setoverAllOutStanding] = useState(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the first file

    if (file && file.type.startsWith("image/")) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop() || ""; // Extract extension

      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target?.result;

        if (result instanceof ArrayBuffer) {
          const byteArray = Array.from(new Uint8Array(result)); // Convert to byte array

          const imgInfo = {
            status: 8,
            docName: fileName,
            docExtension: fileExtension,
            image: byteArray,
            paymentTypeID: 3,
          };

          // Example: Update state or send to API
          setpaymentDetails(imgInfo); // Update state with image details
          setImage(file);
          setPreviewUrl(URL.createObjectURL(file));
        }
      };

      reader.readAsArrayBuffer(file); // Read file as binary data
    } else {
      console.warn("Please upload a valid image file.");
    }
  };

  // Handle the button click to trigger file selection
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handlechequeClose = () => {
    setShowchequetoast(false);
    setDropdownVisible(false);
    const bankData = { ...bankForm };
    bankData.aggID = aggid;
    bankData.chequeNumber = null;
    bankData.bankID = null;
    setBankObj(null);
  };
  // Fetch data
  const { data: listBuilding, isLoading } = useGetAgPropertyMasterQuery({
    userID: +userId,
  }) as { data: PropertyMaster[], isLoading: boolean };
  const { data: unitList, isLoading: islorder } = useGetAgPropertyUnitQuery({
    PropertyMasterid: propertyMasterid,
  }, { skip: propertyMasterid === null }) as {data:AgreementPropertyUnit[],isLoading:boolean,error:any};

  // const { data: userList, isLoading: userLoader } =
  //     useGetUserPendingLiabilitiesQuery({
  //         RentalUnitid: rentalid,
  //         userID: userId,
  //     });
  const { data: userList = [], isLoading: userLoader } = useGetUserPendingLiabilitiesQuery(
      rentalid ? { RentalUnitid: rentalid, userID: userId } : skipToken // Avoid querying with null rentalid
    ) as { data: UserPendingLiabilitie[], isLoading: boolean };

  const {
    data: pendingLiabilitiesByAgId = [],
    isLoading: userPendingLiabilitiesByAgId,
  } = useGetUserPendingLiabilitiesByAgIdQuery(
    rentalid && aggid ? { RentalUnitid: rentalid, aggID: aggid } : skipToken
  ) as { data: UserPendingLiabilitie[], isLoading: boolean };
  const { data: paymentList, isLoading: paymentLoader } =
    useGetPaymentTypesQuery() as { data: PaymentType[], isLoading: boolean };
  const [showMessage, setShowMessage] = React.useState(false);
  React.useEffect(() => {
    if (rentalid !== null && !userLoader && userList.length === 0) {
      setShowMessage(true);
      // toastr.error("No tenant found for this unit");
    } else setShowMessage(false);
  }, [userList, rentalid, userLoader]);

  // State for selected building
  const [selectedBuilding, setSelectedBuilding] = useState<Area | null>(null);
  // State for selected unit
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const handleBuildingChange = (event: any, newValue: Area | null) => {
    debugger
    setSelectedBuilding(newValue);
    setpropertyMasterID(newValue?.propertyMasterID);
    setSelectedUnit(null);
    setrentalid(null);
    setDataList(null);
  };
  const handleUnitChange = (event: any, newValue: Unit | null) => {
    debugger
    setSelectedUnit(newValue);
    const splitid = newValue?.propertyUnitId
      ? newValue.propertyUnitId.split("-")
      : [];

    setrentalid(+splitid[0]);
    setDataList(null);
  };

  const CustomLocalizationProvider = styled(LocalizationProvider)(
    ({ theme }) => ({
      display: "flex !important",
    })
  );

  const CustomDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
    fontFamily: "Arial, sans-serif",
    color: "#333",
    display: "flex !important",
    // height: "0.4375em !important",
  }));
  const { data: bankdata } = useGetBankQuery() as { data: Bank[] };
  const [bankForm, setBankForm] = useState({
    aggID: aggid,
    bankID: null,
    chequeTypeID: 2,
    chequeNumber: "",
    amount: 0,
    dueDate: new Date().toISOString(),
  });
  const [showChequeInfo, setShowChequeInfo] = useState(false);
  const [bankDesc, setBankDesc] = useState(null);
  const handleChangeBankForm = (e) => {
    const { name, value } = e.target;
    // if (name === "bankID") {
    //     setBankDesc(+value)
    //     setbankid(+value)
    // }
    // const selectedBank = bankdata.find(bank => bank.bankType === +value);

    // // If bankID is changed, update bankDesc
    // if (name === "bankID" && selectedBank) {
    //     setBankDesc(selectedBank.desc);
    // }
    // setBankForm((prevValues) => ({
    //     ...prevValues,
    //     [name]: value,

    // }));
    // Parse value to number for specific fields
    const parsedValue = ["bankID", "amount"].includes(name) ? +value : value;

    // Update bankDesc for bankID changes
    if (name === "bankID") {
      const selectedBank = bankdata.find(
        (bank) => bank.bankType === parsedValue
      );
      setBankDesc(selectedBank ? selectedBank.desc : null); // Set description or null
    }

    // Update state with new values
    setBankForm((prevValues) => ({
      ...prevValues,
      [name]: parsedValue,
    }));
  };

  const {
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      status: 8,
      feedback: "",
      AmountReceivedDate: new Date().toISOString().split("T")[0],
      rentAmount: null,
      paymentTypeID: 1,
      aggID: aggid,
      cheque: paymentTypeid === 1 || paymentTypeid === 3 ? null : bankObj, //this check is not working
      paymentDetail: null,
    },
  });
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [overallOutstanding, setOverallOutstanding] = useState<string>(); // Example initial value
  const [rent, setRent] = useState<string>("");
  const [overallOutstandingGenericVal, setoverallOutstandingGenericVal] =
    useState<string>();
  React.useEffect(() => {
    if (
      aggid &&
      pendingLiabilitiesByAgId &&
      pendingLiabilitiesByAgId.length > 0
    ) {
      setDataList(pendingLiabilitiesByAgId[0]);
      setOverallOutstanding(pendingLiabilitiesByAgId[0]?.overAllOutStanding.toString());
      setoverallOutstandingGenericVal(
        pendingLiabilitiesByAgId[0]?.overAllOutStanding.toString()
      );
      if (paymentAmount === 0) {
        setoverAllOutStanding(pendingLiabilitiesByAgId[0]?.overAllOutStanding);
      } else if (
        paymentAmount.toString() > pendingLiabilitiesByAgId[0]?.overAllOutStanding.toString()
      ) {
        setoverAllOutStanding(pendingLiabilitiesByAgId[0]?.overAllOutStanding);
      }
      //   setReopenpopup(true);
    }
  }, [pendingLiabilitiesByAgId, aggid]);

  const handleAddBank = (event) => {
    debugger;
    const form = event.currentTarget;
    event.preventDefault();
    setchequeValidated(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    const bankData = { ...bankForm };
    const isNullOrEmpty = (str) => !str || str.trim() === "";
    if (bankData.chequeNumber === "" || bankData.bankID === null) {
      console.warn("field required");
      event.stopPropagation();
      return;
    }
    bankData.aggID = aggid;
    setBankObj(bankData);
    setShowChequeInfo(true); // Now show the cheque information
    // setChequeAdded(true);
    setShowchequetoast(false);
    setchequeValidated(false);
    setDropdownVisible(false);
  };
  const openPopUp = async (aggID) => {
    setaggid(aggID);
    setRent(""); // Update the rent value
    setValue("rentAmount", parseFloat(null));
    if (aggID === null) {
      const obj = unitList[0];
      setDataList(obj); // Handle default case when aggID is null
    } else {
      if (userPendingLiabilitiesByAgId) {
        setReopenpopup(false);
      } else if (
        pendingLiabilitiesByAgId &&
        pendingLiabilitiesByAgId.length > 0
      ) {
        // Data has been fetched successfully
        const obj = pendingLiabilitiesByAgId[0];

        //   setoverAllOutStanding(obj?.overAllOutStanding)
        setDataList(obj);
        setReopenpopup(true);
      } else {
        // Data is undefined or empty array
        console.warn("No data found or pendingLiabilitiesByAgId is undefined");
        setReopenpopup(false);
        setDataList(null);
      }
    }
  };
  const handleUnitCategoryChange = (e) => {
    const selectedValue = Number(e.target.value);
    setValue("paymentTypeID", selectedValue);
    setpaymentTypeid(selectedValue);
  };
  const handleRemoveImage = () => {
    setImage(null);
    setPreviewUrl(null);
  };
  const handlecheque = () => {
    if (rent === "") {
      showToastMessage(
        "The Payment Amount field is required.Please Enter an Amount",
        "error"
      );
    } else {
      setShowchequetoast(true);
    }
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };
  const handlechequeDelete = () => {
    // setBankForm({
    //     aggID: aggid,
    //     bankID: 0,
    //     chequeTypeID: 0,
    //     chequeNumber: "",
    //     amount: null,
    //     dueDate: new Date().toISOString(),
    // })
    setBankObj(null);
    setShowChequeInfo(false);
    setDropdownVisible(false);
  };
  // const handleChangeReceivedDate = (e) => {
  //     const { name, value } = e.target;
  //     debugger
  //     setValue("AmountReceivedDate", value)
  // };
  const [changevalue, setchangevalue] = React.useState(null);
  const [postRentCollection] = usePostRentCollectionMutation();
  const onSubmit = async (data) => {
    debugger;
    data.aggID = aggid;

    if (paymentTypeid === 3) {
      const imgInfo = {
        status: 8,
        docName: null,
        docExtension: null,
        image: null,
        paymentTypeID: 3,
      };
      data.paymentDetail = paymentDetails === null ? imgInfo : paymentDetails;
    }
    setloader(true);
    try {
      if (paymentTypeid === 2 && bankObj === null) {
        toastr.error("Add at least one cheque");
        setloader(false);
      } else if (paymentTypeid === 2) {
        data.cheque = bankObj;
        const resp = await postRentCollection(data);
        setloader(false);
        toastr.success("Rent Added successfully");
        setReopenpopup(false);
        navigate("/admin/rentCollection");
      } else if (parseInt(rent) > parseInt(overallOutstandingGenericVal)) {
        showToastMessage(
          "Don't exceed payment amount from overall outstanding",
          "error"
        );
        setloader(false);
      } else {
        const resp = await postRentCollection(data);
        setloader(false);
        toastr.success("Rent Added successfully");
        setReopenpopup(false);
        navigate("/admin/rentCollection");
      }
    } catch (error) {
      console.error("error", error);
      toastr.error(error);
      setloader(false);
      setReopenpopup(false);
    }
  };

  const handlePaymentChange = (e) => {
    debugger;
    const newValue = parseFloat(e.target.value) || 0;
    if (newValue < 0) {
      // toastr.error("Payment amount cannot be negative");
      return;
    }
    setchangevalue(newValue);
    // Calculate the change in payment amount
    const changeInPayment = newValue - paymentAmount;

    // Validate the new payment amount against the overall outstanding balance
    if (newValue > overAllOutStanding) {
      toastr.error("Don't exceed payment amount from overall outstanding");
      setValue("rentAmount", newValue); // Reset to max allowable
      return; // Exit early to prevent further processing
    }

    // Update the payment amount
    setPaymentAmount(newValue);

    // Update the bank form's amount property
    setBankForm((prevForm) => ({
      ...prevForm, // Preserve the existing properties
      amount: newValue, // Update only the `amount`
    }));

    // Update the overall outstanding balance considering the change in payment
    setoverAllOutStanding((prevBalance) => prevBalance - changeInPayment);
  };

  const handleFocusChange = (val: boolean) => {
    debugger;
    if (!val) {
      if (rent.trim() !== "") {
        const val1 = parseFloat(overallOutstandingGenericVal);
        const val2 = parseFloat(rent.trim());
        const result = val1 - val2;

        if (val2 > parseFloat(overallOutstanding)) {
          showToastMessage(
            "Don't exceed payment amount from overall outstanding",
            "error"
          );
          // alert("Don't exceed payment amount from overall outstanding"); // Replace with a toast function if needed
          setOverallOutstanding(overallOutstandingGenericVal);
          return;
        }

        setOverallOutstanding(result.toString());
      } else {
        setOverallOutstanding(overallOutstandingGenericVal);
      }
    }
  };

  // Handle value change
  const handleValueChange = (value: string) => {
    debugger;
    setRent(value); // Update the rent value
    setValue("rentAmount", parseFloat(value));
    setBankForm((prevForm) => ({
      ...prevForm, // Preserve the existing properties
      amount: parseInt(value), // Update only the `amount`
    }));
    if (value.trim() === "") {
      setOverallOutstanding(overallOutstandingGenericVal);
      // Reset to generic value if input is empty
      //  setBankForm((prevForm) => ({
      //   ...prevForm, // Preserve the existing properties
      //   amount: parseInt(overallOutstandingGenericVal), // Update only the `amount`
      // }));
    }
  };
  // const handlePaymentChange = (e) => {
  //   debugger
  //   const newValue = parseFloat(e.target.value) || 0;
  //   // Calculate the change in payment amount
  //   const changeInPayment = newValue - paymentAmount;
  //   // Update the payment amount
  //   setPaymentAmount(newValue);
  //   // Update the bank form's amount property
  //   setBankForm((prevForm) => ({
  //     ...prevForm, // Preserve the existing properties
  //     amount: newValue, // Update only the `amount`
  //   }));
  //   // Validate the new payment amount against the overall outstanding balance
  //   if (newValue > overAllOutStanding) {
  //     toastr.error("Don't exceed payment amount from overall outstanding");
  //     setValue("rentAmount", newValue);
  //      // Optionally reset to max allowable
  //     return; // Exit early to prevent further processing
  //   }
  //   // Update the overall outstanding balance considering the change in payment
  //   setoverAllOutStanding((prevBalance) => prevBalance - changeInPayment);
  //   // Handle case when newValue is reverted (characters removed)
  //   if (newValue < paymentAmount) {
  //     setoverAllOutStanding((prevBalance) => prevBalance + (paymentAmount - newValue));
  //   }
  // };
  // const handlePaymentChange = (e) => {
  //   const value = parseFloat(e.target.value) || 0;
  //   setPaymentAmount(value);
  //   setBankForm((prevForm) => ({
  //     ...prevForm, // Preserve the existing properties
  //     amount: value, // Update only the `amount`
  //   }));

  //   // Check if payment amount exceeds overall outstanding balance
  //   if (value > overAllOutStanding) {
  //     toastr.error("Don't exceed payment amount from overall outstanding");
  //     setValue("rentAmount", value);
  //     // Optionally reset the value to the max allowable
  //   }
  //   if (overAllOutStanding === null) {
  //   } else if (
  //     paymentAmount > pendingLiabilitiesByAgId[0]?.overAllOutStanding
  //   ) {
  //   } else {
  //     setoverAllOutStanding((prevBalance) => prevBalance - value);
  //   }
  // };
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: "10px 14px",
            // background:"#f9f9fc"
          },
        },
      },
    },
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("bg-success"); // Default to success

  const showToastMessage = (message: string, variant: "success" | "error") => {
    setToastMessage(message);
    setToastVariant(
      variant === "success" ? "bg-success text-white" : "bg-danger text-white"
    );
    setShowToast(true);

    // Auto-hide after 3 seconds
    setTimeout(() => setShowToast(false), 2000);
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div
          className={`toast position-fixed top-0 end-0 m-3 ${
            showToast ? "show" : ""
          }`}
          style={{ zIndex: 1050 }}
        >
          <div className={`toast-header ${toastVariant}`}>
            <strong className="me-auto">{toastMessage}</strong>
            {/* <button
          type="button"
          className="btn-close"
          onClick={() => setShowToast(false)}
          aria-label="Close"
        ></button> */}
          </div>
          {/* <div className="toast-body">{toastMessage}</div> */}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card p-3">
            <div >
              {/* Date Picker */}
              <h4 className="pb-3">Add Rent</h4>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="col-form-label">
                      Date <span className="text-danger">*</span>
                    </label>
                    <div className="addproperty-form">
                      <input
                        type="date"
                        {...register("AmountReceivedDate", {
                          required: "is required",
                        })}
                        className="form-control addproperty-form"
                      />

                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker', 'DatePicker']}>
                          
                            <DatePicker
                              label="Controlled picker"
                              value={dayjs(getValues("AmountReceivedDate"))}
                              format="DD-MM-YYYY"
                              onChange={(date) => {
                                if (date) {
                                  // const formattedDate =
                                  //   dayjs(date).format("DD-MM-YYYY");
                                
                                  const formattedDate = date;
                                  setValue(
                                    "AmountReceivedDate",
                                    formattedDate.toISOString()
                                  );
                                }
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider> */}
                      {/* <ThemeProvider theme={theme}>
                          <CustomLocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDesktopDatePicker
                              value={dayjs(getValues("AmountReceivedDate"))}
                              format="DD-MM-YYYY"
                              sx={{
                                bgcolor: "background.paper",

                                borderRadius: 2,
                                p: 0,
                                m: 0,
                                height: "1rem",
                              }}
                              onChange={(date) => {
                                if (date) {
                                  // const formattedDate =
                                  //   dayjs(date).format("DD-MM-YYYY");
                                  const formattedDate = date;
                                  setValue(
                                    "AmountReceivedDate",
                                    formattedDate.toISOString()
                                  );
                                }
                              }}
                            >
                              <TextField

                                error={!!errors.AmountReceivedDate}
                                helperText={errors.AmountReceivedDate?.message}
                                fullWidth
                                id="fullWidth"
                                variant="outlined"
                              />
                            </CustomDesktopDatePicker>
                          </CustomLocalizationProvider>
                        </ThemeProvider> */}
                      {/* </>
                    )}
                  /> */}
                    </div>
                  </div>
                </div>

                {/* Area Autocomplete */}
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="col-form-label">
                      Building ID <span className="text-danger">*</span>{" "}
                    </label>
                    <CustomAutocomplete
                      id="building-autocomplete"
                      options={listBuilding || []}
                      value={selectedBuilding}
                      getOptionLabel={(option: Area) =>
                        option?.name || "Building ID"
                      }
                      onChange={handleBuildingChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search building"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <span className="form-icon pe-2">
                                  <i className="ti ti-search" />
                                </span>
                              </InputAdornment>
                            ),
                            // endAdornment: null,
                          }}
                          InputLabelProps={{
                            style: {
                              transform: "translateY(11px)",
                              paddingLeft: "8px",
                            }, // Moves the label slightly upward
                          }}
                          sx={{
                            "& label": {
                              paddingLeft: "18px", // Adds space from the right
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option: Area) => (
                        <li {...props} key={option.propertyMasterID}>
                          {option.name}
                        </li>
                      )}
                    />
                  </div>
                </div>

                {/* Unit Selection */}
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="col-form-label">
                      Unit # <span className="text-danger">*</span>{" "}
                    </label>
                    <CustomAutocomplete
                      id="building-autocomplete"
                      options={unitList || []}
                      value={selectedUnit}
                      getOptionLabel={(option: Unit) =>
                        option?.propertyUnitId || "Unit #"
                      }
                      onChange={handleUnitChange}
                      renderInput={(params) => (
                        //     <TextField
                        //         {...params}
                        //         label="--Select--"
                        //         variant="outlined"
                        //         disabled={!propertyMasterid}
                        //     />
                        // )}
                        <TextField
                          {...params}
                          placeholder="unit"
                          variant="outlined"
                          disabled={!propertyMasterid}
                          InputProps={{
                            ...params.InputProps,

                            endAdornment: (
                              <>
                                {islorder && (
                                  <InputAdornment position="end">
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  </InputAdornment>
                                )}
                                <InputAdornment position="end">
                                  <span className="form-icon pe-2">
                                    <i className="ti ti-search" />
                                  </span>
                                </InputAdornment>
                                {/* {params.InputProps.endAdornment} */}
                              </>
                            ),
                          }}
                          InputLabelProps={{
                            style: {
                              transform: "translateY(11px)",
                              paddingLeft: "8px",
                            }, // Moves the label slightly upward
                          }}
                          sx={{
                            "& label": {
                              paddingLeft: "18px", // Adds space from the right
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option: Unit) => (
                        <li {...props} key={option.unitCategoryID}>
                          {option.propertyUnitId}
                        </li>
                      )}
                    />
                    {showMessage && (
                      <p className="text-danger pt-2">
                        No tenant found for this unit
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {userLoader && (
                <>
                  <div className="row">
                    <h6 className="page-title addrentp-h6 pb-2"></h6>
                    <SkeletonInput />
                  </div>
                </>
              )}
              <div className="row mb-3">
                {userList?.map((data, index) => (
                  <div className="col-md-6 ">
                    {rentalid && (
                      <>
                        {index === 0 ? (
                          <h6 className="page-title addrentp-h6 pb-2">
                            {userList.length === 1 ? "Result" : "Results"} found
                          </h6>
                        ) : (
                          <>
                            {" "}
                            <h6 className="page-title addrentp-h6 pb-2 mt-3">
                              {" "}
                            </h6>{" "}
                          </>
                        )}
                        <button
                          type="button"
                          className="align-items-center  d-flex justify-content-around justify-content-between w-100 addproperty-form scale-up cursor-pointer"
                          // style={{
                          //   height: "41px",
                          //   border: "1px solid #e8e8e8",
                          //   background: "transparent",
                          // }}
                          onClick={() => openPopUp(data?.aggID)}
                        >
                          {/* <div className="addrent-outline" > */}
                          <div className="col-sm-6">
                            <p className="page-title text-start">
                              {data?.tenantName}{" "}
                            </p>
                          </div>
                          <div className="col-sm-6 text-end  ">
                            <p className="page-title ">
                              Liabilities (OMR {data?.dueRent}.0)
                            </p>
                          </div>
                          {/* </div> */}
                        </button>
                      </>
                    )}
                  </div>
                ))}
              </div>
              {/* <div
              className={
                isReopenpopup ? "toggle-popup sidebar-popup" : "toggle-popup"
              }
            >
              <div className={`sidebar-layout ${"w-25"} w-sm-100`}>
                <div className="sidebar-header">
                  <h6 className="page-title AwaitingApprovalPopup-h6 ">
                    Add Rent
                  </h6>
                  <Link
                    to="#"
                    className="sidebar-close toggle-btn"
                    onClick={handleReopenClose}
                  >
                    <i className="ti ti-x" />
                  </Link>
                </div> */}
              {dataList && (
                <>
                  <div className="row">
                    <h6 className="page-title addrentp-h6 pb-2">Rent Detail</h6>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 form-wrap ">
                      <div className=" outline-add-rent">
                        <div className="col-sm-6 ">
                          <p className="page-title ">Agreement Start date </p>
                        </div>
                        <div className="col-sm-6 text-end ">
                          <p className="page-title ">
                            {dayjs(dataList?.aggStartDate).format("D MMM YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  form-wrap">
                      <div className=" outline-add-rent">
                        <div className="col-sm-6 ">
                          <p className="page-title ">Agreement end date </p>
                        </div>
                        <div className="col-sm-6 text-end ">
                          <p className="page-title ">
                            {dayjs(dataList?.aggEndDate).format("D MMM YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  form-wrap">
                      <div className=" outline-add-rent">
                        <div className="col-sm-6 ">
                          <p className="page-title ">Rent (OMR) </p>
                        </div>
                        <div className="col-sm-6 text-end ">
                          <p className="page-title ">{dataList?.rentAmount}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  form-wrap">
                      <div className=" outline-add-rent">
                        <div className="col-sm-6 ">
                          <p className="page-title ">Liabilities (OMR) </p>
                        </div>
                        <div className="col-sm-6 text-end ">
                          <p className="page-title ">{dataList?.dueRent}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 form-wrap">
                      <div className=" outline-add-rent">
                        <div className="col-sm-6 ">
                          <p className="page-title ">Agreement status </p>
                        </div>
                        <div className="col-sm-6 text-end">
                          <p
                            className={`page-title badge badge-pill badge-status ${
                              unitStatusEnumRentColection[dataList?.agStatus]
                                ?.text === "Expired"
                                ? "bg-danger"
                                : unitStatusEnumRentColection[
                                    dataList?.agStatus
                                  ]?.text === "Terminated"
                                ? "bg-warning"
                                : "bg-success"
                            }`}
                            style={{
                              color:
                                unitStatusEnumRentColection[dataList?.agStatus]
                                  ?.color,
                            }}
                          >
                            {unitStatusEnumRentColection[dataList?.agStatus]
                              ?.text === "Vacant"
                              ? "Active"
                              : unitStatusEnumRentColection[dataList?.agStatus]
                                  ?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4  pt-2">
                      <div className="form-wrap mb-2 text-truncate">
                        <label className="col-form-label text-truncate">
                          Total Due Rent (OMR)
                        </label>
                        <input
                          className="form-control addproperty-form"
                          type="text"
                          value={dataList?.dueRent.toFixed(1)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-4  pt-2">
                      <div className="form-wrap mb-2 text-truncate">
                        <label className="col-form-label text-truncate">
                          Payment Amount (OMR){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control addproperty-form"
                          type="number"
                          // min="0" // Prevents negative numbers
                          // step="0.01"
                          {...register("rentAmount", {
                            required: "Enter your payment amount",
                            valueAsNumber: true,
                            validate: (value) =>
                              value > 0 || "The amount must be greater than 0", // Custom validation for greater than 0
                          })}
                          onChange={(e) => handleValueChange(e.target.value)} // Trigger on value change
                          onBlur={() => handleFocusChange(false)} // Trigger on focus loss
                          // onFocus={() => handleFocusChange(true)}
                          // onChange={handlePaymentChange}
                          // onBlur={handlePaymentChange}
                          // onChange={(e) => setValue("rentAmount", parseFloat(e.target.value) || 0)}
                        />
                        {errors.rentAmount && (
                          <p style={{ color: "#9E2A2B" }}>
                            {errors.rentAmount.message}
                          </p>
                        )}
                      </div>
                      {/* <p>Overall Outstanding: {overallOutstanding}</p> */}
                    </div>
                    <div className="col-md-4  pt-2 ">
                      <div className="form-wrap mb-2 text-truncate">
                        <label className="col-form-label text-truncate">
                          Overall Outstanding Balance (OMR)
                        </label>
                        <input
                          className="form-control addproperty-form"
                          type="text"
                          value={parseFloat(overallOutstanding).toFixed(1)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-4  pt-2 ">
                      <div className="form-wrap mb-2 text-truncate">
                        <label className="col-form-label text-truncate">
                          Payment Type <span className="text-danger">*</span>{" "}
                        </label>
                        <select
                          id="country"
                          className="form-select form-c addproperty-form "
                          onChange={handleUnitCategoryChange}
                        >
                          {paymentList?.map((role) => (
                            <option
                              key={role.paymentTypes}
                              value={role.paymentTypes}
                            >
                              {role.desc}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <h6 className="page-title AwaitingApprovalPopup-h6 py-2 ">
                      Feedback (Optional)
                    </h6>
                    <div className="px-3 pt-2">
                      <textarea
                        rows={8}
                        cols={10}
                        className="form-control form-textarea"
                        placeholder="Write Amendments...."
                        name="description"
                        onChange={(e) => setValue("feedback", e.target.value)}
                      />
                    </div>
                  </div>
                  {paymentTypeid === 2 && (
                    <>
                      {showChequeInfo && (
                        <div className="col-12 col-md-9">
                          <div className="row g-3 align-items-center pt-3">
                            {/* Icon and Cheque Info */}
                            <div className="col-12 col-sm-4 d-flex align-items-center gap-2">
                              <FontAwesomeIcon
                                icon={faMoneyCheckDollar}
                                className="fs-4"
                                style={{ color: "#A64041" }}
                              />
                              <span className="fw-semibold">Cheque: 1</span>
                            </div>

                            {/* Bank Name and Cheque No */}
                            <div className="col-12 col-sm-4">
                              <p className="m-0">
                                <strong>Bank Name:</strong> {bankDesc}
                              </p>
                              <p className="m-0">
                                <strong>Cheque No:</strong>{" "}
                                {bankForm.chequeNumber}
                              </p>
                            </div>

                            {/* Amount and Due Date with Icon */}
                            <div className="col-12 col-sm-4 d-flex gap-3 align-items-center position-relative">
                              <div>
                                <span className="fw-semibold d-block">
                                  <strong>Amount:</strong> OMR {bankForm.amount}
                                </span>
                                <span className="fw-semibold d-block">
                                  <strong>Due Date:</strong>{" "}
                                  {dayjs(bankForm.dueDate).format("DD-MM-YYYY")}
                                </span>
                              </div>
                              <i
                                className="fa fa-ellipsis-v action-icon"
                                onClick={toggleDropdown}
                                style={{
                                  background: "#A64041",
                                  WebkitBackgroundClip: "text",
                                  WebkitTextFillColor: "transparent",
                                  backgroundClip: "text",
                                  fontSize: "24px",
                                  cursor: "pointer",
                                }}
                              />
                              {dropdownVisible && (
                                <div className="billdropdown-menu billdropdown-menu-pos">
                                  <p
                                    onClick={handlecheque}
                                    className="cursor-pointer"
                                  >
                                    Edit
                                  </p>
                                  <p
                                    onClick={handlechequeDelete}
                                    className="cursor-pointer"
                                  >
                                    Delete
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {showChequeInfo === false && (
                        <div className="row">
                        <div className="pt-3 px-2 text-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handlecheque}
                          >
                            Add Cheque
                          </button>
                        </div>
                        </div>
                      )}
                    </>
                  )}
                  {paymentTypeid === 3 && (
                    <div className="row">
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />

                      {image ? (
                        <div className="row px-4 pt-2">
                          <div className="col-md-3">
                            <div
                              className="align-items-center d-flex justify-content-between m-auto px-3 py-2"
                              style={{
                                background: "#F4F4F4",
                                borderRadius: "5px",
                              }}
                            >
                              {/* <img
                                                    src={previewUrl || ""}
                                                    alt="Uploaded Preview"
                                                    style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "8px" }}
                                                  /> */}
                              <p className="m-0">{image.name}</p>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={handleRemoveImage}
                              >
                                X
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        
                        <div className="pt-3 px-2 text-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleButtonClick}
                          >
                            Upload Receipt
                          </button>
                        </div>
                        
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="py-3 px-2 text-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loader}
                      >
                        {loader ? (
                          <span className="spinner-border spinner-border-sm mr-1 "></span>
                        ) : null}
                        Save
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div className="toast-container position-fixed top-50 start-50 translate-middle">
                <Toast
                  show={showchequetoast}
                  onClose={handlechequeClose}
                  id="middlecenter-Toast"
                  className="colored-toast bg-white terminateToast negotiate-popup"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                >
                  <div className="d-flex align-items-center py-4 justify-content-between"></div>

                  <Form
                    noValidate
                    validated={chequevalidated}
                    onSubmit={handleAddBank}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Bank Name 
                          </label>
                          <select
                            className="form-select negotiate-form"
                            name="bankID"
                            value={bankForm.bankID}
                            onChange={handleChangeBankForm}
                            required 
                          >
                            <option value="">Select Bank</option>
                            {bankdata?.map((bank) => (
                              <option key={bank.bankType} value={bank.bankType}>
                                {bank.desc}
                              </option>
                            ))}
                          </select>
                          <Form.Control.Feedback type="invalid">
                            Bank Name is required.
                          </Form.Control.Feedback>
                        </div>

                        <div className="form-wrap">
                          <label className="col-form-label">
                            Cheque Number
                          </label>
                          <input
                            className="form-control negotiate-form"
                            name="chequeNumber"
                            placeholder="Enter Cheque Number"
                            value={bankForm.chequeNumber}
                            onChange={handleChangeBankForm}
                            required 
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter Cheque Number.
                          </Form.Control.Feedback>
                        </div>

                        <div className="form-wrap">
                          <label className="col-form-label">Amount (OMR)</label>
                          <input
                            className="form-control negotiate-form"
                            name="amount"
                            placeholder="Enter Amount in OMR"
                            value={bankForm.amount}
                            onChange={handleChangeBankForm}
                            required 
                            type="number"
                            min={0}
                            disabled={true} 
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter Amount.
                          </Form.Control.Feedback>
                        </div>

                        <div className="form-wrap">
                          <label className="col-form-label">Due Date</label>
                          <div className="form-control negotiate-form duedate-form">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                defaultValue={dayjs()} 
                                value={
                                  bankForm.dueDate
                                    ? dayjs(bankForm.dueDate)
                                    : null
                                } 
                                onChange={(date) =>
                                  handleChangeBankForm({
                                    target: {
                                      name: "dueDate",
                                      value: date?.toISOString(),
                                    },
                                  })
                                } 
                                
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 pb-4 pt-3 ">
                      <button
                        type="button"
                        className="btn  addfeature-btn"
                        onClick={handlechequeClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn  btn-danger"
                        onClick={handleAddBank}
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </Toast>
              </div>
              {/* </div>
            </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
