import React, { useState } from "react";
import profile from "../../../../public/assets/img/profiles/tenantprofile.png";
import { Link } from "react-router-dom";
import {
  useGetMaintenanceRequestMutation,
  useGetuserSmartNotificationQuery,
} from "../../../redux/CommonApi";
import {
  RequestTypeEnum,
  unitStatusEnum,
  unitStatusEnumMap,
} from "../../../redux/RequestTypeEnum"; // Adjust the import path as necessary

const MaintenanaceReq = () => {
  const [activeTab, setActiveTab] = useState("incoming");
  const userId = sessionStorage.getItem("userId");
  const userName = sessionStorage.getItem("userName");
  const {
    data: unAssigned,
    error: incomingError,
    isLoading: incomingLoading,
  } = useGetuserSmartNotificationQuery({
    userID: userId,
    requestType: RequestTypeEnum.UNASSIGNED,
    pageNumber: 1,
    rowNumber: 3,
  });
  const {
    data: assigned,
    error: assignedError,
    isLoading: assignedLoading,
  } = useGetuserSmartNotificationQuery({
    userID: userId,
    requestType: RequestTypeEnum.ASSIGNED,
    pageNumber: 1,
    rowNumber: 3,
  });
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
  const processDescription = (description) => {
    const parts = description.split("</br>");
    return parts.length > 1 ? parts[parts.length - 1] : parts[0];
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB");
  };
  const renderBuildingInfo = (unitId, caption) => {
    if (unitId) {
      return unitId;
    }
    if (caption) {
      return caption;
    } else if (caption === 0) {
      return unitId;
    }
    return null;
  };
  const [getMaintenanceRequest, { isLoading }] =
    useGetMaintenanceRequestMutation();
  const handleRequestUpdate = (requestId, status) => {
    
    const stringdata = JSON.stringify({
      date: new Date().toISOString(),
      name: userName,
      status: status,
      userTypeId: 1,
      isCreatedBy: userId,
      UserType: "Landlord",
    });
  
    const requestData = {
      requestId,
      status,
      requestDate: stringdata,
    };
    getMaintenanceRequest(requestData)
      // .unwrap()
      .then((response) => {
        
      })
      .catch((error) => {
        console.error("Failed to update request:", error);
      });
  };

  return (
    <>
      <div className="card cardreq">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title">Maintenance Requests</h5>
          <p className="text-primary">See all Requests</p>
        </div>
        <div className="card-body req-card">
          <ul className="nav nav-tabs nav-tabs-bottom">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeTab === "incoming" ? "active" : ""
                }`}
                to="#bottom-tab1"
                onClick={() => setActiveTab("incoming")}
              >
                UnAssigned Requests
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeTab === "assigned" ? "active" : ""
                }`}
                to="#bottom-tab2"
                onClick={() => setActiveTab("assigned")}
              >
                Assigned Requests
              </Link>
            </li>
          </ul>
          <div className="tab-content">
            {unAssigned && (
              <div
                className={`tab-pane ${
                  activeTab === "incoming" ? "show active" : ""
                }`}
              >
                {unAssigned.data?.map((request) => {
                  const statusInfo = unitStatusEnumMap[request.status] || null;
                  const Urgency = unitStatusEnumMap[request.urgencyId] || null;
                  return (
                    <div key={request.notificationId} className="req-border">
                      <div className="row mt-3">
                        <div className="col-md-8">
                          <div className="d-flex align-items-center w-100  ">
                            <img
                              src={request.imageURL || profileimg}
                              alt="img"
                              className="avatar avatar-rounded me-2"
                            />
                            <div className="">
                              <div className="fs-15 fw-semibold ">
                                {request.userName}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 text-end align-content-center">
                          {renderBuildingInfo(
                            request.unitId,
                            request.caption
                          ) && (
                            <span className="badge fw-13 bg-secondary-transparent">
                              {request.buildingNumber}
                              <sub>
                                {" "}
                                {renderBuildingInfo(
                                  request.unitId,
                                  request.caption
                                )}
                              </sub>
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-2 mb-1">
                        <div className="col-md-8">
                          <span>{processDescription(request.desc)}</span>
                        </div>
                        <div className="col-md-4 text-end">
                          {formatDate(request.date)}
                        </div>
                      </div>
                      <div className="d-flex mb-1">
                        <div className="pe-2">
                          <span className={`badge fw-13 bg-info-maintanance `}>
                            {request.maintenanceCategory}
                          </span>
                        </div>
                        {Urgency && (
                          <div className="pe-2 ">
                            <span
                              className="badge fw-13"
                              style={{
                                color: Urgency.color,
                                backgroundColor: Urgency.backgroundColor,
                              }}
                            >
                              {Urgency.text}
                            </span>
                          </div>
                        )}
                        {statusInfo && (
                          <div>
                            <span
                              className="badge fw-13"
                              style={{
                                color: statusInfo?.color,
                                backgroundColor: statusInfo?.backgroundColor,
                              }}
                            >
                              {statusInfo?.text}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="row mb-1">
                        <div className="col-md-6 align-content-center">
                          <div className="fs-15 fw-semibold text-black align-content-center">
                            Token num-
                            {request.tokenNumber}
                          </div>
                        </div>
                        <div className="col-md-6 text-end">
                          <div className="d-flex justify-content-end">
                            <div
                              className="badge  maintanace-btn mb-2 p-2"
                              onClick={() =>
                                handleRequestUpdate(request.tokenNumber, 11)
                              }
                            >
                              Approve
                            </div>
                            <div className="ps-2">
                              <div
                                className="badge  reject-btn mb-2 p-2"
                                onClick={() =>
                                  handleRequestUpdate(request.tokenNumber, 12)
                                }
                              >
                                Reject
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              className={`tab-pane ${
                activeTab === "assigned" ? "show active" : ""
              }`}
            >
              {assigned && (
                <div
                  className={`tab-pane ${
                    activeTab === "incoming" ? "show active" : ""
                  }`}
                >
                  {assigned.data?.map((request) => {
                    const statusInfo =
                      unitStatusEnumMap[request.status] || null;
                    const Urgency =
                      unitStatusEnumMap[request.urgencyId] || null;
                    return (
                      <div key={request.notificationId} className="req-border">
                        <div className="row mt-3">
                          <div className="col-md-8">
                            <div className="d-flex align-items-center w-100  ">
                              <img
                                src={request.imageURL || profileimg}
                                alt="img"
                                className="avatar avatar-rounded me-2"
                              />
                              <div className="">
                                <div className="fs-15 fw-semibold ">
                                  {request.userName}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 text-end align-content-center">
                            {renderBuildingInfo(
                              request.unitId,
                              request.caption
                            ) && (
                              <span className="badge fw-13 bg-secondary-transparent ">
                                {request.buildingNumber}
                                <sub>
                                  {" "}
                                  {renderBuildingInfo(
                                    request.unitId,
                                    request.caption
                                  )}
                                </sub>
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row mt-2 mb-1">
                          <div className="col-md-8">
                            <span>{processDescription(request.desc)}</span>
                          </div>
                          <div className="col-md-4 text-end">
                            {formatDate(request.date)}
                          </div>
                        </div>

                        <div className="d-flex mb-1">
                          <div className="pe-2">
                            <span
                              className={`badge fw-13 bg-info-maintanance  `}
                            >
                              {request.maintenanceCategory}
                            </span>
                          </div>
                          {Urgency && (
                            <div className="pe-2 ">
                              <span
                                className="badge fw-13"
                                style={{
                                  color: Urgency.color,
                                  backgroundColor: Urgency.backgroundColor,
                                }}
                              >
                                {Urgency.text}
                              </span>
                            </div>
                          )}
                          {statusInfo && (
                            <div>
                              <span
                                className="badge fw-13"
                                style={{
                                  color: statusInfo.color,
                                  backgroundColor: statusInfo.backgroundColor,
                                }}
                              >
                                {statusInfo.text}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="row mb-1">
                          <div className="col-md-6 align-content-center">
                            <div className="fs-15 fw-semibold text-black align-content-center">
                              Token num-
                              {request.tokenNumber}
                            </div>
                          </div>
                          {/* <div className='col-md-6 text-end'>
                            <div className='d-flex justify-content-end'>
                              <div className="badge  maintanace-btn mb-2 p-2">
                                Approve
                              </div>
                              <div className='ps-2'>
                                <div className="badge  reject-btn mb-2 p-2">
                                  Reject
                                </div>
                              </div>
                            </div>
                        
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaintenanaceReq;
