import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_USER_BASE_URL,
});

api.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Platform"] = `web`;
    // config.headers["Origin"] = `https://mumtalikati.com`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
