import React, { useState } from "react";
import { useNegotiationAgreementQuery } from "../../../redux/CommonApi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import PropertyCard from "./PropertyCard"; // Import PropertyCard
import Nodata from "../Nodata";
import { NegotiationTypeEnum } from "../../../redux/RequestTypeEnum";
import CustomLoading from "../CustomLoading";
import { StackPagination } from "../../../shared/StackPagination";

const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background:
        "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#9E2A2B",
    },
    "& .MuiPaginationItem-previousNext:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
}));

const Negotiation = ({
  negotiationType,
  setPageNumber1,
  pageNumber1,
  Cardstatus,
  setTenenacyRequestDetail,
  setAggrementoption,
  setAgreementDocDetail,
  setReopenpopup,
  activesubIndex
}) => {
  const classes = useStyles();
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";

  const landlordId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState(6);

  const handlePageChange = (event, value) => {
    setPageNumber1(value);
  };

  const {
    data: NegotiationAgreement = [],
    error: NegotiationAgreementerror,
    isLoading: NegotiationAgreementisLoading,
  } = useNegotiationAgreementQuery({
    userID: landlordId,
    requestTypeID: negotiationType,
    pageNumber: pageNumber1,
    rowNumber: rowNumbers,
  });
  const onCardClick = (agreementId) => {
    sessionStorage.setItem('agId',agreementId)
    if (negotiationType === NegotiationTypeEnum.LandlordRequest) {
      setAgreementDocDetail(agreementId);
      setAggrementoption(2);
    } else if (negotiationType === NegotiationTypeEnum.TenancyRequest) {
      setTenenacyRequestDetail(agreementId);
      setReopenpopup(true);
    } else if (negotiationType === NegotiationTypeEnum.RenewableRequest) {
      setTenenacyRequestDetail(agreementId);
      setReopenpopup(true);
    }
  };
  if (NegotiationAgreementisLoading) return <CustomLoading/>;

  return (
    <>
      <div className="   mb-0">
      <div className="card-body pb-5">
    <div className="row">
      { NegotiationAgreement?.data?.length > 0 ? (
        NegotiationAgreement?.data.map((item, index) => (
          <PropertyCard
            key={index}
            item={item}
            fallbackImage={fallbackImage}
            profileimg={profileimg}
            onCardClick={() => onCardClick(item.aggreementID)}
            Cardstatus={Cardstatus}
            activesubIndex={activesubIndex}
          />
        ))
      ) : (
        <Nodata />
      )}
    
       
   
    </div>
    </div>
    </div>
{NegotiationAgreement.totalCount > 0 && (
<div className="row row1  fixed-bottom1" >
<div className="d-flex justify-content-center">
  <StackPagination tenancyRequestCount={NegotiationAgreement.totalCount} rowNumbers={rowNumbers}pageNumber={pageNumber1} handlePageChange={handlePageChange}/>
  
</div>
</div>
)}
</>
  );
};

export default Negotiation;
