import api from "../Interceptor";

export const UserProfileDetail = async () => {
  try {
    const landlordId = sessionStorage.getItem("userId");
    const response = await api.get(
      `Users/GetUserIncludingDetails?userID=${landlordId}`
    );
    if (response.status == 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching roles:", error);
  }
};
