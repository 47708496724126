import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useGetIncomeOverviewQuery } from "../../../redux/CommonApi";
import { Link } from "react-router-dom";

const Staffchart = () => {
  const landlordId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetIncomeOverviewQuery(landlordId);
  const totalStaff = data.landlordStaffCounts[0]?.totalStaffCount || 0;
  const series = data.landlordStaffCounts?.map((item) =>
    parseInt(item.roleTypeStaffCount, 10)
  );
  const labels = data.landlordStaffCounts?.map((item) => item.roleTypeName);
  const colors = ["#6edac1", "#fb8a3c", "#eab308", "#609afa"];
  const percentages = series.map((value) =>
    ((value / totalStaff) * 100).toFixed(2)
  );
  const donutChart: ApexOptions = {
    chart: {
      height: 350,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    series: series,
    labels: labels,
    colors: colors,
    dataLabels: {
      enabled: false,
      formatter: function (val, opts) {
        return `${percentages[opts.seriesIndex]}%`;
      },
    },
    legend: {
      show: true,
      position: "bottom",
      formatter: function (label, opts) {
        return `
          <div class=" ">
            <div >${label}
            <span >(${series[opts.seriesIndex]})</span>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
              formatter: function () {
                return `${totalStaff}`;
              },
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 1,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [10, 100],
        colorStops: [
          [{ offset: 100, color: "#6edac1", opacity: 1 }],
          [{ offset: 100, color: "#fb8a3c", opacity: 1 }],
          [{ offset: 100, color: "#eab308", opacity: 1 }],
          [{ offset: 100, color: "#609afa", opacity: 1 }],
        ],
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <div className="card cardreq">
        <div className="card-header d-flex justify-content-between">
          <h6 className="card-title">Staff</h6>
          <p className="text-primary">
            <Link to="/admin/staffmanagement">See all Staff</Link>
          </p>
        </div>
        <div className="card-body staff-card ">
          <div id="donut-chart" className="text-avatar position-relative">
            <ReactApexChart
              options={donutChart}
              series={donutChart.series}
              type="donut"
              height={350}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Staffchart;
