import React from 'react'
import { RentCollectionMaincard } from './RentCollectionMaincard'
import { RentCollectionCard } from './RentCollectionCard'
import InteractiveCard from './InteractiveCard'

export const RentCollection = () => {
    return (
        <div className="page-wrapper">
            <div className="content">
                <div>
                    <RentCollectionMaincard />
                    <RentCollectionCard />
                 

                </div>
            </div>
        </div>
    )
}
