
export interface IAgreementRentTransactions  {
  dueDate: string; 
  rentAmount: number;
  rownumberId: number;
  tenantName: string;
  unitID: number;
  totalCount: number | null; 
  rentCollectionStatus: number;
  rentID: number;
  contact: string;
  }
  export class AgreementRentTransactions implements IAgreementRentTransactions {
    dueDate: string; 
  rentAmount: number;
  rownumberId: number;
  tenantName: string;
  unitID: number;
  totalCount: number | null; 
  rentCollectionStatus: number;
  rentID: number;
  contact: string;

    constructor(data: AgreementRentTransactions) {
      this.dueDate = data.dueDate ?? '';
      this.rentAmount = data.rentAmount ?? 0;
      this.rownumberId = data.rownumberId ?? 0;
      this.tenantName = data.tenantName ?? '';
      this.unitID = data.unitID ?? 0;
      this.totalCount = data.totalCount ?? null;
      this.rentCollectionStatus = data.rentCollectionStatus ?? 0;
      this.rentID = data.rentID ?? 0;
      this.contact = data.contact ?? '';

    }
  }
  