import React, { useState } from 'react';
import { useAggRentHistoryQuery, useAgRentHistoryCountQuery } from '../../../redux/CommonApi';
import { useSelector } from 'react-redux';
import Nodata from '../Nodata';
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import CustomLoading from '../CustomLoading';
const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-OM", {
        style: "currency",
        currency: "OMR",
    });
    return formatter.format(value);
};
const useStyles = makeStyles(() => ({
    ul: {
        "& .Mui-selected": {
            color: "#fff",
            background:
                "linear-gradient(to bottom,rgba(223, 24, 26, 0.85), rgb(158, 42, 43)) !important",
        },
        "& .MuiPaginationItem-previousNext": {
            color: "#9E2A2B",
        },
        "& .MuiPaginationItem-previousNext:hover": {
            backgroundColor: "#f1f1f1",
        },
    },
}));

const Rent = ({setRentHistoryDetail,setRentHistory,setAgreementDocDetail,setgoback}) => {
    const classes = useStyles();
    const AggrementId = useSelector((state: any) => state.CRMS.aggrementId) ?? null;
    const [pageNumber, setPageNumber] = useState(1);
    const [rowNumbers, setRowNumbers] = useState(10);

    const { data: renthistory ,isLoading} = useAggRentHistoryQuery({
        AgID: AggrementId,
        pagenumber: pageNumber,
        rownumber: rowNumbers,
    });
    const handlePageChange = (event, value) => {
        setPageNumber(value);
    };
    const handleRentHistory = () => {
        setRentHistory(null);
        setRentHistoryDetail(1); // Activate the RentHistory component
      };
    const {
        data: Rentcount = 0,
        error: countError,
        isLoading: isCountLoading,
    } = useAgRentHistoryCountQuery({
        AgID: AggrementId,
    });
    if (isLoading || isCountLoading) return <CustomLoading/>;
    const handleBackClick = () => {
        
        setgoback(false);
        // setAgreementDocDetail(null);
        setRentHistory(null);
    };
    return (
        <>
            <div className="d-flex gap-2 align-items-center gap mb-3 agreementdoc" >
                <i
                    className="ti ti-arrow-narrow-left"
                    style={{
                        background: '#7E7E7E',
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        fontSize: "30px",
                    }}
                    onClick={handleBackClick}

                />
                <h4 className="py-2">Rent History</h4>
            </div>
            {renthistory?.length > 0 ? (
                <div className="row">
                    {renthistory?.map((rent) => (
                        <div className="col-md-4"      onClick={handleRentHistory}>

                            <div className="card renthistorycard ">
                                <div className="campaign-box-block campaign-box propertycard bg-tenant-light">

                                    <div key={rent.rownumberId}>
                                        <p>Rent of {rent.rentMonthName} {rent.rentyear}</p>
                                        <div className='d-flex justify-content-between'>
                                            <p><span>Paid on </span>{new Date(rent.rentRecDate).toLocaleDateString()}</p>
                                            <p>{formatCurrency(rent.rentReceived)}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>) : (
                <Nodata />
            )}
            {Rentcount?.length > 6 && (
                <div className="d-flex justify-content-center pb-4">
                    <Stack spacing={2} mt={3}>
                        <div
                            style={{
                                padding: "4px",
                                borderRadius: "12px",
                                background:
                                    "linear-gradient(to bottom,rgba(223, 24, 26, 0.85), rgb(158, 42, 43))",
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: "8px",
                                    backgroundColor: "#fff",
                                    padding: "8px",
                                }}
                            >
                                <Pagination
                                    classes={{ ul: classes.ul }}
                                    count={Math.ceil(Rentcount / rowNumbers)}
                                    page={pageNumber}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </Stack>
                </div>
            )}
        </>
    );
};

export default Rent;
