export interface IOwnerPropertyMaster {
  rowsIndex: number;
  rownumberId: number;
  propertyMasterID: number;
  listingPurposeID: number;
  propertyMasterName: string;
  propertyMasterSubType: string;
  buildingNumber: string;
  propertySubTypeId: number;
  addressStr: string;
  landLordID: number;
  totalCount: number;
  propertyStatusID: number;
  isReadyOnlyval: boolean;
  plotNumber: string;
  pageNumber: number;
  rowsNumbers: number;
  imageString: string | null;
  isSelected: boolean;
  color: string;
  propertyName:string;
}

export class OwnerPropertyMaster implements IOwnerPropertyMaster {
  rowsIndex: number;
  rownumberId: number;
  propertyMasterID: number;
  listingPurposeID: number;
  propertyMasterName: string;
  propertyMasterSubType: string;
  buildingNumber: string;
  propertySubTypeId: number;
  addressStr: string;
  landLordID: number;
  totalCount: number;
  propertyStatusID: number;
  isReadyOnlyval: boolean;
  plotNumber: string;
  pageNumber: number;
  rowsNumbers: number;
  imageString: string | null;
  isSelected: boolean;
  color: string;
  propertyName:string;

  constructor(data: Partial<OwnerPropertyMaster>) {
  
    this.rowsIndex = data?.rowsIndex ?? 0;
    this.rownumberId = data?.rownumberId ?? 0;
    this.propertyMasterID = data?.propertyMasterID ?? 0;
    this.listingPurposeID = data?.listingPurposeID ?? 0;
    this.propertyMasterName = data?.propertyMasterName ?? '';
    this.propertyMasterSubType = data?.propertyMasterSubType ?? '';
    this.buildingNumber = data?.buildingNumber ?? '';
    this.propertySubTypeId = data?.propertySubTypeId ?? 0;
    this.addressStr = data?.addressStr ?? '';
    this.landLordID = data?.landLordID ?? 0;
    this.totalCount = data?.totalCount ?? 0;
    this.propertyStatusID = data?.propertyStatusID ?? 0;
    this.isReadyOnlyval = data?.isReadyOnlyval ?? false;
    this.plotNumber = data?.plotNumber ?? '';
    this.pageNumber = data?.pageNumber ?? 0;
    this.rowsNumbers = data?.rowsNumbers ?? 0;
    this.imageString = data?.imageString ?? null;
    this.isSelected = data?.isSelected ?? false;
    this.color = data?.color ?? 'Color(0xFFFFFFFF)';
    this.propertyName = data.propertyName;
  }
}
