import React from "react";
import "../../style/scss/tenantdashboard.scss";
import {
  useCreateMaintenanceRequestMutation,
  useMaintenanceRequestBuildingAndUnitsQuery,
  useMaintenanceRequestDashboardQuery,
  useMaintenanceRequestImagesMutation,
  useTenantPropertyUnitsMaintenaceQuery,
  useTotalMaintenanceRequestQuery,
} from "../../redux/CommonApi";
import MaintenancceAllReqCard from "./MaintenancceAllReqCard";
import cardbackgroundimage from "../../../public/assets/img/cardbackgroundimage.png";
import MaintenanceUnitCard from "./MaintenanceUnitCard";


import { Loader } from "../../shared/Loader";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { priorityEnum, unitStatusEnum } from "../../redux/RequestTypeEnum";
import { SelectCategoryCard } from "./SelectCategoryCard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
  recentMaintenanceRequests,
  maintenanceRequestDashboard,
  MaintenanceRequestApiResponse,
} from "./Model/maintenanceRequestDashboard";
import { ResponseMessage } from "../Models/GenericModel/ResponseMessage";
import { MaintenanceRequestBuildings } from "../Models/MaintenanceRequest/MaintenanceRequestBuildings";
import { MaintenanceRequestUnits } from "../Models/MaintenanceRequest/MaintenanceRequestUnits";
import { DashboardRecentRequests } from "../Models/MaintenanceRequest/DashboardRecentRequests";
import { MaintenanceDashboardData } from "../Models/MaintenanceRequest/MaintenanceDashboardData";
import { MaintenanceResponseMessage } from "../Models/GenericModel/MaintenanceResponseMessage";
import { RequestDetail } from "../Models/MaintenanceRequest/RequestDetail";
import { MaintenanceRequestMain } from "../Models/MaintenanceRequest/MaintenanceRequestMain";

export const MaintenancceReqDashboard: React.FC = () => {
  const userId = sessionStorage.getItem("userId");
  const userName = sessionStorage.getItem("userName");
  const [status, setStatus] = React.useState<number>(8);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [priorityId, setPriorityId] = React.useState<number | null>(null);
  const [showUnit, setShowUnit] = React.useState(false);
  const [showCategory, setShowCategory] = React.useState(false);
  const [showSelectCategory, setShowSelectCategory] = React.useState(false);
  const [propertyMasterID, setPropertyMasterID] = React.useState(null);
  const [buildingNumber, setbuildingNumber] = React.useState("");
  const [selectCategory, setSelectCategory] = React.useState(null);
  const [priorityID, setPriorityID] = React.useState(null);
  const [showMaintenanceCard, setShowMaintenanceCard] = React.useState(true);
  const [cardOpen, setCardOpen] = React.useState(true);
  const [selectedCard, setSelectedCard] =
    React.useState<maintenanceRequestDashboard | null>(null);
  const [filteredData, setFilteredData] = React.useState<
    maintenanceRequestDashboard[]
  >([]);
  const [recentMaintenanceRequests, setRecentMaintenanceRequests] =
    React.useState<recentMaintenanceRequests[]>([]);
  // const [createMaintenanceRequest] = useCreateMaintenanceRequestMutation();
  const [createMaintenanceRequest] = useCreateMaintenanceRequestMutation<RequestDetail>();
  const [maintenanceRequestImages] = useMaintenanceRequestImagesMutation();
  const [propertyUnitID, setpropertyUnitID] = React.useState(null);
  const [desc, setDesc] = React.useState("");
  const [uploadedImages, setUploadedImages] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [loader, setloader] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activeIndex, setActiveIndex] = React.useState("Received Request");
  const {
    data: dashboardresponse ,
    error,
    isLoading,
  } = useMaintenanceRequestDashboardQuery({
    UserID: +userId,
    UserTypeID: 1,
  }) as { data: MaintenanceResponseMessage<MaintenanceDashboardData>, error: any, isLoading: boolean };

  const {
    data: totalMaintenanceRequestResponse ,
    error: errorTotalMaintenanceRequest,
    isLoading: isLoadingTotalMaintenanceRequest,
  } = useTotalMaintenanceRequestQuery({
    UserID: +userId,
    UserTypeID: 1,
    Status: status,
    PageNumber: pageNumber,
    PriorityId: priorityId,
  }) as { data: ResponseMessage<DashboardRecentRequests>, error: any, isLoading: boolean };

  const {
    data: maintenanceRequestApiResponse,
    error: errorMaintenanceRequestData,
    isLoading: isLoadingMaintenanceRequestData,
  } = useMaintenanceRequestBuildingAndUnitsQuery({
    UserID: +userId,
    UserTypeID: 1,
  }) as {data: ResponseMessage<MaintenanceRequestBuildings>, error:any, isLoading: boolean};

  const {
    data: tenantPropertyUnitsMaintenace,
    error: errortenantPropertyUnitsMaintenace,
    isLoading: isLoadingtenantPropertyUnitsMaintenace,
  } = useTenantPropertyUnitsMaintenaceQuery({
    propertyMasterID: propertyMasterID,
    UserTypeID: 1,
  },
{
  skip: propertyMasterID === null,
}
) as {data: ResponseMessage<MaintenanceRequestUnits> , error: any, isLoading: boolean};

  React.useEffect(() => {
    if (!isLoading && dashboardresponse) {
      setFilteredData(
        dashboardresponse?.data
          ?.tenantDashboard as maintenanceRequestDashboard[]
      );
    }
  }, [isLoading, dashboardresponse]);

  React.useEffect(() => {
    if (
      !isLoadingTotalMaintenanceRequest &&
      totalMaintenanceRequestResponse?.data?.length > 0
    ) {
      const recentRequests =
        totalMaintenanceRequestResponse.data[0].recentMaintenanceRequests;
      setRecentMaintenanceRequests(
        recentRequests as recentMaintenanceRequests[]
      );
    }
  }, [
    totalMaintenanceRequestResponse,
    isLoadingTotalMaintenanceRequest,
    pageNumber,
    priorityId,
    status,
  ]);

  if (error) return <p>Error loading data</p>;
  // on click function
  const handleCardClick = (item: maintenanceRequestDashboard) => {
    setActiveIndex(item.value);
    setSelectedCard(item);
    setStatus(item.status);
    setCardOpen(false);
    setShowUnit(false);
    setShowCategory(false);
    setShowSelectCategory(false);
    setShowMaintenanceCard(true);
    setPageNumber(1);
  };
  const handleExpandAllClick = () => {
    setCardOpen(true); // Show all cards again
    setSelectedCard(null); // Clear selected card
    setShowUnit(false);
    setShowCategory(false);
    setShowSelectCategory(false);
    setShowMaintenanceCard(true);
  };
  const handleClick = () => {
    setShowUnit(true);
    setShowMaintenanceCard(false);
    setPropertyMasterID(null);
  };

  const handleCardunitClick = (item, buildingNumber) => {
    setShowUnit(false);
    setPropertyMasterID(item);
    setbuildingNumber(buildingNumber);
    setShowCategory(true);
  };
  const handleCardSelectClick = (item, unitID) => {
    setShowUnit(false);
    setpropertyUnitID(unitID);
    setShowCategory(false);
    setShowSelectCategory(true);
  };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setTimeout(() => {
  //       setCardOpen(false); // Delay card opening for smoother transition
  //     }, 500);
  //   };
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  const [selectedPriority, setSelectedPriority] = React.useState("View All");

  if (isLoadingTotalMaintenanceRequest) return <Loader />;

  const handlePrioritySelect = (priorityKey: keyof typeof priorityEnum) => {
    const selectedPriorityValue = priorityEnum[priorityKey];
    setPriorityId(selectedPriorityValue);
    setSelectedPriority(priorityKey);
  };

  const handlePrioritySelectAll = () => {
    setPriorityId(null);
    setSelectedPriority("View All");
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
  };

  const onSubmit = async () => {
    // const data = new RequestDetail ({
    //   status: unitStatusEnum.Create,
    //   requestDate: JSON.stringify({
    //     date: new Date(),
    //     name: userName,
    //     status: unitStatusEnum.Create,
    //     userTypeId: 1,
    //     isCreatedBy: 1,
    //     UserType: "Landlord",
    //   }),
    //   maintenanceRequestMain: new MaintenanceRequestMain({
    //     status: unitStatusEnum.Create,
    //     propertyMasterID: propertyMasterID,
    //     propertyUnitID: propertyUnitID === -1 ? null : propertyUnitID,
    //     maintenanceCategoryID: selectCategory,
    //     tenantID: null,
    //     corporateID: null,
    //     lanlordID: +userId,
    //     serviceProviderID: null,
    //     priorityId: priorityID,
    //     description: desc,
    //   }),
    // });
 
 
    const data = new RequestDetail({
      status: unitStatusEnum.Create,
      requestDate: JSON.stringify({
        date: new Date(),
        name: userName,
        status: unitStatusEnum.Create,
        userTypeId: 1,
        isCreatedBy: 1,
        UserType: "Landlord",
      }),
      maintenanceRequestMain: new MaintenanceRequestMain({
        status: unitStatusEnum.Create,
        propertyMasterID: propertyMasterID,
        propertyUnitID: propertyUnitID === -1 ? null : propertyUnitID,
        maintenanceCategoryID: selectCategory,
        tenantID: null,
        corporateID: null,
        lanlordID: +userId,
        serviceProviderID: null,
        priorityId: priorityID,
        description: desc,
      
      }),
    });
    
 
    setloader(true);
    try {
      const response = await createMaintenanceRequest(data);

      let id = null;
      if ("data" in response) {
        debugger
        id = response.data.maintenanceRequestMain.id; // assign id correctly
        // toastr.success("PropertyMaster data submitted successfully!");
      }

      if (id) {
        debugger
        // Remove 'url', 'name' and add 'maintenanceRequestMainID'
        const updatedPropertyMasterImages = uploadedImages.map(
          ({ url, name, ...item }) => ({
            ...item,
            maintenanceRequestMainID: id,
          })
        );

        for (const image of updatedPropertyMasterImages) {
          await maintenanceRequestImages(image); // Upload image
        }
      }
      setloader(false);
      setShow(true);
      setPropertyMasterID(null);
      setShowUnit(false);
      setShowCategory(false);
      setShowSelectCategory(false);
      setShowMaintenanceCard(true);
      setSelectCategory(null);
      setPriorityID(null);
      setUploadedImages([]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className={`card sticky-container ${cardOpen ? "open" : ""}`}>
              <div className="card-body">
                <div className="row">
                  {cardOpen ? (
                    <div className="col-12 col-lg-12">
                      <div className="row gx-4 gy-2">
                        {filteredData?.map((item, index) => (
                          <div key={index} className={`col-12 col-lg-4`}>
                            <div
                              className={`campaign-box-block campaign-box ${
                                activeIndex === item.value
                                  ? "bg-maintenacecard-Active "
                                  : " bg-maintenacecard-light"
                              } `}
                              // style={{
                              //   backgroundImage: `url(${cardbackgroundimage})`,
                              //   backgroundSize: "cover",
                              //   backgroundPosition: "center",
                              //   margin:'0px',
                              //   gap:"2px"
                              // }}

                              onClick={() => handleCardClick(item)}
                            >
                              <div>
                                <div className="d-flex justify-content-center align-items-center gap-3 ">
                                  <img
                                    className="filter-white"
                                    style={{
                                      filter:
                                        "brightness(0) saturate(100%) invert(0%)",
                                      width: "25px",
                                      height: "25px",
                                    }}
                                    src={item.iconPath}
                                  />
                                </div>
                                <h6
                                  className="text-truncate py-2 text-center"
                                  style={{ fontSize: "1rem", width: "100%" }}
                                >
                                  {item.value}
                                </h6>
                                <div className="d-flex justify-content-center">
                                  <h2>{item.count}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    selectedCard && (
                      <div className="col-12">
                        <div
                          className={`campaign-box-block campaign-box propertycard ${
                            activeIndex === selectedCard.value
                              ? "bg-maintenacecard-Active "
                              : "bg-maintenacecard-light"
                          } `}
                          style={{ color: "white" }}
                        >
                          <div className="d-flex justify-content-center align-items-center gap-3">
                            <img
                              className="filter-white"
                              style={{
                                width: "25px",
                                height: "25px",
                              }}
                              src={selectedCard.iconPath}
                            />
                            <h6 className="" style={{ width: "100%" }}>
                              {selectedCard.value}
                              <span className="count-tag">
                                {selectedCard.count}
                              </span>
                            </h6>
                            <h2>
                              <i
                                className="ti ti-menu-2"
                                onClick={handleExpandAllClick}
                                style={{
                                  cursor: "pointer",
                                  transition: "color 0.3s ease",
                                }}
                              />
                            </h2>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            {showMaintenanceCard && (
              <div>
                <div className="row pb-4">
                  <div className="col-md-6">
                    <p className="fw-bolder fs-18">Total Requests</p>
                  </div>
                  <div className="align-items-center col-md-6 d-flex gap-3 justify-content-end text-end">
                    <div>
                      {" "}
                      <span className="fw-bolder page-title">Urgency Sort by</span>{" "}
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        style={{ borderRadius: "60px" }}
                      >
                        {selectedPriority}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{
                          borderRadius: "14px",
                          boxShadow: "-2px 4px 4px 0px #00000026",
                        }}
                      >
                        <div className="profilename">
                          <Dropdown.Item
                            className="dropdown-item"
                            onClick={() => handlePrioritySelectAll()}
                            style={{
                              color: "#147AD6",
                              borderBottom: "0.3px solid #5A5E7C66",
                            }}
                          >
                            All
                          </Dropdown.Item>
                          {Object.keys(priorityEnum).map((priorityKey) => (
                            <Dropdown.Item
                              key={priorityKey}
                              className="dropdown-item"
                              // to="#"
                              onClick={() =>
                                handlePrioritySelect(
                                  priorityKey as keyof typeof priorityEnum
                                )
                              }
                              style={{
                                color:
                                  priorityKey === "High"
                                    ? "#9E2A2B"
                                    : priorityKey === "Low"
                                    ? "#31BA96"
                                    : "#DF9F3E",
                                borderBottom:
                                  priorityKey === "High" ||
                                  priorityKey === "Medium"
                                    ? "0.3px solid #5A5E7C66"
                                    : "none",
                              }}
                            >
                              {priorityKey}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <MaintenancceAllReqCard
                  data={recentMaintenanceRequests}
                  totalcount={totalMaintenanceRequestResponse.totalCount}
                  handlePageChange={handlePageChange}
                  pageNumber={pageNumber}
                  setShowUnit={setShowUnit}
                  setShowMaintenanceCard={setShowMaintenanceCard}
                  handleClick={handleClick}
                />
              </div>
            )}

            {showUnit && (
              <MaintenanceUnitCard
                data={maintenanceRequestApiResponse?.data}
                handleCardunitClick={handleCardunitClick}
                propertyMasterID={propertyMasterID}
                buildingNumber={buildingNumber}
                setShowUnit={setShowUnit}
                setShowMaintenanceCard={setShowMaintenanceCard}
                setPropertyMasterID={setPropertyMasterID}
              />
            )}

            {showCategory && (
              <>
                <MaintenanceUnitCard
                  data={tenantPropertyUnitsMaintenace?.data}
                  handleCardunitClick={handleCardSelectClick}
                  propertyMasterID={propertyMasterID}
                  buildingNumber={buildingNumber}
                  setShowUnit={setShowUnit}
                setShowMaintenanceCard={setShowMaintenanceCard}
                setPropertyMasterID={setPropertyMasterID}
                />

                {/* <pre>{JSON.stringify(tenantPropertyUnitsMaintenace, null, 2)}</pre> */}
              </>
            )}

            {showSelectCategory && (
              <SelectCategoryCard
                setSelectCategory={setSelectCategory}
                setPriorityID={setPriorityID}
                selectCategory={selectCategory}
                priorityID={priorityID}
                desc={desc}
                setDesc={setDesc}
                setUploadedImages={setUploadedImages}
                uploadedImages={uploadedImages}
              />
            )}
            {showSelectCategory && (
              <>
                <div className="py-4 text-end">
                  <button
                    className={`btn btn-primary add-popup ${
                      !propertyMasterID ||
                      !propertyUnitID ||
                      !selectCategory ||
                      !priorityID ||
                      !desc ||
                      uploadedImages.length === 0
                        ? "disabled"
                        : ""
                    }`}
                    onClick={onSubmit}
                    disabled={
                      !propertyMasterID ||
                      !propertyUnitID ||
                      !selectCategory ||
                      !priorityID ||
                      !desc ||
                      uploadedImages.length === 0
                    }
                  >
                    {loader ? (
                      // <span className="spinner-border spinner-border-sm mr-1 "></span>
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                    Create Request
                  </button>
                </div>
              </>
            )}
            <Modal
              size="lg"
              show={show}
              onHide={handleClose}
              animation={false}
              centered
            >
              {/* <Modal.Header closeButton>
                
              </Modal.Header>
             */}
              <div className="text-center">
                <img src="https://d38tibxba17kdz.cloudfront.net/StaffManagementImages/Check.svg" />
              </div>
              <Modal.Title className="text-center">
                Your Request is Successfully done.{" "}
              </Modal.Title>
              <Modal.Body className="text-center">
                Your Request has been submitted. Please contact technician at
                your earliest convenience for further assistance{" "}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-item-center">
                <Button variant="primary" onClick={handleClose}>
                  Got It
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
