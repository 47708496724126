

export interface IMaintenanceRequest{
  maintenanceRequestId: number;
  phoneNumber: string;
  name: string;
  status: number;
  requestDate: string;
}

export class MaintenanceRequest implements IMaintenanceRequest {
  maintenanceRequestId: number;
  phoneNumber: string;
  name: string;
  status: number;
  requestDate: string;

  constructor(data: MaintenanceRequest) {
  
    this.maintenanceRequestId = data.maintenanceRequestId ?? 0;
    this.phoneNumber = data.phoneNumber ?? '';
    this.name = data.name ?? '';
    this.status = data.status ?? 0;
    this.requestDate = data.requestDate ?? '';
  }
}