import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGeneraterecepitQuery, useGetuserSmartNotificationQuery, usePutRequestAgreementMutation, useUpdateRequestAgreementMutation, useUpdateTransactionRentMutation } from "../../../redux/CommonApi";
import { NotificationEnum, RequestTypeEnum, unitStatusEnum, unitStatusEnumMap } from "../../../redux/RequestTypeEnum";
import { useDispatch, useSelector } from "react-redux";
import { notificationData, setAggrementId } from "../../../redux/Commonslice";
import ProceedAgreement from "../TenantMaster/ProceedAgreement";
import { Link } from "react-router-dom";
import { Receipt } from "../../Models/RentCollection/Receipt";
//  const [proceedAgg, setProceedAgg] = useState(null);

const formatCurrency = (amount) => {
  const formattedAmount = new Intl.NumberFormat("en-OM", {
    style: "decimal",
  }).format(amount);
  return `${formattedAmount} OMR`;
};


const Overdue = ({
  userId,
  requestTypeId,
  Pmsnotification,
  setPmsNotifications,
  setIsUpdateData
}) => {
  const [rentId, setRent] = useState(null);
  const {
    data: generatereceiptdata,
    error: generatereceiptdataerror,
    isLoading: isgeneratereceiptdataLoading,
  } = useGeneraterecepitQuery({
    rentID: rentId,

  }) as { data: Receipt, error: any, isLoading: any };
  const [updateTransactionRent] = useUpdateTransactionRentMutation()
  const [updateRequestAgreement] = useUpdateRequestAgreementMutation();
  const [showFeedback, setShowFeedback] = useState(false);
  const [showApproveConditionFields, setShowApproveConditionFields] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [requestAgreementID, setrequestAgreementID] = useState(null);
  const [proposedRent, setProposedRent] = useState<number | string>('');
  const dispatch = useDispatch();
  const [notificationsCache, setNotificationsCache] = useState({});
  const [pageNumbers, setPageNumbers] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [hasMoreCache, setHasMoreCache] = useState({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
  const rowNumber = 10;
  const currentPage = pageNumbers[requestTypeId] || 1;
  const hasMore = hasMoreCache[requestTypeId] ?? true;
  const ScrollRef = useRef(null);
  const prevRequestTypeId = useRef(requestTypeId);
  const popupRef = useRef(null);
  const [buttonLoadingStates, setButtonLoadingStates] = useState({});
  // Api call
  const { data, isLoading, refetch } = useGetuserSmartNotificationQuery({
    userID: userId,
    requestType: requestTypeId,
    pageNumber: currentPage,
    rowNumber,
  });
  //close popup
  useEffect(() => {
    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            togglecloseform();
        }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [popupRef]);
useEffect(() => {
  if (prevRequestTypeId.current !== requestTypeId) {
      setNotificationsCache((prevCache) => {
          const updatedCache = { ...prevCache };
          delete updatedCache[prevRequestTypeId.current];
          return updatedCache;
      });
  }

  if (data && data.data) {
      setTotalCount(data.totalCount);

      setNotificationsCache((prevCache) => {
          const previousData = prevCache[requestTypeId] || [];
          const newData = data.data;

          // Combine previous and new data without filtering out existing records
          const updatedCache = {
              ...prevCache,
              [requestTypeId]: [
                  ...previousData,
                  ...newData,
              ],
          };

          // Remove data for other requestTypeIds to ensure only the current data persists
          Object.keys(updatedCache).forEach((key) => {
              if (key !== String(requestTypeId)) {
                  delete updatedCache[key];
              }
          });

          const notificationsList = updatedCache[requestTypeId] || [];
          dispatch(notificationData(notificationsList));

          return updatedCache;
      });

      prevRequestTypeId.current = requestTypeId;

      // Update hasMoreCache based on the fetched data length
      setHasMoreCache((prevCache) => ({
          ...prevCache,
          [requestTypeId]: data.totalCount > currentPage * rowNumber,
      }));
  }
}, [data, requestTypeId, dispatch, rowNumber]);
  useEffect(() => {
    if (!notificationsCache[requestTypeId] || hasMore) {
      setIsLoadingMore(true);
      setPageNumbers((prev) => ({ ...prev, [requestTypeId]: 1 }));
      refetch(); // Trigger data fetch when no cache or more data is available
      setIsLoadingMore(false);
    } else {
      setPmsNotifications(notificationsCache[requestTypeId]);

    }
  }, [requestTypeId, Pmsnotification, hasMore, refetch, setPmsNotifications]);

  const notification = useSelector((state: any) => state.CRMS.notification);

  const handleScroll = useCallback(() => {
    if (ScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = ScrollRef.current;
      // Calculate the scroll position percentage
      const scrollPosition = (scrollTop + clientHeight) / scrollHeight;
      // Trigger load more when the scroll position exceeds 80%
      if (scrollPosition >= 0.8) {
      if (currentPage * rowNumber < totalCount) { // Check if more data is available
        setPageNumbers((prev) => ({ ...prev, [requestTypeId]: (prev[requestTypeId] || 1) + 1 })); // Increment page number
      }
     
    }
  }
  }, [currentPage, totalCount]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const renderBuildingInfo = (unitId, caption) => {
    if (unitId) {
      return unitId;
    }
    if (caption) {
      return caption;
    }
    return null;
  };

  const processDescription = (description) => {
    if (!description) return null;
    const parts = description.split("</br>");
    return parts.length > 1 ? parts[parts.length - 1] : parts[0];
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB");
  };

  const formatApprovalDate = (dateString) => {
    const approvalDate = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return approvalDate.toLocaleDateString("en-GB");
  };
  const [showProceed, setShowProceed] = useState(false);

  const handleProceedClick = (requestAgreementID) => {

    dispatch(setAggrementId(requestAgreementID))
    setrequestAgreementID(requestAgreementID);
    setShowProceed(true);
  };
  const handleclick = (statusid, rentid) => {
    if (statusid === unitStatusEnum.Approved) {
      setRent(rentid);
      if (!isgeneratereceiptdataLoading && !generatereceiptdataerror) {
        // Assuming the response has an "htmlSting" property (consider fixing the typo to "htmlString" if possible)
        const htmlString = generatereceiptdata.htmlSting || ""; // Default to empty string if not found
        if (htmlString) {
          const openInNewTab = () => {
            const newWindow = window.open();
            newWindow.document.write(htmlString);
            newWindow.document.close();
          };
          // Open the HTML content in a new tab
          openInNewTab();
        } else {
          console.error("HTML string is missing in the API response.");
        }
      } else if (isgeneratereceiptdataLoading) {
      } else if (generatereceiptdataerror) {
        console.error("Error loading receipt data:", generatereceiptdataerror);
      }
    }
  };
  const handleRequestUpdate = async (statusid, rentid) => {
    const obj = [{ rentID: rentid, status: statusid, },];
    const propertyMasterId = await updateTransactionRent(obj);
  };
  const NegotiationRequestUpdate = async (requestAgreementId, requestTypeId, isApprovedByTenant, isApprovedByLandLord, desc, proposedRent, status) => {
    setButtonLoadingStates((prevStates) => ({
      ...prevStates,
      [requestAgreementId]: true,
    }));
    const updatedData = {
      reqAgID: requestAgreementId,
      requestTypeID: requestTypeId,
      isApprovedByTenant: isApprovedByTenant,
      isApprovedByLandLord: isApprovedByLandLord,
      feedback: desc == null ? "" : desc,
      proposedRent: parseFloat(proposedRent as string) || null,
      status: status
    };    
    const result = await updateRequestAgreement({
      Agid: requestAgreementId,
      updatedData,
    }).unwrap();
    setIsUpdateData(true)
    setButtonLoadingStates((prevStates) => ({
      ...prevStates,
      [requestAgreementId]: false,
    }));
  };
  const noDataMessage =
    !isLoading && notificationsCache[requestTypeId]?.length === 0;
  const [show, setShow] = useState(false)
  const [selectedRequests, setSelectedRequests] = useState([]);
  const handleSelect = (notificationId) => {
    if (selectedRequests.includes(notificationId)) {
      setSelectedRequests(selectedRequests.filter(id => id !== notificationId)); // Deselect
    } else {
      setSelectedRequests([...selectedRequests, notificationId]); // Select
    }
  };

  // Handle Select All
  const handleSelectAll = () => {
    if (selectedRequests.length === notification.length) {
      setSelectedRequests([]); // Deselect all
    } else {
      const allIds = notification.map(request => request.notificationId);
      setSelectedRequests(allIds); // Select all
    }
  };

  // Handle Approve All
  // const handleApproveAll = async() => {
  //   // Approve logic can go here
  //   const updatedStatus = notification.map(request => {
  //     if (selectedRequests.includes(request.requestId)) {
  //       // Update the status of each selected item here (for example, changing the request's status)
  //       const obj = [{ rentID: request.requestId, status: unitStatusEnum.APPROVED, },];
  //       // const propertyMasterId = await updateTransactionRent(obj);
  //       return { ...request, status: 'approved' };
  //     }
  //     return request;
  //   });
  // };
  const handleApproveAll = async () => {
    try {
      const updatedRequests = notification.map(request => {
        if (selectedRequests.includes(request.notificationId)) {
          return { rentID: request.requestId, status: unitStatusEnum.Approved };
        }
        return null;
      }).filter(request => request !== null);
      const response = await updateTransactionRent(updatedRequests);

      // if (response.statusCode === 200) {
      //   console.log("Success:", response.message);
      // } else {
      //   console.log("Error:", response.message);
      // }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleRejectAll = async () => {
    try {
      const updatedRequests = notification.map(request => {
        if (selectedRequests.includes(request.notificationId)) {
          return { rentID: request.requestId, status: unitStatusEnum.Rejected };
        }
        return null;
      }).filter(request => request !== null);
      const response = await updateTransactionRent(updatedRequests);

      // if (response.statusCode === 200) {
      //   console.log("Success:", response.message);
      // } else {
      //   console.log("Error:", response.message);
      // }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Handle Reject All
  // const handleRejectAll = () => {
  //   // Reject logic can go here
  //   const updatedStatus = notification.map(request => {
  //     if (selectedRequests.includes(request.notificationId)) {
  //       return { ...request, status: 'rejected' };
  //     }
  //     return request;
  //   });
  //   console.log("Rejected notifications:", updatedStatus);
  // };

  const showOn = () => {
    setShow(true)
  }
  const togglecloseform = () => {
    setShowProceed(false)

  }
  return (
    <div className="notificationCOntainer" ref={ScrollRef}
      onScroll={handleScroll}
      
      style={{ height: "100vh", overflowY: "auto" }}>
      <ul className="notification-list">
        <li className="notification-message mx-3 pt-3">
          {isLoading && currentPage === 1 ? (
            <div className="d-flex align-items-center justify-content-center">
              <div className="loading-spinners"></div>
            </div>
          ) : notification?.length === 0 ? (
            <div
              className="card d-flex align-items-center justify-content-center"
              style={{ padding: "40px 0", border: "none" }}
            >
              <div className="card-body">
                <img
                  src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                  alt="No data found"
                />
                <h3 className="text-center">No Data Found</h3>
              </div>
            </div>
          ) : (
            <>{requestTypeId === 5 && (
              <div className="align-items-center d-flex gap-2 justify-content-end py-2 text-end">
                {show === false && (<div className="badge  maintanace-btn mb-2 p-2" onClick={showOn}>select</div>)}
                {show === true && (<>
                  {/* <div className="btn" onClick={() => setSelectedRequests([])}>Deselect</div> */}
                  <div className="badge  maintanace-btn mb-2 p-2" onClick={handleSelectAll}>   {selectedRequests.length === notification.length ? "Deselect All" : "Select All"}</div>
                  <div className="badge  maintanace-btn mb-2 p-2" onClick={handleApproveAll}>Approve</div>
                  <div className="badge  reject-btn mb-2 p-2" onClick={handleRejectAll}>Reject</div>
                </>)}
              </div>
            )}

              {notification?.map((request) => {
                const statusInfo = unitStatusEnumMap[request.status] || null;
                const Urgency = unitStatusEnumMap[request.urgencyId] || null;
                const isSelected = selectedRequests.includes(request.notificationId);
                return (
                  <div key={request.notificationId}
                    className={`req-border ${isSelected ? 'bg-selected' : ''}`}
                  >
                    <div className="row mt-3">
                      <div className="col-md-8" onClick={() => handleclick(request.status, request.requestId)}>
                        <div className="d-flex align-items-center w-100">
                          <img
                            src={request.imageURL || profileimg}
                            alt="img"
                            className="avatar avatar-rounded me-2"
                          />
                          <div>
                            <div className="fs-15 fw-semibold">
                              {request.userName}
                              {request.expenseCategory && (
                                <div className="fw-semibold align-content-center">
                                  {request.expenseCategory}
                                  <span className="fs-12 primary-text">
                                    ({request.expenseSubCategory})
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 text-end align-content-center">
                        {renderBuildingInfo(
                          request.unitId,
                          request.caption
                        ) && (
                            <div className="badge fw-15 bg-secondary-transparent fs-12">
                              Building #{request.buildingNumber}
                              <sub>
                                {renderBuildingInfo(
                                  request.unitId,
                                  request.caption
                                )}
                              </sub>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mt-2 mb-1">
                      <div className="col-md-8">
                        {processDescription(request.desc)}
                        {requestTypeId === 10 && (
                          <span>
                            {request.desc
                              ? processDescription(request.desc)
                              : "Mumtalikati - A 360 & AI Powered Real Estate Solution to Rent, Buy, Sale and manage your Properties"}
                          </span>
                        )}

                        {request.paymentType && (
                          <div className="fs-15 fw-semibold align-content-center">
                            Payment Type:
                            <span className="fs-13 maintanace-text">
                              {request.paymentType}
                            </span>
                          </div>
                        )}

                        {request.terms && (
                          <div className="fs-15 fw-semibold align-content-center">
                            Terms:
                            <span className="fs-13 maintanace-text">
                              {request.terms}
                            </span>
                          </div>
                        )}
                        {request.remainingDays && (
                          <div className="align-content-center">
                            Agreement will expire in
                            <span className="fs-14 primary-text fw-semibold">
                              {request.remainingDays}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 text-end">
                        {request.createdDate && (
                          <div>{formatDate(request.createdDate)}</div>
                        )}
                        {requestTypeId !== 10 && (
                          <>{formatDate(request.date)}</>
                        )}
                      </div>
                    </div>

                    <div className="d-flex mb-1">
                      {(requestTypeId === 7 ||
                        requestTypeId === 8 ||
                        requestTypeId === 10) && (
                          <>

                            <div className="pe-2">
                              <span className="badge fw-13 bg-info-maintanance">
                                {request.maintenanceCategory}
                              </span>
                            </div>
                            {Urgency && (
                              <div className="pe-2">
                                <span
                                  className="badge fw-13"
                                  style={{
                                    color: Urgency.color,
                                    backgroundColor: Urgency.backgroundColor,
                                  }}
                                >
                                  {Urgency.text}
                                </span>
                              </div>
                            )}
                            {statusInfo && (
                              <div>
                                <span
                                  className="badge fw-13"
                                  style={{
                                    color: statusInfo.color,
                                    backgroundColor: statusInfo.backgroundColor,
                                  }}
                                >
                                  {statusInfo.text}
                                </span>
                              </div>
                            )}
                          </>
                        )}
                    </div>
                    {requestTypeId === 10 && (
                      <>
                        {request.status === 11 && (
                          <div>
                            <span className="fw-13">
                              Approve date: {formatApprovalDate(request.date)}
                            </span>
                          </div>
                        )}
                        {request.status === 12 && (
                          <div>
                            <span className="fw-13">
                              Reject date: {formatApprovalDate(request.date)}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                    <div className="row mb-1">
                      {request.tokenNumber && (
                        <div className="col-md-6 align-content-center">
                          <div className="fs-15 fw-semibold text-black align-content-center">
                            Token num- {request.tokenNumber}
                          </div>
                        </div>
                      )}
                      {request.amount && (
                        <div className="col-md-6 align-content-center">
                          <div className="fs-15 fw-semibold text-black align-content-center">
                            Amount: {formatCurrency(request.amount)}
                          </div>
                        </div>
                      )}
                      {requestTypeId == RequestTypeEnum.TRANSACTIONS && (
                        <div className="col-md-6 text-end">
                          <div className="d-flex justify-content-end">
                            <div
                              className="badge  maintanace-btn mb-2 p-2"
                              onClick={() =>
                                handleRequestUpdate(unitStatusEnum.Approved, request.requestId)
                              }
                            >
                              Approve
                            </div>
                            <div className="ps-2">
                              <div
                                className="badge  reject-btn mb-2 p-2"
                                onClick={() =>
                                  handleRequestUpdate(unitStatusEnum.Rejected, request.requestId)
                                }
                              >
                                Reject
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {(requestTypeId == RequestTypeEnum.TENANCY_REQUEST || requestTypeId == RequestTypeEnum.RENEWAL_REQUEST) && request?.isApprovedByLandLord && request?.isApprovedByTenant && (
                        <div className="col-md-6 text-end">
                          <div className="d-flex justify-content-end">
                            <div
                              className="badge  maintanace-btn mb-2 p-2"
                              onClick={() => handleProceedClick(request?.requestAgreementId)}
                            >
                              Proceed Agreement
                            </div>

                          </div>
                        </div>
                      )}




                      {/* {showProceed && (
                     
        <ProceedAgreement
          setProceedAgg={null}
   
          setgoback={false}
          setTenenacyRequestDetail={null}
          RequestAgreementID={request?.requestAgreementId}
        />
      )} */}
                      {(requestTypeId == RequestTypeEnum.TENANCY_REQUEST || requestTypeId == RequestTypeEnum.REQUEST || requestTypeId == RequestTypeEnum.RENEWAL_REQUEST) && !request?.isApprovedByLandLord && request?.isApprovedByTenant && (

                        <div className="col-md-12 text-end">
                          <div className="d-flex justify-content-end ms-auto">
                            <div>

                              <button
                                className={`badge mb-2 p-2 ${buttonLoadingStates[request?.requestAgreementId] ? 'btn-disabled' : 'maintanace-btn'}`}
                                onClick={() =>
                                  NegotiationRequestUpdate(request?.requestAgreementId, request?.requestTypeId, request?.isApprovedByTenant, true, request?.desc, request?.amount, (request?.isApprovedByTenant && request?.ReqrequestTypeId == RequestTypeEnum.RENEWAL_REQUEST || requestTypeId == RequestTypeEnum.REQUEST) ? unitStatusEnum.Approved : request?.status)
                                }
                                disabled={buttonLoadingStates[request?.requestAgreementId]}
                              > {buttonLoadingStates[request?.requestAgreementId] ? (
                                <span className="spinner-border spinner-border-sm mr-1 "></span> // Display loader (you can use a spinner icon or other loader component)
                              ) : (
                                ''
                              )}
                                Approve
                              </button>
                            </div>
                            {/* Approve with Condition Button */}
                            <div className="ps-2">
                              {showApproveConditionFields && (
                                <div>
                                  <input
                                    type="number"
                                    className="form-control mb-2"
                                    placeholder="Proposed Rent"
                                    value={proposedRent}
                                    onChange={(e) => setProposedRent(e.target.value)}
                                  />
                                  <textarea
                                    className="form-control mb-2"
                                    rows={3}
                                    placeholder="Feedback"
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                  ></textarea>

                                  <button
                                    className={`badge mb-2 p-2 ${buttonLoadingStates[request?.requestAgreementId] ? 'btn-disabled' : 'maintanace-btn'}`}
                                    onClick={() => {
                                      setShowApproveConditionFields(false);
                                      NegotiationRequestUpdate(
                                        request?.requestAgreementId,
                                        request?.requestTypeId,
                                        false,
                                        true,
                                        request?.desc == null ? `Landlord:${feedback}` : `${request?.desc, `Landlord:${feedback}`}`,
                                        proposedRent, // Proposed Rent as request?.amount
                                        request?.status
                                      );
                                    }}
                                    disabled={buttonLoadingStates[request?.requestAgreementId]}
                                  >  {buttonLoadingStates[request?.requestAgreementId] ? (
                                    <>
                                      {" "}
                                      <span className="spinner-border spinner-border-sm mr-1 "></span>
                                    </>
                                  ) : null}
                                    Submit
                                  </button>
                                </div>
                              )}
                              <div
                                className="badge maintanace-btn mb-2 p-2"
                                onClick={() => { setShowFeedback(false), setShowApproveConditionFields(!showApproveConditionFields), setProposedRent(request?.amount) }}
                              >
                                Approve With Condition
                              </div>

                            </div>
                            {/* Reject Button */}
                            <div className="ps-2">
                              {showFeedback && (
                                <div>
                                  <textarea
                                    className="form-control mb-2"
                                    rows={3}
                                    placeholder="Reason of Rejection"
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                  ></textarea>
                                  <button
                                    className={`badge   mb-2 p-2 ${buttonLoadingStates[request?.requestAgreementId] ? 'btn-disabled' : 'maintanace-btn'}`}
                                    onClick={() => {
                                      setShowFeedback(false);
                                      NegotiationRequestUpdate(
                                        request?.requestAgreementId,
                                        request?.requestTypeId,
                                        request?.isApprovedByTenant,
                                        false,
                                        request?.desc == null ? `Landlord:${feedback}` : `${request?.desc, `,Landlord:${feedback}`}`, // Feedback as request?.desc
                                        request?.amount, // Keep current amount
                                        unitStatusEnum.Rejected // Reject status
                                      );
                                    }}
                                    disabled={buttonLoadingStates[request?.requestAgreementId]}

                                  >
                                    {buttonLoadingStates[request?.requestAgreementId] ? (
                                      <>
                                        {" "}
                                        <span className="spinner-border spinner-border-sm mr-1 "></span>
                                      </>
                                    ) : null}

                                    Submit
                                  </button>
                                </div>
                              )}
                              <div
                                className="badge reject-btn mb-2 p-2"
                                onClick={() => { setShowFeedback(!showFeedback), setShowApproveConditionFields(false) }}
                              >
                                Reject
                              </div>

                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
               {/* Loading more message */}
      {isLoadingMore &&  (<div className="d-flex align-items-center justify-content-center">
                  <div className="loading-spinners"></div>
                </div>)}

{/* No more notifications message */}
{!hasMore && (
  <p style={{ textAlign: "center", color: "gray" }}>
    No more notifications
  </p>
)}
              {isLoading && currentPage > 1 && (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="loading-spinners"></div>
                </div>
              )}
            </>
          )}
        </li>
      </ul>
      <div
        className={
          showProceed ? "toggle-popup sidebar-popup" : "toggle-popup"
        
        }
        ref={popupRef} 
      >
        <div className="sidebar-layout w-100 w-sm-100">
          <div className="sidebar-header">
            <h4>Proceed Agrement</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={togglecloseform}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="p-2">
            <ProceedAgreement
              setProceedAgg={null}
              setgoback={false}
              RequestAgreementID={requestAgreementID}
              showProceed={showProceed}
              setShowProceed={setShowProceed}
            />
          </div>
        </div>

      </div>
    </div>

  );
};

export default React.memo(Overdue);
