import React, { useEffect, useState } from "react";
import {
  useGetExpenseDashboardQuery,
  useGetRentCollectionDashboardQuery,
  useGetsetupRentFrequencyQuery,
} from "../../redux/CommonApi";
import { Dropdown } from "react-bootstrap";
import { priorityEnum } from "../../redux/RequestTypeEnum";
import { Link } from "react-router-dom";
import { Loader } from "../../shared/Loader";
import { ExpenseDashboardModel } from "../Models/Expense/ExpenseDashboard";

export const ExpenseDashboardMaincard = () => {
  const userId = sessionStorage.getItem("userId");
  const { data: expenseDashboard , isLoading, error } = useGetExpenseDashboardQuery({ landlordId: +userId });
  

  // const {
  //   data: expenseDashboardData,
  //   isLoading,
  //   error,
  // } = useGetExpenseDashboardQuery({
  //   landlordId: userId,
  // });
  if (isLoading) return <p><Loader /></p>;
  if (error) return <p>Error fetching properties.</p>;

  
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="d-flex justify-content-between">
         
            <h5 className="card-title">Your Expenses</h5>
            <h6 className="card-title text-muted">
          {expenseDashboard.currentMonthDate}
        </h6>
          </div>
        </div>
        
        <h5 className="card-title">
          OMR {expenseDashboard.yourExpense}
        </h5>

        <div className="row">
          <div className="col-md-4 gap-2">
          <span> OMR {Math.abs(expenseDashboard.raiseAmount)}</span>

            {/* <span className={`${expenseDashboardData?.data?.[0].indicator === "neg" ? "bg-danger": "bg-success"}  badge badge-pill badge-status ms-2 `}>
              {" "}
              <i className={`fa-solid ${expenseDashboardData?.data?.[0].indicator === "neg" ? "fa-caret-down": "fa-caret-up"}  me-2`}></i>{" "}
              {expenseDashboardData?.data?.[0].raisePercentageDisplay}
            </span> */}
            <span className={`${expenseDashboard.indicator === "neg" ? "bg-danger" : "bg-success"} badge badge-pill badge-status ms-2`}>
              <i className={`fa-solid ${expenseDashboard.indicator === "neg" ? "fa-caret-down" : "fa-caret-up"} me-2`}></i>
              {expenseDashboard.raisePercentageDisplay}
            </span>
          </div>
        </div>
        <div className="row py-3 gy-2">
          <Link to={"/admin/addExpense"} className="RentCollectionMaincard-sm   col-md-4 ">
            <div
              className="align-items-center  d-flex flex-column justify-content-center rent-colection-card"
              // style={{ background: "#F5434F" }}
              style={{ background: "#cc3833" }}
            >
              <img
                className="filter-white my-2"
                style={{
                  width: "25px",
                  height: "25px",
                }}
                src={
                  "https://d38tibxba17kdz.cloudfront.net/statusIcon/SentRequests.svg"
                }
              />

              <h5 className="card-title text-center ">Add Expense</h5>
            </div>
          </Link>

           <Link to={"/admin/expense-transactions"} className="RentCollectionMaincard-sm  col-md-4">
            <div
              className="align-items-center  d-flex flex-column justify-content-center rent-colection-card"
              // style={{ backgroundColor: "rgb(42 126 157 / 74%)" }}
              style={{ background: "#02686aca" }}
            >
              <img
                className="filter-white my-2"
                style={{
                  width: "25px",
                  height: "25px",
                }}
                src={
                  "https://d38tibxba17kdz.cloudfront.net/statusIcon/Re-Open.svg"
                }
                 alt="Re-Open Icon"
              />

              <h5 className="card-title text-center ">Transactions</h5>
            </div>
          </Link>

          <div className="RentCollectionMaincard-sm  col-md-4">
            <div
              className="align-items-center  d-flex flex-column justify-content-center rent-colection-card"
              // style={{ background: "#FFC268" }}
              style={{ background: "#ecb32d " }}
            >
              <img
                className="filter-white my-2"
                style={{
                  width: "25px",
                  height: "25px",
                }}
                src={
                  "https://d38tibxba17kdz.cloudfront.net/statusIcon/In_progress.svg"
                }
              />
              <h5 className="card-title text-center ">Trail's</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
