import React, { useState } from "react";
import {
  useGetExpenseLandlordBuildingListQuery,
  useGetOwnerPropertyMasterQuery,
  useGetOwnerPropertyMasterTotalCountQuery,
} from "../../redux/CommonApi";
import { Pagination, Stack } from "react-bootstrap";
import { makeStyles } from "@mui/material";
import { StackPagination } from "../../shared/StackPagination";
import { Loader } from "../../shared/Loader";
import { useDispatch } from "react-redux";
import { setExpensePropertyMasterId } from "../../redux/Commonslice";
import { useNavigate } from "react-router";
import { ResponseMessage } from "../Models/GenericModel/ResponseMessage";
import { BuildingExpense } from "../Models/Expense/BuildingExpense";

const ExpenseBuilding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  React.useEffect(() => {
    if (pathname === "/admin/expense") {
      dispatch(setExpensePropertyMasterId(null));
    }
  }, [pathname, dispatch]);

  const landlordId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);


  // const { data:expenseBuildings, error, isLoading } = useGetExpenseLandlordBuildingListQuery({
  //   landLordID: +landlordId,
  //   pageNumber: pageNumber,
  // });

  const { data: expenseBuildings, error, isLoading } = useGetExpenseLandlordBuildingListQuery({
    landLordID: +landlordId,
    pageNumber: pageNumber,
  }) as { data: ResponseMessage<BuildingExpense>, error: any, isLoading: boolean };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
  };
  // const handleCardClick = (propertyId, status) => {};

  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";


 const handleCardClick = (propertyId) => {
    dispatch(setExpensePropertyMasterId(propertyId));
    navigate("/admin/expense-transactions");
  }; 

  if (isLoading)
    return (
      <p>
        <Loader />
      </p>
    );
  if (error) return <p>Error fetching properties.</p>;

  return (
    <>
      <div className="row pb-5">
        {expenseBuildings && expenseBuildings?.data?.length > 0 ? (
          expenseBuildings?.data?.map((property) => {
            // const images = property.imageString || [];
        const imageSrc = property.imageString ?? fallbackImage;

            return (
              <div
                key={property.propertyMasterID}
                className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 cursor-pointer"
                onClick={() =>
                  handleCardClick(
                    property.propertyMasterID
                  )
                }
                
              >
                <div className="card totalunit-card  ">
                  <div className="row g-2 card-body expense-total-building">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div>
                        <img
                          src={imageSrc}
                          className="img-fluid rounded-start object-fit-cover h-100 w-100"
                          alt={property.propertyMasterName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8 ps-3">
                      <div className="pt-1">
                        <p>
                          Building ID: <span>{property?.builidngID}</span>
                        </p>
                        <p className="truncate">
                          OMR <span>{property?.totalBuildingExpense}</span>
                        </p>
                        <p>
                          <span>
                            OMR{" "}
                            <span 
                          className={`${property.raisePercentage > 0 ? "text-danger" : "text-success"}`}
                          >
                              {property?.raiseAmount}
                            </span>
                          </span>
                          <span className="ms-4">
                            {property?.raisePercentageDisplay && (
                              <>
                                <i
                                 
                                  className={`fa-solid ${
                                    property.raisePercentage > 0
                                      ? "fa-caret-down text-danger"
                                      : "fa-caret-up text-success"
                                  }`}
                                ></i>{" "}
                                <span
                                className={`${property.raisePercentage > 0 ? "text-danger" : "text-success"}`}
                                  // style={{
                                  //   color:
                                  //     property.raisePercentage > 0
                                  //       ? "red"
                                  //       : "#31AD6E",
                                  // }}
                                >
                                  {property.raisePercentageDisplay}
                                </span>
                              </>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="card d-flex align-items-center justify-content-center"
            style={{ padding: "40px 0", border: "none" }}
          >
            <div className="card-body">
              <img
                src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                alt="No data found"
              />
              <h3 className="text-center">No Data Found</h3>
            </div>
          </div>
        )}
      </div>
      {expenseBuildings?.totalCount > 0 && (
        <div className="row row1  fixed-bottom1">
          <div className="d-flex justify-content-center">
            <StackPagination
              tenancyRequestCount={expenseBuildings?.totalCount}
              rowNumbers={12}
              pageNumber={pageNumber}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ExpenseBuilding;
