
export interface IRentalFrequency  {
  rentalFrequencyType: number;
  desc: string;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string | null;
  modifyTime: string | null;
  isCreatedBy: string | null;
  isUpdatedBy: string | null;
  }
  export class RentalFrequency implements IRentalFrequency {
    rentalFrequencyType: number;
    desc: string;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: string | null;
    isUpdatedBy: string | null;

    constructor(data: RentalFrequency) {
      this.rentalFrequencyType = data.rentalFrequencyType ?? 0;
      this.desc = data.desc ?? '';
      this.id = data.id ?? 0;
      this.status = data.status ?? 0;
      this.statusCode = data.statusCode ?? '';
      this.createdTime = data.createdTime ?? '';
      this.modifyTime = data.modifyTime ?? '';
      this.isCreatedBy = data.isCreatedBy ?? '';
      this.isUpdatedBy = data.isUpdatedBy ?? '';
    }
  }
  