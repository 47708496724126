
export interface IUserPendingLiabilitie  {
  tenantID: number;
  tenantName: string;
  aggID: number;
  overAllOutStanding: number;
  dueRent: number;
  rentAmount: number;
  aggStartDate: string; 
  aggEndDate: string;   
  agStatus: number;
  }
  export class UserPendingLiabilitie implements IUserPendingLiabilitie {
    tenantID: number;
    tenantName: string;
    aggID: number;
    overAllOutStanding: number;
    dueRent: number;
    rentAmount: number;
    aggStartDate: string; 
    aggEndDate: string;  
    agStatus: number;

    constructor(data: UserPendingLiabilitie) {
      this.tenantID = data.tenantID ?? 0;
      this.tenantName = data.tenantName ?? "";
      this.aggID = data.aggID ?? 0;
      this.overAllOutStanding = data.overAllOutStanding ?? 0;
      this.dueRent = data.dueRent ?? 0;
      this.rentAmount = data.rentAmount ?? 0;
      this.aggStartDate = data.aggStartDate ?? "";
      this.aggEndDate = data.aggEndDate ?? "";
      this.agStatus = data.agStatus ?? 0;
    }
  }
  