import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useGetTenantDashboardMaintenanceRequestQuery } from "../../../redux/CommonApi";
import { ApexOptions } from "apexcharts";

const ExpiringAgreeChart = () => {
  const userId = sessionStorage.getItem("userId");
  const { data } = useGetTenantDashboardMaintenanceRequestQuery({
    userID: userId,
  });
  const [seriesData, setSeriesData] = useState([]);
  useEffect(() => {
    if (data && data.data && data.data.tenantDashboard) {
      const counts = data.data.tenantDashboard.map((item) => item.count);
      setSeriesData(counts);
    }
  }, [data]);

  const donutChart: ApexOptions = {
    chart: {
      height: 350,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    colors: ["#147AD6", "#70BEF2", "#DF9F3E", "#31BA96", "#A282C2", "#F4976B"],
    series: seriesData,
    labels: [
      "Received",
      "In-Progress",
      "On Hold",
      "Work Done",
      "Re-Open",
      "Closed",
    ],
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <div className="card cardreq">
        <div className="card-header d-flex justify-content-between">
          <h6 className="card-title">Maintenance Requests Status</h6>
          <p className="text-primary">See all Status</p>
        </div>
        <div className="card-body">
        
          <div
            id="donut-chart"
            className="text-avatar position-relative donut-chart"
          >
            <ReactApexChart
              options={donutChart}
              series={donutChart.series}
              type="donut"
              height={350}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpiringAgreeChart;
