import React, { useState, useEffect } from "react";
import ImageWithBasePath from "../../ImageWithBasePath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import loginimg from "../../../public/assets/img/authentication/login.png";
import toastr from "toastr";
import OneSignal from "react-onesignal";
import "toastr/build/toastr.min.css";
import "./login.scss"
import { useGetAuthenticateUserMutation, useUpdateActiveSessionMutation } from "../../redux/CommonApi";
import { unitStatusEnum, UserTypeEnum } from "../../redux/RequestTypeEnum";
 import { v4 as uuidv4 } from 'uuid';

const Login = () => {
   const [uniqueId] = useState(`${uuidv4()}`);
  console.log("uniqueId",uniqueId);
  const route = all_routes;
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [browser, setBrowser] = useState("");
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [getAuthenticateUser, { isLoading }] = useGetAuthenticateUserMutation();
  const [PUTActiveSession] = useUpdateActiveSessionMutation();
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const styles = {
    backgroundImage: `url(${loginimg})`,
  };
  const [logo, setlogo] = useState(
    "https://d2og5lryw1ajtt.cloudfront.net/img/mumtalikatilogred.png"
  );

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };
  useEffect(() => {

    const detectBrowser = () => {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
        return "Chrome";
      } else if (userAgent.includes("Firefox")) {
        return "Firefox";
      } else if (
        userAgent.includes("Safari") &&
        !userAgent.includes("Chrome")
      ) {
        return "Safari";
      } else if (userAgent.includes("Edg")) {
        return "Edge";
      } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        return "Internet Explorer";
      } else if (userAgent.includes("OPR") || userAgent.includes("Opera")) {
        return "Opera";
      } else {
        return "Unknown Browser";
      }
    };

    setBrowser(detectBrowser());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let deviceIp = "";
      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        deviceIp = ipData.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
  
      const requestBody = {
        userName: formData.userName,
        password: formData.password,
        deviceToken: uniqueId,
        userLog: {
          deviceName: browser,
          deviceIp: deviceIp,
        },
      };
  
      const response = await getAuthenticateUser (requestBody).unwrap();
      const userTypes = response.userTypes.map((userType) => userType.id);
      if (userTypes.includes(1)) {
        toastr.success("Login successful!");
        sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("userId", response.id);
        sessionStorage.setItem("userName", response.name);
        sessionStorage.setItem("browerUniqueID", uniqueId);
        sessionStorage.setItem("profileImage", response.profileImage);
        const landlordType = response.userTypes.find(
          (userType) => userType.userType === 1
        );
        sessionStorage.setItem("userType", landlordType.userType);
        // localStorage.setItem("usertype", response.desc);


        navigate("/admin/dashboard");
  
        // Set the external user ID in OneSignal after navigation
        window.OneSignalDeferred.push(async function(OneSignal) {
          try {
            await OneSignal.login(uniqueId.toString()); // Set the user ID as external user ID
            console.log("External user ID set successfully:", response.id);
          } catch (error) {
            console.error("Error setting external user ID:", error);
          }
        });
  
        // Call PUTActiveSession after navigation
        const updatedData = {
          status: unitStatusEnum.Vacant,
          isCreatedBy: response?.id,
          userId: response?.id,
          digestToken: response.token, // Use the actual token
          deviceToken: uniqueId.toString(),
          userTypeId: UserTypeEnum.Landlord,
          isDefaultIndicator: true,
        };
  
        // Make the API call to update the active session
        await PUTActiveSession({ userID: response.id, updatedData }).unwrap();
      } else {
        toastr.warning("Access denied");
      }
    } catch (error) {
      toastr.error("An error occurred during login.");
      console.error("Login error:", error);
    }
  };
  


  return (
    <>

      <div className="login-container">
        <div className="login_form">
          <form onSubmit={handleSubmit}>
            <h3>   <div className="login-logo">
                <img src={logo} className="img-fluid" alt="Logo" width={"150px"} />
              </div></h3>
            <div className="input_box">
              <label htmlFor="email">Phone Number
              </label>
              <input type="number"  id="userName"
                    onChange={handleChange}
                    value={formData.userName} placeholder="Enter phone Number" required />
            </div>
            <div className="input_box">
              <div className="password_title">
                <label htmlFor="password">Password</label>
                {/* <a href="#">Forgot Password?</a> */}
              </div>
              <input type={isPasswordVisible ? "text" : "password"}  id="password"
                    onChange={handleChange}
                    value={formData.password}
                    style={{position:"relative"}}
                     placeholder="Enter your password" required />
                         <span style={{position:"absolute",right:"20px",top:"46px",fontSize:"20px"}}
                    className={`ti toggle-password ${
                      isPasswordVisible ? "ti-eye" : "ti-eye-off"
                    }`}
                    onClick={togglePasswordVisibility}
                  ></span>
            </div>
            <button className="lgn_button" type="submit" disabled={isLoading}>  {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}Sign In</button>

            {/* <p className="sign_up">Don't have an account? <a href="#">Sign up</a></p> */}
          </form>
        </div>
      </div>

    </>
  );
};
export default Login;
