// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
    background-color: #e0e0e0;
    animation: skeleton-loading 1.5s infinite linear;
    border-radius: 4px;
  }
  
  .skeleton-input-container {
    display: inline-block;
    width: 100%;
  }
  
  .skeleton-input {
    height: 40px;
    width: 100%;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/RentCollection/Skeleton.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gDAAgD;IAChD,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".skeleton {\r\n    background-color: #e0e0e0;\r\n    animation: skeleton-loading 1.5s infinite linear;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .skeleton-input-container {\r\n    display: inline-block;\r\n    width: 100%;\r\n  }\r\n  \r\n  .skeleton-input {\r\n    height: 40px;\r\n    width: 100%;\r\n  }\r\n  \r\n  @keyframes skeleton-loading {\r\n    0% {\r\n      background-color: #e0e0e0;\r\n    }\r\n    50% {\r\n      background-color: #f0f0f0;\r\n    }\r\n    100% {\r\n      background-color: #e0e0e0;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
