import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useExpenseCategoryQuery,
  usePostExpenseMutation,
  useSubCategoryExpenseQuery,
} from "../../redux/CommonApi";
import ExpenseBuildingPopup from "./ExpenseBuildingPopup";
import { unitStatusEnum } from "../../redux/RequestTypeEnum";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Loader } from "../../shared/Loader";
import { AddExpenseRequest } from "../Models/Expense/AddExpenseRequest";
import { ResponseMessage } from "../Models/GenericModel/ResponseMessage";
import { ExpenseCategory } from "../Models/Expense/ExpenseCategory";
import { ExpenseSubCategory } from "../Models/Expense/ExpenseSubCategory";

export const AddExpense = () => {
  const navigate = useNavigate();
  const { data: categoryData, isLoading, error } = useExpenseCategoryQuery() as { data: ResponseMessage<ExpenseCategory>, isLoading: boolean, error: any   };
  const [postExpense] = usePostExpenseMutation();
  const { data: SubcategoryData1 } = useSubCategoryExpenseQuery() as { data: ResponseMessage<ExpenseSubCategory>, isLoading: boolean, error: any   };
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };
  const [loader, setloader] = useState(false);
  const [category, setCategory] = useState({
    categorydesc: "",
    selectedCategory: null,
    categoryurl: "",
  });
  const [SubCategory, setSubCategory] = useState({
    subcategorydesc: "",
    selectedCategory: null,
    Subcategoryurl: "",
  });
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";

  const [subcategories, setSubcategories] = useState([]);
  const [isBuildingPopUp, setBuildingPopUp] = useState(false);
  const [propertyMasterID, setpropertyMasterID] = React.useState(0);
  const [selectedUnits, setSelectedUnits] = React.useState([]);
  const [saveSelectedUnits, setSaveSelectedUnits] = React.useState([]);
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    amount: "",
    description: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChipClick = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      amount: value,
    }));
  };
  const handleCategoryClick = (id, desc, url) => {
    const categoryData = {
      categorydesc: desc,
      selectedCategory: id,
      categoryurl: url,
    };
    setCategory(categoryData);
    const filteredSubcategories = SubcategoryData1?.data?.filter(
      (item) => item.catid === id
    );
    setSubcategories(filteredSubcategories);
    setSubCategory({
      subcategorydesc: "",
      selectedCategory: null,
      Subcategoryurl: "",
    });
  };

  const handleSubCategoryClick = (id, desc, url) => {
    const SubcategoryData = {
      subcategorydesc: desc,
      selectedCategory: id,
      Subcategoryurl: url,
    };
    setSubCategory(SubcategoryData);

    setBuildingPopUp(!isBuildingPopUp);
  };

  const NextClick = (event) => {
    event.preventDefault();

    setBuildingPopUp(!isBuildingPopUp);
  };

  const chipValues = [250, 300, 400, 50, 100, 150, 200];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(true);
  
    // Bind model using the AddExpense class
    const data = saveSelectedUnits
      .map((property) => {
        return property.units.map((unit) => {
          return new AddExpenseRequest({
            expenseSubCatMapingID: SubCategory.selectedCategory,
            expenseDate: formData.date,
            propertyUnitID: unit.unitID,
            propertyMasterID: property.propertyMasterID,
            amount: formData.amount,
            status: unitStatusEnum.PENDING,
            desc: formData.description,
          });
        });
      })
      .flat();
  
    try {
      const response = await postExpense(data); 
      if (response) {
        setloader(false);
        resetForm();
        toastr.success("Expense Added Successfully");
        navigate("/admin/expense");
      }
    } catch (error) {
      setloader(false);
      console.error(error);
    }
  };
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setloader(true);
  //   const data = saveSelectedUnits
  //     .map((property) => {
  //       return property.units.map((unit) => ({
  //         expenseSubCatMapingID: SubCategory.selectedCategory,
  //         expenseDate: formData.date,
  //         propertyUnitID: unit.unitID,
  //         propertyMasterID: property.propertyMasterID,
  //         amount: formData.amount,
  //         status: unitStatusEnum.PENDING,
  //         desc: formData.description,
  //       }));
  //     })
  //     .flat();
  //   try {
  //     const response = await AddExpense(data);
  //     if (response) {
  //       setloader(false);
  //       resetForm();
  //       toastr.success("Expense Added Successfully");
  //       navigate("/admin/expense");
  //     }
  //   } catch (error) {
  //     setloader(false);
  //     console.error(error);
  //   }
  // };
  const resetForm = () => {
    setSelectedUnits([]);
    setpropertyMasterID(0);
    setFormData({
      date: new Date().toISOString().split("T")[0],
      amount: "",
      description: "",
    });
    setCategory({ categorydesc: "", selectedCategory: null, categoryurl: "" });
    setSubCategory({
      subcategorydesc: "",
      selectedCategory: null,
      Subcategoryurl: "",
    });
  };

  if (isLoading) return <Loader />;
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="card pb-5">
          <div className="card-body">
            <div className="row pb-2">
              <h5 className="card-title">Add Expense</h5>
              <form>
                <div className="row">
                  <div className="form-group col-md-6 order-1 order-sm-1 order-md-1 form-wrap">
                    <label className="col-form-label">
                      Select Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      id="start"
                      name="date"
                      value={formData.date}
                      onChange={handleInputChange}
                      className="form-control addproperty-form"
                    />
                  </div>
                  <div className="form-group col-md-6 order-2 order-sm-2 order-md-2 form-wrap">
                    <label className="col-form-label">
                      Enter Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="amount"
                      placeholder="Enter Amount"
                 
                      name="amount"
                      value={formData.amount}
                      onChange={handleInputChange}
                      min="1"
                      // onKeyUp={(e) => {
                      //   const input = e.target as HTMLInputElement; // Type casting
                      //   if (parseFloat(input.value) < 1) {
                      //     input.value = "1"; // Correct the value if less than 1
                          
                      //   }
                      // }}
                      onKeyDown={(e) => {
                        // Disallow E, e, and other unwanted keys
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "-" ||
                          e.key === "0" ||
                          e.key === "+"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      className="form-control addproperty-form"
                    />
                  </div>

                  <div className="form-group col-md-6 order-4 order-sm-4 order-md-3 form-wrap">
                    <label className="col-form-label">
                      Description (Optional)
                    </label>
                    <input
                      type="text"
                      id="description"
                      placeholder="Write about Expense..."
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      className="form-control addproperty-form"
                    />
                  </div>

                  <div className="row order-5 order-sm-5 order-md-5 ">
                    <div className="form-group col-12 form-wrap">
                      <label className="col-form-label">
                        Select Category <span className="text-danger">*</span>
                      </label>
                      <div className="d-flex flex-row gap-2 flex-wrap">
                        {categoryData?.data?.map((categoryitem) => (
                          <div
                            key={categoryitem?.id}
                            className={`cursor-pointer`}
                            onClick={() =>
                              handleCategoryClick(
                                categoryitem?.id,
                                categoryitem?.desc,
                                categoryitem?.url
                              )
                            }
                          >
                            <div
                              className={`d-flex align-items-center expensecategory category-card-w pt-3 shadow ${
                                category?.selectedCategory === categoryitem.id
                                  ? "selected"
                                  : ""
                              } mb-3 `}
                            >
                              <div className="transaction-image-wrapper-chip">
                                <img
                                  src={categoryitem?.url}
                                  alt={categoryitem?.desc}
                                  className="category-icon"
                                />
                              </div>
                              <span className="py-1">{categoryitem?.desc}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {category?.selectedCategory && (
                      <div className="form-group col-12 form-wrap">
                        <label className="col-form-label">
                          Select Subcategory{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="cursor-pointer d-flex flex-row gap-2 flex-wrap">
                          {subcategories?.map((subcategoryItem) => (
                            <div
                              key={subcategoryItem.mapid}
                              className={` d-flex align-items-center expensecategory category-card-w shadow ${
                                SubCategory?.selectedCategory ===
                                subcategoryItem?.mapid
                                  ? "selected"
                                  : ""
                              } mb-3 mx-2 pt-3`}
                              onClick={() =>
                                handleSubCategoryClick(
                                  subcategoryItem?.mapid,
                                  subcategoryItem?.subcatdesc,
                                  subcategoryItem?.url
                                )
                              }
                            >
                              <div className="transaction-image-wrapper-chip">
                                <img
                                  src={subcategoryItem?.url}
                                  alt={subcategoryItem?.subcatdesc}
                                  className="category-icon"
                                />
                              </div>
                              <span className="py-1">
                                {subcategoryItem?.subcatdesc}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* )} */}
                  {saveSelectedUnits?.length > 0 && (
                    <>
                      <div className="row">
                        {saveSelectedUnits?.length > 0 &&
                          saveSelectedUnits?.map((property) => {
                            // Separate units with unitID === -1 and the rest
                            const buildingUnits = property.units.filter(
                              (item) => item.unitID === -1
                            );
                            const otherUnits = property.units.filter(
                              (item) => item.unitID !== -1
                            );

                            // Combine them as per your desired order
                            const sortedUnits = [
                              ...buildingUnits,
                              ...otherUnits,
                            ];

                            return (
                              <div key={property.propertyMasterID}>
                                <p className="card-text m-0 py-2 fw-semibold page-title">
                                  Building ID: {property.buildingNumber}
                                </p>
                                <div className="d-flex gap-2 flex-wrap">
                                  {sortedUnits.map((item) => (
                                    <div
                                      key={item.unitID}
                                      className="card selected-property-unit-w "
                                      onClick={() => setBuildingPopUp(true)}
                                    >
                                      <img
                                        className="responsive-img1"
                                        src={item.imageUrl || fallbackImage}
                                        alt="Card image cap"
                                        style={{
                                          aspectRatio: "3 / 2",
                                        }}
                                      />
                                      {item.unitID === -1 ? (
                                        <p className="card-text text-center m-0 py-2 fw-semibold page-title">
                                          Building
                                        </p>
                                      ) : (
                                        <p className="card-text text-center m-0 py-2 fw-semibold page-title">
                                          Unit ID: {item.unitID}
                                        </p>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}

                  <div className="order-last">
                    {saveSelectedUnits.length > 0 ? (
                      <>
                        <div className="d-flex fixed-Save justify-content-end ">
                          <button
                            className="btn btn btn-primary px-5"
                            onClick={(e) => handleSubmit(e)}
                            disabled={
                              !formData.amount ||
                              loader ||
                              SubCategory.selectedCategory === null
                            }
                          >
                            {loader ? (
                              <>
                                <i className="fa fa-spinner fa-spin"></i>
                              </>
                            ) : null}
                            Add Expense
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        {/* <div className="d-flex justify-content-end ">
                          <button
                            className="btn btn btn-primary px-5"
                            onClick={(e) => NextClick(e)}
                            disabled={
                              category.selectedCategory === null ||
                              SubCategory.selectedCategory === null
                            }
                          >
                            Next
                          </button>
                        </div> */}
                      </>
                    )}
                  </div>

                  {/* {category1.selectedCategory == null ?  (   <div className="form-group col-md-6 order-5 order-sm-5 order-md-5 py-3">
                    <button
                      className="btn btn btn-primary"
                      onClick={(e) => transactionClick(e)}
                      disabled={!amountValue || !dateValue}
                    >
                      Next
                    </button>
                  </div>):(<></>)}  */}
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={
            isBuildingPopUp ? "toggle-popup sidebar-popup" : "toggle-popup"
          }
        >
          <ExpenseBuildingPopup
            // handleBuildingPopupClose={handleBuildingPopupClose}
            selectedBuildings={selectedUnits}
            setSelectedBuildings={setSelectedUnits}
            setpropertyMasterID={setpropertyMasterID}
            propertyMasterID={propertyMasterID}
            setBuildingPopUp={setBuildingPopUp}
            setSaveSelectedUnits={setSaveSelectedUnits}
            saveSelectedUnits={saveSelectedUnits}
          />
        </div>
      </div>
    </div>
  );
};
