import { configureStore } from "@reduxjs/toolkit";
import { CommonApi } from "./CommonApi";
import commonSlice from "./Commonslice";

const store = configureStore({
  reducer: {
    CRMS: commonSlice,
    [CommonApi.reducerPath]: CommonApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }).concat(CommonApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
