import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  priorityEnumMap,
  priorityEnumMapUrl,
  unitStatusEnum,
  unitStatusEnumMap,
  StatusInfo,
  UserTypeEnum,
} from "../../redux/RequestTypeEnum";
import { StackPagination } from "../../shared/StackPagination";
import { Link } from "react-router-dom";
import MaintenanceUnitCard from "./MaintenanceUnitCard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "../../style/scss/mainatenance.scss";
import medium1 from "../../../public/assets/img/MaintenanceIcon/RequestdetailIcon/category-2 1.svg";
import medium2 from "../../../public/assets/img/MaintenanceIcon/RequestdetailIcon/DescVector.svg";
import imageIcons from "../../../public/assets/img/MaintenanceIcon/RequestdetailIcon/icons image.svg";
import timeIcons from "../../../public/assets/img/MaintenanceIcon/RequestdetailIcon/time (3) 1.svg";
import uniticon from "../../../public/assets/img/MaintenanceIcon/RequestdetailIcon/Group 239736.svg";
import calIcon from "../../../public/assets/img/MaintenanceIcon/RequestdetailIcon/Group.svg";
import {
  useAssignServiceProviderMutation,
  useMaintenanceRequestDetailByIdQuery,
  useMaintenanceRequestImagesMutation,
  useReopenMaintenanceRequestMutation,
} from "../../redux/CommonApi";
import frame from "../../../public/assets/img/copied.svg";
import MaintenancceReopenPopup from "./MaintenancceReopenPopup";
import IconStepper from "./IconStepper";
import CommonModal from "./AssignToTechnicianModal";
import AssignToTechnicianModal from "./AssignToTechnicianModal";
import { useDispatch, useSelector } from "react-redux";
import { setReAssignToNewTechnician, setReAssignToNewTechnicianImages } from "../../redux/Commonslice";
import { Loader } from "../../shared/Loader";
import { MaintenanceRequest } from "../Models/MaintenanceRequest/MaintenanceRequest";
const MaintenancceAllReqCard = ({
  data,
  totalcount,
  handlePageChange,
  pageNumber,
  setShowUnit,
  setShowMaintenanceCard,
  handleClick,
}) => {
  const dispatch = useDispatch();
  const ReAssignToNewTechnician =
  useSelector((state: any) => state.CRMS.ReAssignToNewTechnician) ?? null;
  const ReAssignToNewTechnicianImages =
  useSelector((state: any) => state.CRMS.ReAssignToNewTechnicianImages) ?? null;
  

  const [
    AssignServiceProvider,
    { isLoading: isLoadingassign, isSuccess, isError, error: errorassign },
  ] = useAssignServiceProviderMutation();
  // React.useEffect(() => {
  //   debugger
  //   dispatch(SetTotalCount(totalcount));
  // }, [null]);
 
  const [LandlordRequestAcceptReject] = useReopenMaintenanceRequestMutation();
  const [maintenanceRequestImages] = useMaintenanceRequestImagesMutation();
  const userName = sessionStorage.getItem("userName");
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
  const [showComponent, setShowComponent] = useState(false);
  const [ispermissionpopup, setPermissionpopup] = useState(false);
  const [isReopenpopup, setReopenpopup] = useState(false);
  const [isTechnicianpopup, setTechnicianpopup] = useState(false);
  const [isPrioritypopup, setPrioritypopup] = useState(false);
  const [requestID, setrequestID] = useState(null);
  const [show, setShow] = React.useState(false);
  const [loader, setloader] = useState(false);
  const [priorityID, setPriorityID] = React.useState(null);
  const [reasigntechnician, setReasigntechnician] = useState(0);
  const [buttonText, setButtonText] = useState("");
  const [buttonTextReopen, setButtonTextReopen] = useState("");
  const checkassignedpermissions = () => {
    setPermissionpopup(!ispermissionpopup);
    setReasigntechnician(0);
  };
  // const dispatch = useDispatch();
  // const TotalCount = useSelector((state: any) => state.CRMS.TotalCount);

  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const usertype = sessionStorage.getItem("userType");
  const {
    data: dashboardresponse ,
    error,
    isLoading,
  } = useMaintenanceRequestDetailByIdQuery({
    requestID: requestID,
    UserTypeID: 1,
  },
{
  skip: requestID === null,
}) as {data : any, error: any, isLoading: boolean};
  const permissionpopupfun = (event, id) => {
    event.preventDefault();

    setrequestID(id);
    setPermissionpopup(!ispermissionpopup);
  };
  const handleClose = () => setShow(false);

  const handleReopenShow = () => setReopenpopup(true);
  const handleReopenClose = () => setReopenpopup(false);
  const handleTechnicianShow = () => setTechnicianpopup(true);
  const handlehandleTechnicianClose = () => setTechnicianpopup(false);
  const PrioritypopupClose = () => setPrioritypopup(false);
  const [formData, setFormData] = useState({
    technicianName: "",
    technicianNumber: "",
  });

  const [formErrors, setFormErrors] = useState({
    technicianName: "",
    technicianNumber: "",
  });
  const handleShow = (id) => {
    if (id === 1) {
      setShow(true);
      setReasigntechnician(0);
    } else if (id === 2) {
      setReasigntechnician(2);
      setTechnicianpopup(true);
    }
  };

  const validateFormData = () => {
    const newErrors = {
      technicianName:
        !formData.technicianName || formData.technicianName == ""
          ? "TechnicianName is required"
          : "",
      technicianNumber:
        !formData.technicianNumber || formData.technicianNumber == ""
          ? "TechnicianNumber is required"
          : "",
    };

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const SubmitTechnician = async () => {

    try {
      const data = new MaintenanceRequest({
        maintenanceRequestId: dashboardresponse?.data.token,
        phoneNumber: formData.technicianNumber,
        name: formData.technicianName,
        status: unitStatusEnum.Assign,
        requestDate: JSON.stringify({
          date: new Date(),
          name: userName,
          status: unitStatusEnum.Assign,
          userTypeId: UserTypeEnum.Landlord,
          isCreatedBy: 1,
          UserType: "Landlord",
        }),
      });
      const newErrors = validateFormData();
      setFormErrors(newErrors);
      const hasErrors = Object.values(newErrors).some((error) => error !== "");

      if (hasErrors) {
        setTimeout(() => {
          setFormErrors({
            technicianName: "",
            technicianNumber: "",
          });
        }, 3000);
        return;
      } else {
        setloader(true);
        if(ReAssignToNewTechnician != null) {
          const response = await LandlordRequestAcceptReject(ReAssignToNewTechnician);

          if(ReAssignToNewTechnicianImages  !== undefined || ReAssignToNewTechnicianImages !== null) {
            for (const image of ReAssignToNewTechnicianImages) {
              await maintenanceRequestImages(image);
            }
          }
          const response1 = await AssignServiceProvider(data);
        toastr.success("Request Re-Assigned successfully!");
        setFormData({
          technicianName: "",
          technicianNumber: "",
        });
        dispatch(setReAssignToNewTechnician(null));
        dispatch(setReAssignToNewTechnicianImages(null));
        setTechnicianpopup(false);
        setReopenpopup(false);
        setloader(false);
          
        }
else{
        const response = await AssignServiceProvider(data);
        toastr.success("Request Assigned successfully!");
        setFormData({
          technicianName: "",
          technicianNumber: "",
        });
        dispatch(setReAssignToNewTechnician(null));
        dispatch(setReAssignToNewTechnicianImages(null));
        setTechnicianpopup(false);
        setReopenpopup(false);
        setloader(false);
      }
    }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getStatusNameById = (id) => {
    let statusNames = Object.keys(unitStatusEnum).find(
      (key) => unitStatusEnum[key] === id
    );
    if (id == unitStatusEnum.Create) {
      return (statusNames = "Created");
    } else if (id == unitStatusEnum.Assign) {
      return (statusNames = "Assigned");
    } else if (id == unitStatusEnum.Close) {
      return (statusNames = "Closed");
    } else if (id == unitStatusEnum.Approved) {
      return (statusNames = "Accept");
    } else if (id == unitStatusEnum.PENDING) {
      return (statusNames = "Received");
    } else {
      return statusNames ? statusNames.replace("_", " ") : "";
    }
  };

  const copyToClipboard = (contact) => {
    navigator.clipboard
      .writeText(contact)
      .then(() => {
        setButtonText("copied");
        setTimeout(() => {
          setButtonText("");
        }, 2000);
      })
      .catch((err) => {
        // Handle any errors that might occur during the copying process
        console.error("Failed to copy text: ", err);
        alert("Failed to copy contact");
      });
  };

  const createUpdatedRequest = (dashboardData, status, priorityID) => {
    return {
      ...dashboardData,
      status: status,
      ...(status === unitStatusEnum.Approved && { priorityId: priorityID }),
      userTypeId: 1,
      json: JSON.stringify({
        date: new Date(),
        name: sessionStorage.getItem("userName"),
        status: status,
        userTypeId: 1,
        isCreatedBy: 1,
        UserType: "Landlord",
      }),
    };
  };

  const AcceptRejectMaintenanceRequest = async (status) => {
    if (status === unitStatusEnum.Approved) {
      setPrioritypopup(true);
    } else {
      setloader(true);
      const updatedRequest = createUpdatedRequest(
        dashboardresponse?.data,
        status,
        priorityID
      );
      const response = await LandlordRequestAcceptReject(updatedRequest);
      toastr.warning(
        `${
          status === unitStatusEnum.Close
            ? "Maintenance Request Closed"
            : "Maintenance Request Rejected!"
        }`
      );
      setloader(false);
    }
  };

  const AcceptRequest = async (status) => {
    setloader(true);
    const updatedRequest = createUpdatedRequest(
      dashboardresponse?.data,
      status,
      priorityID
    );
    const response = await LandlordRequestAcceptReject(updatedRequest);
    toastr.success("Maintenance Request Accepted!");
    setPrioritypopup(false);
    setloader(false);
  };
  const circlecolor = (unitStatusEnumMap[dashboardresponse?.data?.status] ||
    {}) as StatusInfo;

  const reopenFunction = (param, param2) => {
    setReopenpopup(param);
    setButtonTextReopen(param2);
  };
  const getUserRole = (userTypeId) => {
    switch (userTypeId) {
      case 1:
        return "Landlord";
      case 2:
        return "Tenant";
      case 5:
        return "Corporate Tenant";
      case 3:
        return "Technician";
      default:
        return "Unknown Role";
    }
  };

  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);

  // Get descriptions
  const descriptionParts = dashboardresponse?.data?.description.split(
    "</br>"
  ) || ["", ""];

  // Toggle functions
  const toggleExpansion1 = () => setIsExpanded1(!isExpanded1);
  const toggleExpansion2 = () => setIsExpanded2(!isExpanded2);

  if (isLoading)  return <Loader />

  return (
    <>
      <div className="row pb-5">
        {data && data.length > 0 ? (
          data.map((property, index) => {
            const images = property.buildingImage || [];
            const imageSrc = images.length > 0 ? images : fallbackImage;

            const statusInfo = unitStatusEnumMap[property.status] || null;
            const priorityEnum = priorityEnumMap[property.priority] || null;
            return (
              <div
                key={index}
                className="col-lg-12 col-md-12 col-xl-12 col-xxl-4 col-sm-12"
                // onClick={() =>
                //   handleCardClick(
                //     property.propertyMasterID,
                //     property.propertyStatusID
                //   )
                // }
                style={{ cursor: "pointer" }}
              >
                <div
                  className="card totalunit-card pmsgradient card-allReq-buid "
                  onClick={(e) => permissionpopupfun(e, property.requestId)}
                >
                  <div className="row g-2 card-body totalunits">
                    <div className="col-md-12 col-lg-4">
                      <div>
                        <img
                          src={imageSrc}
                          className="img-fluid rounded-start object-fit-cover h-100 w-100"
                          alt={property.buildingName}
                          style={{
                            aspectRatio: "1/1",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-8 ps-3">
                      <div className="pt-1">
                        <div className="d-flex justify-content-between">
                          <p className="buildingName-icons d-flex align-items-center gap-2">
                            <img
                              src={property.propertySubTypeURL}
                              alt={property.buildingName}
                              style={{
                                filter:
                                  "invert(11%) sepia(78%) saturate(5962%) hue-rotate(352deg) brightness(66%) contrast(125%)",
                                    aspectRatio: "3/2"
                              }}
                            />
                            <span className="fw-bold page-title">
                              {property.buildingName}
                            </span>
                          </p>
                          <p className="buildingName-icons d-flex align-items-center gap-2">
                            <span className="fw-bold page-title">
                              {dayjs(property.createdDate).format("DD-MM-YYYY")}
                            </span>
                          </p>
                        </div>
{property?.unitCategory &&  
                        <p className="buildingName-icons d-flex align-items-center gap-2">
                          <img
                            src={property.unitCategoryURL}
                            alt={property.unitCategory}
                            style={{
                              filter:
                                "invert(11%) sepia(78%) saturate(5962%) hue-rotate(352deg) brightness(66%) contrast(125%)",
                                aspectRatio: "3/2"
                            }}
                          />
                          <span className="fw-bold page-title">
                            {property.unitCategory}
                          </span>
                        </p>}
                        <p className="buildingName-icons d-flex align-items-center gap-2 page-title">
                          <img
                            src={property.maintenanceCategoryURL}
                            alt={property.buildingName}
                            style={{
                              filter: `${
                                property.categoryName == "Electrical" ||
                                property.categoryName == "Others"
                                  ? "hue-rotate(143deg) saturate(74%)"
                                  : "invert(11%) sepia(78%) saturate(5962%) hue-rotate(352deg) brightness(66%) contrast(125%)"
                              }`,
                              aspectRatio: "3/2"
                            }}
                          />
                          <span className="fw-bold page-title">
                            {property.categoryName}
                          </span>
                        </p>
                        <p className="fs-15 totalunits-address text-truncate page-title ps-2">
                          {property.status === unitStatusEnum.Reopen ||
                          property.desc.split("</br>")[1]
                            ? property.desc.split("</br>")[1]
                            : property.desc}
                        </p>
                        {property.serviceProviderName === null ? (
                          <div className="pt-1">
                            <br />
                          </div>
                        ) : (
                          <p className="buildingName-icons d-flex align-items-center gap-2 ps-2">
                            <i
                              className="fa fa-user page-title"
                              //  style={{color:"#9E2A2B"}}
                              style={{
                                filter:
                                  "invert(11%) sepia(78%) saturate(5962%) hue-rotate(352deg) brightness(66%) contrast(125%)",
                                  aspectRatio: "3/2"
                              }}
                            ></i>
                            <span className="fw-bold page-title">
                              {property.serviceProviderName}
                            </span>
                          </p>
                        )}
                      {window.innerWidth >= 1399 && !property?.unitCategory && <br />}
                       
                        <div className="d-flex justify-content-end">
                          <div className="d-flex gap-2">
                            <a
                              className="badge badge-pill badge-status "
                              style={{
                                color: statusInfo?.color,
                                backgroundColor: statusInfo?.backgroundColor,
                              }}
                            >
                              {statusInfo.text}
                            </a>
                            <a
                              className="badge badge-pill badge-status"
                              style={{
                                color: priorityEnum?.color,
                                backgroundColor: priorityEnum?.backgroundColor,
                              }}
                            >
                              {priorityEnum?.text}
                            </a>
                            <a
                              className="badge badge-pill badge-status "
                              style={{
                                background: "#EFEFEF",
                                color: "#1E1E1E",
                              }}
                            >
                              Token no : {property.requestId}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="card d-flex align-items-center justify-content-center"
            style={{ padding: "40px 0", border: "none" }}
          >
            <div className="card-body">
              <img
                src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                alt="No data found"
              />
              <h3 className="text-center">No Data Found</h3>
            </div>
          </div>
        )}
        
       

       
      </div>
     
      {totalcount > 0 ? (
        <div className="row row1 bg-white fixed-bottom w-100">
          <div className="col-md-12 col-lg "></div>
          <div className="col-md-12 col-lg">
            <div className=" d-flex justify-content-between align-items-center py-0 flex-wrap">
              <StackPagination
                tenancyRequestCount={totalcount}
                rowNumbers={12}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />

              <div className="pt-4  ">
                <Link
                  to="#"
                  className="btn btn-primary add-popup"
                  onClick={handleClick}
                >
                  <i className="ti ti-square-rounded-plus" /> Generate Request
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row row1 bg-white fixed-bottom">
          <div className="align-items-center col-md d-flex justify-content-end flex-wrap">
            {/* Generate Button */}
            <div className="p-2">
              <Link
                to="#"
                className="btn btn-primary add-popup"
                onClick={handleClick}
                style={{ whiteSpace: "nowrap" }}
              >
                <i className="ti ti-square-rounded-plus" /> Generate Request
              </Link>
            </div>
          </div>
        </div>
      )}

      <div
        className={
          ispermissionpopup ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        
        <div className="sidebar-layout w-md-25 w-lg-25 w-sm-100">
          {dashboardresponse && (
            <>
              <div className="MaintenanceRequestPopup pt-0 p-5">
                <div className="align-items-center d-flex justify-content-between pt-4 pb-2">
                  <div className="d-flex align-items-center justify-content-between gap-4">
                    <img
                      src={dashboardresponse?.data?.profileImage || profileimg}
                      className="no-gap"
                      style={{ width: "70px", height: "70px" }}
                      alt="Profile"
                    />
                    <div>
                      {/* {Array.isArray(dashboardresponse?.data?.jsonList) &&
                        dashboardresponse?.data?.jsonList.map((item, index) => ( */}

                      {/* <div>
                        <h3 className="pb-2">
                          {dashboardresponse?.data?.jsonList?.[0].userType }
                        </h3>
                        <h3>{dashboardresponse?.data?.name}</h3>
                      </div> */}

                      <div>
                      {dashboardresponse?.data?.status === unitStatusEnum.Reopen ? (<>
                        <h3>
                        {dashboardresponse?.data?.jsonList?.[dashboardresponse?.data?.jsonList?.length - 1]?.userType}
                       
                      </h3>
                      <h3 className="pt-2">
                    {  dashboardresponse?.data?.jsonList?.[dashboardresponse?.data?.jsonList?.length - 1]?.name}
                      </h3></>):
                      (<>
                        <h3>
                        {getUserRole(dashboardresponse?.data?.userTypeId)}
                       
                      </h3>
                      <h3 className="pt-2">
                        {dashboardresponse?.data?.name}
                      </h3></> )
                    }
                       
                      </div>
                      {/* ))} */}
                      {/* {Array.isArray(dashboardresponse?.data?.jsonList) &&
                        dashboardresponse?.data?.jsonList.length > 0 && (
                          <div>
                            <h3 className="pb-2">
                              {
                                dashboardresponse.data.jsonList[
                                  dashboardresponse.data.jsonList.length - 1
                                ].name
                              }
                            </h3>
                            <h3>
                              {
                                dashboardresponse.data.jsonList[
                                  dashboardresponse.data.jsonList.length - 1
                                ].userType
                              }
                            </h3>
                          </div>
                        )} */}
                    </div>
                  </div>
                  <Link
                    to="#"
                    className="sidebar-close toggle-btn"
                    onClick={checkassignedpermissions}
                  >
                    <i className="ti ti-x" />
                  </Link>
                </div>
                <h2 className="pt-2">
                  Token mum-{dashboardresponse?.data?.token}
                </h2>
                <h3 className="d-flex align-items-center pt-2 ">
                  <span
                    className="success-circle me-2"
                    // style={{
                    //   backgroundColor: circlecolor.backgroundColor || "#CCCCCC",
                    // }}
                    style={{
                      backgroundColor: circlecolor.backgroundColor || "#CCCCCC",
                      boxShadow: `0 0 0 0 ${circlecolor.backgroundColor}`,
                      animation: `pulse 1500ms infinite`,
                    }}
                  ></span>
                  {getStatusNameById(dashboardresponse?.data?.status)}
                </h3>
                {dashboardresponse?.data?.status &&
                dashboardresponse?.data?.status !== unitStatusEnum.PENDING &&
                dashboardresponse?.data?.status !== unitStatusEnum.Create &&
                dashboardresponse?.data?.status !== unitStatusEnum.Approved &&
                dashboardresponse?.data?.status !== unitStatusEnum.Rejected  ? (
                  <div className="pt-3">
                    <button
                      className="btn btn-primary add-popup w-75 p-3"
                      disabled={
                        dashboardresponse?.data?.status === unitStatusEnum.Close
                      }
                    >
                      <span onClick={() => handleShow(1)}>
                        Call To Technician{" "}
                      </span>{" "}
                      {dashboardresponse?.data?.status !==
                        unitStatusEnum.On_Hold &&
                      dashboardresponse?.data?.status !==
                        unitStatusEnum.Work_Done &&
                      dashboardresponse?.data?.status !==
                        unitStatusEnum.Close &&    
                        dashboardresponse?.data?.status !==
                        unitStatusEnum.Reopen ? (
                        <>
                          {" "}
                          |{" "}
                          
                          <span
                            className="p-1 fa fa-times-circle-o"
                            onClick={() => handleShow(2)}
                          ></span>
                        </>
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="pt-3">
                    <button
                      disabled={
                        dashboardresponse?.data?.status ===
                          unitStatusEnum.PENDING ||
                        // dashboardresponse?.data?.status ===
                        //   unitStatusEnum.Rejected ||
                        dashboardresponse?.data?.status ===
                          unitStatusEnum.Reopen
                      }
                      className="btn btn-primary add-popup w-75 p-3"
                      onClick={handleTechnicianShow}
                    >
                      Assign to Technician
                    </button>
                  </div>
                )}
              </div>

              <div className="popupdetail">
                <div className="p-5 pb-0">
                  <p className="requestIcons-icons d-flex align-items-center gap-4">
                    <img src={medium2} alt={profileimg} />
                    <span className="fw-bold page-title">
                      {descriptionParts[0].length > 150
                        ? isExpanded1
                          ? descriptionParts[0]
                          : `${descriptionParts[0].slice(0, 150)}...`
                        : descriptionParts[0]}
                      {descriptionParts[0].length > 150 && (
                        <button
                          onClick={toggleExpansion1}
                          className="btn btn-link p-0 ms-1"
                        >
                          {isExpanded1 ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </span>
                    {/* {descriptionParts[1] && (
                      <div className="popupdetail p-5 pt-3 pb-0">
                        <div>
                          <h6>Re-open Request Feedback</h6>
                          <p className="requestIcons-icons d-flex align-items-center gap-4 pt-3">
                            <img src={medium2} alt={profileimg} />
                            <span className="fw-bold">Re-open Request Feedback
                              {isExpanded2
                                ? descriptionParts[1]
                                : `${descriptionParts[1].slice(0, 150)}...`}
                              <button
                                onClick={toggleExpansion2}
                                className="btn btn-link p-0 ms-1"
                              >
                                {isExpanded2 ? "Show Less" : "Show More"}
                              </button>
                            </span>
                          </p>

                        
                        </div>
                      </div>
                    )} */}
                  </p>
                  <p className="requestIcons-icons d-flex align-items-center gap-4">
                    <img src={medium1} alt={profileimg} />
                    <Link to="#" className="btn btn-primary add-popup ">
                      {dashboardresponse?.data?.maintenanceCategoryName}
                    </Link>
                  </p>

                  {dashboardresponse?.data?.imageUrl && (
                    <p className="requestIcons-icons d-flex align-items-center gap-4 page-title">
                      <img src={imageIcons} alt={profileimg} />
                      {Array.isArray(dashboardresponse?.data?.imageUrl) && (
                        <div
                        className="image-container-request"
                          // style={{
                          //   display: "flex",
                          //   gap: "8px",
                          //   overflowX: "auto",
                          //   maxWidth: "300px",
                          //   whiteSpace: "nowrap",
                          // }}
                        >
                          {dashboardresponse.data.imageUrl
                            .filter((item) => item.uniqueIdentifier === 1)
                            .map((item, index) => (
                              <img
                                key={index}
                                src={item.url}
                                alt={profileimg}
                                style={{
                                  aspectRatio: "3/2",
                                  objectFit: "cover",
                                }}
                              />
                            ))}
                        </div>
                      )}
                    </p>
                  )}

                  <p className="requestIcons-icons d-flex align-items-center gap-4">
                    <img src={timeIcons} alt={profileimg} loading="lazy" />
                    <span className="fw-bold page-title">
                      Urgency:{" "}
                      <span
                        style={{
                          color: circlecolor.backgroundColor,
                          // dashboardresponse?.data?.urgency === "High" ||
                          // dashboardresponse?.data?.urgency?.toUpperCase() ===
                          //   "HIGH"
                          //   ? "#9E2A2B" // Color for HIGH urgency
                          //   : dashboardresponse?.data?.urgency === "Low" ||
                          //     dashboardresponse?.data?.urgency?.toUpperCase() ===
                          //       "LOW"
                          //   ? "#31BA96" // Color for LOW urgency
                          //   : "#DF9F3E", // Default color for other urgencies
                        }}
                      >
                        {dashboardresponse?.data?.urgency}
                      </span>
                    </span>
                  </p>
                  <p className="requestIcons-icons d-flex align-items-center gap-4">
                    <img src={uniticon} alt={profileimg} />
                    <span className="fw-bold page-title">
                      Building ID: {dashboardresponse?.data?.buildingNumber}
                      {dashboardresponse?.data?.unitID && (
                        <>, Unit ID: {dashboardresponse.data.unitID}</>
                      )}
                    </span>
                  </p>
                  {/* <p className="requestIcons-icons d-flex align-items-center gap-4 page-title">
                    <img src={calIcon} alt={profileimg} />

                    {Array.isArray(dashboardresponse?.data?.jsonList) &&
                      dashboardresponse?.data?.jsonList.length > 0 && (
                        <div>
                          <span className="fw-bold page-title">
                            {dayjs(
                              dashboardresponse.data.jsonList[
                                dashboardresponse.data.jsonList.length - 1
                              ].date
                            ).format("YYYY-MM-DD")}
                          </span>
                        </div>
                      )}
                  </p> */}
                  <p className="requestIcons-icons text-truncate">
                    <div className="d-flex justify-content-evenly text-truncate page-title">
                      <div
                        className="text-truncate page-title"
                        style={{
                          display: "block",
                          color: "#434242",
                          fontSize: "11px",
                          fontFamily: "Noto Sans",
                        }}
                      >
                        {dashboardresponse?.data &&
                          `${dashboardresponse?.data?.jsonList[0]?.name} (${dashboardresponse?.data?.jsonList[0]?.userType})`}
                      </div>
                      <div
                        className="text-truncate page-title"
                        style={{
                          display: "block",
                          color: "#434242",
                          fontSize: "11px",
                          fontFamily: "Noto Sans",
                        }}
                      >
                        {dashboardresponse?.data?.jsonList?.length > 1
                          ? `${
                              dashboardresponse?.data?.jsonList[
                                dashboardresponse.data.jsonList.length - 1
                              ].name
                            } (${
                              dashboardresponse?.data?.jsonList[
                                dashboardresponse.data.jsonList.length - 1
                              ].userType
                            })`
                          : "Mumtalikati"}
                      </div>
                    </div>
                  </p>
                </div>
                <IconStepper
                  dashboardresponse={dashboardresponse?.data?.jsonList}
                  dashboardresponse1={dashboardresponse?.data}
                  circlecolor={circlecolor}
                />
                {/* {dashboardresponse?.data?.description.split("</br>")[1] ? (
                  <div className="popupdetail p-5 pt-3  page-title">
                    <div>
                      <h6 className="page-title">Re-open Request Feedback</h6>
                      <p className="requestIcons-icons d-flex align-items-center gap-4 pt-3">
                        <img src={medium2} alt={profileimg} />
                        <span className="fw-bold page-title">
                          {
                            dashboardresponse?.data?.description.split(
                              "</br>"
                            )[1]
                          }
                        </span>
                      </p>
                      {dashboardresponse?.data?.imageUrl && (
                        <p className="requestIcons-icons d-flex align-items-center gap-4 page-title">
                          <img src={imageIcons} alt={profileimg} />
                          {Array.isArray(dashboardresponse?.data?.imageUrl) && (
                            <div
                            className="image-container-request"
                              // style={{
                              //   display: "flex",
                              //   gap: "8px",
                              //   overflowX: "auto",
                              //   maxWidth: "300px",
                              //   whiteSpace: "nowrap",
                              // }}
                            >
                              {dashboardresponse.data.imageUrl
                                .filter((item) => item.uniqueIdentifier === 2)
                                .map((item, index) => (
                                  <img
                                    key={index}
                                    src={item.url}
                                    alt={profileimg}
                                    style={{
                                      aspectRatio: "3/2",
                                      objectFit: "cover",
                                    }}
                                  />
                                ))}
                            </div>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}
                

                {dashboardresponse?.data?.description.split("</br>").length > 1 ? (
  <div className="popupdetail p-5 pt-3 page-title">
    <div>
      
      <h6 className="page-title">Re-open Request Feedback</h6>
      
     
      {dashboardresponse?.data?.description.split("</br>").map((item, index) => (
        index !== 0 && (  // Skip the 0-index item
          <div key={index}>
            <p className="requestIcons-icons d-flex align-items-center gap-4 pt-3">
              <img src={medium2} alt={profileimg} />
              <span className="fw-bold page-title">{item}</span>
            </p>
            {dashboardresponse?.data?.imageUrl && (
              <p className="requestIcons-icons d-flex align-items-center gap-4 page-title">
                <img src={imageIcons} alt={profileimg} />
                {Array.isArray(dashboardresponse?.data?.imageUrl) && (
                  <div className="image-container-request">
                    {dashboardresponse.data.imageUrl
                      .filter((item) => item.uniqueIdentifier === 2)
                      .map((image, imgIndex) => (
                        <img
                          key={imgIndex}
                          src={image.url}
                          alt={profileimg}
                          style={{
                            aspectRatio: "3/2",
                            objectFit: "cover",
                          }}
                        />
                      ))}
                  </div>
                )}
              </p>
            )}
          </div>
        )
      ))}
    </div>
  </div>
) : (
  <></>
)}


                {(dashboardresponse?.data &&
                  dashboardresponse?.data?.status === unitStatusEnum.PENDING) ||
                (dashboardresponse?.data?.status === unitStatusEnum.Reopen &&
                  dashboardresponse?.data?.jsonList[0].userTypeId === 2) ? (
                  <>
                    <div className="p-4 page-title">
                      <div className="d-flex justify-content-center pt-2 page-title">
                        <button
                          disabled={loader}
                          className="btn btn-primary add-popup w-75 p-3"
                          onClick={() =>
                            AcceptRejectMaintenanceRequest(
                              unitStatusEnum.Approved
                            )
                          }
                        >
                          {loader ? (
                            <>
                              <i className="fa fa-spinner fa-spin"></i>
                            </>
                          ) : null}
                          Accept
                        </button>
                      </div>

                      <div className="d-flex justify-content-center pt-2">
                        <Link
                          to="#"
                          className="btn bg-white add-popup w-75 p-3 border"
                          onClick={() =>
                            AcceptRejectMaintenanceRequest(
                              unitStatusEnum.Rejected
                            )
                          }
                        >
                          Reject
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {dashboardresponse?.data &&
                dashboardresponse?.data?.status == unitStatusEnum.Work_Done &&
                dashboardresponse?.data?.jsonList[0].userTypeId ===
                  parseInt(usertype) ? (
                  <>
                    <div className="p-4">
                      {/* <div className="d-flex justify-content-center pt-2">
                        <button
                          disabled={loader}
                          className="btn bg-white add-popup w-75 p-3 border text-black"
                          onClick={() =>
                            AcceptRejectMaintenanceRequest(unitStatusEnum.Close)
                          }
                        >
                          {loader ? (
                            <>
                              <i className="fa fa-spinner fa-spin text-black"></i>
                            </>
                          ) : null}
                          Close
                        </button>
                      </div> */}

                      <div className="d-flex justify-content-center pt-2">
                        <button
                          disabled={loader}
                          className="btn  btn btn-primary  add-popup w-75 p-3 "
                          onClick={() =>
                            AcceptRejectMaintenanceRequest(unitStatusEnum.Close)
                          }
                        >
                          {loader ? (
                            <>
                              <i className="fa fa-spinner fa-spin text-black"></i>
                            </>
                          ) : null}
                          Close
                        </button>
                      </div>

                      {/* <div className="d-flex justify-content-center pt-2">
                        <button
                          disabled={loader}
                          className="btn btn-primary add-popup w-75 p-3"
                          onClick={() => reopenFunction(true, "Re-Open")}
                        >
                          Re-Open
                        </button>
                      </div> */}

                      <div className="d-flex justify-content-center pt-2">
                        <button
                          disabled={loader}
                          className=" MaintenanceRequestPopup opacity-50  w-75 p-3 border rounded-0 text-white"
                          onClick={() => reopenFunction(true, "Re-Open")}
                        >
                          Re-Open
                        </button>
                      </div>

                      <div className="d-flex justify-content-center pt-2">
                        <Link
                          to="#"
                          className="btn bg-white add-popup w-75 p-3 border"
                          onClick={() =>
                            reopenFunction(true, "Re Assign To New Technician")
                          }
                        >
                          Re Assign To New Technician
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {/* call to technician */}
              <div className="modal-wrapper">
                <Modal
                  size="lg"
                  show={show}
                  onHide={handleClose}
                  animation={false}
                  centered
                  dialogClassName="centered-modal custom-modal-bg"
                >
                  <div className="modal-content-wrapper">
                    <div className="d-flex justify-content-center align-items-center text-center">
                      <div className="icon-circle bg-circle d-flex justify-content-center align-items-center mx-2 mb-3">
                        <i
                          className="fa fa-phone"
                          style={{
                            fontSize: "18px",
                            color: "#345C66",
                            display: "inline-block",
                          }}
                        ></i>
                      </div>
                    </div>
                    <Modal.Title
                      className="text-center"
                      style={{ fontWeight: "500" }}
                    >
                      Call to Technician
                    </Modal.Title>
                    <Modal.Body className="text-center">
                      <div className="align-item-center d-flex gap-2 img-tag justify-content-center pb-2">
                        <span>{dashboardresponse?.data?.contact}</span>
                        <img
                          src={frame}
                          className="no-gap"
                          alt="Profile"
                          style={{ height: "1.5rem" }}
                          onClick={() =>
                            copyToClipboard(dashboardresponse?.data?.contact)
                          }
                        />
                        <span>{buttonText}</span>
                      </div>
                    </Modal.Body>
                    <Modal.Body className="text-center">
                      Need technical support? Dial now to connect with our
                      skilled technicians.
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center align-items-center">
                      <div className="pt-2">
                        <Link
                          to="#"
                          className="btn  add-popup w-100 p-2 border rounded-3"
                          onClick={handleClose}
                        >
                          May be Later
                        </Link>
                      </div>
                    </Modal.Footer>
                  </div>
                </Modal>
              </div>

              {/* Assign technician */}
              <div className="modal-wrapper ">
                <AssignToTechnicianModal
                  show={isTechnicianpopup}
                  onHide={handlehandleTechnicianClose}
                  reasigntechnician={reasigntechnician}
                  formData={formData}
                  formErrors={formErrors}
                  onChange={handleChange}
                  onSubmit={SubmitTechnician}
                  loader={loader}
                />
               
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className={
          isReopenpopup ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        <MaintenancceReopenPopup
          handleReopenClose={handleReopenClose}
          maintenanceRequestDetail={dashboardresponse?.data}
          setReopenpopup={setReopenpopup}
          setPermissionpopup={setPermissionpopup}
          buttonTextReopen={buttonTextReopen}
          setTechnicianpopup={setTechnicianpopup}
          isTechnicianpopup={isTechnicianpopup}
          isReopenpopup={isReopenpopup}
        />
      </div>

      <div className="modal-wrapper">
        <Modal
          size="lg"
          show={isPrioritypopup}
          onHide={PrioritypopupClose}
          animation={false}
          centered
          dialogClassName="centered-modal custom-modal-bg w-25 w-sm-100"
        >
          <div className="modal-content-wrapper">
            <div className="text-center">
              <h6 className="mb-2 text-white">Select Urgency of request </h6>
            </div>
            <Modal.Body>
              <div className="row pt-3">
                {Object.values(priorityEnumMapUrl)
                  .reverse()
                  .map((item, index) => (
                    <div key={index} className="col-md-4">
                      <div
                        className={`card main-card card-unit-buid align-items-center d-flex justify-content-center ${
                          priorityID === item.id ? "bg-danger" : ""
                        }`}
                        onClick={() => setPriorityID(item.id)}
                      >
                        <div className="card-body text-center">
                          <div className="circle-category align-items-center d-flex justify-content-center">
                            <img
                              src={item.url}
                              alt={item.text}
                              width={"30px"}
                            />
                          </div>
                          <p className="pt-1 text-black">{item.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center align-items-center">
              <div className="pt-2">
                <button
                  disabled={loader}
                  className="btn  add-popup w-100 p-2  rounded-3"
                  onClick={() => AcceptRequest(unitStatusEnum.Approved)}
                >
                  {loader ? (
                    <>
                      <i className="fa fa-spinner fa-spin"></i>
                    </>
                  ) : null}
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default MaintenancceAllReqCard;
