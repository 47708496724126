import { PaymentType } from "./PaymentType";

export interface IAgreementTransactionsDetail {
  amountReceivedDate: string;
  rentAmount: number;
  paymentTypeID: number;
  paymentType: PaymentType;
  aggID: number;
  aggreement: any | null;
  chequeID: number | null;
  cheque: any | null;
  paymentDetailId: number | null;
  paymentDetail: any | null;
  feedback: string | null;
  receiptNo: number;
  outStandingAmount: number;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string;
  modifyTime: string;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;
  }
  export class AgreementTransactionsDetail implements IAgreementTransactionsDetail {
    amountReceivedDate: string;
    rentAmount: number;
    paymentTypeID: number;
    paymentType: PaymentType;
    aggID: number;
    aggreement: any | null;
    chequeID: number | null;
    cheque: any | null;
    paymentDetailId: number | null;
    paymentDetail: any | null;
    feedback: string | null;
    receiptNo: number;
    outStandingAmount: number;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string;
    modifyTime: string;
    isCreatedBy: number | null;
    isUpdatedBy: number | null;

    constructor(data: AgreementTransactionsDetail) {
      this.amountReceivedDate = data.amountReceivedDate ?? '';
      this.rentAmount = data.rentAmount ?? 0;
      this.paymentTypeID = data.paymentTypeID ?? 0;
      this.paymentType = new PaymentType(data.paymentType );
      this.aggID = data.aggID ?? 0;
      this.aggreement = data.aggreement ?? null;
      this.chequeID = data.chequeID ?? null;
      this.cheque = data.cheque ?? null;
      this.paymentDetailId = data.paymentDetailId ?? null;
      this.paymentDetail = data.paymentDetail ?? null;
      this.feedback = data.feedback ?? '';
      this.receiptNo = data.receiptNo ?? 0;
      this.outStandingAmount = data.outStandingAmount ?? 0;
      this.id = data.id ?? 0;
      this.status = data.status ?? 0;
      this.statusCode = data.statusCode ?? '';
      this.createdTime = data.createdTime ?? '';
      this.modifyTime = data.modifyTime ?? '';
      this.isCreatedBy = data.isCreatedBy ?? 0;
      this.isUpdatedBy = data.isUpdatedBy ?? 0;
    }
  }
  