// import LoginAcc from "../feature-module/auth/LoginAcc";
import EmailVerification from "../feature-module/auth/emailVerification";
import ForgotPassword from "../feature-module/auth/forgotPassword";
import Login from "../feature-module/auth/login";
import Register from "../feature-module/auth/register";
import ResetPassword from "../feature-module/auth/resetPassword";
import Campaign from "../feature-module/campaign/staff";
import CampaignComplete from "../feature-module/campaign/securityGroups";
import Staffmanagement from "../feature-module/campaign/staffmanagement";
import { all_routes } from "./all_routes";
import Dashboard from "../feature-module/campaign/dashboard";
import Profile from "../feature-module/settings/generalSettings/profile";
import PmsDashboard from "../feature-module/pms/PmsDashboard";
import PmsNotification from "../feature-module/pms/NotificationPanel/PmsNotification";
import RentNotification from "../feature-module/pms/NotificationPanel/SubNotification";
import TenantDashboard from "../feature-module/pms/TenantMaster/TenantDashboard";
import PropertyMasterCards from "../feature-module/pms/MainPmsDashboard/PropertyMasterCards";
import PropertyDetail from "../feature-module/pms/MainPmsDashboard/PropertyDetail";

import AddNewProperty from "../feature-module/pms/AddProperty/AddNewProperty";
import UnitCategory from "../feature-module/pms/AddProperty/UnitCategory";
import {MaintenancceReqDashboard} from "../feature-module/MaintenanceRequest/MaintenancceReqDashboard";
import { RentCollection } from "../RentCollection/RentCollection";
import { AllTenants } from "../RentCollection/AllTenants";
import { RentCollectionTenantsdetials } from "../RentCollection/RentCollectionTenantsdetials";

import { AddRent } from "../RentCollection/AddRent";
import { RentHistory } from "../RentCollection/RentHistory";
import ExpenseDashboard from "../feature-module/Expense/ExpenseDashboard";
import { ExpenseTransactions  }  from "../feature-module/Expense/ExpenseTransactions";
import { AddExpense } from "../feature-module/Expense/AddExpense";





export const publicRoutes = [
  // {
  //   path: all_routes.campaign,
  //   element: <Campaign />,
  // },
  // {
  //   path: all_routes.campaignComplete,
  //   element: <CampaignComplete />,
  // },
  {
    path: all_routes.staffmanagement,
    element: <Staffmanagement />,
  },
  {
    path: all_routes.dashboard,
    element: <Dashboard />,
  },
  {
    path: all_routes.userProfile,
    element: <Profile />,
  },
  {
    path: all_routes.pms,
    element: <PmsDashboard />,
  },
  {
    path: all_routes.tenantMaster,
    element: <TenantDashboard />,
  },
  {
    path: all_routes.pmsdashboard,
    element: <PropertyMasterCards />,
  },
  {

    path: all_routes.addProperty,
    element: <AddNewProperty />,
  },
  {

    path: all_routes.rentCollection,
    element: <RentCollection />,
  },
  {
    path: all_routes.unitCategory,
    element: <UnitCategory />,

  },
  {
    path: all_routes.maintenancerequest,
    element: <MaintenancceReqDashboard />,

  },
  {
    path: all_routes.alltenantsRentCollection,
    element: <AllTenants />,

  },
  {
    path: all_routes.rentCollectiontenant,
    element: <RentCollectionTenantsdetials />,
  },
  {
    path: all_routes.agreementRentHistory,
    element: <RentHistory />,
  },
  {
    path: all_routes.addRent,
    element: <AddRent />,
  },
  {
    path: all_routes.expenseDashboard,
    element: <ExpenseDashboard />,
  },
  {
    path: all_routes.expenseTransactions,
    element: <ExpenseTransactions />,
  },
  {
    path: all_routes.addExpense,
    element: <AddExpense />,
  },
  // {
  //   path:"/property-detail/:propertyId/:status",
  //    element:<PropertyDetail />
  // },
  // {
  //   path: all_routes.notification,
  //   element: <PmsNotification/>,
  // },
  // {
  //   path: all_routes.rent,
  //   element: <RentNotification/>,
  // },
];

export const authRoutes = [
  {
    path: all_routes.login,
    element: <Login />,
  },
  // {
  //   path: all_routes.register,
  //   element: <Register />,
  // },
  // {
  //   path: all_routes.emailVerification,
  //   element: <EmailVerification />,
  // },
  // {
  //   path: all_routes.resetPassword,
  //   element: <ResetPassword />,
  // },
  // {
  //   path: all_routes.forgotPassword,
  //   element: <ForgotPassword />,
  // },
];
