
export interface IPaymentType  {
  paymentTypes: number;
  desc: string;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string | null;
  modifyTime: string | null;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;
  }
  export class PaymentType implements IPaymentType {
  paymentTypes: number;
  desc: string;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string | null;
  modifyTime: string | null;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;

    constructor(data: PaymentType) {
      this.paymentTypes = data.paymentTypes ?? 0;
      this.desc = data.desc ?? '';
      this.id = data.id ?? 0;
      this.status = data.status ?? 0;
      this.statusCode = data.statusCode ?? '';
      this.createdTime = data.createdTime ?? '';
      this.modifyTime = data.modifyTime ?? '';
      this.isCreatedBy = data.isCreatedBy ?? 0;
      this.isUpdatedBy = data.isUpdatedBy ?? 0;
    }
  }
  