import React from 'react';
import { useGetAgreementDetailQuery, useGetTransactionByAgIDQuery } from '../../../redux/CommonApi';
import { useSelector } from 'react-redux';
import Nodata from '../Nodata';
import dayjs from 'dayjs';
import CustomLoading from '../CustomLoading';
const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-OM", {
        style: "currency",
        currency: "OMR",
    });
    return formatter.format(value);
};
const RentDetail = () => {
  const agreementId = useSelector((state: any) => state.CRMS.aggrementId) ?? null;

  const {
    data: aggDetail,
    error: aggDetailError,
    isLoading: isAggDetailLoading,
  } = useGetAgreementDetailQuery({ AgID: agreementId });

  const {
    data: rentDetail,
    error: rentDetailError,
    isLoading: isRentDetailLoading,
  } = useGetTransactionByAgIDQuery({ AgID: agreementId });
  const startDate = dayjs(aggDetail?.aggStartDate);
  const endDate = dayjs(aggDetail?.aggEndDate);
  
  // Calculate the difference in months between startDate and endDate
  const termDuration = endDate.diff(startDate, 'month');
  if (isAggDetailLoading || isRentDetailLoading) return <CustomLoading/>;
  return (
    <>
      <div className="d-flex gap-2 align-items-center gap mb-3 agreementdoc">
        <i
          className="ti ti-arrow-narrow-left"
          style={{
            background: '#7E7E7E',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            fontSize: '30px',
          }}
        />
        <h4 className="py-2">Rent History Detail</h4>
      </div>

      {aggDetail && aggDetail.landLordID ? (
        <>
        <div className="row">
          <div className="col-md-5">
            <div className="card renthistorycard ">
              <div className=" bg-Rent-light campaign-box-block campaign-box propertycard ">
              <div className="table-responsive">
              <table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>Landlord ID</td>
                      <td>{aggDetail.landLordID}</td>
                    </tr>
                    <tr>
                      <td>Tenant ID</td>
                      <td>{aggDetail.tenantID}</td>
                    </tr>
                    <tr>
                      <td>Unit ID</td>
                      <td>{aggDetail.rentalUnitID}</td>
                    </tr>
                    <tr>
                      <td>Rent</td>
                      <td>{formatCurrency(aggDetail.rentAmount)}</td>
                    </tr>
                    <tr>
                      <td>Security Deposit</td>
                      <td>{formatCurrency(aggDetail.advanceAmount)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card renthistorycard ">
              <div className="bg-Rent-light campaign-box-block campaign-box propertycard">
              <h4 className="py-2">Rent History Detail</h4>
              <div className="table-responsive renthistorycard">
              <table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>Date</td>
                      <td>Type</td>
                      <td>Status </td>
                      <td>Amount</td>
     
                    </tr>
                    {rentDetail?.map((rentDetail, index) => (
                  <tr key={index}>
                    <td>{dayjs(rentDetail?.amountReceivedDate).format('DD-MM-YYYY')}</td>
                    <td>{rentDetail?.paymentType?.desc || 'N/A'}</td>
                    <td>{rentDetail?.status}</td>
                    <td>{formatCurrency(rentDetail?.rentAmount)}</td>
                  </tr>
                ))}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
            
                <div className="col-md-5">
            <div className="card renthistorycard ">
              <div className=" bg-Rent-light campaign-box-block campaign-box propertycard ">
                <div className='d-flex justify-content-between'>
              <p className="">Term</p>
              <span className='badge badge-pill badge-status bg-teapink align-content-center'>
              {aggDetail?.terms} months
              </span>
              </div>
              <div className='mt-5'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
            <span className='badge badge-pill badge-status bg-transparent align-content-center'>
            {dayjs(aggDetail?.aggStartDate).format('DD/MM/YYYY')}
       
              </span>
              <p className='text-center pt-2'>Move-In</p>
              </div>
              
              <div>
              <span className='badge badge-pill badge-status bg-transparent align-content-center'>
              {dayjs(aggDetail?.aggEndDate).format('DD/MM/YYYY')}
            
              </span>
              <p className='text-center pt-2'>Move-out</p>
              </div>
            </div>
            </div>
              </div>
            </div>
          </div>
        </div>
     
        </>

      ) : (
        <Nodata />
      )}
    </>
  );
};

export default RentDetail;
