import React from "react";
import { Link } from "react-router-dom";

const NotificationTabs = ({ notifications, activeTab, setActiveTab }) => {
  
  return (
    <>
    
        <ul className="tab-container nav  nav-tabs nav-tabs-bottom">
      {notifications.map((notification) => (
        <li className="nav-item" key={notification.notificationTypeId}>
          <Link
            className={`nav-link tab ${
              notification.notificationTypeName === activeTab
                ? "active-tab"
                : ""
            }`}
            to={`#bottom-tab${notification.notificationTypeId}`}
            onClick={(event) =>
              setActiveTab(notification.notificationTypeName, event)
            }
          >
            {notification.notificationTypeName}
          </Link>
        </li>
      ))}
    </ul>
    </>

  );
};

export default NotificationTabs;
