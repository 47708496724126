import React, { useEffect, useRef, useState } from 'react'
import totalunitActiveImg from "../../../../public/assets/img/icons/totalunit-w.png";
import totalunitInactiveImg from "../../../../public/assets/img/icons/totalunit.png";
import totalunitActive from "../../../../public/assets/img/icons/totalunitActive.png";
import totalunitInactive from "../../../../public/assets/img/icons/totalunitInactiv.png";
import partiallyOccupiedActiveImg from "../../../../public/assets/img/icons/OccupiedInactive.png";
import partiallyOccupiedInactiveImg from "../../../../public/assets/img/icons/occupiedActive.png";
import vacantUnitsActiveImg from "../../../../public/assets/img/icons/vacantActive.png";
import vacantUnitsInactiveImg from "../../../../public/assets/img/icons/vacantInactive.png";
import inactiveUnitsActiveImg from "../../../../public/assets/img/icons/inactive.png";
import inactiveUnitsInactiveImg from "../../../../public/assets/img/icons/inactive.png";
import checkIcon from "../../../../public/assets/img/icons/checkIcon.svg"
import AddPropertyForm from './AddPropertyForm';
import { Link } from 'react-router-dom';
import AddMap from './AddMap';
import { useGetPropertyMasterSubTypesIncludingDetailsQuery, useGetPropertyMasterTypesQuery } from '../../../redux/CommonApi';
import { PropertySubTypeEnum, UnitCategoryEnum } from '../../../redux/RequestTypeEnum';
import PropperyIcons from './PropertyIcon';
const addNewProperty = () => {
    const [activeIndex, setActiveIndex] = useState(''); // Active master type
    const [hoveredSubTypeID, setHoveredSubTypeID] = useState(null);
    const { data, error, isLoading } = useGetPropertyMasterSubTypesIncludingDetailsQuery({});
    const { data: PropertyType } = useGetPropertyMasterTypesQuery({});
    const [selectedMasterTypeID, setSelectedMasterTypeID] = useState(1); // Selected masterTypeID
    const [selectedSubTypeID, setSelectedSubTypeID] = useState(1);
    const [selectedSubTypeName, setSelectedSubTypeName] = useState("");
    const [showDiv, setShowDiv] = useState(false)
    // Selected subTypeID

    useEffect(() => {
        if (PropertyType && PropertyType.length > 0) {
            setActiveIndex(PropertyType[0].masterType); // Set the first master type as active by default
            setSelectedMasterTypeID(PropertyType[0].masterType); // Set default selected masterTypeID
        }
    }, [PropertyType]);
    useEffect(() => {
        if (selectedMasterTypeID) {
            // Filter property subtypes based on selected propertyMasterTypeID

            const filteredSubtypes = data?.filter(
                (item) => item.propertyMasterTypeID === selectedMasterTypeID && item.propertySubType?.desc !== "Plot"
            ) || [];


            // Set the first subtype as selected by default if there are subtypes available
            if (filteredSubtypes.length > 0) {
                setSelectedSubTypeID(filteredSubtypes[0].propertySubType.subType);
            } else {
                setSelectedSubTypeID(null); // Reset if no subtypes are available
            }
        }
    }, [selectedMasterTypeID, data]);
    const handleActiveTabs = (masterType) => {
        setActiveIndex(masterType); // Update active tab on click
        setSelectedMasterTypeID(masterType); // Update selected masterTypeID
        setSelectedSubTypeID(null); // Reset the subType when switching master types
    };
    const getIconByMasterType = (masterType) => {
        switch (masterType) {
            case 'Building':
            case 'TownHouse':
            case 'LowerPortion':
            case 'UpperPortion':
            case 'PentHouse':
            case 'Villa':
            case 'Room':
            case 'Flat':
                return PropperyIcons[masterType]; // Residential icons
            case 'MixUse':
            case 'WareHouse':
            case 'Shop':
            case 'BusinessCenter':
            case 'Factory':
            case 'Hall':
                return PropperyIcons[masterType]; // Commercial icons
            default:
                return null; // or a default icon
        }
    };

  
    const filteredSubtypes = data?.filter(
        (item) => item.propertyMasterTypeID === selectedMasterTypeID && item.propertySubType?.desc !== "Plot"
    ) || [];
    const handlePActiveTabs = (subTypeID, propertySubTypeDesc) => {
    
        
        if (subTypeID === PropertySubTypeEnum.Building || subTypeID === PropertySubTypeEnum.MixUse) {
            setShowDiv(false)
            setSelectedSubTypeName("");
        } else {
            setShowDiv(true)
        }
        setSelectedSubTypeID(subTypeID);
        setSelectedSubTypeName(propertySubTypeDesc); // Track selected sub-type
    };


    const propertyType = [
        "Building",
        "Town house",
        "Lower Portion",
        "Upper Portion",
        "Pent House",
        "Villa",
        "Room",
        "Flat",
    ];
    const iconMapping = {
        "Building": {
            active: totalunitActive,
            inactive: totalunitInactive,
        },
        "Partially Occupied": {
            active: partiallyOccupiedActiveImg,
            inactive: partiallyOccupiedInactiveImg,
        },
        "Occupied Units": {
            active: partiallyOccupiedActiveImg,
            inactive: partiallyOccupiedInactiveImg,
        },
        "Vacant Units": {
            active: vacantUnitsActiveImg,
            inactive: vacantUnitsInactiveImg,
        },
        "Inactive Units": {
            active: inactiveUnitsActiveImg,
            inactive: inactiveUnitsInactiveImg,
        },
        "Residential": {
            active: inactiveUnitsActiveImg,
            inactive: inactiveUnitsInactiveImg,
        },
        "Commercial": {
            active: inactiveUnitsActiveImg,
            inactive: inactiveUnitsInactiveImg,
        },
        "Residential/Commercial": {
            active: inactiveUnitsActiveImg,
            inactive: inactiveUnitsInactiveImg,
        },
    };


    //   const categories = Object.keys(displayData);
    const tabMasterContainerRef = useRef(null);
    const scrollMasterLeft = () => {
        if (tabMasterContainerRef.current) {
            tabMasterContainerRef.current.scrollBy({ left: -150, behavior: 'smooth' });
        }
    };
    const scrollMasterRight = () => {
        if (tabMasterContainerRef.current) {
            tabMasterContainerRef.current.scrollBy({ left: 150, behavior: 'smooth' });
        }
    };
    const tabContainerRef = useRef(null);

    const scrollLeft = () => {
        if (tabContainerRef.current) {
            tabContainerRef.current.scrollBy({ left: -150, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (tabContainerRef.current) {
            tabContainerRef.current.scrollBy({ left: 150, behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-sm-12">
                                        <h4 className="page-title">
                                            Add New Property
                                            {/* <span className="count-title">Dashboard</span> */}
                                        </h4>
                                        <p className='page-title'>
                                            We are glad to see you again!
                                        </p>
                                    </div>
                                    <div className="col-md-8 text-end ">

                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className='d-flex align-items-center'>
                                            <img src={checkIcon} alt="tdes" />
                                            {/* <span className='addproperty-icon '>
                                                <i
                                                    className="ti ti-circle-check "
                                                    style={{
                                                        background:
                                                            "linear-gradient(to bottom, rgba(223, 24, 26, 0.85),rgb(158, 42, 43))",
                                                        WebkitBackgroundClip: "text",
                                                        WebkitTextFillColor: "transparent",
                                                        backgroundClip: "text",
                                                    }}
                                                />
                                            </span> */}
                                            <h4 className="ps-2 ">Property Purpose </h4>
                                        </div>
                                        <div className=" tab-breadcrums align-content-center wrapper-nav my-2">
                                            <nav aria-label="breadcrumb " className='addproperty-pad'>
                                                <ol className="breadcrumb  breadcrumb-line breadcrumb-li ">
                                                    <li
                                                        className={`breadcrumb-item `}
                                                        style={{
                                                            color: "#000"
                                                        }}
                                                        aria-current="page" >
                                                        <span
                                                            className={`property-breadcrumb `} >
                                                            <i
                                                                className="ti ti-key "
                                                            />
                                                            <span className="ps-2">Rent</span>
                                                        </span>
                                                    </li>

                                                </ol>
                                            </nav>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <img src={checkIcon} alt="tdes" className='' />
                                            <h4 className="ps-2">Property Type </h4>
                                        </div>
                                        <div className='col-xxl-auto col-md-12 my-2 addproperty-pad'>
                                            <div className="card  addproperty-card card-shadow-1">
                                            <div className="add-scroller add-scroller-left" onClick={scrollMasterLeft}>
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                </div>
                                                <div className="card-body tab-breadcrums align-content-center wrapper-nav" ref={tabMasterContainerRef}>
                                                    <nav aria-label="breadcrumb ">
                                                        <ol className="breadcrumb  breadcrumb-gradient-line  breadcrumb-li ">
                                                            {PropertyType?.map((category, index) => (
                                                                <li
                                                                    className={`breadcrumb-item breadcrumb-property-item`}
                                                                    onClick={() => handleActiveTabs(category.masterType)}
                                                                    style={{
                                                                        color:
                                                                            activeIndex === category.masterType ? "$white" : "",
                                                                    }}
                                                                    key={category.masterType}
                                                                    aria-current="page"
                                                                >
                                                                    <span
                                                                        className={` ${activeIndex === category.masterType
                                                                            ? "property-breadcrumb"
                                                                            : "inactive-breadcrumb"
                                                                            }`}
                                                                            
                                                                    >
                                                                        {/* <img
                                    src={
                                      activeIndex === category
                                        ? iconMapping[category].active
                                        : iconMapping[category].inactive
                                    }
                                    alt={category}
                                  /> */}

                                                                        <span className="">{category.desc}</span>
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </nav>
                                                </div>
                                                <div className="add-scroller add-scroller-right" onClick={scrollMasterRight}>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <img src={checkIcon} alt="tdes" />
                                            <h4 className="ps-2">Property Sub Type </h4>
                                        </div>
                                        {/* <div className='d-flex'>
                                            <span className='addproperty-icon '>
                                                <i
                                                    className="ti ti-circle-check "
                                                    style={{
                                                        background:
                                                            "linear-gradient(to bottom, rgba(223, 24, 26, 0.85),rgb(158, 42, 43))", WebkitBackgroundClip: "text",
                                                        WebkitTextFillColor: "transparent",
                                                        backgroundClip: "text",
                                                    }}
                                                />
                                            </span>
                                            <h4 className="ps-2 ">Property Sub Type </h4>
                                        </div> */}
                                        <div className={`${(selectedMasterTypeID === 3 && selectedSubTypeID === PropertySubTypeEnum.Building) ? "col-xxl-auto " :" col-xxl-auto" } col-md-12 col-sm-12 my-2 addproperty-pad`}>
                                            <div className="card addproperty-card tab-container-wrapper card-shadow-1">
                                                {/* Left scroller */}
                                                <div className="add-scroller add-scroller-left" onClick={scrollLeft}>
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                </div>

                                                {/* Scrollable container */}
                                                <div className="card-body tab-breadcrumbs align-content-center wrapper-nav" ref={tabContainerRef}>
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb add-breadcrumb breadcrumb-separatorless breadcrumb-li  mx-2">
                                                            {filteredSubtypes?.map((propertytabs, index) => (
                                                                <li
                                                                    className={`breadcrumb-item breadcrumb-property-item`}
                                                                    onClick={() => handlePActiveTabs(propertytabs.propertySubType.subType, propertytabs.propertySubType.desc )}
                                                                    style={{
                                                                        color: selectedSubTypeID === propertytabs.propertySubType.subType ? '#fff' : '',
                                                                    }}
                                                                    key={propertytabs.propertySubType.subType}
                                                                    aria-current="page"
                                                                >

                                                                    <span
                                                                        className={` ${selectedSubTypeID === propertytabs.propertySubType.subType
                                                                            ? 'property-breadcrumb'
                                                                            : 'inactive-breadcrumb'
                                                                            }`}
                                                                        onMouseEnter={() => setHoveredSubTypeID(propertytabs.propertySubType.subType)}
                                                                        onMouseLeave={() => setHoveredSubTypeID(null)}
                                                                    >
                                                                        <span className="page-title">

                                                                            <img
                                                                                src={getIconByMasterType(propertytabs.propertySubType.desc.replace(/[\s-]+/g, ''))}
                                                                                alt={"1"}
                                                                                style={{
                                                                                    width: '20px', height: '20px', marginRight: '5px', paddingTop: "5px",
                                                                                    filter:
                                                                                        selectedSubTypeID === propertytabs.propertySubType.subType
                                                                                            ? 'brightness(0%) invert(100%)' 
                                                                                            : hoveredSubTypeID === propertytabs.propertySubType.subType
                                                                                                ? 'brightness(0%) invert(100%)'
                                                                                                : 'none' 
                                                                                }}
                                                                            />
                                                                            {propertytabs.propertySubType.desc}


                                                                        </span>
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </nav>
                                                </div>

                                                {/* Right scroller */}
                                                <div className="add-scroller add-scroller-right" onClick={scrollRight}>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <AddPropertyForm selectedMasterTypeID={selectedMasterTypeID} selectedSubTypeID={selectedSubTypeID} showDiv={showDiv} selectedSubTypeName={selectedSubTypeName} />
                                        {/* <AddMap/> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default addNewProperty