/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import CampaignModal from "../../core/modals/compaignComplete_modal";
import CollapseHeader from "../../core/common/collapse-header";
import { PMSDashBoardApi } from "../../Services/DashboardServices";
import { useGetPmsDashboardQuery } from "../../redux/CommonApi";
import Pmschatbot from "./pmschatbot";
import PMSCharts from "./PmsDashboardCharts/PMSCharts";
import PropertyMasterCards from "./MainPmsDashboard/PropertyMasterCards";

const PmsDashboard = () => {
  const landlordId = parseInt(sessionStorage.getItem("userId"), 10);
  const { data, error, isLoading } = useGetPmsDashboardQuery({landlordId}) as {data:any, error:any, isLoading:boolean};
  const totalBuilding = data?.data?.find(
    (item) => item.name === "Total Building"
  );
  const Tenants = data?.data?.find((item) => item.name === "Tenants");
  const Units = data?.data?.find((item) => item.name === "Units");
  const iconMapping = {
    "Total Building":
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Properties.svg",
    Units:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Units.svg",
    Tenants:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Tenant.svg",
    Income:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg",
    Expense:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Expense.svg",
    "Net Income":
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg",
    Staff:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/Customers.svg",
    "Security Group":
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.securitygroup.svg",
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">
                      PMS
                      {/* <span className="count-title">Dashboard</span> */}
                    </h4>
                  </div>
                  <div className="col-8 text-end">
                    <div className="head-icons">{/* <CollapseHeader /> */}</div>
                  </div>
                </div>
              </div>
              {/* <Pmschatbot /> */}
              {/* <div className="row">
                               
                                    <>
                                        {totalBuilding && (
                                            <div className="col-xl-4 col-lg-4">
                                                <div className="campaign-box bg-danger-light">
                                                <div>
                                                    <p>{totalBuilding.name}</p>
                                                    <h1 >{totalBuilding.totalCount}</h1>
                                                    </div>
                                                    <div className="campaign-img">
                                                        <span>
                                                            <img src="https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Properties.svg" />
                                                        </span>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        )}
                                        {Units && (
                                            <div className="col-xl-4 col-lg-4">
                                               <div className="campaign-box bg-danger-light">
                                                <div>
                                                    <p>{Units.name}</p>
                                                    <h1 >{Units.totalCount}</h1>
                                                    </div>
                                                    <div className="campaign-img">
                                                        <span>
                                                            <img src="https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Units.svg" />
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {Tenants && (
                                            <div className="col-xl-4 col-lg-4">
                                                <div className="campaign-box bg-danger-light">
                                                <div>
                                                    <p>{Tenants.name}</p>
                                                    <h1 >{Tenants.totalCount}</h1>
                                                    </div>
                                                    <div className="campaign-img">
                                                        <span>
                                                            <img src="https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Tenant.svg" />
                                                        </span>
                                                        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        )}
                                    </>
                            


                            </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <PropertyMasterCards/> */}
    </>
  );
};

export default PmsDashboard;
