import React, { useState,useEffect } from "react";
import { useGetRentCollectionDashboardPropertyQuery } from "../redux/CommonApi";
import { StackPagination } from "../shared/StackPagination";
import { useNavigate } from 'react-router-dom'
import { Loader } from "../shared/Loader";
import InteractiveCard from "./InteractiveCard";
import { ResponseMessage } from "../feature-module/Models/GenericModel/ResponseMessage";
import { RentCollectionDashboardProperty } from "../feature-module/Models/RentCollection/RentCollectionDashboardProperty";

export const RentCollectionCard = () => {
  const navigate = useNavigate();
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const landlordId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);
  const { data: listcard, error, isLoading } = useGetRentCollectionDashboardPropertyQuery({
    userID: +landlordId,
    pageNumber: pageNumber,
  }) as { data: ResponseMessage<RentCollectionDashboardProperty>, error: any, isLoading: boolean };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };


  const [selectedProperty, setSelectedProperty] = useState(null);


  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";

  // const handlePageChange = (event, value) => {
  //   setPageNumber(value);
  // };

  const getCardColors = (percentage) => {
    let col1, col2;
    if (percentage >= 50) {
      const opp = percentage > 100 ? 1 : percentage / 100;
      col1 = `rgba(158, 42, 43, ${opp})`;
      col2 = `rgba(158, 42, 43, ${opp - 0.3})`;
    } else if (percentage >= 30 && percentage < 50) {
      const opp = percentage / 100;
      col1 = "rgba(223, 159, 62, 0.7)";
      col2 = `rgba(223, 159, 62, ${opp})`;
    } else {
      const opp = percentage / 100;
      col1 = "rgba(75, 171, 142, 0.7)";
      col2 = `rgba(75, 171, 142, ${opp})`;
    }
    return { col1, col2 };
  };

  if (isLoading) return <p><Loader /></p>;
  if (error) return <p>Error fetching properties.</p>;


  const handleCardClick = (propertyId) => {
    setSelectedProperty({ propertyId });
    navigate("/admin/rentCollectiontenants", {
      state: { propertyMasterID: propertyId }
    });
  };

  return (
    <>
      <div className='row gx-4 gy-4' style={{paddingBottom:'60px'}}>
        {listcard && listcard?.data?.length > 0 ? (
          listcard.data.map((property, index) => {
            const images = property.imageString || [];
            const imageSrc = images.length > 0 ? images : fallbackImage;


            const { col1, col2 } = getCardColors(property.percentage);

            return (
              <div className="col-lg-6 col-md-12 col-sm-12"  key={index} style={{ cursor: "pointer" }}   onClick={() =>
                handleCardClick(
                  property.propertyMasterID
                )
              }>
                <InteractiveCard imageSrc={imageSrc} property={property} col1={col1} col2={col2} />
              </div>

            );
          })
        ) : (
          <div
            className="card d-flex align-items-center justify-content-center"
            style={{ padding: "40px 0", border: "none" }}
          >
            <div className="card-body">
              <img
                src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                alt="No data found"
              />
              <h3 className="text-center">No Data Found</h3>
            </div>
          </div>
        )}
      </div>
      {/* <div className="row pb-5">
        {listcard && listcard?.data?.length > 0 ? (
          listcard.data.map((property, index) => {
            const images = property.imageString || [];
            const imageSrc = images.length > 0 ? images : fallbackImage;


            const { col1, col2 } = getCardColors(property.percentage);

            return (
              <div
                key={index}
                className="col-lg-6 col-md-12 col-sm-12"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleCardClick(
                    property.propertyMasterID
                  )
                }
              >
                <div
                  className="card totalunit-card rent-collection-property "
                  style={{
                    background: `linear-gradient(135deg, ${col1}, ${col2})`,
                  }}
                >
                  <div className="row g-2 card-body totalunits-1">
                    <div className="col-md-12 col-lg-4">
                      <div>
                        <img
                          src={imageSrc}
                          className="img-fluid rounded-start object-fit-cover h-100 w-100"
                          alt={property.buildingType}
                          style={{ height: '100% !important' }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-8 ps-3">
                      <div className="pt-1">
                        <p className="page-title">
                          Type: <span className="page-title">{property.buildingType}</span>
                        </p>
                        <p className="page-title">
                          Building ID: <span className="page-title">{property.buildingNumber}</span>
                        </p>
                        <p className="truncate page-title">
                          Building Name: <span className="page-title">{property.buildingName}</span>
                        </p>
                        <p className="fs-15 totalunits-address truncate page-title">
                          <i
                            className="ti ti-map-pin-filled page-title"
                            style={{ color: "#9E2A2B" }}
                          />{" "}
                          {property.addressStr}
                        </p>
                        <p className="truncate page-title">
                          Total Tenants: <span className="page-title">{property.totalTenantCount}</span>
                        </p>
                        <p className="truncate page-title">
                          Received Rent: <span className="page-title">{property.totalRentReceived}</span>
                        </p>
                        <p className="truncate page-title">
                          Pending Rent: <span className="page-title">{property.totalpending}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="card d-flex align-items-center justify-content-center"
            style={{ padding: "40px 0", border: "none" }}
          >
            <div className="card-body">
              <img
                src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                alt="No data"
              />
              <h3 className="text-center">No Data</h3>
            </div>
          </div>
        )}
      </div> */}
      {listcard?.totalCount > 10 && (
        <div className="row row1  fixed-bottom1">
          <div className="d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <StackPagination
                tenancyRequestCount={listcard?.totalCount}
                rowNumbers={10}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
