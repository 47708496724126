import React, { useState } from "react";
import { useGetTerminatedAgreementQuery } from "../../../redux/CommonApi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import PropertyCard from "./PropertyCard"; // Import PropertyCard
import { formatDate } from "../../../formatDate";
import Nodata from "../Nodata";
import CustomLoading from "../CustomLoading";
import { useDispatch } from "react-redux";
import { setAggrementId } from "../../../redux/Commonslice";
import { StackPagination } from "../../../shared/StackPagination";
const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background:
      "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#9E2A2B",
    },
    "& .MuiPaginationItem-previousNext:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
}));

const TerminatedAgreement = ({ Cardstatus , setAgreementDocDetail,setAggrementoption}) => {
  const classes = useStyles();
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const profileimg =
    "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";

  const landlordId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState(6);
  const dispatch = useDispatch();
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const {
    data: terminatedAgreement = [],
    error: terminatedAgreementError,
    isLoading: isTerminatedAgreementLoading,
  } = useGetTerminatedAgreementQuery({
    userID: landlordId,
    pageNumber: pageNumber,
    rowNumber: rowNumbers,
  });
console.log('agidd',terminatedAgreement)
  if (isTerminatedAgreementLoading) return <CustomLoading/>;
  const onCardClick = (agreementId) => {
    dispatch(setAggrementId(agreementId)); // Dispatch action with agreement ID
    setAgreementDocDetail(agreementId); // Set the agreement doc detail
    setAggrementoption(1);
  };

  return (
    <>
      <div className=" mb-0">
      <div className="card-body pb-5">
    <div className="row">
      {terminatedAgreement.data && terminatedAgreement.data.length > 0 ? (
        terminatedAgreement.data.map((item, index) => (
          <PropertyCard
            key={index}
            item={item}
            fallbackImage={fallbackImage}
            profileimg={profileimg}
            onCardClick={() => onCardClick(item.agreementID)}
            Cardstatus={Cardstatus}
            activesubIndex={null}
          />
        ))
      ) : (
        <Nodata/>

      )}
      {/* <div className="d-flex justify-content-center pb-4">
        <Stack spacing={2} mt={3}>
          <div
            style={{
              padding: "4px",
              borderRadius: "12px",
              background:
              "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                backgroundColor: "#fff",
                padding: "8px",
              }}
            >
              <Pagination
                classes={{ ul: classes.ul }}
                count={Math.ceil(
                  parseInt(terminatedAgreement.totalCount.toString(), 10) /
                    parseInt(rowNumbers.toString(), 10)
                )}
                page={pageNumber}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </Stack>
      </div> */}
    </div>
    </div>
    </div>
    {terminatedAgreement.totalCount > 0 && (
    <div className="row row1  fixed-bottom1" >
<div className="d-flex justify-content-center">
  <StackPagination tenancyRequestCount={terminatedAgreement.totalCount} rowNumbers={rowNumbers}pageNumber={pageNumber} handlePageChange={handlePageChange}/>
  
</div>
</div>
    )}
    </>
  );
};

export default TerminatedAgreement;
