
export interface IBank {
  bankType: number;
  desc: string;
  id: number;
  status: number;
  statusCode: number | null;
  createdTime: string | null;
  modifyTime: string | null;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;
}
  export class Bank implements IBank {
    bankType: number;
    desc: string;
    id: number;
    status: number;
    statusCode: number | null;
    createdTime: string | null;
    modifyTime: string | null;
    isCreatedBy: number | null;
    isUpdatedBy: number | null;

    constructor(data: Bank ) {
      this.bankType = data.bankType ?? 0;
      this.desc = data.desc ?? '';
      this.id = data.id ?? 0;
      this.status = data.status ?? 0;
      this.statusCode = data.statusCode ?? null;
      this.createdTime = data.createdTime ?? null;
      this.modifyTime = data.modifyTime ?? null;
      this.isCreatedBy = data.isCreatedBy ?? null;
      this.isUpdatedBy = data.isUpdatedBy ?? null;
    }
  }
  