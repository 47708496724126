import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useGetTenantDashBoardQuery } from "../../../redux/CommonApi";
import GaugeComponent from 'react-gauge-component';
const TenantChart = () => {
  const userId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetTenantDashBoardQuery({
    userID: userId,
  });
  const activeCount = data?.find((item) => item.subModuleId === 2)?.count || 0; // Current Agreements
const expiredCount = data?.find((item) => item.subModuleId === 4)?.count || 0; // Expired Agreements
const terminatedCount = data?.find((item) => item.subModuleId === 7)?.count || 0; // Terminated Agreements
const negotiationCount = data?.find((item) => item.subModuleId === 8)?.count || 0; // Negotiation Agreements
 console.log("activeCount",expiredCount,"expiredCount",expiredCount,"terminatedCount",terminatedCount,"negotiationCount",negotiationCount)
const chartData = [
  { label: "Active", count: activeCount, color: "#43a594" },
  { label: "Negotiation", count: negotiationCount, color: "#ea670c" },
  { label: "Expired", count: expiredCount, color: "#9E2A2B" },
  { label: "Terminated", count: terminatedCount, color: "#5695f7" },
];

// Sort the chart data based on counts
chartData.sort((a, b) => b.count - a.count);
  // let activeCount = 0;
  // let expiredCount = 0;
  // let terminatedCount = 0;
  // let negotiationCount = 0;
  // if (data && data.length > 0) {
  //   activeCount = data.find((item) => item.subModuleId === 2)?.count || 0; // Current Agreements
  //   expiredCount = data.find((item) => item.subModuleId === 4)?.count || 0; // Expired Agreements
  //   terminatedCount = data.find((item) => item.subModuleId === 7)?.count || 0; // Terminated Agreements
  //   negotiationCount = data.find((item) => item.subModuleId === 7)?.count || 0; // Negotiation Agreements
  // }
  // const chartData = [
  //   { label: "Active", count: activeCount, color: "#43a594" },
  //   { label: "Negotiation", count: negotiationCount, color: "#ea670c" },
  //   { label: "Expire", count: expiredCount, color: "#9E2A2B" },
  //   { label: "Terminated", count: terminatedCount, color: "#5695f7" },
  // ];

  // chartData.sort((a, b) => b.count - a.count);
  // const series = chartData.map((item) => item.count);
  // const labels = chartData.map((item) => item.label);
  // const colors = chartData.map((item) => item.color);
  // const radialChart: ApexOptions = {
  //   chart: {
  //     height: 360,
  //     type: "radialBar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   legend: {
  //     show: true,
  //     position: "bottom",
  //   },
  //   colors: colors,
  //   plotOptions: {
  //     radialBar: {
  //       dataLabels: {
  //         name: {
  //           fontSize: "22px",
  //         },
  //         value: {
  //           fontSize: "16px",
  //         },
  //         total: {
  //           show: true,
  //           label: "Total",
  //           formatter: function () {
  //             return `${activeCount + negotiationCount + expiredCount + terminatedCount
  //               }`;
  //           },
  //         },
  //       },
  //     },
  //   },
  //   series: series,
  //   labels: labels,
  // };
  const value = chartData[0]?.count || 0; // Top metric
  const color = chartData[0]?.color || '#3865A3'; // Top color for the arc
console.log("value",value)
  return (
    <>
      <div>
        <div className="card cardreq">
          <div className="card-header">
            <h5 className="card-title">Agreement Status</h5>
          </div>
          <div className="card-body">
          {/* <GaugeComponent
  type="semicircle"
  arc={{
    colorArray: [color, "#d3d3d3"], // Dynamic color for the active segment
    padding: 0.02, // Space between arcs
    subArcs: [
      { length: value / 100 }, // Proportion based on the value percentage (0 to 1)
      { length: 1 - value / 100 }, // Remaining portion to complete the arc
    ],
  }}
  pointer={{ type: "blob", animationDelay: 0 }}
  value={value} // Dynamic value from data
/> */}

            <GaugeComponent
              type="semicircle"
     
              pointer={{ type: "blob", animationDelay: 0 }}
              arc={{
                colorArray: [color, "#64A338", "#F5C916", "#E03B24"], // Use multiple colors
                
                padding: 0.02, 
                cornerRadius:50,
          
                subArcs:
                  [
                    {limit: 34, color:"#64A338",tooltip:{text:"Active"} },
                    {  color:"#F5C916",tooltip:{text:"Negotiation"} },
                    { color:"#E03B24",tooltip:{text:"Expired"} },
                    { color:"#3865A3",tooltip:{text:"Terminated"} },
              
                  ],
                  
              }}
              
              value={value}
            />
            {/* <div id="radial-chart">
              <ReactApexChart
                options={radialChart}
                series={radialChart.series}
                type="radialBar"
                height={360}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantChart;
