export interface IMaintenanceRequestCategory {
  id: number;
  desc: string;
  url: string;
}
export class MaintenanceRequestCategory implements IMaintenanceRequestCategory {
  id: number;
  desc: string;
  url: string;
  constructor(data: MaintenanceRequestCategory) {
    this.id = data.id ?? 0;
    this.desc = data.desc ?? "";
    this.url = data.url ?? "";
  }
}
