import React, { useState } from 'react'
import { useAgreementTerminationViewQuery, useCurrentAgreementDetailQuery, useTerminateAgreementMutation } from '../../../redux/CommonApi';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { unitStatusEnum } from '../../../redux/RequestTypeEnum';
import { useNavigate } from 'react-router';
import toastr from "toastr";
import toastimg from "../../../../public/assets/img/Tenanticon/toastimg.svg";
import { Toast } from 'react-bootstrap';
const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-OM", {
        style: "currency",
        currency: "OMR",
    });
    return formatter.format(value);
};

const VerifyTerminate = (Aggrementoption) => {
    const agreementId = useSelector((state: any) => state.CRMS.aggrementId) ?? null;
    const profileimg = "https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png";
    const navigate = useNavigate();
    const { data: tenancyRequestDetail, error, isLoading } = useCurrentAgreementDetailQuery({
        requestAgID: agreementId,
        option:1,
    });

    const { data: terminateView } = useAgreementTerminationViewQuery({
        AgID: agreementId,
    });
console.log("tt" , terminateView)
    const [isChecked, setIsChecked] = useState(false);
    const [adjustedDue, setAdjustedDue] = useState(terminateView?.rentalDueAmount);

    const [isExpanded, setIsExpanded] = useState(false);
    const [TerminateAgreement] = useTerminateAgreementMutation();

    // Handle checkbox change
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            setAdjustedDue(terminateView?.advanceAmount - terminateView?.rentalDueAmount);
        } else {
            setAdjustedDue(terminateView?.rentalDueAmount);
        }
    };

    // Toggle the section visibility
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const getStatusText = (statusID) => {
        const statusText = Object.keys(unitStatusEnum).find(key => unitStatusEnum[key] === statusID);
        return statusText ? statusText.replace('_', ' ') : 'Unknown Status';
    };

    // Handle the button click to verify and terminate the agreement
    const handleverify = () => {

        const requestData = {
            AgId: agreementId,
            feedback: "", // You can update this if you need to send feedback
            status: unitStatusEnum.TERMINATE, // Update this according to the correct status you want to send
            isAdjusted: isChecked,
        };
        TerminateAgreement(requestData)
            .unwrap()
            .then((response) => {
                toastr.success("Terminate successful!");
                    setShowMiddleCenterToast(false);
                    window.location.reload();
                navigate('/admin/tenantMaster');
            })
            .catch((error) => {
                toastr.error("Failed to Terminate");
            });
    };
    const [showMiddleCenterToast, setShowMiddleCenterToast] = useState(false);
    const handleMiddleCenterButtonClick = () => {
        setShowMiddleCenterToast(true);
    };

    const handleMiddleCenterToastClose = () => {
        setShowMiddleCenterToast(false);
    };
      
    return (
        <>
            <div className="d-flex gap-2 align-items-center gap mb-3 agreementdoc">
                <i className="ti ti-arrow-narrow-left" style={{
                    background: '#7E7E7E',
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    fontSize: "30px",
                }} />
                <h4 className="py-2">Agreement Termination</h4>
            </div>

            <div className="col-md-12">
                <div className="card agreementdoc coporatedetail">
                    <div className="card-body">
                        <div className="img-tag d-flex align-items-center gap-2 pb-4">
                            <img
                                src={tenancyRequestDetail?.userImage || profileimg}
                                className="no-gap"
                                alt="Profile"
                                style={{ height: "5rem" }}
                            />
                            <p className="">
                                Tenant
                                <br />
                                <span>{tenancyRequestDetail?.tenantName}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <h4 className="py-2">Tenant Pending Dues</h4>
            <div className="col-md-12">
                <div className="card verifyterminate">
                    <div className="card-body">
                        <div className='card bg-mumtalikati'>
                            <div className='card-body'>
                                <div className="d-flex gap-2 align-items-center gap mb-3 agreementdoc">
                                    <i className="ti ti-point-filled" style={{
                                        background: "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        fontSize: "30px",
                                    }} />
                                    <h4 className="py-2">Tenant Pending Dues</h4>
                                </div>
                                <h4>{formatCurrency(terminateView?.pendingDueAmount)}</h4>
                            </div>
                        </div>

                        <div>
                            <h5 className='pb-2'>Tenant Dues</h5>
                            <div className='d-flex justify-content-between'>
                                <p>Total Rent Dues</p>
                                <span>{formatCurrency(terminateView?.rentalDueAmount)}
                                    <i className={`ti ${isExpanded ? 'ti-chevron-down' : 'ti-chevron-right'}`} onClick={handleToggle}
                                        style={{
                                            background: "linear-gradient(to bottom,rgba(223, 24, 26, 0.85), rgb(158, 42, 43))",
                                            WebkitBackgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                            backgroundClip: "text",
                                        }}
                                    />
                                </span>
                            </div>

                            {isExpanded && (
                                <div className="up-border pb-2">
                                    <div className="d-flex justify-content-between py-2">
                                        <h5 style={{ color: "#A64041" }}>Date</h5>
                                        <h5>Status</h5>
                                        <h5>Amount</h5>
                                    </div>
                                    {terminateView?.rentalPlans?.map((rent) => (
                                        <div className="d-flex justify-content-between" style={{ color: "#A64041" }}>
                                            <span>{dayjs(rent?.dueDate).format('DD/MM/YYYY')}</span>
                                            <span>{getStatusText(rent?.statusID)?.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</span>
                                            <span>{formatCurrency(rent?.rentAmount)}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <h5 className='pb-2'>Utilities Dues</h5>
                            <div className='d-flex justify-content-between'>
                                <p>Utilities Charges</p>
                                <span>{formatCurrency(terminateView?.utilities)}</span>
                            </div>

                            <h5 className='pb-2'>Maintenance Dues</h5>
                            <div className='d-flex justify-content-between'>
                                <p>Maintenance Charges</p>
                                <span>{formatCurrency(terminateView?.maintenanceDues)}</span>
                            </div>

                            <h5 className='pb-2'>Landlord Dues</h5>
                            <div className='d-flex justify-content-between'>
                                <p>Security Deposit</p>
                                <span>{formatCurrency(terminateView?.advanceAmount)}</span>
                            </div>
                        </div>

                        <div className="form-check form-check-lg align-items-center">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label className="form-check-label modal-p">
                                Adjust Security Deposit In tenant due amount
                            </label>
                        </div>

                        {isChecked && (
                            <div>
                                <div className='d-flex justify-content-between pt-2'>
                                    <p>Security Deposit</p>
                                    <span>{formatCurrency(terminateView?.advanceAmount)}</span>
                                </div>
                                <div className='d-flex justify-content-between dueTenant'>
                                    <p>Tenant total Dues</p>
                                    <span>{formatCurrency(terminateView?.rentalDueAmount)}</span>
                                </div>
                                <div className='d-flex justify-content-between py-2'>
                                    <p>Due on Tenant</p>
                                    <span>{formatCurrency(adjustedDue)}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-between pb-4'>
                <p>*After termination one term required for notice period and rent is included in tenant liabilities</p>
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleMiddleCenterButtonClick} // Call the verify function here
                >
                    Verify & terminate
                </button>
            </div>
            <div className="toast-container position-fixed top-50 start-50 translate-middle">
                <Toast
                    show={showMiddleCenterToast}
                    onClose={handleMiddleCenterToastClose}
                    id="middlecenter-Toast"
                    className="colored-toast bg-white terminateToast"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <div className="img-tag d-flex align-items-center  pt-4 justify-content-center">
                        <img
                            src={toastimg}
                            className="no-gap"
                            alt="Profile"
                            style={{ height: "5rem" }}
                        />

                    </div>
                    <h5 className=" d-flex align-items-center py-4 justify-content-center">Agreement Termination</h5>
                    <Toast.Body><p>Are you sure you want to terminate agreement against building ID ({tenancyRequestDetail?.buildingNumber}) and unit ID ({tenancyRequestDetail?.aggUnitID}) </p></Toast.Body>
                    <div className="d-flex justify-content-end gap-2 pb-4 pt-3 ">
                        {/* <button
                            type="button"
                            className="btn addfeature-btn "
                            id="middlecenterToastBtn"
                            onClick={handleMiddleCenterToastClose}
                        >
                            Cancle
                        </button> */}

                        {/* <button
                            type="button"
                            className="btn  btn-danger  "
                            onClick={handleverify}
                        >
                            Save
                        </button> */}
                        <button
                            type="button"
                            className="btn  btn-danger  "
                            onClick={handleverify}
                        >
                            Got it
                        </button>
                    </div>
                </Toast>
            </div>
        </>
    );
};

export default VerifyTerminate;
