// Define the RequestTypeEnum object
import high from "./../../public/assets/img/MaintenanceIcon/high.svg"
import low from "./../../public/assets/img/MaintenanceIcon/low.svg"
import medium from "./../../public/assets/img/MaintenanceIcon/medium.svg"
export const RequestTypeEnum = {
  TENANCY_REQUEST: 1,
  REQUEST: 2,
  RENEWAL_REQUEST: 3,
  PAID: 4,
  TRANSACTIONS: 5,
  OVERDUE: 6,
  UNASSIGNED: 7,
  ASSIGNED: 8,
  EXPENSE_TRANSACTIONS: 9,
  TRAILS: 10,
};

export const NotificationEnum = {
  RENT: 1,
  AGREEMENT: 2,
  MAINTENANCE_REQUEST: 3,
  EXPENSE_MANAGEMENT: 4,
};
export const PropertyMasterTypeEnum = {
  Residential: 1,
  Commercial: 2,
  ResidentalCommercial: 3
};
export const unitStatusEnum = {
  Vacant: 1,
  Inactive: 2,
  Occupied: 3,
  Sold: 4,
  Available: 5,
  DISABLE_AUTO_VACANCY: 6,
  ENABLED_AUTO_VACANCY: 7,
  PENDING: 8,
  NEGOTIATION: 9,
  RENEWABLE: 10,
  Approved: 11,
  Rejected: 12,
  PARTIALLY_OCCUPIED: 13,
  EXPIRED: 14,
  PAID: 16,
  CANCEL: 17,
  TERMINATE: 18,
  Close: 19,
  LOCKED: 20,
  OVERDUE: 21,
  UNPAID: 22,
  Sent: 23,
  In_Progress: 24,
  On_Hold: 25,
  Work_Done: 26,
  Reopen: 27,
  Assign: 28,
  Create: 29,
};
export const priorityEnum = {
  High: 1,
  Medium: 2,
  Low: 3,
};

export const PaymentTypeEnum = {
  Cash :1,
  Cheque : 2,
  DirectTransfer:3
};

export const  priorityEnumMap = {
  [priorityEnum.High]: {
    text: "High",
    color: "#9E2A2B",
    backgroundColor: "#E2BFBF",
  },
  [priorityEnum.Medium]: {
    text: "Medium",
    color: "#DF9F3E",
    backgroundColor: "#DF9F3E4D",
  },
  [priorityEnum.Low]: {
    text: "Low",
    color: "#1E1E1E",
    backgroundColor: "#D3D3D3",
  },
};
export const totalunitEnum = {
  Active: 1,
  partiall: 13,
  LOW: 3,
};
export const idToTextEnum = {
  1: "Active",
  13: "Partial",
  3: "Low",
};
export const ownerDashboard = {
  TotalUnits: 1,
  partiallyOccupied: 13,
  Occupied: 3,
};

export type StatusInfo = {
  text: string;
  color: string;
  backgroundColor?: string; 
};
export const unitStatusEnumMap: { [key: string]: StatusInfo } = {
  [unitStatusEnum.Vacant]: { text: "Vacant", color: "#CCCCCC" },
  [unitStatusEnum.Inactive]: { text: "Inactive", color: "#CCCCCC" },
  [unitStatusEnum.Occupied]: { text: "Occupied", color: "#CCCCCC" },
  [unitStatusEnum.Sold]: { text: "Sold", color: "#CCCCCC" },
  [unitStatusEnum.Available]: { text: "Available", color: "#CCCCCC" },
  [unitStatusEnum.DISABLE_AUTO_VACANCY]: {
    text: "Disable Auto Vacancy",
    color: "#CCCCCC",
    backgroundColor: "#dfdfdf",
  },
  [unitStatusEnum.ENABLED_AUTO_VACANCY]: {
    text: "Enabled Auto Vacancy",
    color: "#CCCCCC",
  },
  [unitStatusEnum.PENDING]: {
    text: "Received",
    color: "#fff",
    backgroundColor: "#147AD6",
  },
  [unitStatusEnum.NEGOTIATION]: { text: "Negotiation", color: "#CCCCCC" },
  [unitStatusEnum.RENEWABLE]: { text: "Renewable", color: "#CCCCCC" },
  [unitStatusEnum.Approved]: {
    text: "Accept",
    color: "#fff",
    backgroundColor: "#089917",
  },
  [unitStatusEnum.Rejected]: {
    text: "Reject",
    color: "#fff",
    backgroundColor: "#DD2222",
  },
  [unitStatusEnum.PARTIALLY_OCCUPIED]: {
    text: "Partially Occupied",
    color: "#CCCCCC",
  },
  [unitStatusEnum.EXPIRED]: { text: "Expired", color: "#CCCCCC" },
  [unitStatusEnum.PAID]: { text: "Paid", color: "#CCCCCC" },
  [unitStatusEnum.CANCEL]: { text: "Cancel", color: "#CCCCCC" },
  [unitStatusEnum.TERMINATE]: { text: "Terminate", color: "#CCCCCC" },
  [unitStatusEnum.Close]: {
    text: "Closed",
    color: "#fff",
    backgroundColor: "#9E2A2B",
  },
  [unitStatusEnum.LOCKED]: { text: "Locked", color: "#CCCCCC" },
  [unitStatusEnum.OVERDUE]: { text: "Overdue", color: "#CCCCCC" },
  [unitStatusEnum.UNPAID]: { text: "Unpaid", color: "#CCCCCC" },
  [unitStatusEnum.Sent]: {
    text: "Sent",
    color: "#fff",
    backgroundColor: "#147AD6",
  },
  [unitStatusEnum.In_Progress]: {
    text: "In-Progress",
    color: "#fff",
    backgroundColor: "#DF9F3E",
  },
  [unitStatusEnum.On_Hold]: {
    text: "On Hold",
    color: "#fff",
    backgroundColor: "#70BEF2",
  },
  [unitStatusEnum.Work_Done]: {
    text: "Work Done",
    color: "#fff",
    backgroundColor: "#31BA96",
  },
  [unitStatusEnum.Reopen]: {
    text: "Re-Open",
    color: "#fff",
    backgroundColor: "#A282C2",
  },
  [unitStatusEnum.Assign]: {
    text: "Assigned",
    color: "#fff",
    backgroundColor: "#147AD6",
  },
  [unitStatusEnum.Create]: {
    text: "Created",
    color: "#fff",
    backgroundColor: "#F58F78",
  },
  [priorityEnum.High]: {
    text: "High",
    color: "#fff",
    backgroundColor: "#F58F78",
  },
  [priorityEnum.Medium]: {
    text: "Medium",
    color: "#fff",
    backgroundColor: "#007bff",
  },
  [priorityEnum.Low]: {
    text: "Low",
    color: "#fff",
    backgroundColor: "#28a745",
  },
};
export const NegotiationTypeEnum = {
  TenancyRequest: 1,
  LandlordRequest: 2,
  RenewableRequest: 3,
  ChangeRequest: 4,
};

export const UserTypeEnum = {
  Landlord: 1,
  Tenant: 2,
  ServiceProvider: 3,
  RealStateAgent: 4,
  Corporate: 5,
  Admin: 6,
  Staff: 7,
  Developer: 8,
  Customer: 9,
};

export const PropertyUnitCategoryEnum = {
  "TownHouse": 2,
  "Lower Portion": 3,
  "Upper Portion": 4,
  "PentHouse": 5,
  "Villa": 6,
  "Room": 7,
  "Flat": 8,
  "Plot": 15,
  "Mix-Use": 9,
  "WareHouse": 10,
  "Shop": 11,
  "Business Center": 12,
  "Factory": 13,
  "Hall": 14,
};
export const PropertySubType = {
  O2BHK: 2,
  O3BHK: 3,
  O4BHK: 4,
  O5BHK: 5,
  Shop: 6,
  Office: 7,
  WareHouse: 8,
  Factory: 9,
  Hall: 10,
  BusinessCenter: 11,
  WholeBuilding: 12,
  Studio: 13,

};




export const UnitCategoryEnum = {
  "1BHK": 1,
  "2BHK": 2,
  "3BHK": 3,
  "4BHK": 4,
  "5BHK": 5,
  "Shop": 6,
  "Office": 7,
  "WareHouse": 8,
  "Factory": 9,
  "Hall": 10,
  "BusinessCenter": 11,
  "WholeBuilding": 12,
  "Studio": 13
};


export const PropertySubTypeEnum =
{
  Building: 1,
  TownHouse: 2,
  LowerPortion: 3,
  UpperPortion: 4,
  PentHouse: 5,
  Villa: 6,
  Room: 7,
  Flat: 8,
  MixUse: 9,
  WareHouse: 10,
  Shop: 11,
  BusinessCenter: 12,
  Factory: 13,
  Hall: 14,
  Plot: 15,
  Project: 16
};
export const ChequeTypeEnum ={
        Advance : 1,
        Rental :2,
};
export const utilityTypeEnum={
  'Electricity' : 1,
  'Water' :2,
  'Gas':3,
}
export const  priorityEnumMapUrl = {
  [priorityEnum.Low]: {
    text: "Low",
    id: 3,
    url: low,
  },
  [priorityEnum.Medium]: {
    text: "Medium",
    id: 2,
    url: medium,
  },
 
  [priorityEnum.High]: {
    text: "High",
    id: 1,
    url: high,
  }
};
export const  statusEnumMapUrl = {
  [unitStatusEnum.Rejected]: {
    text: "Rejected",
    id: 12,
    color:" #dd2222",
  },
  [unitStatusEnum.PENDING]: {
    text: "Pending",
    id: 8,
    color: "#147AD6",
  },
 
  [unitStatusEnum.Approved]: {
    text: "Approved",
    id: 11,
    color: "#089917",
  }
};
// export const unitStatusEnums = {
//   Rejected: 'REJECTED',
//   PENDING: 'PENDING',
//   Approved: 'APPROVED',
// };
export const Rentstatus =
{
  "Paid": 0,
  "OverDue":1,
  "30DPD":30,
  "60DPD":60,
  "90DPD":90,
};

export const RentstatusStyles = {
  [Rentstatus.Paid]: {
    label: "Paid",
    color: "#fff",
    backgroundColor: "#4CAF50", // Green
  },
  [Rentstatus.OverDue]: {
    label: "Overdue",
    color: "#fff",
    backgroundColor: "#FF9800", // Orange
  },
  [Rentstatus["30DPD"]]: {
    label: "30DPD",
    color: "#fff",
    backgroundColor: "#F44336", // Red
  },
  [Rentstatus["60DPD"]]: {
    label: "60DPD",
    color: "#fff",
    backgroundColor: "#F44336", // Red
  },
  [Rentstatus["90DPD"]]: {
    label: "90DPD",
    color: "#fff",
    backgroundColor: "#F44336", // Red
  },
};
export const unitStatusEnumRENTMap: { [key: string]: StatusInfo } = {
  [unitStatusEnum.Vacant]: { text: "Vacant", color: "#CCCCCC" },
  [unitStatusEnum.Inactive]: { text: "Inactive", color: "#CCCCCC" },
  [unitStatusEnum.Occupied]: { text: "Occupied", color: "#CCCCCC" },
  [unitStatusEnum.Sold]: { text: "Sold", color: "#CCCCCC" },
  [unitStatusEnum.Available]: { text: "Available", color: "#CCCCCC" },
  [unitStatusEnum.DISABLE_AUTO_VACANCY]: {
    text: "Disable Auto Vacancy",
    color: "#CCCCCC",
    backgroundColor: "#dfdfdf",
  },
  [unitStatusEnum.ENABLED_AUTO_VACANCY]: {
    text: "Enabled Auto Vacancy",
    color: "#CCCCCC",
  },
  [unitStatusEnum.PENDING]: {
    text: "Received",
    color: "#fff",
    backgroundColor: "#147AD6",
  },
  [unitStatusEnum.NEGOTIATION]: { text: "Negotiation", color: "#CCCCCC" },
  [unitStatusEnum.RENEWABLE]: { text: "Renewable", color: "#CCCCCC" },
  [unitStatusEnum.Approved]: {
    text: "Accept",
    color: "#fff",
    backgroundColor: "#089917",
  },
  [unitStatusEnum.Rejected]: {
    text: "Reject",
    color: "#fff",
    backgroundColor: "#DD2222",
  },
  [unitStatusEnum.PARTIALLY_OCCUPIED]: {
    text: "Partially Occupied",
    color: "#CCCCCC",
  },
  [unitStatusEnum.EXPIRED]: { text: "Expired", color: "#CCCCCC" },
  [unitStatusEnum.PAID]: { text: "Paid", color: "#CCCCCC" },
  [unitStatusEnum.CANCEL]: { text: "Cancel", color: "#CCCCCC" },
  [unitStatusEnum.TERMINATE]: { text: "Terminated", color: "#CCCCCC" },
  [unitStatusEnum.Close]: {
    text: "Closed",
    color: "#fff",
    backgroundColor: "#9E2A2B",
  },
  [unitStatusEnum.LOCKED]: { text: "Locked", color: "#CCCCCC" },
  [unitStatusEnum.OVERDUE]: { text: "Overdue", color: "#CCCCCC" },
  [unitStatusEnum.UNPAID]: { text: "Unpaid", color: "#CCCCCC" },
  [unitStatusEnum.Sent]: {
    text: "Sent",
    color: "#fff",
    backgroundColor: "#147AD6",
  },
  [unitStatusEnum.In_Progress]: {
    text: "In-Progress",
    color: "#fff",
    backgroundColor: "#DF9F3E",
  },
  [unitStatusEnum.On_Hold]: {
    text: "On Hold",
    color: "#fff",
    backgroundColor: "#70BEF2",
  },
  [unitStatusEnum.Work_Done]: {
    text: "Work Done",
    color: "#fff",
    backgroundColor: "#31BA96",
  },
  [unitStatusEnum.Reopen]: {
    text: "Re-Open",
    color: "#fff",
    backgroundColor: "#A282C2",
  },
  [unitStatusEnum.Assign]: {
    text: "Assigned",
    color: "#fff",
    backgroundColor: "#147AD6",
  },
  [unitStatusEnum.Create]: {
    text: "Created",
    color: "#fff",
    backgroundColor: "#F58F78",
  },

};
export const unitStatusEnumRentColection: { [key: string]: StatusInfo } = {
  [unitStatusEnum.Vacant]: { text: "Vacant", color: "#000" },
  [unitStatusEnum.TERMINATE]: { text: "Terminated", color: "#9E2A2B" },
  [unitStatusEnum.EXPIRED]: { text: "Expired", color: "#9E2A2B" }
}