export interface IPropertyCategoryDetail {
  propertyMasterID: number;
  unitCategoryID: number;
  listingPurposeID: number;
  propertyMasterName: string;
  propertyUnitDescription: string;
  rentPrice: number | null;
  sellPrice: number | null;
  buildingNumber: string;
  buildingName: string;
  addressStr: string;
  landLordID: number;
  iconUrl: string | null;
  plotNumber: string;
  propertyMasterTypeID: number;
  unitName: string;
  bedRoom: number;
  bathRoom: number;
  sqft: number;
  contact: string;
  hall: number;
  kitchen: number;
  parking: string | null;
  name: string;
  residentialUnits: number | null;
  commercialUnits: number | null;
  propertyUnitID: number;
  numberofFloors: number | null;
  floorNumber: number | null;
  builtYear: number;
  imageString: string[];
  totalImages: number;
  propertyMasterDescription: string;
  propertySubTypeName: string;
  vacant: number;
  occupied: number;
  totalUnit: number;
}

export class PropertyCategoryDetail implements IPropertyCategoryDetail {
  propertyMasterID: number;
  unitCategoryID: number;
  listingPurposeID: number;
  propertyMasterName: string;
  propertyUnitDescription: string;
  rentPrice: number | null;
  sellPrice: number | null;
  buildingNumber: string;
  buildingName: string;
  addressStr: string;
  landLordID: number;
  iconUrl: string | null;
  plotNumber: string;
  propertyMasterTypeID: number;
  unitName: string;
  bedRoom: number;
  bathRoom: number;
  sqft: number;
  contact: string;
  hall: number;
  kitchen: number;
  parking: string | null;
  name: string;
  residentialUnits: number | null;
  commercialUnits: number | null;
  propertyUnitID: number;
  numberofFloors: number | null;
  floorNumber: number | null;
  builtYear: number;
  imageString: string[];
  totalImages: number;
  propertyMasterDescription: string;
  propertySubTypeName: string;
  vacant: number;
  occupied: number;
  totalUnit: number;

  constructor(data: Partial<PropertyCategoryDetail>) {
    this.propertyMasterID = data.propertyMasterID ?? 0;
    this.unitCategoryID = data.unitCategoryID ?? 0;
    this.listingPurposeID = data.listingPurposeID ?? 0;
    this.propertyMasterName = data.propertyMasterName ?? '';
    this.propertyUnitDescription = data.propertyUnitDescription ?? '';
    this.rentPrice = data.rentPrice ?? null;
    this.sellPrice = data.sellPrice ?? null;
    this.buildingNumber = data.buildingNumber ?? '';
    this.buildingName = data.buildingName ?? '';
    this.addressStr = data.addressStr ?? '';
    this.landLordID = data.landLordID ?? 0;
    this.iconUrl = data.iconUrl ?? null;
    this.plotNumber = data.plotNumber ?? '';
    this.propertyMasterTypeID = data.propertyMasterTypeID ?? 0;
    this.unitName = data.unitName ?? '';
    this.bedRoom = data.bedRoom ?? 0;
    this.bathRoom = data.bathRoom ?? 0;
    this.sqft = data.sqft ?? 0;
    this.contact = data.contact ?? '';
    this.hall = data.hall ?? 0;
    this.kitchen = data.kitchen ?? 0;
    this.parking = data.parking ?? null;
    this.name = data.name ?? '';
    this.residentialUnits = data.residentialUnits ?? null;
    this.commercialUnits = data.commercialUnits ?? null;
    this.propertyUnitID = data.propertyUnitID ?? 0;
    this.numberofFloors = data.numberofFloors ?? null;
    this.floorNumber = data.floorNumber ?? null;
    this.builtYear = data.builtYear ?? 0;
    this.imageString = data.imageString ?? [];
    this.totalImages = data.totalImages ?? 0;
    this.propertyMasterDescription = data.propertyMasterDescription ?? '';
    this.propertySubTypeName = data.propertySubTypeName ?? '';
    this.vacant = data.vacant ?? 0;
    this.occupied = data.occupied ?? 0;
    this.totalUnit = data.totalUnit ?? 0;
  }

  
}

