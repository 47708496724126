import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react'
import { Form } from 'react-bootstrap';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const AddBill = ({ 
    addBillForm, 
    handleChangeBillForm, 
    handleAddBill, 
    billvalidated, 
    utilitydata, 
    handleCloseBillToast  
}) => {
    return (
        <Form className='pt-4' noValidate validated={billvalidated} onSubmit={(event) => handleAddBill(event)}>
            <div className="row">
                <div className="col-md-12">
                    {/* Bill Type */}
                    <div className="form-wrap">
                        <label className="col-form-label">Bill Type</label>
                        <select
                            className="form-select negotiate-form"
                            name="utilityTypeID"
                            value={addBillForm?.utilityTypeID}
                            onChange={handleChangeBillForm}
                        >
                            {utilitydata?.map((utility) => (
                                <option key={utility.utilityTypes} value={utility.utilityTypes}>
                                    {utility.desc}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Meter ID */}
                    <div className="form-wrap">
                        <label className="col-form-label">Meter ID</label>
                        <input
                            className="form-control negotiate-form"
                            name="meterID"
                            placeholder="Enter Meter ID"
                            value={addBillForm?.meterID}
                            onChange={handleChangeBillForm}
                            required
                        />
                        <div className="invalid-feedback">Meter ID is required.</div>
                    </div>

                    {/* Meter Reading */}
                    <div className="form-wrap">
                        <label className="col-form-label">Meter Reading</label>
                        <input
                            className="form-control negotiate-form"
                            name="meterReading"
                            placeholder="Enter Meter Reading"
                            value={addBillForm?.meterReading}
                            onChange={handleChangeBillForm}
                            required
                            type="number"
                            min={0}
                        />
                        <div className="invalid-feedback">Meter reading is required and must be a positive number.</div>
                    </div>

                    {/* Meter Reading Date */}
                    <div className="form-wrap">
                        <label className="col-form-label">Meter Reading Date</label>
                        <div className="form-control negotiate-form duedate-form">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    defaultValue={dayjs()}
                                    value={
                                        addBillForm?.meterReadingDate
                                            ? dayjs(addBillForm?.meterReadingDate)
                                            : null
                                    }
                                    onChange={(date) =>
                                        handleChangeBillForm({
                                            target: {
                                                name: "meterReadingDate",
                                                value: date ? date.toISOString() : "",
                                            },
                                        })
                                    }
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    {/* Outstanding Amount */}
                    <div className="form-wrap">
                        <label className="col-form-label">Outstanding Amount (OMR)</label>
                        <input
                            className="form-control negotiate-form"
                            name="outstandingAmount"
                            placeholder="Enter Outstanding Amount"
                            value={addBillForm?.outstandingAmount}
                            onChange={handleChangeBillForm}
                            required
                            type="number"
                            min={1}
                        />
                        <div className="invalid-feedback">Outstanding amount is required and must be greater than 0.</div>
                    </div>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="d-flex justify-content-end gap-2 pb-4 pt-3">
                <button
                    type="button"
                    className="btn addfeature-btn"
                    onClick={ handleCloseBillToast }
                >
                    Cancel
                </button>
                <button type="submit" className="btn btn-danger">
                    Save
                </button>
            </div>
        </Form>
    );
}

export default AddBill