
export interface IPropertyRentStatus  {
  tenantID: number;
  tenantName: string;
  agreementID: number;
  totalPending: number;
  rownumberId: number;
  aggStartDate: string; // or Date if working with Date objects
  unitID: number;
  totalCount: number | null;
  phoneNumber: string;
  tag: string;
  isSelected: boolean;
  }
  export class PropertyRentStatus implements IPropertyRentStatus {
    tenantID: number;
    tenantName: string;
    agreementID: number;
    totalPending: number;
    rownumberId: number;
    aggStartDate: string;
    unitID: number;
    totalCount: number | null;
    phoneNumber: string;
    tag: string;
    isSelected: boolean;

    constructor(data: PropertyRentStatus) {
      this.tenantID = data.tenantID ?? 0;
      this.tenantName = data.tenantName ?? '';
      this.agreementID = data.agreementID ?? 0;
      this.totalPending = data.totalPending ?? 0;
      this.rownumberId = data.rownumberId ?? 0;
      this.aggStartDate = data.aggStartDate ?? '';
      this.unitID = data.unitID ?? 0;
      this.totalCount = data.totalCount ?? null;
      this.phoneNumber = data.phoneNumber ?? '';
      this.tag = data.tag ?? '';
      this.isSelected = data.isSelected ?? false;

    }
  }
  