

const CustomLoading = () => {
    return (

        <div className="d-flex align-items-center justify-content-center">
            <div className="loading-spinners">
            </div>
        </div>

    )
}

export default CustomLoading