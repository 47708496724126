import React, { useEffect, useState } from 'react'
import "../../../style/scss/awaitingApprovalPopup.scss";
import CustomLoading from '../CustomLoading';
import { useGetTenancyRequestDetailQuery, useUpdateRequestAgreementMutation, usePutRequestAgreementMutation, useGetRequestAgreementQuery, } from '../../../redux/CommonApi';
import { useDispatch, useSelector } from 'react-redux';
import { NegotiationTypeEnum, RequestTypeEnum, unitStatusEnum } from '../../../redux/RequestTypeEnum';
import toastr from "toastr";
import dayjs from "dayjs";
import { Toast } from "react-bootstrap";
import { setAggrementId } from '../../../redux/Commonslice';
export const AwaitingApprovalPopup = ({ handleReopenClose, TenenacyRequestDetail, setProceedAgg, setTenenacyRequestDetail, setgoback }) => {
    const agreementId = useSelector((state: any) => state.CRMS.aggrementId) ?? null;
    const userId = sessionStorage.getItem("userId");
    const dispatch = useDispatch();
    const { data: GetRequestData, isLoading: isRequestDataLoading } = useGetRequestAgreementQuery({ id: TenenacyRequestDetail },{skip:TenenacyRequestDetail === null});

    const {
        data: tenancyRequestDetail,
        error,
        isLoading,
    } = useGetTenancyRequestDetailQuery({
        requestAgID: TenenacyRequestDetail,
    }, {skip: TenenacyRequestDetail === null});
    console.log("data", tenancyRequestDetail)
    const [updateRequestAgreement] = usePutRequestAgreementMutation();
    const [approveReq, setApproveReq] = useState({
        // reqAgID: TenenacyRequestDetail,
        // requestTypeID: tenancyRequestDetail?.requestTypeID,
        // isApprovedByTenant: true,
        // isApprovedByLandLord: true,
        feedback: "",
        proposedRent: tenancyRequestDetail?.proposeRent,
        // status: 0
    });
    useEffect(() => {
        if (tenancyRequestDetail) {
          setApproveReq((prev) => ({
            ...prev,
            proposedRent: tenancyRequestDetail.proposeRent || 0, // Ensure a fallback value
          }));
        }
      }, [tenancyRequestDetail]); // Trigger only when tenancyRequestDetail changes
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setApproveReq((prevValues) => ({
            ...prevValues,
            [name]: name === 'proposedRent' ? Number(value) : value,
        }));
    };

    const handleApprove = async (statusId) => {
        let updatedData;
        let fullFeedback = '';
        if (tenancyRequestDetail.feedback) {
            fullFeedback = `${tenancyRequestDetail.feedback}, Landlord: ${approveReq.feedback}`;
        } else {
            fullFeedback = `Landlord: ${approveReq.feedback}`;
        }
        if (statusId === 1) {

            updatedData = {
                ...GetRequestData,
                // reqAgID: TenenacyRequestDetail,
                // requestTypeID:(approveReq.proposedRent===null || approveReq.proposedRent==="" )? tenancyRequestDetail.requestTypeID:approveReq.proposedRent,
                proposeRent: tenancyRequestDetail.proposeRent,
                isApprovedByTenant: true,
                isApprovedByLandLord: true,
                // status: unitStatusEnum.Approved,
                status: ((tenancyRequestDetail.requestTypeID == NegotiationTypeEnum.LandlordRequest) && tenancyRequestDetail.isApprovedByTenant) ? unitStatusEnum.Approved : tenancyRequestDetail.status,
            };
        } else if (statusId === 2) {
            updatedData = {
                ...GetRequestData,
                proposeRent: tenancyRequestDetail.proposeRent,
                isApprovedByTenant: false,
                isApprovedByLandLord: false,
                status: unitStatusEnum.Rejected,
                feedback: fullFeedback,
            };

        } else if (statusId === 3) {
            updatedData = {
                ...GetRequestData,
                status: 8,
                proposeRent: approveReq.proposedRent === null || approveReq.proposedRent === "" ? tenancyRequestDetail.proposeRent : approveReq.proposedRent,
                feedback: fullFeedback,
                isApprovedByTenant: false,
                isApprovedByLandLord: true,
            };
        }

        // Update the approveReq state
        setApproveReq(updatedData);

        try {
            // Send the updated data to the API via the PUT call
            const result = await updateRequestAgreement({
                Agid: TenenacyRequestDetail,
                updatedData,
            }).unwrap();
            if (statusId === 1) {
                toastr.success("Request approved successfully.");
                window.location.reload();
            }
            else if (statusId === 2) {
                toastr.success("Request Rejected successfully.");
                window.location.reload();
            }
            else if (statusId === 3) {
                toastr.success("Request has been successfully processed with conditional approval.");
                window.location.reload();
            }
            // window.location.reload(); 
        } catch (error) {
            toastr.error("Failed to send approval request", error);
            return; // Stop execution if there's an error
        }
    };

    // const handleApprove = (statusId) => {
    //   // Update the approveReq state with new values
    //   if (statusId === 1) {
    //     setApproveReq((prevApproveReq) => ({
    //       ...prevApproveReq,
    //       proposedRent: tenancyRequestDetail.proposeRent,
    //       isApprovedByTenant: tenancyRequestDetail.isApprovedByTenant,
    //       isApprovedByLandLord: true,
    //       status: tenancyRequestDetail.status,
    //     }));
    //   }
    //   else if (statusId === 2) {
    //     setApproveReq((prevApproveReq) => ({
    //       ...prevApproveReq,
    //       proposedRent: tenancyRequestDetail.proposeRent,
    //       isApprovedByTenant: tenancyRequestDetail.isApprovedByTenant,
    //       isApprovedByLandLord: false,
    //       status: unitStatusEnum.REJECTED,
    //     }));
    //   }
    //   else if (statusId === 3) {
    //     setApproveReq((prevApproveReq) => ({
    //       ...prevApproveReq,
    //       isApprovedByTenant: false,
    //       isApprovedByLandLord: true,
    //       status: tenancyRequestDetail.status,
    //     }));
    //   }
    //   try {
    //     const result = await updateRequestAgreement({
    //       Agid: agreementId,
    //       updatedData: approveReq,
    //     }).unwrap();
    //     toastr.success('Approval request sent successfully,');
    // } catch (error) {
    //   toastr.error('Failed to send approval request', error);
    //     return; // Stop execution if there's an error
    // }

    // };

    useEffect(() => {
        // Scroll to the top of the page when this component is rendered
        window.scrollTo(0, 0);
    }, []);
    const [showMiddleCenterToast, setShowMiddleCenterToast] = useState(false);
    const [showRejectToast, setShowRejectToast] = useState(false);
    const handleMiddleCenterButtonClick = () => {
        setShowRejectToast(false);
        setShowMiddleCenterToast(true);
    };

    const handleMiddleCenterToastClose = () => {
        setShowMiddleCenterToast(false);
    };
    const handleRejection = () => {
        setShowRejectToast(true);
        setShowMiddleCenterToast(false);

    };

    const handleRejectionClose = () => {
        setShowRejectToast(false);
    };
    const handleProceedAgg = () => {

        dispatch(setAggrementId(tenancyRequestDetail?.reqAgID))
        setTenenacyRequestDetail(tenancyRequestDetail?.reqAgID);
        setProceedAgg(24);
        handleReopenClose()
    };
    const handleBackClick = () => {
        setgoback(false);
        setTenenacyRequestDetail(null);
    };

    useEffect(() => {
        // Scroll to the top of the page when this component is rendered
        window.scrollTo(0, 0);
    }, []);
    if (isLoading) return <CustomLoading />;
    const redirectToWhatsApp = (contact) => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const phoneNumber = contact;
        const message = `Hi, I am interested in your property on Mumtalikati. Link:https://www.mumtalikati.com${window.location.pathname}`;
        return isMobile
            ? `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`
            : `https://wa.me/?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    };
    return (
        <div className="sidebar-layout w-25 w-sm-100">
            <div className="container">
                <div className="">
                    <button
                        style={{ background: "transparent", transform: "rotate(268deg)", fontSize: 'larger' }}
                        className="sidebar-close toggle-btn text-end bg-none border-0 icon-container"
                        onClick={handleReopenClose}
                    >
                        <i className="ti ti-chevrons-up" />
                    </button>
                    <span className='AwaitingApprovalPopup-text page-title'>Awaiting Approval</span>
                </div>
                <div className='d-flex justify-content-center align-items-center pt-2'>
                    <img
                        src={tenancyRequestDetail?.userImage || 'https://d2og5lryw1ajtt.cloudfront.net/img/landlord-bydefault.png'}
                        className="no-gap AwaitingApprovalPopup-img"

                        alt="Profile"
                    />
                </div>
                <div className='d-flex justify-content-center align-items-center pt-2 pb-2 gap-2'>
                    <div className='AwaitingApprovalPopup-circle'>
                        <a href={`tel:${tenancyRequestDetail?.contact}`}>
                            <i className="fa fa-phone trin-trin" aria-hidden="true"></i>
                        </a>

                    </div>
                    <div className='AwaitingApprovalPopup-circle '>
                        <a href={redirectToWhatsApp(tenancyRequestDetail?.contact)}>
                            <i className="fas fa-comment-dots trin-scale" aria-hidden="true"></i>
                        </a>

                    </div>
                </div>
                <h6 className='page-title AwaitingApprovalPopup-h6 px-3 pb-2'>Unit Details</h6>
                {tenancyRequestDetail?.buildingType && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Type </p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{tenancyRequestDetail.buildingType} </p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.buildingNumber && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Building Id </p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{tenancyRequestDetail.buildingNumber} </p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.buildingName && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Building Name </p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{tenancyRequestDetail?.buildingName} </p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.rentalUnitID && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Unit Id</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{tenancyRequestDetail?.rentalUnitID} </p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.floorNumber && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Floor No</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{tenancyRequestDetail?.floorNumber} </p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.unitCategoryName && (
                    <div className='px-3 pt-2 pb-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Unit Category</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{tenancyRequestDetail?.unitCategoryName} </p>
                            </div>
                        </div>
                    </div>
                )}
                <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Personal Legal Information</h6>
                {tenancyRequestDetail?.name && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Name</p>
                            </div>

                            <div className='col-sm-6 text-end '>
                                <p className='page-title '> {tenancyRequestDetail?.name}
                                </p>

                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.passPort && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Passport</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p>{tenancyRequestDetail?.passPort ? (<> <i
                                    className="fas fa-check"
                                    style={{
                                        background:
                                            "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        fontSize: "15px",
                                    }}
                                /></>) : (<i className="fa fa-close page-title"></i>)} </p>
                            </div>
                        </div>
                    </div>
                )}

                {tenancyRequestDetail?.drivingLicense && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Driving License</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p>{tenancyRequestDetail?.drivingLicense ? (<> <i
                                    className="fas fa-check"
                                    style={{
                                        background:
                                            "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        fontSize: "15px",
                                    }}
                                /></>) : (<i className="fa fa-close page-title"></i>)} </p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.gender && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Gender</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p> {tenancyRequestDetail?.gender} </p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.dob && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>DOB</p>
                            </div>
                            <div className='col-sm-6 text-end '>

                                <p className='page-title'>{dayjs(tenancyRequestDetail?.dob).format("DD-MM-YYYY")}</p>
                            </div>
                        </div>
                    </div>
                )}
                {tenancyRequestDetail?.nationality && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Country</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p> {tenancyRequestDetail?.nationality} </p>
                            </div>
                        </div>
                    </div>
                )}


                {tenancyRequestDetail?.nationalCard && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>National ID</p>
                            </div>
                            <div className='col-sm-6 text-end '>
                                <p>{tenancyRequestDetail?.nationalCard ? (<> <i
                                    className="fas fa-check"
                                    style={{
                                        background:
                                            "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        fontSize: "15px",
                                    }}
                                /></>) : (<i className="fa fa-close page-title"></i>)} </p>
                            </div>
                        </div>
                    </div>
                )}


                <div className='px-3 pt-2 pb-2'>
                    <div className=' AwaitingApprovalPopup-outline'>
                        <div className='col-sm-6 '>
                            <p></p>
                        </div>

                        <div className='col-sm-6 text-end '>
                            <p className='page-title '> Other
                            </p>

                        </div>
                    </div>
                </div>
                <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Rent & Agreement Details</h6>
                {tenancyRequestDetail?.rentAmount && (
                    <div className='px-3 pt-2'>
                        <div className='AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title'>Unit Listed Rent</p>
                            </div>

                            <div className='col-sm-6 text-end'>
                                <p className='page-title'> {tenancyRequestDetail?.rentAmount}
                                </p>

                            </div>
                        </div>
                    </div>)}
                {tenancyRequestDetail?.rentFrequency && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Rent Frequency</p>
                            </div>

                            <div className='col-sm-6 text-end '>
                                <p className='page-title '> {tenancyRequestDetail?.rentFrequency}
                                </p>

                            </div>
                        </div>
                    </div>)}
                {tenancyRequestDetail?.rentalMode && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Rent Mode</p>
                            </div>

                            <div className='col-sm-6 text-end '>
                                <p className='page-title '> {tenancyRequestDetail?.rentalMode}
                                </p>

                            </div>
                        </div>
                    </div>)}
                {tenancyRequestDetail?.terms && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Terms</p>
                            </div>

                            <div className='col-sm-6 text-end '>
                                <p className='page-title '> {tenancyRequestDetail?.terms}
                                </p>

                            </div>
                        </div>
                    </div>)}
                {tenancyRequestDetail?.aggStartDate && (
                    <div className='px-3 pt-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Agreement Start Date</p>
                            </div>

                            <div className='col-sm-6 text-end '>

                                <p className='page-title '>{dayjs(tenancyRequestDetail?.aggStartDate).format("DD-MM-YYYY")}</p>
                            </div>
                        </div>
                    </div>)}
                {tenancyRequestDetail?.aggEndDate && (
                    <div className='px-3 pt-2 pb-2'>
                        <div className=' AwaitingApprovalPopup-outline'>
                            <div className='col-sm-6 '>
                                <p className='page-title '>Agreement End Date </p>
                            </div>

                            <div className='col-sm-6 text-end '>
                                <p className='page-title '>{dayjs(tenancyRequestDetail?.aggEndDate).format("DD-MM-YYYY")}</p>


                            </div>
                        </div>
                    </div>)}
                {tenancyRequestDetail?.feedback && (<>
                    <h6 className='page-title AwaitingApprovalPopup-h6 py-2 px-3'>Feedback</h6>
                    <div className="px-3 pt-2">
                        <textarea
                            rows={10}
                            cols={10}
                            className="form-control form-textarea"
                            placeholder="Write about Request...."
                            name="description"
                            value={tenancyRequestDetail?.feedback}
                        // onChange={handleChange}
                        />
                    </div>
                </>

                )}
                <div className='pb-4'>
                    {!tenancyRequestDetail?.isApprovedByLandLord && (
                        <div className="justify-content-center d-flex agreementdoc gap-2 pb-3 pt-4">
                            <button
                                type="button"
                                className="btn btn-danger btn-radius "
                                id="middlecenterToastBtn"
                                onClick={handleRejection}
                            >REJECT
                            </button>

                            <button
                                type="button"
                                className="btn  addfeature-btn"
                                onClick={handleMiddleCenterButtonClick}
                            >
                                APPROVE WITH CONDITION
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger btn-radius"
                                id="middlecenterToastBtn"
                                onClick={() => handleApprove(1)}
                            >
                                APPROVE
                            </button>
                        </div>
                    )}
                    {/* {tenancyRequestDetail?.isApprovedByLandLord && tenancyRequestDetail?.isApprovedByTenant && tenancyRequestDetail?.status === unitStatusEnum.PENDING && (
                        <div className="justify-content-end d-flex agreementdoc gap-2 pb-3 pt-4">
                            <button
                                type="button"
                                className="btn btn-danger btn-radius "
                                id="middlecenterToastBtn"
                                onClick={handleProceedAgg}
                            >Proceed Agreement
                            </button>
                        </div>
                    )} */}
                    {(
                        (
                            tenancyRequestDetail?.status === unitStatusEnum.PENDING &&
                            tenancyRequestDetail?.isApprovedByLandLord === true &&
                            tenancyRequestDetail?.isApprovedByTenant === true &&
                            (tenancyRequestDetail?.requestTypeID === 1 || tenancyRequestDetail?.requestTypeID === 3)
                        )
                    ) && (
                            <div className="justify-content-end d-flex agreementdoc gap-2 pb-3 pt-4">
                                <button
                                    type="button"
                                    className="btn btn-danger btn-radius"
                                    id="middlecenterToastBtn"
                                    onClick={handleProceedAgg}
                                >
                                    Proceed Agreement
                                </button>
                            </div>
                        )}

                </div>
                <div className="toast-container position-fixed top-50 start-50 translate-middle">
                    <Toast
                        show={showMiddleCenterToast}
                        onClose={handleMiddleCenterToastClose}
                        id="middlecenter-Toast"
                        className="colored-toast bg-white terminateToast negotiate-popup"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="d-flex align-items-center py-4 justify-content-between" >
                            <h5 className=" ">Negotiate</h5>
                            <div className="img-tag d-flex align-items-center gap-2 pb-4">
                                <i className="ti ti-x" style={{
                                    background: '#A64041',
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    backgroundClip: "text",
                                    fontSize: "30px",
                                }}
                                    onClick={handleMiddleCenterToastClose} />
                            </div>
                        </div>
                        {/* <Toast.Body><p>Are you sure you want to terminate agreement against building ID ({tenancyRequestDetail.buildingNumber}) and unit ID ({tenancyRequestDetail.aggUnitID}) </p></Toast.Body> */}
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Proposed Rent</label>
                                        <input
                                            className="form-control negotiate-form"
                                            name="proposedRent"
                                            value={approveReq.proposedRent ?? (tenancyRequestDetail?.rentAmount || '')}

                                            onChange={handleChange}
                                            placeholder="OMR 115"
                                        // value={addPropertyForm.address.plot}
                                        // onChange={handleAddressChange}
                                        // aria-describedby="number"
                                        // required
                                        // {...register("address.plot", {
                                        //   required: "plot is required ",
                                        // })}
                                        />
                                        {/* {errors.address?.plot && (
                  <p style={{ color: "orangered" }}>
                    {errors.address.plot.message}
                  </p>
                )} */}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Feedback</label>
                                        <textarea
                                            rows={5}
                                            cols={5}
                                            className="form-control tenant-textarea"
                                            placeholder="Description"
                                            name="feedback"
                                            value={approveReq.feedback}
                                            onChange={handleChange}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end gap-2 pb-4 pt-3 ">

                                <button
                                    type="button"
                                    className="btn  btn-danger  "
                                    onClick={() => handleApprove(3)}
                                >
                                    SEND TO TENANT
                                </button>
                            </div>
                        </form>

                    </Toast>
                </div>
                <div className="toast-container position-fixed top-50 start-50 translate-middle">
                    <Toast
                        show={showRejectToast}
                        onClose={handleRejectionClose}
                        id="middlecenter-Toast"
                        className="colored-toast bg-white terminateToast negotiate-popup"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="d-flex align-items-center py-4 justify-content-between" >

                            <div className="img-tag d-flex align-items-center justify-content-between gap-2 pb-4">
                                <h5 className=" ">Rejection Reason</h5>
                                <i className="ti ti-x" style={{
                                    background: '#A64041',
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    backgroundClip: "text",
                                    fontSize: "25px",
                                }}
                                    onClick={handleRejectionClose} />
                            </div>
                        </div>
                        {/* <Toast.Body><p>Are you sure you want to terminate agreement against building ID ({tenancyRequestDetail.buildingNumber}) and unit ID ({tenancyRequestDetail.aggUnitID}) </p></Toast.Body> */}
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Reason of Rejection</label>
                                        <textarea
                                            rows={5}
                                            cols={5}
                                            className="form-control tenant-textarea"
                                            placeholder="Description"
                                            name="feedback"
                                            value={approveReq.feedback}
                                            onChange={handleChange}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end gap-2 pb-4 pt-3 ">

                                <button
                                    type="button"
                                    className="btn  btn-danger  "
                                    onClick={() => handleApprove(2)}
                                >
                                    SEND TO TENANT
                                </button>
                            </div>
                        </form>

                    </Toast>
                </div>
            </div>
        </div>
    )
}
