import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useGetIncomeOverviewQuery } from "../../../redux/CommonApi";
const RentChart = () => {
  const landlordId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetIncomeOverviewQuery(landlordId);
  const options: ApexOptions = {
    series: [
      {
        data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14],
      },
    ],
    colors: ["#F72328"],
    chart: {
      type: "line",
      width: 100,
      height: 35,
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };
  return (
    <div className="card cardreq ">
      <div className="card-header d-flex justify-content-between">
        <h6 className="card-title">Rent Collection</h6>
      </div>
      <div className="card-body">
        <div className="position-relative text-avatar row RentCollection">
          <div className="col-md-6 col-sm-6 text-center">
            <div>
              <h1 className="chart-g">-{data.netRentalIncomePercentage}%</h1>
            </div>
            <div className="pt-2">
              <p>Last Updated 2 Min ago</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 text-center">
            <div id="chart-1">
              <Chart
                options={options}
                series={options.series}
                type="line"
                height={35}
                width={100}
              />
            </div>
            <div>
              <h4>Monthly Trends</h4>
              <p>( Last 6 Months )</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentChart;
