
export interface IExpenseDetail {
  updatedBy: string;
  updatedTime: string;
  feedback: string | null;
  iconUrl: string;
  category: string;
  subCategory: string;
  expenseDetail: string;
  amount: number;
  expenseDate: string;
  buildingID: string;
  unitID: number;
  caption: string | null;
  status: number;
  receiptNo: number;
}

export class ExpenseDetail implements IExpenseDetail {
  updatedBy: string;
  updatedTime: string;
  feedback: string | null;
  iconUrl: string;
  category: string;
  subCategory: string;
  expenseDetail: string;
  amount: number;
  expenseDate: string;
  buildingID: string;
  unitID: number;
  caption: string | null;
  status: number;
  receiptNo: number;

  constructor(data: IExpenseDetail) {
    this.updatedBy = data.updatedBy ?? "";
    this.updatedTime = data.updatedTime ?? "";
    this.feedback = data.feedback ?? null;
    this.iconUrl = data.iconUrl ?? "";
    this.category = data.category ?? "";
    this.subCategory = data.subCategory ?? "";
    this.expenseDetail = data.expenseDetail ?? "";
    this.amount = data.amount ?? 0;
    this.expenseDate = data.expenseDate ?? "";
    this.buildingID = data.buildingID ?? "";
    this.unitID = data.unitID ?? 0;
    this.caption = data.caption ?? null;
    this.status = data.status ?? 0;
    this.receiptNo = data.receiptNo ?? 0;
  }
}
