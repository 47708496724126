import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import DonutChart from "./donut";
import { useGetIncomeOverviewQuery } from "../../../redux/CommonApi";
import Staffchart from "./Staffchart";
import TenantChart from "./TenantChart";
import MaintenanaceReq from "./MaintenanaceReq";
import ExpiringAgreeChart from "./ExpiringAgreeChart";
import OcuupancyChart from "./OcuupancyChart";
import RentChart from "./RentChart";
import Ocuupied from "./PropertyChart";

const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat("en-OM", {
    style: "currency",
    currency: "OMR",
    minimumFractionDigits: 1, // Ensures one decimal place
    maximumFractionDigits: 1, // Ensures no extra decimals
  });
  return formatter.format(value);
};
const Incomeoverview = () => {
  const landlordId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetIncomeOverviewQuery(landlordId);

  if (isLoading) {
    return (
      <>
        <div className=" d-flex align-items-center justify-content-centerspinner-border "></div>
      </>
    );
  }
  if (error) return <div>Error loading data</div>;

  const pMSColumnCharts = data.pMSColumnCharts || [];


  const categories = pMSColumnCharts.map((chart) => chart.rentMonthName);
  const incomeData = pMSColumnCharts.map((chart) => chart.income);
  const expenseData = pMSColumnCharts.map((chart) => chart.expense);
  const currentMonthIncome = data.incomeOfCurrentMonth || 0;
  const currentMonthExpense = data.expenseOfCurrentMonth || 0;
  const iconMap = {
    Income:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg",
    Expense:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Expense.svg",
  };
  var sCol: ApexOptions = {
    chart: {
      height: 250,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35%",
        borderRadius: 2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#45a695", "#ef5e5f"],
    series: [
      {
        name: "Income",
        data: incomeData,
      },
      {
        name: "Expense",
        data: expenseData,
      },
    ],
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 0,
      max: Math.max(...incomeData, ...expenseData) + 10, // Adjust the max value as needed
      tickAmount: 4,
      labels: {
        formatter: function (val) {
          return formatCurrency(val); // Format y-axis labels as OMR
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return formatCurrency(val);
        },
      },
    },
  };
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="card cardreq">
            <div className="card-header">
              <h5 className="card-title">Income Overview</h5>
              {/* <p className='pt-2'>Show Income overview 01 July 2023 to 01 July 2024</p> */}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-8 col-lg-12">
                  <div id="s-col" />
                  <ReactApexChart
                    options={sCol}
                    series={sCol.series}
                    type="bar"
                    height={250}
                  />
                </div>
                <div className="col-xl-4 col-lg-12">
                  <div className="">
                    <div className="campaign-box bg-grey-light ">
                      <div className="campaign-img">
                        <span>
                          <img src="https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg" />
                        </span>
                      </div>
                      <div>
                        <h4 className="incomechart">
                          {formatCurrency(currentMonthIncome)}
                        </h4>
                      </div>
                    </div>
                    {/* <div className="campaign-box bg-grey-light">
                                            <div className='px-3'>
                                                <h4 className='expensechart'>{formatCurrency(currentMonthExpense)}</h4>
                                                <p className='pt-3'>
                                                    Money in <span className='chart-r'> <i className="ti ti-arrow-down chart-r " />
                                                    4.2%</span>
                                                </p>
                                            </div>
                                        </div> */}
                    <div className="campaign-box bg-red-exp ">
                      <div className="campaign-img">
                        <span>
                          <img src="https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Expense.svg" />
                        </span>
                      </div>
                      <div>
                        <h4 className="expensechart">
                          {formatCurrency(currentMonthExpense)}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          {/* <DonutChart />
           */}
          <Ocuupied />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <OcuupancyChart />
        </div>
        <div className="col-md-6">
          <RentChart />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Staffchart />
        </div>
        <div className="col-md-6">
          <TenantChart />
          {/* <StaffStatus /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <MaintenanaceReq />
        </div>
        <div className="col-md-6">
          <ExpiringAgreeChart />
        </div>
      
      </div>
    </>
  );
};

export default Incomeoverview;
