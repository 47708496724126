
export interface IBuildingExpense {
  propertyMasterID: number;
  totalBuildingExpense: number;
  raiseAmount: number;
  raisePercentage: number;
  raisePercentageDisplay: string;
  indicator: string;
  imageString: string | null;
  builidngID: string;
  propertyMasterName: string;
}
export class BuildingExpense implements IBuildingExpense {
  propertyMasterID: number;
  totalBuildingExpense: number;
  raiseAmount: number;
  raisePercentage: number;
  raisePercentageDisplay: string;
  indicator: string;
  imageString: string | null;
  builidngID: string;
  propertyMasterName: string;
  constructor(data: IBuildingExpense) {
    this.propertyMasterID = data.propertyMasterID ?? 0;
    this.totalBuildingExpense = data.totalBuildingExpense ?? 0;
    this.raiseAmount = data.raiseAmount ?? 0;
    this.raisePercentage = data.raisePercentage ?? 0;
    this.raisePercentageDisplay = data.raisePercentageDisplay ?? "--";
    this.indicator = data.indicator ?? "pos";
    this.imageString = data.imageString ?? null;
    this.builidngID = data.builidngID ?? "";
    this.propertyMasterName = data.propertyMasterName ?? "";
  }
}
