import React, { useState } from "react";
import IterateUplaod from "./MultipleImageUpload";
import MultipleImageUpload from "./MultipleImageUpload";
import { FaChevronUp, FaChevronDown } from "react-icons/fa"; 

const Chaveron = ({ onIncrement, onDecrement }) => {

 
  
  return (
    <div 
    style={{
      position: "absolute",
      right: "25px", 
      top: "70%",
      transform: "translateY(-50%)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <i className="fa fa-caret-up fafaicon" onClick={onIncrement}  style={{ cursor: "pointer",fontSize:"15px" }}></i>
    <i className="fa fa-caret-down fafaicon" onClick={onDecrement} style={{ cursor: "pointer",fontSize:"15px" }}></i>
  </div>
  );
};
export default Chaveron;
