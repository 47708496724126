import React from "react";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { unitStatusEnum } from "../../redux/RequestTypeEnum";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import VerticalStepper from "./VerticalStepper";

const steps = [
  { label: "", iconClass: "fa fa-download" },
  { label: "", iconClass: "fa fa-bookmark-o" },
  { label: "", iconClass: "fa fa-spinner" },
];


const IconStepper = ({ dashboardresponse, dashboardresponse1 ,circlecolor}) => {
  

  const getStatusName = (status) => {
    if(dashboardresponse?.length > 2){
    const statusNames = Object.keys(unitStatusEnum).find(
      (key) => unitStatusEnum[key] === status
    );
    return statusNames ? statusNames.replace("_", " ") : "";}
    else {
      return "";
    }
  };
  

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const Createditem =
    dashboardresponse?.find(
      (item) =>
        item.status === unitStatusEnum.Create ||
        item.status === unitStatusEnum.PENDING
    ) || {};
  const AssignedItem =
    dashboardresponse?.find(
      (item) =>
        (item.status === unitStatusEnum.Assign && dashboardresponse?.length !<= 2) ||
        item.status === unitStatusEnum.Approved
    ) || {};
  const getItemByStatus = (dashboardresponse, status) => {
    
    return dashboardresponse?.find((item) => item.status === status) || {};
  };
  const InProgressItem = 
  dashboardresponse1?.status !== unitStatusEnum.PENDING 
    ? getItemByStatus(dashboardresponse, dashboardresponse1?.status)
    : {};

  // const InProgressItem = getItemByStatus( dashboardresponse, dashboardresponse1?.status !== unitStatusEnum.PENDING);

  const MaintenanceCreateddate = Createditem.date || "";
  const MiantenanceAssignedDate = AssignedItem.date || "";
  const InProgressDate = InProgressItem.date || "";

  const createdate = MaintenanceCreateddate
    ? dayjs(MaintenanceCreateddate).format("DD/MM/YYYY")
    : "";
  const Accepteddate = MiantenanceAssignedDate
    ? dayjs(MiantenanceAssignedDate).format("DD/MM/YYYY")
    : "";
  const InProgressFormattedDate = InProgressDate
    ? dayjs(InProgressDate).format("DD/MM/YYYY")
    : "";

  const updatedSteps = steps.map((step, index) => {
    if (index === 0) {
      return { ...step, label: createdate };
    } else if (index === 1) {
      return {
        ...step,
        label:
          (dashboardresponse1?.status === unitStatusEnum.Assign && dashboardresponse?.length !<= 2) || (dashboardresponse1?.status === unitStatusEnum.Approved && dashboardresponse?.length !<= 2)
            ? Accepteddate
            : "",
      };
    } 
    else if (  index === 2 && dashboardresponse1?.status !== unitStatusEnum.Create ) 
      {
        if(dashboardresponse?.length > 2){
        return { ...step, label: ` ${InProgressFormattedDate}` };}
        else {
          return { ...step, label:  "" }
        }
      }
    return step;
  });
  const handleSecondStepClick = () => {
    
    if (
      dashboardresponse1?.status !== unitStatusEnum.Create &&
      dashboardresponse1?.status !== unitStatusEnum.PENDING &&
      (dashboardresponse1?.status !== unitStatusEnum.Assign || dashboardresponse?.length > 2) &&

      (dashboardresponse1?.status !== unitStatusEnum.Approved  || dashboardresponse?.length > 2)
    ) {
      setShow(true);
    }
  };

  const renderStepIcon = (index) => {
    if (
      index === 1 &&
     ( dashboardresponse1?.status !== unitStatusEnum.Assign  || dashboardresponse?.length > 2)&&
      dashboardresponse1?.status !== unitStatusEnum.Create &&
      dashboardresponse1?.status !== unitStatusEnum.PENDING &&
      (dashboardresponse1?.status !== unitStatusEnum.Approved || dashboardresponse?.length > 2) 
      // &&
      // dashboardresponse1?.status !== unitStatusEnum.Rejected 
    ) {
      return (
        <button
          style={{
            backgroundColor: "#345C66",
            borderRadius: "5px",
            color: "white",
            fontSize: "12px",
            zIndex: "12",
          }}
        >
          View All
        </button>
      );
    }

    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "23px",
          height: "23px",
          borderRadius: "50%",
          backgroundColor: dashboardresponse1?.status === unitStatusEnum.Rejected && index === 2 ? circlecolor.backgroundColor : "#345C66"
      
        }}
      >
        <i
          className={steps[index].iconClass}
          style={{ fontSize: "12px", color: "white" }}
        />
      </span>
    );
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {updatedSteps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={() => renderStepIcon(index)}
                onClick={index === 1 ? handleSecondStepClick : undefined}
              >
                {index === 0 && createdate && (
                  <Typography
                    variant="caption"
                    style={{
                      display: "block",
                      color: "#434242",
                      fontSize: "11px",
                      fontFamily: "Noto Sans",
                    }}
                  >
                    Create Token
                  </Typography>
                )}
                {index === 1  && dashboardresponse?.length !<= 2  && (
                  <Typography
                    variant="caption"
                    style={{
                      display: "block",
                      color: "#434242",
                      fontSize: "11px",
                      fontFamily: "Noto Sans",
                    }}
                  >
                    {((dashboardresponse1?.status === unitStatusEnum.Assign && dashboardresponse?.length !<= 2) ||
                      dashboardresponse1?.status === unitStatusEnum.Approved ) &&
                    Accepteddate
                      ? "Accept Token"
                      : ""}
                  </Typography>
                )}
                {index === 2 && InProgressFormattedDate && (
                  <Typography
                    variant="caption"
                    style={{
                      display: "block",
                      color: `${dashboardresponse1?.status === unitStatusEnum.Rejected && index === 2 ? circlecolor.backgroundColor : "#434242"}`,
                      fontSize: "11px",
                      fontFamily: "Noto Sans",
                    }}
                  >
                    {
                    InProgressItem.status !== unitStatusEnum.Create &&
                    InProgressItem.status !== unitStatusEnum.PENDING 
                      ? getStatusName(InProgressItem.status)
                      : ""}
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  style={{
                    color:`${dashboardresponse1?.status === unitStatusEnum.Rejected && index === 2 ? circlecolor.backgroundColor : "#434242"}`,
                    fontSize: "11px",
                    fontFamily: "Noto Sans",
                  }}
                >
                  {step.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="modal-wrapper">
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          animation={false}
          centered
          dialogClassName="centered-modal custom-modal-bg w-md-25 w-lg-25 w-sm-100 rounded-3"
          style={{paddingRight:"1rem"}}
        >
          <div className="modal-content-wrapper rounded-3 ">
            <Modal.Title
              className="page-title"
              style={{ fontWeight: "400", color: "white" }}
            >
              Token process
            </Modal.Title>
            <Modal.Body className="text-center">
              <div className="d-flex gap-2 img-tag  pb-2">
                <VerticalStepper tokendetail={dashboardresponse} />
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-end">
              <div className="pt-2">
                <Link
                  to="#"
                  className="btn  add-popup w-100 p-2 border rounded-3"
                  onClick={handleClose}
                >
                  Got it
                </Link>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default IconStepper;
