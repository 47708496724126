import { maintenanceRequestDashboard, recentMaintenanceRequests } from "../../MaintenanceRequest/Model/maintenanceRequestDashboard";

export interface ITenantDashboard  {
  iconPath: string;
  value: string;
  count: number;
  color: string;
  id: number;
  status: number;
  }
  export class TenantDashboard  implements ITenantDashboard  {
    iconPath: string;
  value: string;
  count: number;
  color: string;
  id: number;
  status: number;
  constructor(data: ITenantDashboard ){
    this.iconPath = data.iconPath ?? '';
    this.value = data.value ?? '';
    this.count = data.count ?? 0;
    this.color = data.color ?? '';
    this.id = data.id ?? 0;
    this.status = data.status ?? 0;

  }
  
  }




