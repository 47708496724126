import React, { useState } from 'react'
import { RentCollectionTenantCards } from './RentCollectionTenantCards'
import { useLocation } from "react-router-dom";
import { useGetAgRentTransactionsbyLandLordCountQuery, useGetAgRentTransactionsbyLandLordQuery, useGetAgRentTransactionsbyPropertyMasterIDCountQuery, useGetAgRentTransactionsbyPropertyMasterIDQuery } from '../redux/CommonApi'
import { StackPagination } from '../shared/StackPagination'
import { Dropdown } from 'react-bootstrap';
import { AgreementRentTransactions } from '../feature-module/Models/RentCollection/AgRentTransactions';
export const RentCollectionTenantsdetials = () => {
  const location = useLocation();
  const { propertyMasterID } = location.state || {};
  console.log("propertyMasterIDtest", propertyMasterID)
  const userId = sessionStorage.getItem("userId");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPriority, setSelectedPriority] = useState("Latest");
  const [Option, setOption] = useState(1);

  const dropList = ['Latest', 'Oldest', 'Approved', 'Pending', 'Rejected', '30 Days', '60 Days', '90 Days'];
  const isPropertyMasterValid = propertyMasterID !== undefined && propertyMasterID !== 0;

  const { data, isLoading, error } = useGetAgRentTransactionsbyLandLordQuery(
    {
      userID: +userId,
      Option: Option,
      pageNumber: pageNumber,
    },
    { skip: isPropertyMasterValid }
  ) as { data: AgreementRentTransactions[], isLoading: boolean, error: any };

  const { data: count, isLoading: countIsLoading, error: countError } =
    useGetAgRentTransactionsbyLandLordCountQuery(
      {
        userID: +userId,
        Option: Option,
      },
      { skip: isPropertyMasterValid }
    );

  const {
    data: AgRentTransactionsbyPropertyMaster,
    isLoading: isLoadingPropertyMaster,
    error: propertyMasterError,
  } = useGetAgRentTransactionsbyPropertyMasterIDQuery(
    {
      PropertyMasterID: propertyMasterID,
      Option: Option,
      pageNumber: pageNumber,
    },
    { skip: !isPropertyMasterValid }
  ) as { data: AgreementRentTransactions[], isLoading: boolean, error: any };

  const {
    data: AgRentTransactionsbyPropertyMasterCount,
    isLoading: isLoadingPropertyMasterCount,
    error: propertyMasterCountError,
  } = useGetAgRentTransactionsbyPropertyMasterIDCountQuery(
    {
      PropertyMasterID: propertyMasterID,
      Option: Option,
    },
    { skip: !isPropertyMasterValid }
  );

  // Common variables for data and count
  const displayData = isPropertyMasterValid
    ? AgRentTransactionsbyPropertyMaster
    : data;
  const displayCount = isPropertyMasterValid
    ? AgRentTransactionsbyPropertyMasterCount
    : count;

    const isloadingRent = isPropertyMasterValid
    ? isLoadingPropertyMaster
    : isLoading;
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };


  const handlePrioritySelect = (index, feq) => {
    setOption(index + 1)
    setSelectedPriority(feq)
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className='row'>
          <div className="col-md-6">
            <h5 className="card-title">Rent Collection</h5>
          </div>
          <div className="align-items-center col-md-6 d-flex gap-2 justify-content-end text-end">
            <span>Sort By</span>
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                style={{ borderRadius: "60px" }}
              >
                {selectedPriority}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  borderRadius: "14px",
                  boxShadow: "-2px 4px 4px 0px #00000026",
                }}
              >
                <div className="profilename">
                  {dropList?.map((feq, index) => (
                    <Dropdown.Item
                      key={index}
                      className="dropdown-item"
                      onClick={() => handlePrioritySelect(index, feq)}
                    >
                      {feq}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="pt-4 pb-5">
          <div className='card px-4 py-4'>
            <RentCollectionTenantCards data={displayData} isloadingRent={isloadingRent} />
          </div>
        </div>

        { displayCount === 0 ? (<>

        </>) : (<>     <div className="row row1 bg-white fixed-bottom1" >
          <div className="d-flex justify-content-center"> <StackPagination
            tenancyRequestCount={displayCount}
            rowNumbers={10}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}

          />   </div>
        </div></>)}



      </div>
    </div>
  )
}
