import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import { authRoutes, publicRoutes } from "./Routerlink";
import PrivateRoute from "../PrivateRoute"; // Import the PrivateRoute component
import ThemeSettings from "../core/common/themeSettings";
import { StackPagination } from "../shared/StackPagination";

const ALLRoutes = () => {
  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/admin") {
      sessionStorage.clear();
    }
  }, []);
  const userID = sessionStorage.getItem("userId");

  const HeaderLayout = () => (
    <>
      <Header userID={userID} />
      <Sidebar userID={userID} />
      <Outlet />
      <ThemeSettings />
    </>
  );

  const AuthPages = () => (
    <>
      <Outlet />
    </>
  );

  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<HeaderLayout />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route> */}
        {/* Add more routes here if you have additional pages */}
        {authRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={<AuthPages />}>
            <Route path={route.path} element={route.element} key={idx} />
          </Route>
        ))}
        {/* Protecting all other routes */}
        <Route path="/" element={<PrivateRoute element={<HeaderLayout />} />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </Router>
  );
};

export default ALLRoutes;
