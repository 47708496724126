import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { useDispatch, useSelector } from 'react-redux';
export default function InteractiveCard({ imageSrc, property, col1, col2 }) {
  const isDarkTheme = useSelector((state: any) => state?.CRMS.isDarkTheme);
  return (
    <Card
      orientation="horizontal"
      sx={{
        width: '100%',
        flexWrap: 'wrap',
        transition: 'background-color 0.3s, transform 0.3s',
        // backgroundColor: !isDarkTheme ? '#2b2b2b' : col1 || '#ffffff',
        // color: !isDarkTheme ? '#ffffff' : '#000000',
        // '&:hover': {
        //   // backgroundColor: !isDarkTheme ? '#1f1f1f' : col2 || '#f0f0f0',
        //   transform: 'scale(1.02)',
        // },
        '&:hover': {
          transform: 'scale(1.02)',
          '[data-theme="dark"] &': {
            backgroundColor: '#29293A', // Darker shade for hover
            borderColor: '#555', // Hover border for dark theme
          },
        },
        // background:`linear-gradient(135deg, ${col1}, ${col2})`,
        // borderColor:"transparent",
        // [`& > *`]: {
        //   '--stack-point': '500px',
        //   minWidth:
        //     'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
        // },

        // overflow: 'auto',
        // resize: 'horizontal',
        '[data-theme="dark"] &': {
          backgroundColor: '#1E1E2F', // Dark background
          color: '#FFF', // Light text color
          borderColor: '#333', // Border color for dark theme
        },
      }}
    >
      <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
        <img
          src={imageSrc || "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361"}
          srcSet={imageSrc || "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361"}
          loading="lazy"
          alt={property.buildingNumber}
        />
      </AspectRatio>
      <CardContent sx={{
        '[data-theme="dark"] &': {
          backgroundColor: 'transparent',
        },
      }}>

        <Typography
          level="body-sm"
          // textColor="text.tertiary"
          sx={{
            fontWeight: 'lg',
            color: 'text.tertiary', // Default for light theme

            // Dark theme styles
            '[data-theme="dark"] &': {
              color: '#FFF', // White text for dark mode
            },
          }}
        >
          Type: {property.buildingType}
        </Typography>

        <Typography
          level="body-sm"
          sx={{
            fontWeight: 'lg',
            color: 'text.tertiary', // Default for light theme

            // Dark theme styles
            '[data-theme="dark"] &': {
              color: '#FFF', // White text for dark mode
            },
          }}
        >
          Building ID:  {property.buildingNumber}
        </Typography>
        <Typography
          level="body-sm"
          sx={{
            fontWeight: 'lg',
            color: 'text.tertiary', // Default for light theme

            // Dark theme styles
            '[data-theme="dark"] &': {
              color: '#FFF', // White text for dark mode
            },
          }}
        >
          Building Name: {property.buildingName}
        </Typography>
        <Typography
          level="body-sm"
          sx={{
            fontWeight: 'lg',
            color: 'text.tertiary', // Default for light theme

            // Dark theme styles
            '[data-theme="dark"] &': {
              color: '#FFF', // White text for dark mode
            },
          }}
        >
          <i
            className="ti ti-map-pin-filled page-title"
            style={{ color: "#9E2A2B" }}
          />{" "} {property.addressStr}
        </Typography>
        <Typography
          level="body-sm"
          sx={{
            fontWeight: 'lg',
            color: 'text.tertiary', // Default for light theme

            // Dark theme styles
            '[data-theme="dark"] &': {
              color: '#FFF', // White text for dark mode
            },
          }}
        >
          Total Tenants: {property.totalTenantCount}
        </Typography>
        <Typography
          level="body-sm"
          sx={{
            fontWeight: 'lg',
            color: 'text.tertiary', // Default for light theme

            // Dark theme styles
            '[data-theme="dark"] &': {
              color: '#FFF', // White text for dark mode
            },
          }}
        >
          Received Rent: {property.totalRentReceived.toFixed(1)}
        </Typography>
        <Typography
          level="body-sm"
          sx={{
            fontWeight: 'lg',
            color: 'text.tertiary', // Default for light theme

            // Dark theme styles
            '[data-theme="dark"] &': {
              color: '#FFF', // White text for dark mode
            },
          }}
        >
          Pending Rent: {property.totalpending.toFixed(1)}
        </Typography>

      </CardContent>
    </Card>

  );
}
