import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../style/scss/unitCategory.scss";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useSelector, useDispatch } from "react-redux";
import UnitCategoryCard from "./UnitCategoryCard";
import InputTable from "../MainPmsDashboard/InputTable";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import {
  usePropertyUnitCategoryTypesQuery,
  useAddPropertyMasterMutation,
  useAddPropertyUnitMutation,
  useAddPropertyFeatureMutation,
  useAddPropertyMasterImagesMutation,
  useAddPropertyCategoryImageMutation,
} from "../../../redux/CommonApi";

import { UnitCategoryEnum } from "../../../redux/RequestTypeEnum";
import {
  removeImagesByCategoryId,
  setPropertyMaster,
  setPropertyMasterFeature,
  setPropertyMasterImages,
  setPropertyMasterUniteImages,
  setPropertyMasterUnitFeature,
} from "../../../redux/Commonslice";

const UnitCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);
  const [addPropertyMaster] = useAddPropertyMasterMutation();
  const [addPropertyUnit] = useAddPropertyUnitMutation();
  const [addPropertyFeature] = useAddPropertyFeatureMutation();
  const [addPropertyMasterImages] = useAddPropertyMasterImagesMutation();
  const [addpropertcategoryImages] = useAddPropertyCategoryImageMutation();

  const PropertyMaster =
    useSelector((state: any) => state.CRMS.propertyMaster) ?? null;
  const propertyMasterFeaturedata =
    useSelector((state: any) => state.CRMS.propertyMasterFeature) ?? null;
  const propertyMasterImages =
    useSelector((state: any) => state.CRMS.propertyMasterImages) ?? null;

  const unitcategoriesImages =
    useSelector((state: any) => state.CRMS.propertyMasterUniteImages) ?? null;
  const propertyMasterUnitFeatures =
    useSelector((state: any) => state.CRMS.propertyMasterUnitFeature) ?? null;
  if (PropertyMaster == null || PropertyMaster == undefined) {
    navigate("/admin/addproperty");
  }

  const [floors, setFloors] = useState(
    PropertyMaster?.numberofFloors ? Number(PropertyMaster?.numberofFloors) : 2
  );
  const [categories, setCategories] = useState(
    PropertyMaster?.unitCategoryID ? Number(PropertyMaster?.unitCategoryID) : 3
  );
  // const [residentialunits, setresidentialunits] = useState(
  //   PropertyMaster?.residentialUnits
  //     ? Number(PropertyMaster?.residentialUnits) + Number(PropertyMaster?.commercialUnits)
  //     : 0
  // );
  const [residentialunits, setresidentialunits] = useState(
    (Number(PropertyMaster?.residentialUnits ?? 0) + 
     Number(PropertyMaster?.commercialUnits ?? 0))
  );
  const [totalUnits, setTotalUnits] = useState(0);
  const [formData, setFormData] = useState([]);
  const [units, setUnits] = useState([]);
  const { data = [], error, isLoading } = usePropertyUnitCategoryTypesQuery({});
  let filterdata;
  if (PropertyMaster && PropertyMaster.propertyMasterTypeID == 1) {
    filterdata = data.filter((item) =>
      [1, 2, 3, 4, 5, 13].includes(item.unitCategory)
    );
  } else if (PropertyMaster && PropertyMaster.propertyMasterTypeID == 2) {
    filterdata = data.filter((item) => [6, 7, 8].includes(item.unitCategory));
  } else if (PropertyMaster && PropertyMaster.propertyMasterTypeID == 3) {
    filterdata = data.filter((item) =>
      [1, 2, 3, 4, 5, 6, 7, 8, 13].includes(item.unitCategory)
    );
  }
  if (filterdata && filterdata.length > 0) {
    filterdata.sort((a, b) => {
      if (a.unitCategory === 13) return -1; // Move items with unitCategory 13 to the start
      if (b.unitCategory === 13) return 1;
      return 0; // Maintain order for other items
    });
  }

 
  const availableCategories = Object.keys(UnitCategoryEnum);


  const [selectedCategories, setSelectedCategories] = useState(
    filterdata && filterdata.length > 0
      ? (PropertyMaster.propertyMasterTypeID === 2
          ? [...filterdata].reverse() // Reverse a copy of the array if condition is met
          : filterdata
        ) // Otherwise, use the original order
          .map((item) => {
            const categoryName = Object.keys(UnitCategoryEnum).find(
              (key) => UnitCategoryEnum[key] === item.unitCategory
            );
            return {
              id: item.unitCategory,
              name: categoryName,
            };
          })
      : []
  );

  const [floorLabels, setFloorLabels] = useState(
    Array.from({ length: floors }, (_, i) =>
      i === 0 ? "Ground Floor" : `Floor ${i}`
    )
  );
  const categoryLabels = selectedCategories.map((category) => category.name);
  const options = data.map((role) => ({
    value: role.id,
    label: role.desc,
  }));

  useEffect(() => {
    // Initialize form data and errors when the number of categories changes
    setFormData(
      Array.from({ length: categories }, (v, index) => ({
        category: index === 0 ? "Studio" : `${index}BHK`,
        bedrooms: index === 0 || index === 1 || index > 5 ? 1 : index,
        bathrooms: index === 0 || index === 1 || index > 5 ? 1 : index,
        area: 0,
        kitchen: 1,
        hall: 1,
        rent: 0,
      }))
    );
    setErrors(
      Array.from({ length: categories }, () => ({ rent: "", area: "" }))
    );
  }, [categories]);

  const handleFormChange = (index, newValues) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newValues;
      return updatedData;
    });
   
  };
  const [errors, setErrors] = useState<Array<{ rent: string; area: string }>>(
    Array.from({ length: categories }, () => ({ rent: "", area: "" }))
  );

  const hasNoZeroValues = () => {
    return units.every((floor) => floor.every((unit) => unit > 0));
  };

  const handleBlur = () => {

    setErrors(validateFormData());
  };
  const handleSubmit = async () => {
    const newErrors = validateFormData();
    setErrors(newErrors);
    setTimeout(() => {
      setErrors(Array.from({ length: categories }, () => ({ rent: "", area: "" })));
    }, 4000);

    if (newErrors.some((error) => error.rent !== "" || error.area !== "")) {
      toastr.error("Some fields are required.");
      return;
    }
  
    else if (totalUnits > residentialunits) {
      toastr.error(
        "Your Allocated units are more than" + `${residentialunits}`
      );
      return;
    } else if (totalUnits < residentialunits) {
      toastr.error(
        "Your Allocated units are less than" + `${residentialunits}`
      );
      return;
    } else if (totalUnits !== residentialunits) {
      toastr.error("Error allocating units");
      return;
    }

    // if (!hasNoZeroValues()) {
    //   toastr.error("Please Allocate units for each column");
    //   return;
    // }
    setloader(true);
    try {
      const propertyMasterId = await submitPropertyMaster();
      if (!propertyMasterId) {
        // toastr.error("Failed to submit data1.");
        return;
      }
      if (propertyMasterFeaturedata) {
        await submitPropertyFeatures(propertyMasterId);
      }

      await submitPropertyImages(propertyMasterId);

      if (unitcategoriesImages) {
        await submitUnitCategoryImages(propertyMasterId);
      }

      const validFormData = prepareUnitData(propertyMasterId);
      if (validFormData.length > 0) {
        await submitPropertyUnits(validFormData);
      } else {
        console.log("No valid data to submit!");
      }
      if (propertyMasterUnitFeatures) {
        await submitUnitCategoryFeatures(propertyMasterId);
      }
    } catch (error) {
      toastr.error("Failed to submit data");
    }

    dispatch(setPropertyMaster(null));
    dispatch(setPropertyMasterImages(null));
    dispatch(setPropertyMasterFeature(null));
    dispatch(setPropertyMasterUniteImages(null));
    dispatch(setPropertyMasterUnitFeature(null));

    toastr.success("Propert added Successfully");
    setloader(false);
    navigate("/admin/addproperty");
  };

  const validateFormData = () => {
    return formData.map((item) => {
      let rentError = "";
      let areaError = "";

      if (item.rent <= 0) {
        rentError = "Rent is required.";
      }

      if (item.area <= 0) {
        areaError = "Area is required.";
      }

      return { rent: rentError, area: areaError };
    });
  };

  const hasValidationErrors = (errors) => {
    return errors.some((error) => error.rent || error.area);
  };

  const submitPropertyMaster = async () => {
    try {
      const response = await addPropertyMaster(PropertyMaster);
      if ("data" in response) {
        const propertyMasterId = response.data.id;
        // toastr.success("PropertyMaster data submitted successfully!");
        return propertyMasterId;
      } else {
        // console.error("Failed to submit PropertyMaster data:", response.error);
      }
    } catch (error) {
      // console.error("Failed to submit PropertyMaster data:", error);
    }
    return null;
  };

  const submitPropertyFeatures = async (propertyMasterId) => {
    // const updatedPropertyMasterFeature = propertyMasterFeaturedata.map(item => ({
    //   ...item,
    //   propertyMasterID: propertyMasterId
    // }));
    const updatedPropertyMasterFeature =
      propertyMasterFeaturedata && propertyMasterFeaturedata.length > 0
        ? propertyMasterFeaturedata.map((item) => ({
            ...item,
            propertyMasterID: propertyMasterId,
          }))
        : [];
    const response = await addPropertyFeature(updatedPropertyMasterFeature);
  
  };

  const submitPropertyImages = async (propertyMasterId) => {
    const updatePropertyMasterImages = propertyMasterImages.map(
      ({ url, ...item }) => ({
        ...item,
        propertyMasterID: propertyMasterId,
      })
    );

    for (const image of updatePropertyMasterImages) {
      const response = await addPropertyMasterImages(image);
    
    }
    // toastr.success("All images uploaded successfully!");
  };

  const submitUnitCategoryImages = async (propertyMasterId) => {
    const images = Object.values(unitcategoriesImages).flat();
    const updatePropertyCategoryImages = images.map(({ url, ...item }) => ({
      ...item,
      propertyMasterID: propertyMasterId,
    }));

    for (const catImage of updatePropertyCategoryImages) {
      await addpropertcategoryImages(catImage);
    }
    // toastr.success("All category images uploaded successfully!");
  };

  const submitUnitCategoryFeatures = async (propertyMasterId) => {
    const propertyMasterUnitFeature1 = Object.values(
      propertyMasterUnitFeatures
    ).flat();
    const updatePropertyCategoryImages = propertyMasterUnitFeature1.map(
      ({ ...item }) => ({
        ...item,
        propertyMasterID: propertyMasterId,
      })
    );

    const response = await addPropertyFeature(updatePropertyCategoryImages);

    toastr.success("unit category features added successfully!");
  };

  const prepareUnitData = (propertyMasterId) => {
    const validFormData = [];

    units.forEach((floorUnits, floorIndex) => {
      floorUnits.forEach((unitCount, categoryIndex) => {
        if (unitCount > 0) {
          const unitCategoryID = categoryLabels[categoryIndex];
          for (let i = 0; i < unitCount; i++) {
            const unitData = {
              propertyMasterID: propertyMasterId,
              name:unitCategoryID,
              unitCategoryID: UnitCategoryEnum[unitCategoryID],
              bedRoom: formData[categoryIndex]?.bedrooms || 0,
              bathRoom: formData[categoryIndex]?.bathrooms || 0,
              hall: formData[categoryIndex]?.hall || 0,
              kitchen: formData[categoryIndex]?.kitchen || 0,
              rentPrice: formData[categoryIndex]?.rent || 0,
              sqft: formData[categoryIndex]?.area || 0,
              floorNumber: floorIndex + 1,
              floorCaption: floorLabels[floorIndex],
              count: 1,
            };
            validFormData.push(unitData);
          }
        }
      });
    });

    return validFormData;
  };

  const submitPropertyUnits = async (validFormData) => {
    try {
      const response = await addPropertyUnit(validFormData);
      // toastr.success("Unit added successfully");
    } catch (error) {
      console.error("Failed to submit PropertyUnit data:", error);
      // toastr.error("Failed to submit data4.");
    }
  };

  const handleTotalUnitsChange = (total) => {
    setTotalUnits(total);
  };

  const handleUnitsChange = (newUnits) => {
    setUnits(newUnits);
  };

  const handleCategoryChange = (index, selectedOption) => {
    debugger
    const currentCategory = selectedCategories[index];
    // const studioimages = JSON.parse(localStorage.getItem(`uploadedImages_${currentCategory.id}`)) || [];
    localStorage.removeItem(`uploadedImages_${currentCategory.id}`);
    dispatch(removeImagesByCategoryId({ categoryId: currentCategory.id }));
    const updatedCategories = [...selectedCategories];
    updatedCategories[index] = {
      id: selectedOption.value,
      name: selectedOption.label,
    };
    setSelectedCategories(updatedCategories);

    if (![6, 7, 8].includes(selectedOption.value)) {
      setFormData((prevFormData) =>
        prevFormData.map((item, idx) =>
          idx === index
            ? {
                ...item,
                category: selectedOption.label,
                bedrooms:
                  selectedOption.value === UnitCategoryEnum.Studio
                    ? 1
                    : selectedOption.value,
                bathrooms:
                  selectedOption.value === UnitCategoryEnum.Studio
                    ? 1
                    : selectedOption.value,
                area: 0,
                kitchen: 1,
                hall: 1,
                rent: 0,
              }
            : item
        )
      );
    } else if ([6, 7, 8].includes(selectedOption.value)) {
      setFormData((prevFormData) =>
        prevFormData.map((item, idx) =>
          idx === index
            ? {
                ...item,
                category: selectedOption.label,
                bedrooms:
                  selectedOption.value === UnitCategoryEnum.Shop ||
                  selectedOption.value === UnitCategoryEnum.WareHouse ||
                  selectedOption.value === UnitCategoryEnum.Office
                    ? 1
                    : selectedOption.value,
                bathrooms:
                  selectedOption.value === UnitCategoryEnum.Shop ||
                  selectedOption.value === UnitCategoryEnum.WareHouse ||
                  selectedOption.value === UnitCategoryEnum.Office
                    ? 1
                    : selectedOption.value,
                area: 0,
                kitchen: 1,
                hall: 1,
                rent: 0,
              }
            : item
        )
      );
    }
    // localStorage.setItem("updatedUploadedImages", JSON.stringify(studioimages));
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-md-4 col-sm-12">
                  <h4 className="page-title page-title-unit-cat">
                    Add New Property
                  </h4>
                  <p className="page-title">We are glad to see you again!</p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row row-space">
                  {Array.from({ length: categories }).map((_, index) => (
                    <div className="col-lg-2" key={index}>
                      <div className="form-wrap">
                        <label className="col-form-label">
                          Category {index + 1}
                        </label>

                        <div className="select-wrapper">
                          <select
                            id={`category-${index}`}
                            className="custom-select form-selectborder form-c cat-drop-down-rounded page-title"
                            required
                            value={selectedCategories[index].id}
                            onChange={(e) =>
                              handleCategoryChange(
                                index,
                                options.find(
                                  (option) =>
                                    option.value === parseInt(e.target.value)
                                )
                              )
                            }
                          >
                            {/* {filterdata.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.desc}
                              </option>
                            ))} */}
                            {filterdata &&
                              filterdata.length > 0 &&
                              filterdata.map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.desc}
                                </option>
                              ))}
                          </select>
                          <i className="fas fa-angle-down custom-select-arrow"></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="col-lg-3 col-md-4 align-items-center unitdetail">
                  <p className=" pb-2">Enter Units Details</p>
                </div>
                <div className="row">
                  {Array.from({ length: categories }).map((_, index) => (
                    <div
                      className={`${
                        categories == 1
                          ? "col-md-12 col-lg-12"
                          : "col-md-6 col-lg-6"
                      }`}
                      key={index}
                    >
                      <UnitCategoryCard
                        // heading={index}
                        heading={selectedCategories[index].name}
                        values={formData[index]}
                        onFormChange={(newValues) =>
                          handleFormChange(index, newValues)
                        }
                        errors={errors[index]}
                        onBlur={handleBlur}
                        index={index}
                      />
                    </div>
                  ))}
                </div>

                <div className="col-lg col-md align-items-center">
                  <p className="py-2 table-p">
                    Please Allocate Units to Each Floor:
                  </p>
                </div>
                <InputTable
                  onTotalUnitsChange={handleTotalUnitsChange}
                  onUnitsChange={handleUnitsChange}
                  setFloorLabels={setFloorLabels}
                  floorLabels={floorLabels}
                  categoryLabels={categoryLabels}
                  categoryCount={categories}
                  floors={floors}
                />
                <div className="col-lg col-md align-items-center">
                  <p className="py-2">
                    Your total units must be equal to {residentialunits}
                  </p>
                </div>
                <div className="row mt-2">
                  <div className="d-flex col-md-8 col-sm-12 col-lg-8">
                    <p className="table-p">Total Count</p>
                    <span className="px-3">
                      <Link to="#" className="totalcount-btn px-4">
                        {totalUnits}
                      </Link>
                    </span>
                  </div>
                  <div className="text-end col-lg-4 col-md-4 col-sm-4 btn-next">
                    {/* <Link
                      to="#"
                      className="btn next-btn"
                      onClick={handleSubmit}
                      disabled={loader}
                    >
                      {loader ? (
                        <span className="spinner-border spinner-border-sm mr-1 "></span>
                      ) : null}
                      Submit
                    </Link> */}
                    {loader ? (
                      <button className="btn next-btn" disabled>
                        <span className="spinner-border spinner-border-sm mr-1 "></span>
                        Submit
                      </button>
                    ) : (
                      <Link
                        to="#"
                        className="btn next-btn"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitCategory;
