export interface IMaintenanceResponseMessage<T> {
  message: string;
  statusCode: number;
  totalCount: number;
  data: T;
}

export class MaintenanceResponseMessage<T> implements IMaintenanceResponseMessage<T> {
  message: string;
  statusCode: number;
  totalCount: number;
  data: T;

  constructor(data: IMaintenanceResponseMessage<T>) {
    this.message = data.message ?? "Internal Server Error";
    this.statusCode = data.statusCode ?? 500;
    this.totalCount = data.totalCount ?? 0;
    this.data = data.data ?? {} as T;
  }
}