import React from 'react';

const ExpenseFormRow = ({ role, selectedForm, UserTypeEnum, userTypeTextMap, onChangeExpenseForm }) => {
    return (
        <div className="row">
            {/* Display role description */}
            <div className="col-md-4">{role.desc}</div>

            {/* Radio button for Tenant */}
            <div className="form-check form-check-md col-md-4">
                <input
                    className="form-check-input"
                    type="radio"
                    name={`Radio-${role.id}`} // Unique name per role
                    id={`Radio-sm-${role.id}-tenant`}
                    value={UserTypeEnum.Tenant}
                    onChange={() => onChangeExpenseForm(role.id, UserTypeEnum.Tenant)}
                    checked={selectedForm.userTypeID === UserTypeEnum.Tenant}
                />
                <label className="form-check-label" htmlFor={`Radio-sm-${role.id}-tenant`}>
                    {userTypeTextMap[UserTypeEnum.Tenant]}
                </label>
            </div>

            {/* Radio button for Landlord */}
            <div className="form-check form-check-md col-md-4">
                <input
                    className="form-check-input"
                    type="radio"
                    name={`Radio-${role.id}`} // Unique name per role
                    id={`Radio-sm-${role.id}-landlord`}
                    value={UserTypeEnum.Landlord}
                    onChange={() => onChangeExpenseForm(role.id, UserTypeEnum.Landlord)}
                    checked={selectedForm.userTypeID === UserTypeEnum.Landlord}
                />
                <label className="form-check-label" htmlFor={`Radio-sm-${role.id}-landlord`}>
                    {userTypeTextMap[UserTypeEnum.Landlord]}
                </label>
            </div>
        </div>
    );
};

export default ExpenseFormRow;
