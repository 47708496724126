import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MultipleImageUpload from "./MultipleImageUpload";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { IconButton, InputAdornment } from "@mui/material";
// import ClearIcon from "@mui/icons-material/Clear";

import {
  useAddPropertyCategoryImageMutation,
  useAddPropertyFeatureMutation,
  useAddPropertyMasterMutation,
  useAddPropertyUnitMutation,
  useGetAllAreasQuery,
  useGetCountryQuery,
  useGetGovernorateQuery,
  useGetPropertyFeaturesQuery,
  useGetWilayatAndGovernateByAreaIdQuery,
  useGetWilayatQuery,
  usePropertyMasterMutation,
  usePropertyUnitCategoryTypesQuery,
  usePropertyUnitMutation,
} from "../../../redux/CommonApi";
import { TextField } from "@mui/material";
import FeatureModal from "./FeatureModal";
import {
  PropertyMasterTypeEnum,
  PropertySubTypeEnum,
  PropertyUnitCategoryEnum,
  UnitCategoryEnum,
} from "../../../redux/RequestTypeEnum";

import PropertyMasterImageupload from "./PropertyMasterImageupload";
import { CustomAutocomplete } from "./CustomAutocomplete";
import { PropertyMaster } from "./model/interface";
import { useSelector, useDispatch } from "react-redux";
import {
  setPropertyMaster,
  setPropertyMasterImages,
  setPropertyMasterUniteImages,
  setPropertyMasterUnitFeature,
} from "../../../redux/Commonslice";
import { Modal } from "bootstrap";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { setPropertyId } from "../../../redux/Commonslice";
import Chaveron from "./Chaveron";
import PropertyIcon from "../../../../public/assets/img/icons/tdes.svg";
import UnitIcon from "../../../../public/assets/img/icons/propertyDes.svg";
import AddMap from "./AddMap";
import Demo from "./Demo";
interface Area {
  id: number;
  name: string;
  wilayatId: number;
}
const AddPropertyForm = ({
  selectedMasterTypeID,
  selectedSubTypeID,
  showDiv,
  selectedSubTypeName
}) => {
  
  const [addPropertyMaster] = useAddPropertyMasterMutation();
  const [addPropertyUnit] = useAddPropertyUnitMutation();
  const [addpropertcategoryImages] = useAddPropertyCategoryImageMutation();
  const [addPropertyFeature] = useAddPropertyFeatureMutation();
  const [imagesizerror, setimagesizerror] = useState("");
  const [loader, setloader] = useState(false);
  const unitcategoriesImages =
    useSelector((state: any) => state.CRMS.propertyMasterUniteImages) ?? null;
  const propertyMasterUnitFeatures =
    useSelector((state: any) => state.CRMS.propertyMasterUnitFeature) ?? null;

    
  const handleInputChange = (field, newValue) => {
    setUnitcategoryForm((prevForm) => ({
      ...prevForm,
      [field]: newValue,
    }));
  };
  const handleIncrement = (field, max = 9999) => {
    handleInputChange(field, Math.min(unitcategoryForm[field] + 1, max));
  };
  const handleDecrement = (field, min = 0) => {
    handleInputChange(field, Math.max(unitcategoryForm[field] - 1, min));
  };
  const dispatch = useDispatch();
  const [singleFile, setSingleFile] = useState([]);
  const navigate = useNavigate();
  const { data: FeatureData } = useGetPropertyFeaturesQuery({});
  const { data: governate } = useGetGovernorateQuery({});
  const { data: wilayat } = useGetWilayatQuery({});
  const { data: areasList } = useGetAllAreasQuery({});
  const { data: Country } = useGetCountryQuery({});
  const { data: unitcategory } = usePropertyUnitCategoryTypesQuery({});
  // const [propertyMaster, { isLoading, error }] = usePropertyMasterMutation();
  // const [PropertyUnit] = usePropertyUnitMutation();
  const [selectedGovernorate, setSelectedGovernorate] = useState("");
  const [selectedWilayat, setSelectedWilayat] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<Area[]>([]);
  const [areaId, setAreaId] = useState<number | null>(null);
  const [wilayatError, setWilayatError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClearable, setIsClearable] = useState(false);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const UserID = sessionStorage.getItem("userId");
  const limitedUnitCategories = unitcategory ? unitcategory.slice(0, 5) : [];
  const unitCategory = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const years = Array.from(
    { length: 101 },
    (_, i) => new Date().getFullYear() - i
  ); // Generate last 100 years
  // Filter the wilayats based on selected governorate
  // useEffect(() => {
  //     setUnitcategoryForm((prevForm) => ({
  //         ...prevForm,
  //         unitCategoryID: selectedSubTypeID,
  //     }));
  // }, [selectedSubTypeID]);


  const selectedSingleUnitId = unitcategory?.find(
    (item) => item.desc === selectedSubTypeName
  );
  const filteredWilayats =
    wilayat?.filter((w) => w.governorateID === Number(selectedGovernorate)) ||
    [];
  const { data: wilayatAndGovernorateData, isSuccess } =
    useGetWilayatAndGovernateByAreaIdQuery(
      {
        AreaId: areaId,
      },
      { skip: !areaId }
    );

  const {
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useForm<PropertyMaster>({
    defaultValues: {
      landLordID: +UserID,
      propertyMasterTypeID: selectedMasterTypeID,
      propertyMasterSubTypeID: selectedSubTypeID,
      listingPurposeID: 1,
      address: {
        plot: "",
        building: "",
        wilayatID: 0,
        areaID: 0,
        area: "",
        countryID: 1,
        governorateID: 0,
        wayNumber: "",
      },
      residentialUnits: null,
      commercialUnits: null,
      builtYear: selectedYear ?? 0,
      numberofFloors: null,
      plotNumber: "",
      buildingNumber: "",
      name: "",
      description: "",
      propertyStatusID: 1,
      lat: 0,
      long: 0,
      unitCategoryID: selectedSubTypeID,
    },
  });
  useEffect(() => {
    // Update the property master type and subtype IDs when selectedMasterTypeID or selectedSubTypeID changes
    setValue("propertyMasterTypeID", selectedMasterTypeID);
    setValue("propertyMasterSubTypeID", selectedSubTypeID);
    setValue("address.governorateID", 0);
    setValue("address.wilayatID", 0);
    setValue("address.areaID", 0);
    resetForm()
    reset()
  }, [selectedMasterTypeID, selectedSubTypeID, setValue]);

  useEffect(() => { }, [selectedMasterTypeID, selectedSubTypeID, selectedYear]);
  // Filter the areas based on the selected Wilayat ID
  useEffect(() => {
    if (selectedWilayat) {
      const filteredAreas =
        areasList?.filter(
          (area) => area.wilayatId === Number(selectedWilayat)
        ) || [];
      setFilteredOptions(filteredAreas);
    } else if (selectedGovernorate && !selectedWilayat) {
      // Show all areas if no Wilayat is selected
      setFilteredOptions(areasList || []);
    } else {
      // Show all areas if no Wilayat is selected
      setFilteredOptions(areasList || []);
    }
  }, [selectedWilayat, areasList]);
  // Update state when the API call is successful
  useEffect(() => {
    if (isSuccess && wilayatAndGovernorateData) {
      if (!selectedGovernorate && !selectedWilayat) {
        setSelectedGovernorate(
          wilayatAndGovernorateData.governateId.toString()
        ); // Set Governorate ID
        setSelectedWilayat(wilayatAndGovernorateData.wilayatId.toString()); // Set Wilayat ID
      }
    }
  }, [
    isSuccess,
    wilayatAndGovernorateData,
    selectedGovernorate,
    selectedWilayat,
  ]);

  const handleGovernorateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedGovernorate(selectedValue);
    setSelectedWilayat(""); // Reset wilayat when a new governorate is selected
    setFilteredOptions([]); // Clear areas when governorate changes
    setValue("address.governorateID", Number(selectedValue));
    setValue("address.wilayatID", Number(0));
    setValue("address.areaID", Number(0));
    setWilayatError(false);
  };

  // Handle wilayat selection
  const handleWilayatChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedWilayat(selectedValue);
    setValue("address.wilayatID", Number(selectedValue));
  };

  const [unitcategoryForm, setUnitcategoryForm] = useState({
    propertyMasterID: 0,
    unitCategoryID: null,
    bedRoom: 1,
    bathRoom: 1,
    hall: 1,
    kitchen: 1,
    rentPrice: 0,
    sellPrice: null,
    sqft: 0,
    floorNumber: null,
    name: "",
    description: null,
    tag: null,
    count: 1,
    parking: null,
  });
  // Handle area selection and auto-fill Wilayat & Governorate using RTK Query
  const handleAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    value: Area | null
  ) => {
    if (value) {
      setAreaId(value.id);

      setValue("address.areaID", value.id);
      setWilayatError(false);
    }
  };

  // const selectedOption = filteredOptions.find((option) => option.id === areaId);


  const [formErrors, setFormErrors] = useState({
    rent: "",
    area: "",
  });
  const validateFormData = () => {
    const newErrors = {
      rent:
        !unitcategoryForm.rentPrice || unitcategoryForm.rentPrice === 0
          ? "Rent is required"
          : "",
      area:
        !unitcategoryForm.sqft || unitcategoryForm.sqft === 0
          ? "Area is required"
          : "",
    };

    return newErrors;
  };
  const resetForm = () => {
    setUnitcategoryForm({
      propertyMasterID: 0,
      unitCategoryID: null,
      bedRoom: 0,
      bathRoom: 0,
      hall: 0,
      kitchen: 0,
      rentPrice: 0,
      sellPrice: null,
      sqft: 0,
      floorNumber: null,
      name: "",
      description: null,
      tag: null,
      count: 1,
      parking: null,
    });
    setFormErrors({ rent: "", area: "" });
  };

  const onSubmit: SubmitHandler<PropertyMaster> = async (data) => {
    debugger
    const filteredAreas =
    areasList?.filter(
      (area) => area.id === Number(data.address.areaID));

      const filteredWilayats = wilayat?.filter(
        (wilayat) => wilayat.id === filteredAreas[0].wilayatId);
    if (
      selectedSubTypeID === PropertySubTypeEnum.Building ||
      selectedSubTypeID === PropertySubTypeEnum.MixUse
    ) {
      data.propertyMasterSubTypeID = selectedSubTypeID;
      data.propertyMasterTypeID = selectedMasterTypeID;
      debugger

   
      data.address.wilayatID = filteredAreas[0].wilayatId;
      data.address.governorateID = filteredWilayats[0].governorateID;
       
      dispatch(setPropertyMaster(data));
      dispatch(setPropertyMasterImages(singleFile));
      navigate("/admin/unitcategory");
    } else {
      setloader(true);
      data.propertyMasterSubTypeID = selectedSubTypeID;
      data.propertyMasterTypeID = selectedMasterTypeID;
      
      data.address.wilayatID = filteredAreas[0].wilayatId;
      data.address.governorateID = filteredWilayats[0].governorateID;
      const propertyMasterId = await submitPropertyMaster(data);
      setloader(false);
      if (!propertyMasterId) {

        return;

      }
      const UnitCategoryEnumReverse = Object.fromEntries(
        Object.entries(UnitCategoryEnum).map(([key, value]) => [value, key])
      );
      unitcategoryForm.propertyMasterID = propertyMasterId;
      unitcategoryForm.unitCategoryID = data.unitCategoryID;
      unitcategoryForm.name =UnitCategoryEnumReverse[data.unitCategoryID];
      // unitcategoryForm.unitCategoryID = (selectedMasterTypeID === PropertyMasterTypeEnum.Residential || selectedMasterTypeID === PropertyMasterTypeEnum.ResidentalCommercial) ? selectedMasterTypeID : selectedSingleUnitId.unitCategory
      setloader(true);
      const newErrors = validateFormData();
      setFormErrors(newErrors);
      const hasErrors = Object.values(newErrors).some((error) => error !== "");

      if (!hasErrors) {
        try {
          debugger
          const unitcategoryArray = [unitcategoryForm];
          await addPropertyUnit(unitcategoryArray);
          
        } catch (error) {
          //   console.error("Failed to submit PropertyUnit data:", error);
          setloader(false);
        }
      } else {
      
        return;
      }
      setloader(true);
      await submitUnitCategoryImages(propertyMasterId, data.unitCategoryID);
      //   dispatch(setPropertyMasterImages(null));

      if (propertyMasterUnitFeatures) {
        await submitUnitCategoryFeatures(propertyMasterId, data.unitCategoryID);
      }
      dispatch(setPropertyMasterUniteImages(null));
      dispatch(setPropertyMasterUnitFeature(null));
      setloader(false);
      reset();
      resetForm();
      toastr.success("Property added successfully");
    }
  };
  const submitPropertyMaster = async (data) => {
    try {
      const response = await addPropertyMaster(data);
      if ("data" in response) {
        const propertyMasterId = response.data.id;
        return propertyMasterId;
      } else {
        // console.error("Failed to submit PropertyMaster data:", response.error);
      }
    } catch (error) {
      //   console.error("Failed to submit PropertyMaster data:", error);
    }
    return null;
  };

  const submitUnitCategoryImages = async (propertyMasterId, unitCategoryID) => {
    debugger
    if(!unitcategoriesImages){
      const images = Object.values(unitcategoriesImages).flat();
      const updatePropertyCategoryImages = images.map(({ url, ...item }) => ({
        ...item,
        propertyMasterID: propertyMasterId,
        propertUnitCategoryID: unitCategoryID,
      }));
  
      for (const catImage of updatePropertyCategoryImages) {
        await addpropertcategoryImages(catImage);
      }
    }

  };


  const submitUnitCategoryFeatures = async (propertyMasterId, unitCategoryID) => {
    const propertyMasterUnitFeature1 = Object.values(
      propertyMasterUnitFeatures
    ).flat();
    const updatePropertyCategoryImages = propertyMasterUnitFeature1.map(
      ({ ...item }) => ({
        ...item,
        propertyMasterID: propertyMasterId,
        propertyUnitCategoryID: unitCategoryID
      })
    );

    const response = await addPropertyFeature(updatePropertyCategoryImages);


  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
  };

  const handleChangeUnit = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, type } = e.target;
  };
  const handleChangeunit = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, type } = e.target;
    if (type === "select-one") {
      setUnitcategoryForm((prev) => ({
        ...prev,
        [name]: +value,
      }));
    } else {
      // Handling input element change
      setUnitcategoryForm((prev) => ({
        ...prev,
        [name]: +value,
      }));
    }
  };
  const unitCategoryName =
    Object.keys(PropertyUnitCategoryEnum).find(
      (key) => PropertyUnitCategoryEnum[key] === selectedSubTypeID
    ) || "Unknown";
   
  const getAllStoredImages = () => {
    let allImages = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith("uploadedImages_")) {
        const images = JSON.parse(sessionStorage.getItem(key)) || [];
        allImages = [...allImages, ...images];
      }
    }

    return allImages;
  };
  const uploadSingleFiles = (e) => {
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      if (file.size > 5 * 1024 * 1024) {
        setimagesizerror("Each file must be less than 5MB");
        setTimeout(() => {
          setimagesizerror("");
        }, 5000);
        continue;
      }
      setimagesizerror("");
      reader.onload = (event) => {
        const result = event.target.result;
        let byteArray;

        if (typeof result !== "string") {
          byteArray = new Uint8Array(result);
        }

        const newImage = {
          propertyMasterID: 0,
          image: byteArray ? Array.from(byteArray) : null,
          url: URL.createObjectURL(file),
          imageName: file.name,
          imageExtension: file.name.split(".").pop(),
        };
        setSingleFile((prevFiles) => [...prevFiles, newImage]);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const removeImage = (index) => {
    setSingleFile((prevFiles) => [
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);
  };
  const [unitId, setUnitId] = useState<number | null>(null);
  const addFeature = (e, heading) => {
    setUnitId(heading);
    dispatch(setPropertyId(heading));
    const modalElement = document.getElementById("exampleModalScrollable3");
    if (modalElement) {
      const modal = new Modal(modalElement);
      modal.show();
    }
  };
  const handleUnitCategoryChange = (e) => {
    const selectedValue = Number(e.target.value);
    setUnitcategoryForm(prevState => ({
      ...prevState,
      unitCategoryID: selectedValue,
      bedRoom: selectedValue,
      bathRoom: selectedValue,
      hall: selectedValue,
      kitchen: selectedValue,
      rentPrice: 0,
      sqft: 0,
    }));
  };
  const handleclick = (event) => {
    if (!selectedGovernorate) {
      setWilayatError(true);
    }

  };
  // const handleClears = () => {
  //   setAreaId(null); 
  //   setValue("address.areaID", null); 
  // };



  const selectedOption = areaId
    ? filteredOptions.find((option) => option.id === areaId)
    : null;

  return (
    <>
      <div className=" d-flex">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row addproperty-pad">
            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Building Name <span className="text-danger">*</span> </label>
                <input
                  className="form-control addproperty-form"
                  type="text"
                  {...register("name", {
                    required: "is required",
                  })}
                />
                {errors.name && (
                  <p style={{ color: "#9E2A2B" }}>{errors.name.message}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Building Number <span className="text-danger">*</span></label>
                <input
                  className="form-control addproperty-form"
                  {...register("buildingNumber", {
                    required: "is required",
                  })}
                />
                {errors.name && (
                  <p style={{ color: "#9E2A2B" }}>{errors.name.message}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Plot Number <span className="text-danger">*</span></label>
                <input
                  className="form-control addproperty-form"
                  {...register("address.plot", {
                    required: "is required",
                    onChange: (e) => setValue("plotNumber", e.target.value),
                  })}
                />
                {errors.address?.plot && (
                  <p style={{ color: "#9E2A2B" }}>
                    {errors.address.plot.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Country <span className="text-danger">*</span></label>
                <select
                  id="country"
                  className="form-select form-c addproperty-form "
                  {...register("address.countryID", {
                    required: "is required ",
                    valueAsNumber: true,
                  })}
                >
                  {/* <option value="" disabled>
                    Select Country
                  </option> */}
                  {Country?.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
                {errors.address?.countryID && (
                  <p style={{ color: "#9E2A2B" }}>
                    {errors.address?.countryID.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Governorate  <span className="text-danger">*</span></label>
                <select
                  id="governorate"
                  className="form-select form-c addproperty-form "
                  value={selectedGovernorate}
                  aria-label="Governorate selection"
                  {...register("address.governorateID", {
                    required: "is required",
                    valueAsNumber: true,
                  })}
                  onChange={handleGovernorateChange}
                >
                  <option value="" disabled>
                    --Select--
                  </option>
                  {governate?.map((governate) => (
                    <option key={governate.id} value={governate.id}>
                      {governate.name}
                    </option>
                  ))}
                </select>
                {errors.address?.governorateID && (
                  <p style={{ color: "#9E2A2B" }}>
                    {errors.address?.governorateID.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap" onClick={handleclick}>
                <label className="col-form-label">Wilayat <span className="text-danger">*</span></label>
                <select
                  id="wilayat"
                  className={`form-select form-c addproperty-form ${wilayatError ? "is-invalid" : ""}`}
                  value={selectedWilayat}
                  onChange={handleWilayatChange}
                  disabled={!selectedGovernorate}

                // Disable wilayat until a governorate is selected
                // onClick={() => {
                //   if (!selectedGovernorate) {

                //     setWilayatError(true);
                //   }
                // }}

                >
                  <option value="" disabled>
                    --Select--
                  </option>
                  {filteredWilayats?.map((wilayat) => (
                    <option key={wilayat.id} value={wilayat.id}>
                      {wilayat.name}
                    </option>
                  ))}
                </select>
                {errors.address?.wilayatID && (
                  <p style={{ color: "#9E2A2B" }}>
                    {errors.address?.wilayatID.message}
                  </p>
                )}


                {wilayatError && !selectedGovernorate && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    Please select a governorate first.
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Area <span className="text-danger">*</span></label>

                <Controller
                  name="address.areaID"
                  control={control}
                  rules={{ required: "is required" }}
                  render={({ field }) => (
                    <CustomAutocomplete
                      {...field}
                      onChange={handleAutocompleteChange}
                      id="free-solo-with-text-demo"
                      options={filteredOptions}
                      value={selectedOption}
                      getOptionLabel={(option: Area) => option?.name || "--Select--"}
                      disableClearable={false}
                      // freeSolo
                      // disabled={!watch("address.wilayatID")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="--Select--"
                          error={!!errors.address?.areaID}
                          helperText={
                            errors.address?.areaID
                              ? errors.address.areaID.message
                              : null
                          }
                        // InputProps={{
                        //   ...params.InputProps,
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       {selectedOption ? (
                        //         <IconButton onClick={handleClears}>
                        //           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 25 24" fill="#9e2a2b">
                        //             <path opacity="0.6" d="M24.0448 21.0303C24.3632 21.3488 24.5421 21.7807 24.5421 22.231C24.5421 22.6814 24.3632 23.1133 24.0448 23.4317C23.7264 23.7502 23.2944 23.9291 22.8441 23.9291C22.3937 23.9291 21.9618 23.7502 21.6434 23.4317L12.6748 14.4603L3.70345 23.4289C3.385 23.7474 2.95309 23.9263 2.50274 23.9263C2.05239 23.9263 1.62048 23.7474 1.30204 23.4289C0.983589 23.1105 0.804688 22.6786 0.804688 22.2282C0.804687 21.7778 0.983589 21.3459 1.30204 21.0275L10.2734 12.0589L1.30486 3.08755C0.986414 2.7691 0.807513 2.3372 0.807513 1.88684C0.807513 1.43649 0.986414 1.00459 1.30486 0.686139C1.62331 0.367692 2.05521 0.18879 2.50557 0.18879C2.95592 0.18879 3.38782 0.367692 3.70627 0.686139L12.6748 9.65752L21.6462 0.684726C21.9647 0.366279 22.3966 0.187378 22.8469 0.187378C23.2973 0.187378 23.7292 0.366279 24.0476 0.684726C24.3661 1.00317 24.545 1.43508 24.545 1.88543C24.545 2.33578 24.3661 2.76769 24.0476 3.08614L15.0762 12.0589L24.0448 21.0303Z" fill="#CACACA" />
                        //           </svg>
                        //         </IconButton>
                        //       ) : null}
                        //       {params.InputProps.endAdornment}
                        //     </InputAdornment>
                        //   ),
                        // }}
                        />
                      )}
                      renderOption={(props, option: Area) => (
                        <li {...props} key={option.id}>
                          <span>{option.name}</span>
                        </li>
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap icon-form">
                <label className="col-form-label">Mark Location</label>
                <input
                  className="form-control addproperty-form"
                  type=""
                  id="id_number"
                  aria-describedby="number"
           
                />
               {/* <i className="fa fa-map-marker text-danger" aria-hidden="true"></i> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Way Number <span className="text-danger">*</span></label>
                <input
                  className="form-control addproperty-form"
                  type="number"
                  name="address.wayNumber"
                  {...register("address.wayNumber", {
                    required:
                      selectedMasterTypeID === 1 && selectedSubTypeID === 1
                        ? "is required"
                        : false,
                    valueAsNumber: true,
                  })}
                />
                {errors.address?.wayNumber && (
                  <p style={{ color: "#9E2A2B" }}>
                    {errors.address.wayNumber.message}
                  </p>
                )}
              </div>
            </div>
            {selectedSubTypeID == 1 || selectedSubTypeID == 9 ? (
              <>

                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="col-form-label">
                    {selectedSubTypeID == 9 ? (
          <>
            Commercial Units <span className="text-danger">*</span>
          </>
        ) : (
          <>
            Residential Units <span className="text-danger">*</span>
          </>
        )}
                    </label>
                    {/* <input
                      className="form-control addproperty-form"
                      type="number"
                      {...register("residentialUnits", {
                        required: "is required",
                        valueAsNumber: true,
                      })}
                    /> */}
                    <input
                      className="form-control addproperty-form"
                      type="number"
                      {...register("residentialUnits", {
                        required: "is required",
                        valueAsNumber: true,
                      })}
                      onKeyPress={(e) => {
                        if (e.key === '-' || e.key === '+' || e.key === '.') {
                          e.preventDefault(); // Prevents entering +, -, and . characters
                        }
                      }}
                      min="0" // Prevents negative numbers
                      step="1" // Allows only whole numbers (no decimals)
                    />



                    {errors.residentialUnits && (
                      <p style={{ color: "#9E2A2B" }}>
                        {errors.residentialUnits.message}
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
 {selectedMasterTypeID === 3 && selectedSubTypeID === PropertySubTypeEnum.Building ? (
              <>

                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="col-form-label">
                       Commercial Units
                       
                    </label>
                    {/* <input
                      className="form-control addproperty-form"
                      type="number"
                      {...register("residentialUnits", {
                        required: "is required",
                        valueAsNumber: true,
                      })}
                    /> */}
                    <input
                      className="form-control addproperty-form"
                      type="number"
                      {...register("commercialUnits", {
                        required: "is required",
                        valueAsNumber: true,
                      })}
                      onKeyPress={(e) => {
                        if (e.key === '-' || e.key === '+' || e.key === '.') {
                          e.preventDefault(); // Prevents entering +, -, and . characters
                        }
                      }}
                      min="0" // Prevents negative numbers
                      step="1" // Allows only whole numbers (no decimals)
                    />



                    {errors.commercialUnits && (
                      <p style={{ color: "#9E2A2B" }}>
                        {errors.commercialUnits.message}
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}


            {selectedMasterTypeID == 1 && selectedSubTypeID !== PropertySubTypeEnum.Building ?
              (<></>) : (<>
                <div className="col-md-6">
                  <div className="form-wrap">
                    {/* <label className="col-form-label">NO. of Floors</label> */}
                    <label className="col-form-label">
                      {(selectedMasterTypeID === 1 && selectedSubTypeID === 1) ||
                        (selectedMasterTypeID === 2 && selectedSubTypeID === 9) ||
                        (selectedMasterTypeID === 3 && selectedSubTypeID === 1)
                        ? "NO. of Floors"
                        : "Floor No"}
                        {" "}
                         <span className="text-danger">*</span>
                    </label>


                    <input
                      className="form-control addproperty-form"
                      type="number"
                      id="id_number"
                      {...register("numberofFloors", {
                        required: "number of Floors is required ",
                        valueAsNumber: true,
                      })}
                    />
                    {errors.numberofFloors && (
                      <p style={{ color: "orangered" }}>
                        {errors.numberofFloors.message}
                      </p>
                    )}
                  </div>
                </div></>)}


            <div className="col-md-6">
              <div className="form-wrap">
                <label className="col-form-label">Year <span className="text-danger">*</span></label>

                <select
                  id="role"
                  className="form-select form-c addproperty-form"
                  {...register("builtYear", {
                    required: "is required ",
                    valueAsNumber: true,
                  })}
                >
                  <option value="" disabled>
                    Select a year
                  </option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errors.builtYear && (
                  <p style={{ color: "#9E2A2B" }}>{errors.builtYear.message}</p>
                )}
              </div>
            </div>
          </div>
          {(selectedSubTypeID === PropertySubTypeEnum.TownHouse || selectedSubTypeID === PropertySubTypeEnum.LowerPortion || selectedSubTypeID === PropertySubTypeEnum.UpperPortion || selectedSubTypeID === PropertySubTypeEnum.PentHouse || selectedSubTypeID === PropertySubTypeEnum.Villa || selectedSubTypeID === PropertySubTypeEnum.Room || selectedSubTypeID === PropertySubTypeEnum.Flat) && (
            <div className="d-flex align-items-center">
              <img src={UnitIcon} alt="tdes" />
              <h4 className="ps-2 ">Unit Categories</h4>

              <div className="col-lg-1 col-md-2 col-sm-4 ps-2 pt-4">
                <div className="form-wrap">
                  <select
                    id="role"
                    className="form-select form-c addproperty-form"
                    {...register("unitCategoryID", {
                      required: "required ",
                      valueAsNumber: true,
                    })}
                    onChange={handleUnitCategoryChange}
                  >

                    {unitCategory && unitCategory.slice(0, 5).map((role, index) => (
                      <option
                        className="custom-options"
                        key={index}
                        value={role}
                      >
                        {role}BHK
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {(selectedSubTypeID === PropertySubTypeEnum.Building) && (
            <div className="d-flex align-items-center">
              <img src={UnitIcon} alt="tdes" />
              <h4 className="ps-2 ">Unit Categories</h4>

              <div className="col-lg-1 col-md-2 col-sm-4 ps-2 pt-4">
                <div className="form-wrap">
                  <select
                    id="role"
                    className="form-select form-c addproperty-form"
                    {...register("unitCategoryID", {
                      required: "required ",
                      valueAsNumber: true,
                    })}
                    onChange={handleUnitCategoryChange}
                  >
                    {(selectedMasterTypeID === 3) ? (<>
                      {unitCategory && unitCategory.slice(0, 9).map((role, index) => (
                        <option
                          className="custom-options"
                          key={index}
                          value={role}
                        >
                          {role}
                        </option>
                      ))}
                    </>) : (<>
                      {unitCategory.slice(0, 6).map((role, index) => (
                        <option
                          className="custom-options"
                          key={index}
                          value={role}
                        >
                          {role}
                        </option>
                      ))}
                    </>)}
                  </select>
                </div>
              </div>
            </div>
          )}
          {(selectedSubTypeID === PropertySubTypeEnum.MixUse) && (
            <div className="d-flex align-items-center">
              <img src={UnitIcon} alt="tdes" />
              <h4 className="ps-2 ">Unit Categories</h4>

              <div className="col-lg-1 col-md-2 col-sm-4 ps-2 pt-4">
                <div className="form-wrap">
                  <select
                    id="role"
                    className="form-select form-c addproperty-form"
                    {...register("unitCategoryID", {
                      required: "required ",
                      valueAsNumber: true,
                    })}
                    onChange={handleUnitCategoryChange}
                  >

                    {unitCategory && unitCategory.slice(0, 3).map((role, index) => (
                      <option
                        className="custom-options"
                        key={index}
                        value={role}
                      >
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {/* <div className="d-flex align-items-center">
            <img src={UnitIcon} alt="tdes" />
            <h4 className="ps-2 ">Unit Categories</h4>

            <div className="col-lg-1 col-md-2 col-sm-4 ps-2 pt-4">
              <div className="form-wrap">
                <select
                  id="role"
                  className="form-select form-c addproperty-form"
                  {...register("unitCategoryID", {
                    required: "required ",
                    valueAsNumber: true,
                  })}
                  onChange={handleUnitCategoryChange}
                >
                  {selectedSubTypeID === 9 ? (
                    <>
                      {unitCategory.slice(0, 3).map((role, index) => (
                        <option
                          className="custom-options"
                          key={index}
                          value={role}
                        >
                          {role}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      {selectedMasterTypeID === 3 ? (
                        <>
                          {unitCategory.slice(0, 9).map((role, index) => (
                            <option
                              className="custom-options"
                              key={index}
                              value={role}
                            >
                              {role}
                            </option>
                          ))}
                        </>
                      ) : (
                        <>
                          {(selectedSubTypeID === 2 || selectedSubTypeID === 3 || selectedSubTypeID === 4 || selectedSubTypeID === 5 || selectedSubTypeID === 6 || selectedSubTypeID === 7 || selectedSubTypeID === 8) ? (
                            <>
                              {unitCategory.slice(0, 5).map((role, index) => (
                                <option
                                  className="custom-options"
                                  key={index}
                                  value={role}
                                >
                                  {role}BHK
                                </option>
                              ))}</>
                          ) : (<>
                            {(selectedSubTypeID === 10 || selectedSubTypeID === 11 || selectedSubTypeID === 12 || selectedSubTypeID === 13 || selectedSubTypeID === 14) ? (
                              <></>
                            ) : (<>
                              {unitCategory.slice(0, 6).map((role, index) => (
                                <option
                                  className="custom-options"
                                  key={index}
                                  value={role}
                                >
                                  {role}
                                </option>
                              ))}
                            </>)}

                          </>)}

                        </>
                      )}
                    </>
                  )}
                </select>
                {errors.unitCategoryID && (
                  <p style={{ color: "orangered" }}>
                    {errors.unitCategoryID.message}
                  </p>
                )}
              </div>
            </div>
          </div> */}
          <div className="d-flex my-2 align-items-center">
            <img src={PropertyIcon} alt="tdes" />
            <h4 className="ps-2 "> Property Description</h4>
          </div>
          <div className="col-12 addproperty-pad">
            <div className="card addproperty-form drop-shodow-card">
              <div className="card-body">
                <span></span>
                <div className="d-flex pb-2">
                  {/* property-desc */}
                  <span
                    style={{
                      width: "11.75px",
                      height: "41.13px",
                      borderRadius: "11.75px",
                      background: "#BE0C2F",
                    }}
                  ></span>
                  <p className="addprperty-h4 pt-1">
                    <span className="ps-2">Property Descripton</span>
                  </p>
                </div>
                <textarea
                  rows={10}
                  cols={10}
                  className="form-control form-textarea"
                  placeholder="Write Building description...."
                  name="description"
                  onChange={handleChange}
                />
              </div>
            </div>
            {showDiv === false ? (
              <></>
            ) : (
              <>
                {(selectedSubTypeID === PropertySubTypeEnum.TownHouse || selectedSubTypeID === PropertySubTypeEnum.LowerPortion || selectedSubTypeID === PropertySubTypeEnum.UpperPortion || selectedSubTypeID === PropertySubTypeEnum.PentHouse || selectedSubTypeID === PropertySubTypeEnum.Villa || selectedSubTypeID === PropertySubTypeEnum.Room || selectedSubTypeID === PropertySubTypeEnum.Flat || selectedSubTypeID === PropertySubTypeEnum.WareHouse  || selectedSubTypeID === PropertySubTypeEnum.BusinessCenter || selectedSubTypeID === PropertySubTypeEnum.Factory || selectedSubTypeID === PropertySubTypeEnum.Hall) && (
                  <>
                    <div className="col-lg col-md">
                      <p className="townhouse-p">
                        Enter {unitCategoryName} details
                      </p>
                      <div className="card unitcategoryCard">
                        <div className="card-body">
                          <div className="col-lg-3 col-md-4 align-items-center">
                            <h4 className="totalunit-h4 py-2 fafaicon">
                              {unitCategoryName}
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                              <div
                                className="form-wrap"
                                style={{ position: "relative" }}
                              >
                                <label className="col-form-label">
                                  {(selectedSubTypeID === PropertySubTypeEnum.BusinessCenter || selectedSubTypeID === PropertySubTypeEnum.Factory || selectedSubTypeID == PropertySubTypeEnum.WareHouse || selectedSubTypeID == PropertySubTypeEnum.Hall) ? 'Rooms':'Bedrooms' }
                                   <span className="text-danger"></span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={unitcategoryForm?.bedRoom}
                                  placeholder="01"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "bedRoom",
                                      Number(e.target.value)
                                    )
                                  }
                                  aria-describedby="number"
                                  style={{ paddingRight: "30px" }}
                                />
                                <Chaveron
                                  onIncrement={() => handleIncrement("bedRoom")}
                                  onDecrement={() => handleDecrement("bedRoom")}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                              <div
                                className="form-wrap"
                                style={{ position: "relative" }}
                              >
                                <label className="col-form-label">
                                  Bathrooms<span className="text-danger"></span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={unitcategoryForm?.bathRoom}
                                  placeholder="01"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "bathRoom",
                                      Number(e.target.value)
                                    )
                                  }
                                  aria-describedby="number"
                                />
                                <Chaveron
                                  onIncrement={() => handleIncrement("bathRoom")}
                                  onDecrement={() => handleDecrement("bathRoom")}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                              <div
                                className="form-wrap"
                                style={{ position: "relative" }}
                              >
                                <label className="col-form-label">
                                  Area (SQM) <span className="text-danger"></span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={unitcategoryForm?.sqft}
                                  placeholder="01"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "sqft",
                                      Number(e.target.value)
                                    )
                                  }
                                  aria-describedby="number"
                                />
                                {formErrors.area && (
                                  <div className="text-danger">
                                    {formErrors.area}
                                  </div>
                                )}
                              </div>
                            </div>
                            {selectedSubTypeID && (selectedSubTypeID !== PropertySubTypeEnum.Hall ) ? ( <div className="col-lg-4 col-md-4 col-sm-12">
                              <div
                                className="form-wrap"
                                style={{ position: "relative" }}
                              >
                                <label className="col-form-label">
                                  Kitchen <span className="text-danger"></span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={unitcategoryForm?.kitchen}
                                  placeholder="01"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "kitchen",
                                      Number(e.target.value)
                                    )
                                  }
                                  aria-describedby="number"
                                />
                                <Chaveron
                                  onIncrement={() => handleIncrement("kitchen")}
                                  onDecrement={() => handleDecrement("kitchen")}
                                />
                              </div>
                            </div>): (<></>)}
                           
                            {(selectedSubTypeID === PropertySubTypeEnum.TownHouse || selectedSubTypeID === PropertySubTypeEnum.LowerPortion || selectedSubTypeID === PropertySubTypeEnum.UpperPortion || selectedSubTypeID === PropertySubTypeEnum.PentHouse || selectedSubTypeID === PropertySubTypeEnum.Villa || selectedSubTypeID === PropertySubTypeEnum.Room || selectedSubTypeID === PropertySubTypeEnum.Flat || selectedSubTypeID === PropertySubTypeEnum.BusinessCenter || selectedSubTypeID === PropertySubTypeEnum.Factory) && (
                              <div className="col-lg-4 col-md-4 col-sm-12">
                                <div
                                  className="form-wrap"
                                  style={{ position: "relative" }}
                                >
                                  <label className="col-form-label">
                                    Hall <span className="text-danger"></span>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    value={unitcategoryForm?.hall}
                                    placeholder="01"
                                    onChange={(e) =>
                                      handleInputChange(
                                        "hall",
                                        Number(e.target.value)
                                      )
                                    }
                                    aria-describedby="number"
                                  />
                                  <Chaveron
                                    onIncrement={() => handleIncrement("hall")}
                                    onDecrement={() => handleDecrement("hall")}
                                  />
                                </div>
                              </div>
                            )}
{/* area */}
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div
                                className="form-wrap"
                                style={{ position: "relative" }}
                              >
                                <label className="col-form-label">
                                  Rent (OMR) <span className="text-danger"></span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={unitcategoryForm?.rentPrice}
                                  placeholder="01"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "rentPrice",
                                      Number(e.target.value)
                                    )
                                  }
                                  aria-describedby="number"
                                />
                                {formErrors.rent && (
                                  <div className="text-danger">
                                    {formErrors.rent}
                                  </div>
                                )}

                                {/* <Chaveron
                                  onIncrement={() => handleIncrement("rentPrice")}
                                  onDecrement={() => handleDecrement("rentPrice")}
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row px-3">
                            <div className="card ">
                              <div className="row">
                                <MultipleImageUpload UnitcategoryId={0} index={null} />
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <div>
                              {/* Add Features button */}
                              <a
                                className="btn addfeature-btn"
                                type="button"
                                // data-bs-toggle="modal"
                                // data-bs-target="#exampleModalScrollable3"
                                onClick={(e) =>
                                  addFeature(e, getValues("unitCategoryID"))
                                }
                              >
                                <i className="fa fa-add pe-2" aria-hidden="true">
                                  {" "}
                                  <span className="fafaicon"></span>
                                </i>
                                Add Features
                              </a>
                              {unitId && (
                                <FeatureModal
                                  unitCategoryID={unitId}
                                  FeatureData={FeatureData}
                                  // idx={null}
                              

                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-end col-lg-12 col-md-12 col-sm-12 btn-next ">
                      <button
                        type="submit"
                        className="btn next-btn"
                        disabled={loader}
                      >
                        {loader ? (
                          <span className="spinner-border spinner-border-sm mr-1 "></span>
                        ) : null}
                        Submit
                      </button>
                    </div>
                  </>
                )}
{(selectedSubTypeID === PropertySubTypeEnum.Shop)&&(
  <>
        <div className="col-lg col-md">
                      <p className="townhouse-p">
                        Enter {unitCategoryName} details
                      </p>
                      <div className="card unitcategoryCard">
                        <div className="card-body">
                          <div className="col-lg-3 col-md-4 align-items-center">
                            <h4 className="totalunit-h4 py-2 fafaicon">
                              {unitCategoryName}
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                              <div
                                className="form-wrap"
                                style={{ position: "relative" }}
                              >
                                <label className="col-form-label">
                                  Area (SQM) <span className="text-danger"></span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={unitcategoryForm?.sqft}
                                  placeholder="01"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "sqft",
                                      Number(e.target.value)
                                    )
                                  }
                                  aria-describedby="number"
                                />
                                {formErrors.area && (
                                  <div className="text-danger">
                                    {formErrors.area}
                                  </div>
                                )}
                              </div>
                            </div>
                          
                
{/* area */}
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div
                                className="form-wrap"
                                style={{ position: "relative" }}
                              >
                                <label className="col-form-label">
                                  Rent (OMR) <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={unitcategoryForm?.rentPrice}
                                  placeholder="01"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "rentPrice",
                                      Number(e.target.value)
                                    )
                                  }
                                  aria-describedby="number"
                                />
                                {formErrors.rent && (
                                  <div className="text-danger">
                                    {formErrors.rent}
                                  </div>
                                )}

                                {/* <Chaveron
                                  onIncrement={() => handleIncrement("rentPrice")}
                                  onDecrement={() => handleDecrement("rentPrice")}
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row px-3">
                            <div className="card ">
                              <div className="row">
                                <MultipleImageUpload UnitcategoryId={0} index={null} />
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <div>
                              {/* Add Features button */}
                              <a
                                className="btn addfeature-btn"
                                type="button"
                                // data-bs-toggle="modal"
                                // data-bs-target="#exampleModalScrollable3"
                                onClick={(e) =>
                                  addFeature(e, getValues("unitCategoryID"))
                                }
                              >
                                <i className="fa fa-add pe-2" aria-hidden="true">
                                  {" "}
                                  <span className="fafaicon"></span>
                                </i>
                                Add Features
                              </a>
                              {unitId && (
                                <FeatureModal
                                  unitCategoryID={unitId}
                                  FeatureData={FeatureData}
                                  // idx={null}
                                
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-end col-lg-12 col-md-12 col-sm-12 btn-next ">
                      <button
                        type="submit"
                        className="btn next-btn"
                        disabled={loader}
                      >
                        {loader ? (
                          <span className="spinner-border spinner-border-sm mr-1 "></span>
                        ) : null}
                        Submit
                      </button>
                    </div>
  </>
)}
              </>
            )}
            {/* {showDiv === false ? (
        
            ) : (
              <></>
            )} */}
            {(selectedSubTypeID === PropertySubTypeEnum.Building || selectedSubTypeID === PropertySubTypeEnum.MixUse) && (
              <div className="row">
                <div className="col-md-6 col-sm-12 col-lg-6 d-flex align-items-center">
                  <span className="px-3 d-block text-center uploadimage-text">
                    {/* <MultipleImageUpload/> */}
                    <div
                      className="upload-wrapper"
                      style={{ position: "relative" }}
                    >
                      <div className="upload-btn-wrapper">
                        <button
                          type="button"
                          className="btn next-btn next-btn-1"
                          disabled={singleFile.length === 10}
                        >
                          Upload Images
                        </button>
                        {/* (maximum 5mb file) */}
                        <input
                          type="file"
                          name="myfile"
                          onChange={uploadSingleFiles}
                          multiple
                          disabled={singleFile.length === 10}
                        />
                      </div>
                      {imagesizerror && (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "45px",
                            fontSize: "12px",
                          }}
                        >
                          {imagesizerror}
                        </p>
                      )}
                    </div>

                    {/* (maximum 5mb file) */}
                  </span>
                  <div>
                    <div>
                      {/* Add Features button */}
                      <a
                        className="btn addfeature-btn"
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#exampleModalScrollable3"
                        onClick={(e) => addFeature(e, 0)}
                      >
                        <i className="fa fa-add pe-2" aria-hidden="true">
                          {" "}
                          <span className="fafaicon"></span>
                        </i>
                        Add Features
                      </a>
                      <FeatureModal
                        unitCategoryID={unitId}
                        FeatureData={FeatureData}
                        // idx={null}
                      
                      />
                    </div>
                  </div>
                </div>
                <div className="text-end col-lg-6 col-md-6 col-sm-4 btn-next d-flex align-items-center justify-content-end">
                  <button className="btn next-btn" type="submit">
                    Next
                  </button>
                </div>
                <PropertyMasterImageupload
                  singleFile={singleFile}
                  removeImage={removeImage}
                />
              </div>
            )}

          </div>
        </form>
      </div>
      {/* <Demo/> */}
    </>
  );
};

export default AddPropertyForm;
