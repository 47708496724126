
export interface IRentCollectionDashboardProperty {
  propertyMasterID: number;
  addressStr: string;
  buildingNumber: string;
  buildingName: string;
  totalTenantCount: number;
  buildingType: string;
  totalpending: number;
  totalRentReceived: number;
  overDue: number;
  propertyMasterSubTypeID: number;
  totalCount: number;
  imageString: string;
  percentage: number;
  }
  export class RentCollectionDashboardProperty implements IRentCollectionDashboardProperty {
    propertyMasterID: number;
  addressStr: string;
  buildingNumber: string;
  buildingName: string;
  totalTenantCount: number;
  buildingType: string;
  totalpending: number;
  totalRentReceived: number;
  overDue: number;
  propertyMasterSubTypeID: number;
  totalCount: number;
  imageString: string;
  percentage: number;


    constructor(data: RentCollectionDashboardProperty) {
    this.propertyMasterID = data.propertyMasterID ?? 0;
    this.addressStr = data.addressStr ?? '';
    this.buildingNumber = data.buildingNumber ?? '';
    this.buildingName = data.buildingName ?? '';
    this.totalTenantCount = data.totalTenantCount ?? 0;
    this.buildingType = data.buildingType ?? '';
    this.totalpending = data.totalpending ?? 0;
    this.totalRentReceived = data.totalRentReceived ?? 0;
    this.overDue = data.overDue ?? 0;
    this.propertyMasterSubTypeID = data.propertyMasterSubTypeID ?? 0;
    this.totalCount = data.totalCount ?? 0;
    this.imageString = data.imageString ?? '';
    this.percentage = data.percentage ?? 0;
    }
  }
  