
export interface ISingleResponseMessage<T> {
  message: string;
  statusCode: number;
  totalCount: number;
  data: T;
}
export class SingleResponseMessage<T> implements ISingleResponseMessage<T> {
  message: string;
  statusCode: number;
  totalCount: number;
  data: T;
  constructor(data:SingleResponseMessage<T>) {
    this.message = data.message ?? "Internal Server error";
    this.statusCode = data.statusCode ?? 500;
    this.totalCount = data.totalCount ?? 0;
    this.data = data.data ?? {} as T;
  }
}
