interface BannerPorps {
    userNames: {
        userName: string;
    }[];
    speed: number;
  }
  
  export function TitleSection({ userNames, speed }: BannerPorps) {
    const tileStyle = {
      animation: `swipe ${speed}ms linear infinite`,
    };
  
    return (
      <div className='net-titles' style={tileStyle}>
        {userNames && userNames.map(({ userName },index) => (
          <div key={index}  className='net-title'>
            <p className="userName-title mum-gradient-text ">{userName}</p>
            {/* <img src={src} alt={name} /> */}
          </div>
        ))}
      </div>
    );
  }
  
  export default function Notificationscroll({ userNames, speed }: BannerPorps) {
    return (
      <div className='banner-wrapper'>
        <div className='wrapper'>
          <TitleSection userNames={userNames} speed={speed} />
          <TitleSection userNames={userNames} speed={speed} />
        </div>
      </div>
    );
  }
