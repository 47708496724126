
export interface Ireceipt {
  htmlSting: string;
}
  export class Receipt implements Ireceipt {
    htmlSting: string;
    constructor(data: Receipt ) {
     this.htmlSting = data.htmlSting;
    }
  }
  