/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select, { StylesConfig } from 'react-select';
import "toastr/build/toastr.min.css";
import {
    // eslint-disable-next-line prettier/prettier
    createLandlordStaff,
    getAllRoles,
    imageUpload,
    GetstaffDetailById,
    UpdateLandlordStaff,
    SearchByPhoneNumber
} from "../../Services/StaffServices";
const AddStaff = ({
    togglecloseform,
    toggleAddCampaignForm,
    ParamstaffId,
    handleChange,
    handleSubmit,
    handleSearch,
    searchQuery,
    handleFileUpload,
    IdentityImageError,
    IdentityImage,
    idImageUrl,
    StaffformData,
    isDisabled,
    ProfileImageError,
    fileRef,
    fileName,
    isProfileImageFilled,
    defaultimg,
    toggleEditStaffForm,
    roles,
    isIdentityImageFilled,
    loader,
}) => {
    
    const ParamstaffIds = ParamstaffId;
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#E41F07" : "#fff",
            color: state.isFocused ? "#fff" : "#000",
            "&:hover": {
                backgroundColor: "#E41F07",
            },
        }),
    };


    const isButtonDisabled = !isProfileImageFilled || !isIdentityImageFilled  || !StaffformData.cell_number || !StaffformData.username || !StaffformData.id_number || !StaffformData.role;
    const UpdateButtonDisabled = !StaffformData.cell_number || !StaffformData.username || !StaffformData.id_number || !StaffformData.role;
    
   
    return (
        <>
            <div className="sidebar-layout">
                <div className="sidebar-header">
                    <h4>Staff</h4>
                    <Link
                        to="#"
                        className="sidebar-close toggle-btn"
                        onClick={togglecloseform}
                    >
                        <i className="ti ti-x" />
                    </Link>
                </div>
                <div className="toggle-body">
                    <form className="" onSubmit={(e) => handleSubmit(e)}>
                        <div className="pro-create">
                            <div className="d-flex gap-4 pt-4">
                                <div className="position-relative">
                                    <input
                                        onChange={(e) => handleFileUpload(5, e)}
                                        type="file"
                                        ref={fileRef}
                                        id="profileUrls"
                                        hidden
                                        accept="image/*"
                                    />
                                    <img
                                        className="rounded-circle "
                                        onClick={() => fileRef.current.click()}
                                        src={defaultimg || "https://d38tibxba17kdz.cloudfront.net/StaffManagementImages/defaultProfileImage.svg"}
                                        alt="Profile"
                                        width={"80px"}
                                        height={"80px"}

                                    />

                                </div>
                
                                <div className="pt-3">
                                    <h4>Profile photo</h4>
                                    <p>This will be displayed on your profile.</p>

                                    {ProfileImageError && <span style={{ color: "red" }}>{ProfileImageError}</span>}

                                </div>
                            </div>
                            <div className="row">
                                {!ParamstaffIds ? (
                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label" id="search">
                                                Search Staff <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                placeholder="923456798654"
                                                type="number"
                                                value={searchQuery}
                                                maxLength={12}
                                                // onKeyDown={(e) => handleSearch(e)}
                                                // onChange={handleSearch}
                                                // onChange={(e) => {
                                                    
                                                //     const value = e.target.value.replace(/\D/g, "").slice(0, 12);
                                                //     handleSearch(value);
                                                // }}
                                                onKeyDown={(e) => {
                                                    // Disallow E, e, and other unwanted keys
                                                    if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    // Allow only numeric values and limit to 12 digits
                                                    const value = e.target.value.slice(0, 12);
                                                    handleSearch(value);
                                                }}
                                            />

                                        </div>
                                    </div>) : (null)}
                                <div className="col-md-6">
                                    <div className="form-wrap">
                                        <label className="col-form-label">
                                            Name <span className="text-danger">*</span>
                                        </label>
                                        <input className="form-control"
                                            type="text"
                                            id="username"
                                            placeholder="Name"
                                            value={StaffformData.username}
                                            onChange={(e) => handleChange(e)}
                                            aria-describedby="text"
                                            required
                                            disabled={isDisabled.username && StaffformData.username !== ''} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-wrap">
                                        <label className="col-form-label">
                                            ID Number <span className="text-danger">*</span>
                                        </label>
                                        <input className="form-control"
                                            type=""
                                            id="id_number"
                                            placeholder="659874"
                                            value={StaffformData.id_number}
                                            onChange={(e) => handleChange(e)}
                                            aria-describedby="number"
                                            required
                                            disabled={isDisabled.id_number && StaffformData.id_number !== ''} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-wrap">
                                        <label className="col-form-label">
                                            Cell Number <span className="text-danger">*</span>
                                        </label>
                                        <input className="form-control"
                                            type="tel"
                                            id="cell_number"
                                            placeholder="923026705676"
                                            maxLength={12}
                                            value={StaffformData.cell_number}
                                            onChange={(e) => handleChange(e)}
                                            required
                                            disabled={!ParamstaffIds } />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-wrap">
                                        <label className="col-form-label">
                                            Role <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            id="role"
                                            className="form-select form-c"
                                            value={StaffformData.role}
                                            onChange={(e) => handleChange(e)}
                                            disabled={isDisabled.role && StaffformData.role !== ""}
                                            aria-label="Default select example"
                                            required
                                        >
                                            <option value="" disabled>
                                                Select Role
                                            </option>
                                            {roles.map((role) => (
                                                <option key={role.id} value={role.id}>
                                                    {role.desc}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">

                                    <div className="form-wrap">
                                        <label className="col-form-label">
                                            Attachment <span className="text-danger">*</span>
                                        </label>
                                        <div className="drag-attach">
                                            <input
                                                type="file"
                                                id="identityImage"
                                                accept="image/*"
                                                onChange={(e) => handleFileUpload(9, e)}

                                            />
                                        
                                        { idImageUrl ? (
                <div className="input-group-text">
                    <a href={idImageUrl} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: "50px", height: "25px" }} src={idImageUrl} alt="Uploaded" />
                    </a>
                </div>
            ) : IdentityImage  ? (
                <div className="input-group-text">
                    <a href={idImageUrl} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: "50px", height: "25px" }} src={IdentityImage} alt="Uploaded" />
                    </a>
                </div>
            ) : (
                <div className="img-upload">
                    <i className="ti ti-file-broken" />
                    {fileName ? fileName : "Upload File"}
                </div>
            )}
                                                {IdentityImageError && <div style={{ color: "red" }}>{IdentityImageError}</div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {ParamstaffIds ? (
                                <div className="col-md-6">
                                    <label className="form-label">Active User</label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control form-cr"
                                            placeholder="User Active/Inactive"
                                            id="activeUser"
                                            disabled={StaffformData.activeUser === 8}
                                            value={StaffformData.activeUser === 8 ? "Pending" : StaffformData.activeUser === 12
                                                ? "Rejected" : (StaffformData.activeUser == 1 ? "Active" : "Inactive")}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="input-group-text input-g">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    id="activeUser"
                                                    checked={StaffformData.activeUser === 8 || StaffformData.activeUser == 1}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={StaffformData.activeUser === 8}
                                                />
                                                <span className={
                                                    StaffformData.activeUser === 8
                                                        ? "sliderdisable"
                                                        : StaffformData.activeUser == 1
                                                            ? "slider"
                                                            : "sliderinactive"
                                                }></span>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            ) : (null)}
                            <div className="submit-button text-end">
                                <button onClick={togglecloseform} className="btn btn-light sidebar-close">
                                    Cancel
                                </button>


                                {ParamstaffIds ? (
                                    <>         <button type="submit" className="btn btn-primary" disabled={UpdateButtonDisabled || loader}>
                                        {loader ? (


                                            <span className="spinner-border spinner-border-sm mr-1 "></span>

                                        ) : null}
                                        Update staff</button>
                                    </>
                                ) : (
                                    <>
                                        <button type="submit" className="btn btn-primary" disabled={isButtonDisabled || loader}>
                                            {loader ? (


                                                <span className="spinner-border spinner-border-sm mr-1 "></span>

                                            ) : null}
                                            Add Staff</button>
                                    </>

                                )}

                            </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddStaff

