export interface IFeature {
  featureTypesID: number;
  featureTypes: string | null;
  desc: string;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string | null;
  modifyTime: string | null;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;
}

export class Feature implements IFeature {
  featureTypesID: number;
  featureTypes: string | null;
  desc: string;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string | null;
  modifyTime: string | null;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;

  constructor(data: Partial<Feature>) {
    this.featureTypesID = data.featureTypesID ?? 0;
    this.featureTypes = data.featureTypes ?? null;
    this.desc = data.desc ?? '';
    this.id = data.id ?? 0;
    this.status = data.status ?? 0;
    this.statusCode = data.statusCode ?? null;
    this.createdTime = data.createdTime ?? null;
    this.modifyTime = data.modifyTime ?? null;
    this.isCreatedBy = data.isCreatedBy ?? null;
    this.isUpdatedBy = data.isUpdatedBy ?? null;
  }
}
