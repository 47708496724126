import React, { useState } from 'react'
import { useGetMaintenanceCategoryQuery } from '../../redux/CommonApi'
import { priorityEnumMapUrl } from '../../redux/RequestTypeEnum'
import MultipleImageUpload from '../pms/AddProperty/MultipleImageUpload';
import { ResponseMessage } from '../Models/GenericModel/ResponseMessage';
import { MaintenanceRequestCategory } from '../Models/MaintenanceRequest/MaintenanceRequestCategory';
import { MaintenanceRequestImage } from '../Models/MaintenanceRequest/MaintenanceRequestImage';
export const SelectCategoryCard = ({ setSelectCategory, setPriorityID, selectCategory, priorityID, desc, setDesc,setUploadedImages,uploadedImages }) => {
    const { data, error, isLoading } = useGetMaintenanceCategoryQuery() as {data : ResponseMessage<MaintenanceRequestCategory>, error: any, isLoading:any};
    const [errors, setError] = useState("");

  
    const uploadSingleFiles = (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            if (file.size > 5 * 1024 * 1024) {
                setError("Each file must be less than 5MB");
                setTimeout(() => {
                    setError("");
                }, 5000);
                continue;
            }

            setError("");

            reader.onload = (event) => {
                const result = event.target.result;
                let byteArray;

                if (typeof result !== "string") {
                    byteArray = new Uint8Array(result);
                }

                const newImage = new MaintenanceRequestImage( {
                    status: 1,
                    image: byteArray ? Array.from(byteArray) : null, // Store as byte array
                    defaultIndicator: true,
                    uniqueIdentifier: 1, // Use timestamp for unique ID
                    maintenanceRequestMainID: 0,
                    url: URL.createObjectURL(file),
                    name: file.name,
                });

                setUploadedImages((prevImages) => [...prevImages, newImage]);
            };

            reader.readAsArrayBuffer(file);
        }
    };
    const handleRemoveImage = (index) => {
        const filteredImages = uploadedImages.filter((_, i) => i !== index);
        setUploadedImages(filteredImages);
    };
    return (
        <div>
            <p className='page-title'>Select Category</p>
            <div className="row">
                {data && data?.data.map((item, index) => (
                    <div key={index} className="col-md-2">
                        <div className={`card main-card card-unit-buid align-items-center d-flex justify-content-center ${selectCategory === item.id ? 'bg-danger' : ''}`} onClick={() => setSelectCategory(item.id)}>
                            <div className="card-body text-center">
                                <div className='circle-category align-items-center d-flex justify-content-center'>
                                    <img src={item.url} alt={item.desc} width={'30px'} />
                                </div>
                                <p className='pt-2 page-title'>{item.desc}</p>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <p>Select Request Urgency</p>
            <div className="row">
                {Object.values(priorityEnumMapUrl).reverse().map((item, index) => (
                    <div key={index} className="col-md-2">
                        <div className={`card main-card card-unit-buid align-items-center d-flex justify-content-center ${priorityID === item.id ? 'bg-danger' : ''}`} onClick={() => setPriorityID(item.id)}>
                            <div className="card-body text-center">
                                <div className="circle-category align-items-center d-flex justify-content-center">
                                    <img src={item.url} alt={item.text} width={"30px"} />
                                </div>
                                <p className="pt-2 page-title">{item.text}</p>
                            </div>
                        </div>
                    </div>
                ))}

            </div>

            <div className="card addproperty-form drop-shodow-card">
                <div className="card-body">
                    <span></span>
                    <div className="d-flex pb-2">

                        <span
                            style={{
                                width: "11.75px",
                                height: "41.13px",
                                borderRadius: "11.75px",
                                background: "#BE0C2F",
                            }}
                        ></span>
                        <p className="addprperty-h4 pt-1">
                            <span className="ps-2 page-title">Descripton</span>
                        </p>
                    </div>
                    <textarea
                        rows={10}
                        cols={10}
                        className="form-control form-textarea page-title"
                        placeholder="Write about Request...."
                        name="description"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="container-fluid" style={{ paddingTop: "20px" }}>
                    <form>
                        <div className="form-group multi-preview">
                            <div className="image-grid">

                                {uploadedImages.length > 0 &&
                                    uploadedImages.map((image, index) => (
                                        <div key={index} className="image-wrapper">
                                            <img
                                                src={image.url}
                                                alt={image.name}
                                                className="uploaded-image"
                                                style={{aspectRatio:"3/2"}}
                                            />
                                            <span
                                                className="remove_img d-flex justify-content-center align-items-center"
                                                onClick={() => handleRemoveImage(index)}
                                                
                                            >
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ))}
                            </div>

                            <div className="upload-wrapper" style={{ position: "relative" }}>
                                <div className="upload-btn-wrapper">
                                    <button
                                        type="button"
                                        className="btn btn-danger btndangerbackground"
                                        disabled={uploadedImages.length === 10}
                                    >
                                        Upload Images
                                    </button>
                                    <input
                                        type="file"
                                        name="myfile"
                                        onChange={uploadSingleFiles}
                                        multiple
                                        disabled={uploadedImages.length === 10}
                                    />
                                </div>
                                {error && <p style={{ color: "red", position: "absolute", top: "45px" }}>{error}</p>}
                            </div>

                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="text-end" style={{ paddingRight: "20px" }}>
                        <i className="fa fa-picture-o fafaicon" aria-hidden="true">
                            <span className="fafaicon ps-2">{uploadedImages.length}</span>
                        </i>
                    </div>
                </div>
            </div>
        </div>
    )
}
