
export interface IExpenseSubCategory {
  catid: number;
  subcatdesc: string;
  url: string;
  mapid: number;
}

export class ExpenseSubCategory implements IExpenseSubCategory {
  catid: number;
  subcatdesc: string;
  url: string;
  mapid: number;

  constructor(data: ExpenseSubCategory) {
    this.catid = data.catid ?? 0;
    this.subcatdesc = data.subcatdesc ?? '';
    this.url = data.url ?? '';
    this.mapid = data.mapid ?? 0;
  }
}
