
export interface IExpenseCategory {
  id: number;
  desc: string;
  url: string;
}

export class ExpenseCategory implements IExpenseCategory {
  id: number;
  desc: string;
  url: string;

  constructor(data: ExpenseCategory) {
    debugger
   this.id = data.id ?? 0;
    this.desc = data.desc ?? "";
    this.url = data.url ?? "";
  }
}
