import api from "../Interceptor";

export const AssignSecurityGrouptostaff = async (
  staffObject,
  AssignSecuritygroupToStaff,
  UserID
) => {
  
  try {
   
    const response = await api.post("/Staff/AssignSecuritygroupToStaff", {
      landlordStaffId: staffObject.landlordStaffId,
      securityGroupId: AssignSecuritygroupToStaff.securityGroupID,
      landlordId: UserID,
    });
  
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};

export const createSecurityGroup = async (postData, setFormData) => {
  try {
    const response = await api.post("/SecurityGroup", postData);
    if (response.status === 200) {
   
      setFormData({
        name: "",
        desc: "",
        activeUser: false,
      });
    } else {
      console.error("Failed to post security group:", response.statusText);
    }
    return response.data;
  } catch (error) {
    console.error("Error posting security group:", error);
    throw error;
  }
};

export const getSecurityGroupStaff = async (securityGroupId, UserID,pageNumber,rowNumbers) => {
  try {
  
    
    const response = await api.get(
      `/SecurityGroup/StaffBySecurityGroup?SecurityGroupId=${securityGroupId}&UserID=${UserID}&PageNumber=${pageNumber}&RowNumber=${rowNumbers}`
    );

    // if (
    //   response.data.length === 1 &&
    //   response.data[0].staffId === null &&
    //   response.data[0].status === null &&
    //   response.data[0].name === null
    // ) {
    //   return [];
    // }

    return response.data || [];
  } catch (error) {
    console.error("Error fetching:", error);
    throw error;
  }
};

export const RemoveUserFromSecurityGroup = async (Staff) => {
  try {
   
    const response = await api.post("/Permission/ChangeSecurityGroupByStaff", {
      landlordId: Staff.landlordID,
      staffId: Staff.staffId,
    });
    
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};



export const AllSecurityGroups = async (UserID,pageNumber,rowNumbers) => {
  try {
    

    const response = await api.get(
      `/SecurityGroup/AllSecurityGroups?UserID=${UserID}&PageNumber=${pageNumber}&RowNumber=${rowNumbers}`
    );
   
    if (response.status === 200) {
     
     
    } else {
      console.error("Failed to Get permissions data:", response.statusText);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting permission data:", error);
  }
};



export const ChangeSecurityGroupStatus = async (SecurityGroup) => {
  try {
    
    
    const response = await api.post("SecurityGroup/ActiveInactiveSecurityGroup", {
      securityGroupId: SecurityGroup.securityGroupID,
      status: SecurityGroup.status === 1 ? 2 : 1,
    });
   
    return response;
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};