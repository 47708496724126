import { Autocomplete, styled } from '@mui/material';

export const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
    "& .MuiFormLabel-root-MuiInputLabel-root": {
        display: 'none',
    },
    "& .MuiFormLabel-root .MuiInputLabel-root": {
        fontSize: "14px",
        top: "-6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: '40',
        border: "1px solid #e8e8e8",
        borderBottomRightRadius: 'none',
        borderBottomLeftRadius: 'none',
        borderStartEndRadius: 'none',
        borderStartStartRadius: '0px',
        borderLeft: 'none',
        backgroundColor: '#F9F9FC',
    },
    '&.MuiInputBase-root-MuiOutlinedInput-root ': {
        fontSize: '1.4rem',
    },
    '&.MuiAutocomplete-root': {
        width: '100%',
        height: '2.4rem',
    },
    "& .MuiAutocomplete-input": {
        fontSize: '14px !important',
        fontWeight: '400',
        color: '#6f6f6f',
        border: "none !important",
        backgroundColor: 'none !important',
        borderRadius: '5px',
        padding: '10px 4px 10px 5px !important',
    },
    "&.MuiAutocomplete-root .MuiOutlinedInput-root ": {
        padding: '0px',
        border: "1px solid #e8e8e8",
        backgroundColor: '#F9F9FC',
    },
    "& .MuiFormLabel-root.Mui-focused": {
        color: 'rgba(0, 0, 0, 0.6)',
        display: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: "1px solid #e8e8e8",
    },
    '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: "1px solid #e8e8e8",
        backgroundColor: '#F9F9FC',
    },
    '&.MuiAutocomplete-root:hover': {
        border: '1px solid #e8e8e8 !important',
        backgroundColor: '#F9F9FC',
    },
    '& .Mui-focused fieldset': {
        border: "1px solid #e8e8e8",
        backgroundColor: '#F9F9FC',
    },
    '& :focus': {
        backgroundColor: 'none',
        border: 'none !important',
        outline: 'none !important',
    },
    '&:focus-visible': {
        border: 'none !important',
        outline: 'none !important',
    },
    '&:hover': {
        border: 'none !important',
        outline: 'none !important',
        borderRadius: '5px',
        borderBottomRightRadius: 'none',
        borderBottomLeftRadius: 'none',
        borderStartEndRadius: 'none',
        borderStartStartRadius: 'none',
        borderLeft: 'none !important',
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-input': {
        fontSize: '14px !important',
        fontWeight: '400',
        color: '#717171',
        border: "1px solid #e8e8e8",
        backgroundColor: '#F9F9FC',
        borderRadius: '5px',
    },
    '&.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium': {
        fill: '#BE0C2F !important'
    },
    "&.MuiAutocomplete-root.MuiAutocomplete-inputRoot": {
         // Adjusts padding after removing the icon
    },
    // Dark theme styles
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        paddingRight: '0 !important', // Override padding-right
    },
    '[data-theme="dark"] & .MuiAutocomplete-input': {
        backgroundColor: '#171724',  // Dark background color
        color: '#fff',  // White text color
        border: '1px solid #555',  // Darker border color

        // Placeholder text color for dark theme
        "&::placeholder": {
            color: '#fff',  // Dark placeholder color
            opacity: 1,
        },
    },
    '[data-theme="dark"] & .MuiOutlinedInput-notchedOutline': {
        backgroundColor: '#333',
        border: '1px solid #555',
    },
    '[data-theme="dark"] & .MuiAutocomplete-root:hover': {
        backgroundColor: '#444',
        border: '1px solid #777',
    },
}));
