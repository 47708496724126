import { MaintenanceRequestMain } from "./MaintenanceRequestMain";


export interface IMaintenanceRequestImage {
  maintenanceRequestMainID: number;
  maintenanceRequestMain: MaintenanceRequestMain | null;
  image: number[] | null; 
  url: string;
  defaultIndicator: boolean;
  uniqueIdentifier: number;
  name:string
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string;
  modifyTime: string;
  isCreatedBy: string | null;
  isUpdatedBy: string | null;
}
export class MaintenanceRequestImage implements IMaintenanceRequestImage { 
  maintenanceRequestMainID: number;
  maintenanceRequestMain: MaintenanceRequestMain | null;
  image: number[] | null; 
  url: string;
  defaultIndicator: boolean;
  uniqueIdentifier: number;
  name:string
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string;
  modifyTime: string;
  isCreatedBy: string | null;
  isUpdatedBy: string | null;
constructor(data: Partial<MaintenanceRequestImage> ={} ){
this.maintenanceRequestMainID = data.maintenanceRequestMainID || 0;
this.maintenanceRequestMain = data.maintenanceRequestMain || null;
this.image = data.image || null;
this.url = data.url || "";
this.defaultIndicator = data.defaultIndicator || false;
this.uniqueIdentifier = data.uniqueIdentifier || 0;
this.name = data.name || "";
this.id = data.id || 0;
this.status = data.status || 0;
this.statusCode = data.statusCode || null;
this.createdTime = data.createdTime || "";
this.modifyTime = data.modifyTime || "";
this.isCreatedBy = data.isCreatedBy || null;
this.isUpdatedBy = data.isUpdatedBy || null;
  }
}

