export interface IMaintenanceRequestUnits {
  propertyMasterID: number;
  unitID:number;
  caption:string; 
  imageUrl:string;
  }
  export class MaintenanceRequestUnits implements IMaintenanceRequestUnits {
    propertyMasterID: number;
    unitID:number;
    caption:string; 
    imageUrl:string;
  constructor(data: MaintenanceRequestUnits){
 this.propertyMasterID = data.propertyMasterID ?? 0;
 this.unitID = data.unitID ?? 0;
 this.caption = data.caption ?? '';
 this.imageUrl = data.imageUrl ?? '';
  }
  }
