import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/Store";
import ALLRoutes from "./router/router";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import '../src/style/css/feather.css'
import '../src/style/icon/tabler-icons/webfont/tabler-icons.css'
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '../src/style/icon/boxicons/boxicons/css/boxicons.min.css'
import '../src/index.scss'
import React from "react";
import Feature from "./feature-module/feature";
export default function Root(props) {
  const correctPassword = '12345';

  // Disable right-click and prompt for password
  // const handleContextMenu = (e) => {
  //   e.preventDefault(); // Prevent the default right-click action (context menu)
  //   const userPassword = prompt('Enter the password to open inspect:');
  //   if (userPassword === correctPassword) {
  //     alert('Access granted! You can now open Developer Tools.');
  //     // Allow right-click for future attempts (optional)
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   } else {
  //     alert('Incorrect password. Access denied.');
  //   }
  // };

  // Attach the right-click listener on component mount
  // React.useEffect(() => {
  //   document.addEventListener('contextmenu', handleContextMenu);
  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);
  // React.useEffect(() => {
  //   // Password prompt
  //   const password = "YourPassword"; // Replace with your desired password
  //   const enteredPassword = prompt("Please enter the password to open the console:");

  //   if (enteredPassword !== password) {
  //     alert("Incorrect password. Access denied.");
  //     setTimeout(() => {
  //       window.location.href = "http://localhost:9000/"; // Redirect to another page
  //     }, 1000);
  //   } else {
  //     console.log("Access granted");
  //   }

  //   // Disable right-click
  //   document.addEventListener("contextmenu", function (e) {
  //     e.preventDefault();
  //   });

  //   // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J
  //   const handleKeyDown = (e: KeyboardEvent) => {
  //     if (e.keyCode === 123 || (e.ctrlKey && e.shiftKey && [73, 74].includes(e.keyCode))) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  return (
    <div
    // onContextMenu={handleContextMenu}
    >
      <Provider store={store}>
        <Feature />
      </Provider>
    </div>
  );
}
