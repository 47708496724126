
export interface IAddExpenseRequest {
  expenseSubCatMapingID: number;
  expenseDate: string; // You can use `Date` if you prefer
  propertyUnitID: number;
  propertyMasterID: number;
  amount: string;
  status: number; // Can be an enum or string as needed
  desc: string;
}
export class AddExpenseRequest implements IAddExpenseRequest {
  expenseSubCatMapingID: number;
  expenseDate: string;
  propertyUnitID: number;
  propertyMasterID: number;
  amount: string;
  status: number;
  desc: string;
  constructor(data: IAddExpenseRequest) {
    this.expenseSubCatMapingID = data.expenseSubCatMapingID ?? 0;
    this.expenseDate = data.expenseDate ?? "";
    this.propertyUnitID = data.propertyUnitID ?? 0;
    this.propertyMasterID = data.propertyMasterID ?? 0;
    this.amount = data.amount ?? "";
    this.status = data.status ?? 0;
    this.desc = data.desc ?? "";
  }
}
