import React from "react";
import { Modal } from "react-bootstrap";

const AssignToTechnicianModal = ({
  show,
  onHide,
  technicianIcon = "fa fa-phone",
  reasigntechnician,
  formData,
  formErrors,
  onChange,
  onSubmit,
  loader,
}) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      animation={false}
      centered
      dialogClassName="centered-modal custom-modal-bg w-25 w-sm-100 "
      style={{ paddingRight: "1rem" }}
    >
      <div className="modal-content-wrapper">
        <div className="d-flex justify-content-center align-items-center text-center mb-3">
          <div className="icon-circle bg-circle d-flex justify-content-center align-items-center mx-2">
            <i
              className={technicianIcon}
              style={{
                fontSize: "18px",
                color: "#345C66",
                display: "inline-block",
              }}
            ></i>
          </div>
        </div>
        {reasigntechnician == 2 ? (
          <div className="text-center">
            <h6 className="mb-2 text-white">Re Assign to new Technician </h6>
          </div>
        ) : (
          <></>
        )}
        <Modal.Body>
          <form className="flex-column">
            <div className="mb-3">
              <div>
                <label className="mb-2">Technician Name</label>
              </div>
              <input
                type="text"
                name="technicianName"
                value={formData.technicianName}
                onChange={onChange}
                className="form-control rounded-3"
                placeholder="Enter Name"
              />
              {formErrors.technicianName && (
                <div className="text-danger">{formErrors.technicianName}</div>
              )}
            </div>
            <div>
              <div>
                <label className="mb-2">Technician Number</label>
              </div>
              <input
                type="number"
                name="technicianNumber"
                value={formData.technicianNumber}
                // onChange={onChange}
                onChange={(e) => {
                  // Validate and limit the length to 12 digits
                  const { name, value } = e.target;
                  if (value.length <= 12) {
                    onChange(e); // Call the existing handleChange function
                  }
                }}
                className="form-control rounded-3"
                placeholder="923252627898"
                onKeyDown={(e) => {
                  // Prevent unwanted keys like E, e, +, and -
                  if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
               
              />
              {formErrors.technicianNumber && (
                <div className="text-danger">{formErrors.technicianNumber}</div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
          <div className="pt-2">
            <button
              disabled={loader}
              className="btn add-popup w-100 p-2 rounded-3"
              onClick={onSubmit}
            >
              {loader && <i className="fa fa-spinner fa-spin"></i>}
              Assign
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AssignToTechnicianModal;
