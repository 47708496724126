import { MaintenanceRequestMain } from "./MaintenanceRequestMain";


export interface IRequestDetail {
  maintenanceRequestMainID: number;
  maintenanceRequestMain: MaintenanceRequestMain;
  requestDate: string;
  status: number;
  isCreatedBy: number;
}

export class RequestDetail implements IRequestDetail {
  maintenanceRequestMainID: number;
  maintenanceRequestMain: MaintenanceRequestMain;
  requestDate: string;
  status: number;
  isCreatedBy: number;
  constructor(data: Partial<RequestDetail> = {}) {
  
    this.maintenanceRequestMain = new MaintenanceRequestMain(data.maintenanceRequestMain);
    this.requestDate = data.requestDate ?? '';
    this.status = data.status ?? 0;
    this.isCreatedBy = data.isCreatedBy ?? 0;
  }
}
