import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useGetAggRentHistoryQuery, useGetAgRentHistoryCountQuery } from '../redux/CommonApi';
import dayjs from "dayjs";
import { StackPagination } from '../shared/StackPagination';
import RentDetailPopup from './RentDetailPopup';
import { Loader } from '../shared/Loader';
import { NoData } from '../shared/NoData';
import { AgreementRentHistory } from '../feature-module/Models/RentCollection/AgreementRentHistory';

export const RentHistory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const Agid = sessionStorage.getItem('aggrementId');
    const { aggrementId } = location.state || {};
    const [pageNumber, setpageNumber] = React.useState(1);
    const [isReopenpopup, setReopenpopup] = React.useState(false);
    const handleReopenClose = () => {
        setReopenpopup(false);


    };
    React.useEffect(() => {
        if (aggrementId) {
            sessionStorage.setItem('aggrementId', aggrementId);
        }
    }, [aggrementId]);
    const {
        data: RentHistorydata ,
        error,
        isLoading,
    } = useGetAggRentHistoryQuery({
        AgreementId: aggrementId || Agid,
        pageNumber: pageNumber
    }) as { data: AgreementRentHistory[]; error: any; isLoading: any; };

    const {
        data: RentHistorycount = [],
        error: errorCount,
        isLoading: isLoadingCount,
    } = useGetAgRentHistoryCountQuery({
        AgreementId: aggrementId || Agid,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading rent history</div>;
    }

    if (RentHistorydata.length === 0) {
        return<div className="page-wrapper"><div className="content"><NoData /></div></div> ;
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        debugger
        setpageNumber(value);
    };
    const handleCardClick = (aggrementId) => {
        setReopenpopup(true);

    };


    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                  
                    {RentHistorydata.length > 0 ? (
                        <div className="card px-4 py-4 pb-5">

                            <div className="row gx-2 gy-2">
                                <h5 className='pb-4'>Rent History</h5>
                                {RentHistorydata && RentHistorydata?.map((rent, index) => (
                                    <div key={index} className="col-xxl-4 col-xl-12 col-sm-12 mb-1 all_tenants"
                                        onClick={() =>
                                            handleCardClick(
                                                rent.rentReceived
                                            )
                                        }>
                                        <div className="card m-0 card-shadow-1 cursor-pointer">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6 text-start py-2">
                                                        <h6 className=" sm-user text-truncate">Rent of {rent.rentMonthName} {rent.rentyear}</h6>
                                                        <p className="sm-user text-truncate">Paid on {dayjs(rent.rentRecDate).format("DD MMM YYYY")}</p>
                                                    </div>

                                                    <div className="col-sm-6 text-end py-3">
                                                        <h5 className=" text-center">
                                                            <span >OMR {rent.rentReceived.toFixed(1)}</span>
                                                        </h5>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (<Loader />)}
                    {RentHistorycount > 12 && (
                        <div className="row row1 bg-white fixed-bottom1" >
                            <div className="d-flex justify-content-center">
                                <StackPagination
                                    tenancyRequestCount={RentHistorycount}
                                    rowNumbers={12}
                                    pageNumber={pageNumber}
                                    handlePageChange={handlePageChange}

                                />
                            </div>
                        </div>
                    )}

                </div>
                <div
                    className={
                        isReopenpopup ? "toggle-popup sidebar-popup" : "toggle-popup"
                    }
                >

                    <RentDetailPopup handleReopenClose={handleReopenClose} />
                </div>
            </div>



        </>
    );
};
