import React from "react";
import { RentCollectionAllTenants } from "./RentCollectionAllTenants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chip, Stack } from "@mui/material";
import toastr from "toastr";
import { Rentstatus, RentstatusStyles } from "../redux/RequestTypeEnum";
import { useGetPropertyRentStatusQuery } from "../redux/CommonApi";
import { StackPagination } from "../shared/StackPagination";
import { useDispatch } from "react-redux";
import { setRentPropertyMasterId } from "../redux/Commonslice";
import { Loader } from "../shared/Loader";
import "toastr/build/toastr.min.css";
import { PropertyRentStatus } from "../feature-module/Models/RentCollection/PropertyRentStatus";
import { RentCollectionResponse } from "../feature-module/Models/RentCollection/RentCollectionResponse";

export const AllTenants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRequests, setSelectedRequests] = React.useState([]);
  const [activeChip, setActiveChip] = React.useState(Rentstatus.Paid);
  const [pageNumber, setpageNumber] = React.useState(1);
  const [IscheckedTenant, setIscheckedTenant] = React.useState(false);
  const location = useLocation();
  const [ischeck, setIscheck] = React.useState(false);
  const { propertyMasterID } = location.state || {};

  const {
    data: Rentstatusdata,
    error,
    isLoading,
  } = useGetPropertyRentStatusQuery({
    PropertyMasterID: propertyMasterID,
    Option: activeChip,
    pageNumber: pageNumber,
  }) as { data: RentCollectionResponse<PropertyRentStatus>, isLoading: boolean, error: any };
  const handleChipClick = (id) => {
    setActiveChip(id);
    setIscheck(false);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setpageNumber(value);
  };
  // const handleClick = () => {
  //   dispatch(setRentPropertyMasterId(100));
  // };
  const handleSelectAll = (e) => {
    e.preventDefault();
    if (activeChip === Rentstatus.Paid) {
      navigate("/admin/rentCollectiontenant", {
        state: { propertyMasterID: propertyMasterID },
      });
    } else {
      setIscheck(true);
      if (!IscheckedTenant) {
        toastr.info(" Please Select to send reminder ");
      }
    }
  };
  // { const { label1, colors1, backgroundColord1 } = RentstatusStyles[activeChip];}
  if (isLoading) return <Loader />;
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <h5>Your Property</h5>
          <Stack
            direction="row"
            spacing={2}
            className="my-3"
            sx={{
              overflowX: "auto",
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            {Object.entries(Rentstatus).map(([key, id]) => {
              const { label, color, backgroundColor } = RentstatusStyles[id];
              const isActive = activeChip === id;

              return (
                <Chip
                  key={id}
                  label={label}
                  onClick={() => handleChipClick(id)}
                  sx={{
                    backgroundColor: isActive ? backgroundColor : "#fff",
                    color: isActive ? color : "#000",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "50px",
                    fontFamily: "'Noto Sans', sans-serif",
                    border: "none",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: isActive ? backgroundColor : "#fff",
                      color: isActive ? color : "#000",
                      boxShadow: "0px 4px 6px  rgba(0, 0, 0, 0.1)",
                    },
                  }}
                />
              );
            })}
          </Stack>
          <div>
            <div className="row">
              <div className="col-md-5 col-sm-4"></div>
              <div className="col-md-7 col-sm-8">
                <div
                  className="export-list text-sm-end me-2"
                  // onClick={handleClick}
                >
                  <ul>
                    <li>
                      {Rentstatusdata.data?.length > 0 ? (
                        <button
                          // to="/admin/rentCollectiontenant"
                          // state={{ propertyMasterID }}
                          onClick={handleSelectAll}
                          style={{
                            // background: RentstatusStyles[activeChip ] ?.backgroundColor,
                            // borderColor:RentstatusStyles[activeChip]?.backgroundColor
                            background:
                              activeChip === Rentstatus.Paid
                                ? "#3A9CB5" // Override background color when activeChip is Paid
                                : RentstatusStyles[activeChip]?.backgroundColor,
                            borderColor:
                              activeChip === Rentstatus.Paid
                                ? "#3A9CB5" // Override border color when activeChip is Paid
                                : RentstatusStyles[activeChip]?.backgroundColor,
                          }}
                          className="btn btn-primary add-popup"
                        >
                          {activeChip === Rentstatus.Paid ? (
                            <>
                              <i className="ti ti-square-rounded-plus" />
                              Rent Transactions
                            </>
                          ) : (
                            "Send Remainder"
                          )}
                        </button>
                      ) : (
                        <></>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center p-3 pb-0 pt-1  ">
            {Rentstatusdata.data?.length > 0 ? (
              <div className="d-flex flex-column align-items-center">
                <h5 className="text-center mb-1">
                  <span className="card-title text-primary">
                    {Rentstatusdata.totalCount} Results Found
                  </span>
                </h5>
              </div>
            ) : (
              <></>
            )}
            <div className="d-flex flex-column align-items-center me-3">
              {Rentstatusdata.data?.length > 0 ? (
                <h5 className="text-center mb-1">
                  <span className="card-title text-danger">
                    {" "}
                    OMR {""} {Rentstatusdata.totalPending}
                  </span>
                </h5>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className=" row pb-5">
          <div className="pt-2">
            <div className="card px-4 py-4">
              <RentCollectionAllTenants
                Rentstatusdata={Rentstatusdata.data}
                ischeck={ischeck}
                setIscheckedTenant={setIscheckedTenant}
              />
            </div>
          </div>
        </div>
        {Rentstatusdata.totalCount > 10 && (
          <div className="row row1 bg-white fixed-bottom1">
            <div className="d-flex justify-content-center">
              <StackPagination
                tenancyRequestCount={Rentstatusdata.totalCount}
                rowNumbers={12}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
