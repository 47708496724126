
export interface IResponseMessage<T> {
  message: string;
  statusCode: number;
  totalCount: number;
  data: T[];
}
export class ResponseMessage<T> implements IResponseMessage<T> {
  message: string;
  statusCode: number;
  totalCount: number;
  data: T[];
  constructor(data:IResponseMessage<T>) {
    this.message = data.message ?? "Internal Server error";
    this.statusCode = data.statusCode ?? 500;
    this.totalCount = data.totalCount ?? 0;
    this.data = data.data ?? [];
  }
}
