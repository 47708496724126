import React from "react";
import { Link } from "react-router-dom";
import { useGetPropertyMasterCategoryDetailQuery } from "../../../redux/CommonApi";
import { PropertySubTypeEnum } from "../../../redux/RequestTypeEnum";
import { OwnerPropertyMasterDetail } from "../../Models/Pms/OwnerPropertyMasterDetail";

const UnitsCard = ({ propertyId, status, setSelectedPropertyUnit }) => {
  const { data, error, isLoading } = useGetPropertyMasterCategoryDetailQuery({
    PropertyMasterId: propertyId,
    status: status,
  }) as {data: OwnerPropertyMasterDetail[], error: any, isLoading: boolean};
  if (isLoading) return <p>Loading units...</p>;
  if (error) return <p>Error loading units</p>;
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";
  const handleclickunit = (unitid) => {
    setSelectedPropertyUnit(unitid);
  };
  return (
    <div className="row">
      {data?.map((unit, index) => (
        <div
          className="col-md-12 col-lg-4"
          key={index}
          onClick={() => handleclickunit(unit.unitCategoryID)}
        >
          <div className="card unitcard">
            <div className="card-body unitcard-body">
              <img
                src={unit.imageString[0] || fallbackImage}
                className="card-img mb-3"
                alt="Property Unit"
              />
              <div className="d-flex justify-content-between">
                <div className="">
                  <h6 className="">
                    Building ID: {unit.buildingNumber}
                    <span className="ps-2">
                      {/* <i
                        className="ti ti-map-pin-filled ps-3"
                        style={{ color: "#4D4D4D" }}
                      />{" "} */}
                      Total Units :{unit.totalUnits}
                    </span>
                  </h6>
                </div>
                {status === 3 ? (<h6 className="text-end">{unit?.propertyUnitName}</h6>) : (<h6 className="text-end">{(unit?.propertySubTypeId === PropertySubTypeEnum.Building || unit?.propertySubTypeId === PropertySubTypeEnum.MixUse) ? unit?.propertyUnitName : unit?.propertyMasterSubTypeName}</h6>)}

              </div>
              <div className="d-flex py-2 ">
                <span className="">Total Occupied : {unit.occupied}</span>
                <span className="ps-2">
                  Partially Occupied : {unit.partiallyOccupied}
                </span>
                <span className="ps-2">Total Vacant : {unit.vacant}</span>
              </div>
              <div className="row">
                <div className="col-md-8 col-8">
                  <span className=" add-truncate ">
                    <i
                      className="ti ti-map-pin-filled"
                      style={{
                        background:
                          "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                      }}
                    />{" "}
                    {unit.addressStr}
                  </span>
                </div>
                <div className="col-md-4 col-4 text-end">
                  <i
                    className="ti ti-edit"
                    style={{
                      background:
                        "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }}
                  />
                  <i
                    className="ti ti-trash ps-2"
                    style={{
                      background:
                        "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UnitsCard;
