import React, { useState } from "react";
import { useGetOwnerPropertyMasterQuery, useGetOwnerPropertyMasterTotalCountQuery } from "../../../redux/CommonApi";
import { Pagination, Stack } from "react-bootstrap";
import { makeStyles } from "@mui/material";
import { StackPagination } from "../../../shared/StackPagination";
import { OwnerPropertyMaster } from "../../Models/Pms/OwnerPropertyMaster";
import { Loader } from "../../../shared/Loader";

// Define your styles using MUI's makeStyles
// const useStyles = makeStyles(() => ({
//   ul: {
//     "& .Mui-selected": {
//       color: "#fff",
//       background:
//       "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
//     },
//     "& .MuiPaginationItem-previousNext": {
//       color: "#9E2A2B",
//     },
//     "& .MuiPaginationItem-previousNext:hover": {
//       backgroundColor: "#f1f1f1",
//     },
//   },
// }));

const Totalunits = ({ setSelectedProperty, status, setPageNumber,pageNumber}) => {
  // const classes = useStyles();
  const landlordId = sessionStorage.getItem("userId");
  // const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState(6);

  // Initialize classes for pagination styling

 
  const { data, error, isLoading } = useGetOwnerPropertyMasterQuery({
    rowNumber: rowNumbers,
    pageNumber: pageNumber,
    userID: +landlordId,
    Status: status,
  }) as {data:OwnerPropertyMaster[], error:any, isLoading:boolean};

  console.log("dataowner",data);
const {data : ownerpropertymastercount = 0, 
  error : ownerpropertymastererror,
  isLoading: isCountLoading,} =useGetOwnerPropertyMasterTotalCountQuery({
  userID: landlordId,
  Status: status,
}) 


  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    
    setPageNumber(value);
  };
  const handleCardClick = (propertyId, status) => {
    setSelectedProperty({ propertyId, status });
  };

  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";

  if (isLoading || isCountLoading) return <Loader />;
  if (error) return <p>Error fetching properties.</p>;

  return (
    <>
      <div className="row pb-5 ">
        {data && data.length > 0 ? (
          data.map((property) => {
            // const images = property.imageString || [];
            // const imageSrc = images.length > 0 ? images[0] : fallbackImage;

         
            return (
              <div
                key={property.propertyMasterID}
                className="col-lg-6 col-xl-6 col-xxl-4 col-md-6 col-sm-12"
                onClick={() =>
                  handleCardClick(
                    property.propertyMasterID,
                    property.propertyStatusID
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <div className="card totalunit-card pmsgradient ">
                  <div className="row g-2 card-body totalunits">
                    <div className="col-md-12 col-lg-4">
                      <div>
                        <img
                          src={property.imageString || fallbackImage}
                          className="img-fluid rounded-start object-fit-cover h-100 w-100"
                          alt={property.propertyMasterName}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-8 ps-3">
                      <div className="pt-1">
                        <p>
                          Type: <span>{property.propertyMasterSubType}</span>
                        </p>
                        <p>
                          Building ID: <span>{property.buildingNumber}</span>
                        </p>
                        <p className="truncate">
                          Building Name:{" "}
                          <span>{property.propertyMasterName}</span>
                        </p>
                        <p className="fs-15 totalunits-address truncate">
                          <i
                            className="ti ti-map-pin-filled"
                            style={{ color: "#9E2A2B" }}
                          />{" "}
                          {property.addressStr}
                        </p>
                        <p>
                          Status:{" "}
                          <span style={{ color: "#31AD6E" }}>
                            {property.propertyStatusID === 1 ? "Active" : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="card d-flex align-items-center justify-content-center"
            style={{ padding: "40px 0", border: "none" }}
          >
            <div className="card-body">
              <img
                src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                alt="No data found"
              />
              <h3 className="text-center">No Data Found</h3>
            </div>
          </div>
        )}
       
      </div>
      {ownerpropertymastercount > 0 && (
      <div className="row row1 bg-white fixed-bottom1" >
        <div className="d-flex justify-content-center">
         <StackPagination 
         tenancyRequestCount={ownerpropertymastercount}
        rowNumbers={rowNumbers}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange} 

         />
        </div>
        </div>
        )}
    </>
  );
};

export default Totalunits;
