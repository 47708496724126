export interface IOwnerPropertyMasterDetail {
  addressStr: string;
  propertyMasterID: number;
  buildingNumber: string;
  propertyMasterSubTypeName: string;
  propertyUnitName: string;
  plotNumber: string;
  propertyMasterName: string;
  propertyCategoryUnit: number;
  propertySubTypeId: number;
  propertyMasterTypeID: number;
  statuss: number;
  totalUnits: number;
  vacant: number;
  occupied: number;
  partiallyOccupied: number;
  noOfFloor: number;
  residentialUnits: number;
  commercialUnits: number;
  description: string;
  rentPrice: number | null;
  sellPrice: number | null;
  unitCategoryID: number;
  builtYear: number;
  totalImages: number;
  imageString: string[];
  totalcategories: number;
  categoriesList: ICategory[];
}

export class OwnerPropertyMasterDetail implements IOwnerPropertyMasterDetail {
  addressStr: string;
  propertyMasterID: number;
  buildingNumber: string;
  propertyMasterSubTypeName: string;
  propertyUnitName: string;
  plotNumber: string;
  propertyMasterName: string;
  propertyCategoryUnit: number;
  propertySubTypeId: number;
  propertyMasterTypeID: number;
  statuss: number;
  totalUnits: number;
  vacant: number;
  occupied: number;
  partiallyOccupied: number;
  noOfFloor: number;
  residentialUnits: number;
  commercialUnits: number;
  description: string;
  rentPrice: number | null;
  sellPrice: number | null;
  unitCategoryID: number;
  builtYear: number;
  totalImages: number;
  imageString: string[];
  totalcategories: number;
  categoriesList: ICategory[];

  constructor(data: Partial<OwnerPropertyMasterDetail>) {
    this.addressStr = data.addressStr ?? '';
    this.propertyMasterID = data.propertyMasterID ?? 0;
    this.buildingNumber = data.buildingNumber ?? '';
    this.propertyMasterSubTypeName = data.propertyMasterSubTypeName ?? '';
    this.propertyUnitName = data.propertyUnitName ?? '';
    this.plotNumber = data.plotNumber ?? '';
    this.propertyMasterName = data.propertyMasterName ?? '';
    this.propertyCategoryUnit = data.propertyCategoryUnit ?? 0;
    this.propertySubTypeId = data.propertySubTypeId ?? 0;
    this.propertyMasterTypeID = data.propertyMasterTypeID ?? 0;
    this.statuss = data.statuss ?? 0;
    this.totalUnits = data.totalUnits ?? 0;
    this.vacant = data.vacant ?? 0;
    this.occupied = data.occupied ?? 0;
    this.partiallyOccupied = data.partiallyOccupied ?? 0;
    this.noOfFloor = data.noOfFloor ?? 0;
    this.residentialUnits = data.residentialUnits ?? 0;
    this.commercialUnits = data.commercialUnits ?? 0;
    this.description = data.description ?? '';
    this.rentPrice = data.rentPrice ?? null;
    this.sellPrice = data.sellPrice ?? null;
    this.unitCategoryID = data.unitCategoryID ?? 0;
    this.builtYear = data.builtYear ?? 0;
    this.totalImages = data.totalImages ?? 0;
    this.imageString = data.imageString ?? [];
    this.totalcategories = data.totalcategories ?? 0;
    this.categoriesList = data.categoriesList ?? [];
  }

  
}


export interface ICategory {
  propertyCategoryUnit: number;
  propertyUnitName: string;
  rentPrice: number | null;
  sellPrice: number | null;
}

export class Category implements ICategory {
  propertyCategoryUnit: number;
  propertyUnitName: string;
  rentPrice: number | null;
  sellPrice: number | null;
  constructor(data: Partial<Category>){
    this.propertyCategoryUnit = data.propertyCategoryUnit ?? 0;
    this.propertyUnitName = data.propertyUnitName ?? '';
    this.rentPrice = data.rentPrice ?? 0;
    this.sellPrice = data.sellPrice ?? 0;
  }
}