export interface ILandlordStaff {
  landlordStaffId: number;
  status: number;
  securityGroupID: number | null;
  securityGroupName: string | null;
  name: string;
  contact: string;
  roleTypeID: number;
  desc: string;
  documentPath: string | null;
}

export class LandlordStaff implements ILandlordStaff {
  landlordStaffId: number;
  status: number;
  securityGroupID: number | null;
  securityGroupName: string | null;
  name: string;
  contact: string;
  roleTypeID: number;
  desc: string;
  documentPath: string | null;

  constructor(data: Partial<LandlordStaff>) {
   this.landlordStaffId = data.landlordStaffId ?? 0;
   this.status = data.status ?? 0;
   this.securityGroupID = data.securityGroupID ?? 0;
   this.securityGroupName = data.securityGroupName ?? '';
   this.name = data.name ?? '';
   this.contact = data.contact ?? '';
   this.roleTypeID = data.roleTypeID ?? 0;
   this.desc = data.desc ?? '';
   this.documentPath = data.documentPath ?? '';
  }
}
