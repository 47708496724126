
export interface IUpdateExpenseStatus  {
  expenseId: number;
  status: number;
  feedback: string;
}

export class UpdateExpenseStatus  implements IUpdateExpenseStatus {
  expenseId: number;
  status: number;
  feedback: string;

  constructor(expenseId: number, status: number, feedback: string ) {
   this.expenseId = expenseId ?? 0;
    this.status = status ?? 0;
    this.feedback = feedback ?? '';
  }
}
