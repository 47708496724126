export const Loader = () => {
    return (
        <div className="loader-container">
            <div className="lds-spinner" style={{backgroundColor:"#822323",opacity: "0.4"}}>
                <div></div><div></div><div></div><div></div>
                <div></div><div></div><div></div><div></div>
                <div></div><div></div><div></div><div></div>
            </div>
        </div>
    );
};
