import React from 'react'
import { useExpiredAgreementDetailQuery } from '../../../redux/CommonApi';
import PropertyDetail from '../MainPmsDashboard/PropertyDetail';
import AggrementDetail from './AggrementDetail';

const ExpireAggDetail = ({expireDetail}) => {
    const {
        data: expiredAggrementDetail = {},
        error,
        isLoading,
      } = useExpiredAgreementDetailQuery({
        agId: expireDetail,
      });
  return (
   < AggrementDetail expiredAggrementDetail={expiredAggrementDetail} />
  )
}

export default ExpireAggDetail