// src/components/PropertyCard.js
import React, { useEffect } from "react";
import { formatDate } from "../../../formatDate";
import TenantSearchBar from "./TenantSearchBar";
import dayjs from 'dayjs';
const PropertyCard = ({
  item,
  fallbackImage,
  profileimg,
  onCardClick,
  Cardstatus,
  activesubIndex,
}) => {
  useEffect(() => {
    // Scroll to the top of the page when this component is rendered
    window.scrollTo(0, 0);
  }, []);
  console.log(item)
  return (
    <>

      <div
        className="col-lg-4 col-md-6 col-xl-4 col-sm-12"
        style={{ cursor: Cardstatus !== 2 || Cardstatus !== 3 ? "pointer" : "default" }}
        onClick={() => {
          if (Cardstatus !== 2 || Cardstatus !== 3) {
            onCardClick && onCardClick(item); // Only trigger onCardClick when Cardstatus is not 2
          }
        }}
      >
        <div className=''>
          <div className="card tenantcard">
            {Cardstatus === 7 && (
              <span className="bg-negotiation text-center page-title">
                Negotiation
              </span>
            )}
            {Cardstatus !== 2 && Cardstatus !== 7 && (
              <>
                {item.status === 1 && (
                  <div className="badge badge-pill badge-status bg-success page-title">
                    Active
                  </div>
                )}
                {item.status === 8 && (
                  <div className="text-center bg-negotiation page-title">
                    PENDING
                  </div>
                )}
                {item.status === 10 && (
                  <div className="text-center bg-negotiation page-title">
                    PENDING
                  </div>
                )}
                {item.status === 2 && (
                  <span className="badge badge-pill badge-status bg-danger page-title">
                    Inactive
                  </span>
                )}
                {item.status === 12 && (
                  <span className="bg-expired text-center page-title">
                    EXPIRED
                  </span>
                )}
                {Cardstatus === 4 && (
                  <span className="bg-expired text-center page-title">
                    EXPIRED
                  </span>
                )}

              </>
            )}

            <div className="card-body" >
              {Cardstatus != 2 ? (
                <div>
                  <img
                    src={
                      item.imageString ||
                      item.propertyImageString ||
                      item.propertyImage ||
                      fallbackImage
                    }

                    className="card-img img-fluid rounded-start object-fit-cover h-250 w-100"
                    alt="property"
                  />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <span className="fw-semibold page-title">
                      Your Current Agreement
                    </span>
                    <div className="d-flex text-end">
                      <div className="tenanticon-circle  bg-circle d-flex justify-content-center align-items-center mx-2">
                        <i
                          className="fa fa-phone"
                          style={{
                            fontSize: "13px",
                            background:
                              "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8), rgba(188, 1, 119, 0.9))",
                            WebkitBackgroundClip: "text",
                            color: "transparent",
                            display: "inline-block",
                          }}
                        ></i>
                      </div>
                      <div className="tenanticon-circle  bg-circle d-flex justify-content-center align-items-center ">
                        <i
                          className="fa fa-comment"
                          style={{
                            fontSize: "13px",
                            background:
                              "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8), rgba(188, 1, 119, 0.9))",
                            WebkitBackgroundClip: "text",
                            color: "transparent",
                            display: "inline-block",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div
                className={`${Cardstatus === 2 ? "col-lg " : "col-md-12 col-lg-12"
                  } `}
              >
                <div className="pt-3 ">
                  {Cardstatus === 7 ? (
                    <div className="img-tag d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="page-title">
                          Building Type: <span className="page-title">{item.buildingType || item.propertyType}</span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>

                      <div className="img-tag d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2 pb-2">
                          <img
                            src={item.userImage || item.imageString || profileimg}
                            className="no-gap"
                            style={item.userImage ? {} : { width: "50px" }}
                            alt="Profile"
                          />
                          <p className="truncate page-title">
                            Tenant: <span className="page-title">{item.name || item.tenantName}</span>
                          </p>
                        </div>

                        {Cardstatus === 2 && (
                          <p>
                            <span onClick={() => onCardClick(item)}>
                              <i
                                className="ti ti-eye-closed"
                                style={{
                                  fontSize: "18px",
                                  background:
                                    "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8), rgba(188, 1, 119, 0.9))",
                                  WebkitBackgroundClip: "text",
                                  color: "transparent",
                                  display: "inline-block",
                                }}
                              ></i>
                            </span>
                          </p>
                        )}
                      </div>
                    </>

                  )}
                  {Cardstatus != 7 ? (
                    <p className="page-title">{
                      item.buildingType && (<>
                        {item.buildingType ? "Building Type: " : "Building Name: "}
                        <span className="page-title">
                          {item.buildingType ||
                            item.buildingType ||
                            item.propertyType ||
                            item.propertyMasterName}
                        </span>
                      </>)
                    }

                    </p>
                  ) : (
                    <></>
                  )}
                  {/* <span className="ribbone-renewl-right">
                  <span className="">
                    Ribbon
                  </span>
                  </span> */}
                  {Cardstatus === 4 &&
                    <p className="page-title">
                      Building Name: <span className="page-title">{item.propertyMasterName}</span>
                    </p>}

                  <p className="page-title">
                    Building ID: <span className="page-title">{item.buildingNumber}</span>
                  </p>
                  {Cardstatus === 4 &&
                    <p className="page-title">
                      Caption: <span className="page-title">{item.tag}</span>
                    </p>}

                  {Cardstatus === 4 ? "" : (<p className="page-title">
                    Unit ID:{" "}
                    <span className="page-title">
                      {item.rentalUnitID || item.aggUnitID || item.rentalUnitId}
                    </span>
                  </p>)}
                  {Cardstatus === 6 &&
                    <p className="page-title">
                      Unit Category: <span className="page-title">{item.unitCategoryName}</span>
                    </p>}
                  {Cardstatus === 2 && (
                    <p className="truncate page-title">
                      Caption:{" "}
                      <span className="page-title">
                        {item.tag || item.rentalUnitID || item.aggUnitID || item.rentalUnitId}
                      </span>

                    </p>
                  )}

                  {Cardstatus === 5 && <p className="page-title">
                    No of Terms : <span>{item.terms || item.terms}</span>
                  </p>}
                  {item.proposeRent && activesubIndex ==="Tenancy Requests"  ? (
                    <p className="page-title">
                      Proposed Rent :OMR <span className="page-title">{item.proposeRent || 0}</span>
                    </p>
                  ) : (
                    <></>
                  )}
                  {Cardstatus == 7 ? (
                    <p className="fs-15 totalunits-address truncate ">
                      <i
                        className="ti ti-map-pin-filled"
                        style={{ color: "#9E2A2B" }}
                      />{" "}
                      {item.addressStr}
                    </p>
                  ) : (<div>{Cardstatus == 1? null:(<></>)}
                    </div>
                  )}
                  {Cardstatus === 3 && (<>
                    <p className="page-title">
                      Caption: <span className="page-title">{item.aggUnitID}</span>
                    </p>
                    <p className="page-title">
                      Status: <span className="page-title">{item.status === "Pending" ? "Active" : item.status}</span>
                    </p>
                  </>)
                  }
                  {Cardstatus === 3 &&
                    <p className="page-title">
                      Your Agreement is due to expire in.
                    </p>}

                  {Cardstatus !== 2 && Cardstatus !== 7 && Cardstatus !== 4 ? (
                    <>
                      {" "}
                      {/* <p>
                    Unit category: <span>{item.unitCategoryName}</span>
                  </p> */}
                      <div className="d-flex justify-content-between align-items-center">
                        {Cardstatus == 6 ? (
                          <p className="page-title">
                            Agreement Terms: <span className="page-title">{item.terms || item.terms}</span>
                          </p>) : (<>
                            {item.proposeRent === null && Cardstatus === 1 ? (<p className="page-title">
                              No of Terms: <span className="page-title">{item.terms || item.terms}</span>
                            </p>) : (
                              <p className="page-title">
                                {item.proposeRent && item.proposeRent !== 0  ? (
                                  <>Proposed Rent: OMR <span className="page-title">{item.proposeRent}</span></>
                                ) : (
                                  <span className="page-title"></span>
                                )}


                              </p>
                            )}

                          </>)}
                      
                        {Cardstatus !== 2 && (item.createdTime || item.aggEndDate || item.terminatedTime) ? (
                          <p className='dateformate page-title'>
                            {item.noOfDaysLeft && <><span style={{ color: "#9e2a2b" }}>{item.noOfDaysLeft} Days </span>:</>}  {dayjs(item.createdTime || item.aggEndDate || item.terminatedTime).format('DD-MM-YYYY')}
                          </p>
                        ) : null}

                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {Cardstatus === 3 && item.isRenewAgreement === true ? (
                    <p className="page-title">
                      Agreement renewal request is in Process.
                    </p>) : (<br />)}
                  {Cardstatus === 4 && (
                    <p className="page-title">
                      <span className="page-title">
                        {dayjs(item.aggStartDate).format('DD-MM-YYYY')} to{" "}{dayjs(item.aggEndDate).format('DD-MM-YYYY')}

                      </span>
                    </p>
                  )
                  }
                  {Cardstatus === 2 && <p className="page-title">
                    Building Name: <span className="page-title">{item.propertyMasterName}</span>
                  </p>}
                  {Cardstatus === 2 ? (
                    <>
                      {" "}
                      <p className="page-title">
                        Rent : <span>{item.monthlyRent} OMR</span>
                      </p>
                      <p className="dateformate page-title" >
                        Your Aggrement will Expire in{" "}
                        <span style={{ color: "#DF181AD9", fontWeight: "800", fontSize: "13px" }}>
                          {item.noOfDaysLeft} Days{" "}
                        </span>{" "}
                        <br />
                        {dayjs(item.aggEndDate).format('DD-MM-YYYY')}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}

                  {Cardstatus === 3 && (
                    <>
                      <div className="row" style={{ margin: item.isRenewAgreement === false ? '' : '-10px' }}
                      >
                        {item.isRenewAgreement === false ? (
                          <div className="col-md-6 d-flex gap-2 ">
                            {/* <button  onClick={() => onCardClick(item)} 
                          className="bg-danger btn rounded-5">View</button> */}
                            <button className="btn rounded-5" style={{ border: "1px solid #000000", color: "#000000" }}>Renew</button>
                          </div>
                        ) : (<><br /><br /></>)}

                      </div></>)}

                </div>
              </div>
            </div>
            {Cardstatus === 5 && item.isShowBadge && <div className="card-footer p-0 text-center" style={{ background: "#9e2a2b" }}>
              <div className="my-2 text-white">Sent by {item.isShowBadge ? "Tenant" : "Landlord"}</div>
            </div>}
          </div>
        </div>


      </div>

    </>
  );
};

export default PropertyCard;
