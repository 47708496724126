import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    useGetBankQuery, useGetExpenseTypesQuery, useGetPropertyUnitStatusQuery,
    useGetRequestAgreementQuery, useGetUtilityTypesQuery, usePaymentTypesQuery,
    usePostPropertyExpenseMutation,
    usePostRequestAgreementAttachmentMutation,
    usePutRequestAgreementMutation
} from '../../../redux/CommonApi';
import { NegotiationTypeEnum, PaymentTypeEnum, RequestTypeEnum, unitStatusEnum, UserTypeEnum } from '../../../redux/RequestTypeEnum';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Toast } from 'react-bootstrap';
import toastr from "toastr";
import dayjs from 'dayjs';
import { setTenantImages } from '../../../redux/Commonslice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faGasPump, faMoneyCheckDollar, faWater } from '@fortawesome/free-solid-svg-icons';
import AddBill from './AddBill';
import AddCheque from './AddCheque';
import ExpenseFormRow from './ExpenseFormRow';
import { number } from 'yup';
import { Bank } from '../../Models/RentCollection/Bank';



const formatCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-OM", {
        style: "currency",
        currency: "OMR",
        minimumFractionDigits: 1,  // Force one decimal place
        maximumFractionDigits: 1,  // Limit to one decimal place
    });

    return formatter.format(value);
};

const ProceedAgreement = ({ setProceedAgg, setgoback, RequestAgreementID, showProceed, setShowProceed }) => {


    const isRequestAgreementValid = RequestAgreementID !== undefined && RequestAgreementID !== null;
    console.log("RequestAgreementID:", RequestAgreementID);
    const { data: GetRequestData } = useGetRequestAgreementQuery({ id: RequestAgreementID }, { skip: !isRequestAgreementValid });
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [billvalidated, setBillValidated] = useState(false);
    const [chequevalidated, setchequeValidated] = useState(false);
    const agreementId = useSelector((state: any) => state.CRMS.aggrementId) ?? null;
    const { data: expense } = useGetExpenseTypesQuery({});
    const { data: propertyStatus } = useGetPropertyUnitStatusQuery({});
    const { data: PaymentType } = usePaymentTypesQuery({});
    const [PutRequestAgreement] = usePutRequestAgreementMutation();
    const { data: bankdata } = useGetBankQuery() as {data : Bank[]};
    const { data: utilitydata } = useGetUtilityTypesQuery({});
    const [PostPropertyExpense] = usePostPropertyExpenseMutation();
    const [PostRequestAgreementAttachment] = usePostRequestAgreementAttachmentMutation();
    const landlordId = sessionStorage.getItem("userId");
    const [reqexpenses, setReqExpenses] = useState<Record<string, any>>({});

    const [requtilities, setRequtilities] = useState([]);
    const [imagesizerror, setimagesizerror] = useState("");
    const [singleFile, setSingleFile] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showChequeInfo, setShowChequeInfo] = useState(false);
    const [chequeAdded, setChequeAdded] = useState(false);
    const [dropdownVisibleIndexes, setDropdownVisibleIndexes] = useState(null);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [billToastVisible, setBillToastVisible] = useState(false);
    const [chequeToastVisible, setChequeToastVisible] = useState(false);
    const [billIndex, setbillIndex] = useState(null);
    const dropdownRef = useRef(null);

    const handleOpenBillToast = () => {
        setChequeToastVisible(false);
        if (!billToastVisible) setBillToastVisible(true);
    };

    const handleCloseBillToast = () => {


        setBillToastVisible(false)
    };

    const handleOpenChequeToast = () => {
        setDropdownVisible(false);
        setBankForm(SavebankForm);
        handleCloseBillToast();
        if (!chequeToastVisible) setChequeToastVisible(true);
    };

    const handleCloseChequeToast = () => {
        setChequeToastVisible(false);

        setBankForm({
            aggID: agreementId,
            bankID: 0,
            chequeTypeID: 0,
            chequeNumber: "",
            amount: null,
            dueDate: new Date().toISOString(),
        })


    };

    const userTypeTextMap = {
        [UserTypeEnum.Tenant]: 'Tenant',
        [UserTypeEnum.Landlord]: 'Landlord'
    };
    const [formValues, setFormValues] = useState({
        landLordID: 0,
        tenantID: 0,
        corporateID: null,
        reqUsrID: null,
        reqUsrTypeID: null,
        rentalModeID: 0,
        rentalFrequencyID: 0,
        paymentTypeID: null,
        aggStartDate: "",
        aggEndDate: "",
        advanceAmount: null,
        terms: 0,
        rentalUnitID: 0,
        rentAmount: 0,
        proposeRent: 0,
        propertyUnitStatusID: null,
        desc: null,
        noticePeriod: null,
        graceDays: 0,
        isCreatedBy: 0,
        isUpdatedBy: 0,
        isApprovedByTenant: true,
        isApprovedByLandLord: false,
        feedback: null,
        requestTypeID: 0,
        isAggreement: false,
        id: 0,
        status: 0,
    });
    const [addBillForm, setAddBillForm] = useState({
        aggID: RequestAgreementID,
        utilityTypeID: 0,
        meterID: "",
        meterReading: "",
        meterReadingDate: new Date().toISOString(),
        outstandingAmount: null
    });
    console.log(addBillForm, "billform")
    const [bankForm, setBankForm] = useState({
        aggID: agreementId,
        bankID: 0,
        chequeTypeID: 1,
        chequeNumber: "",
        amount: formValues.advanceAmount,
        dueDate: new Date().toISOString(),
    });
    const [SavebankForm, setSaveBankForm] = useState({
        aggID: agreementId,
        bankID: 0,
        chequeTypeID: 1,
        chequeNumber: "",
        amount: formValues.advanceAmount,
        dueDate: new Date().toISOString(),
    });
    console.log(bankForm, "bankForm")

    useEffect(() => {
        if (expense) {
            const initialForm = {};
            expense.forEach((role) => {
                initialForm[role.id] = {
                    aggID: agreementId,
                    expenseTypeID: role.id,
                    payerID: landlordId,
                    userTypeID: UserTypeEnum.Tenant,
                };
            });

            // Initialize state only if it's empty
            setReqExpenses((prevForm) => (Object.keys(prevForm).length === 0 ? initialForm : prevForm));
            console.log("Initial Form Set:", initialForm);
        }
    }, [expense, agreementId, landlordId]);
    // Handle form state changes

    useEffect(() => {
        if (GetRequestData) {


            setFormValues({
                landLordID: GetRequestData?.landLordID,
                tenantID: GetRequestData?.tenantID,
                corporateID: GetRequestData?.corporateID,
                reqUsrID: GetRequestData?.reqUsrID,
                reqUsrTypeID: GetRequestData?.reqUsrTypeID,
                rentalModeID: GetRequestData?.rentalModeID || '',
                rentalFrequencyID: GetRequestData?.rentalFrequencyID || '',
                paymentTypeID: null,
                aggStartDate: GetRequestData?.aggStartDate || '',
                aggEndDate: GetRequestData?.aggEndDate || '',
                advanceAmount: 0,
                terms: GetRequestData?.terms || '',
                rentalUnitID: GetRequestData?.rentalUnitID || '',
                rentAmount: GetRequestData?.rentAmount || '',
                proposeRent: GetRequestData?.proposeRent == null ? GetRequestData?.rentAmount : GetRequestData?.proposeRent,
                propertyUnitStatusID: null,
                desc: null,
                noticePeriod: 0,
                graceDays: GetRequestData?.graceDays || '',
                isCreatedBy: GetRequestData?.isCreatedBy || '',
                isUpdatedBy: GetRequestData?.isUpdatedBy || '',
                isApprovedByTenant: false,
                isApprovedByLandLord: true,
                feedback: null,
                requestTypeID: GetRequestData?.requestTypeID == NegotiationTypeEnum.RenewableRequest ? NegotiationTypeEnum.RenewableRequest : NegotiationTypeEnum.LandlordRequest,
                isAggreement: false,
                id: GetRequestData?.id || '',
                status: GetRequestData?.requestTypeID == NegotiationTypeEnum.RenewableRequest ? unitStatusEnum.In_Progress : GetRequestData?.status,
            });
        }
    }, [GetRequestData]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        const numericValue = +value;

        if (name === "advanceAmount") {
            setIsButtonDisabled(numericValue < 1); // Disable button if <= 1
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: +value,
        }));
    }, []);
    const [bankDesc, setBankDesc] = useState(null)
    const handleChangeBankForm = (e) => {
        const { name, value } = e.target;
        if (name === "bankID") {
            setBankDesc(+value)
        }
        const selectedBank = bankdata.find(bank => bank.bankType === +value);

        // If bankID is changed, update bankDesc
        if (name === "bankID" && selectedBank) {
            setBankDesc(selectedBank.desc);
        }
        setBankForm((prevValues) => ({
            ...prevValues,
            [name]: value,

        }));

    };
    useEffect(() => {
        if (bankdata?.length > 0) {
            setBankForm((prevValues) => ({
                ...prevValues,
                bankID: bankdata[0].bankType // Set the first bankID from bankdata
            }));
        }
    }, [bankdata]);

    const handleChangeBillForm = (e) => {
        const { name, value } = e.target;
        // Find the selected utility based on the utilityTypeID
        const selectedUtility = utilitydata.find(
            (utility) => utility.utilityTypes === value
        );

        setAddBillForm((prevValues) => ({
            ...prevValues,
            [name]: value, // Update utilityTypeID
            utilityDescription: selectedUtility ? selectedUtility.desc : '', // Store the utility description
        }));
    };

    useEffect(() => {
        if (utilitydata && utilitydata.length > 0) {
            setAddBillForm((prevValues) => ({
                ...prevValues,
                utilityTypeID: utilitydata[0].utilityTypes // Set default value to first index
            }));
        }
    }, [utilitydata]);


    const handleAddBill = (event, index) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {


            setRequtilities((prevRequtilities) => {
                if (isEditing && editingIndex !== null) {
                    // Update the existing entry
                    return prevRequtilities.map((item, i) =>
                        i === editingIndex ? { ...addBillForm } : item
                    );
                } else {
                    // Add a new entry
                    return [...prevRequtilities, { ...addBillForm }];
                }
            });

            setAddBillForm({
                aggID: RequestAgreementID,
                utilityTypeID: 1,
                meterID: "",
                meterReading: "",
                meterReadingDate: new Date().toISOString(),
                outstandingAmount: 0,
            });
            handleCloseBillToast();
            // Reset editing state
            setIsEditing(false);
            setEditingIndex(null);
        }

        setBillValidated(false);
    };

    const handleAddBank = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setchequeValidated(true);

        // Check form validity
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }
        // Handle successful form submission (replace with your actual logic)
        bankForm.amount = formValues.advanceAmount
        setSaveBankForm(bankForm);

        // Show cheque info after successful save
        setShowChequeInfo(true); // Now show the cheque information
        setChequeAdded(true);
        // Optionally hide the "Add Cheque" button here if needed
        // setShowchequetoast(false);
        handleCloseChequeToast();
        setchequeValidated(false); // Reset validation state for the next submission
    };
    const handlechequeDelete = () => {
        setSaveBankForm({
            aggID: agreementId,
            bankID: 0,
            chequeTypeID: 0,
            chequeNumber: "",
            amount: null,
            dueDate: new Date().toISOString(),
        })
        setShowChequeInfo(false);
        setChequeAdded(false);
    }
    const preparePayload = () => {
        const reqCheques = SavebankForm.chequeNumber ? [SavebankForm] : [];
        const reqexpensesData = Object.values(reqexpenses).map(exp => ({
            aggID: exp.aggID || agreementId,
            expenseTypeID: exp.expenseTypeID,
            payerID: +exp.payerID || +landlordId,
            userTypeID: exp.userTypeID,
        }));
        // const requtilities = isExistBill ? [addBillForm] : [];
        const requtilitiesData = requtilities.map(uti => ({
            aggID: reqexpensesData[0]?.aggID,
            utilityTypeID: +uti.utilityTypeID,
            meterID: uti.meterID,
            meterReading: uti.meterReading,
            meterReadingDate: uti.meterReadingDate,
            outstandingAmount: uti.outstandingAmount,

        }))
        return {
            reqAggID: reqexpensesData[0]?.aggID,
            aggID: null,
            reqCheques: reqCheques,
            reqexpenses: reqexpensesData,
            requtilities: requtilitiesData,
        };
    };
    const compressImage = (file, callback) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result as string;;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Set the canvas dimensions to compress (e.g., half the size of the original image)
                const maxWidth = 1024; // Example: max width of 1024px
                const maxHeight = 1024; // Example: max height of 1024px

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                // Draw the image on the canvas at the new size
                ctx.drawImage(img, 0, 0, width, height);

                // Compress the image to JPEG format with reduced quality (e.g., 0.7 for 70%)
                canvas.toBlob(
                    (blob) => {
                        const compressedFile = new File([blob], file.name, {
                            type: "image/jpeg",
                            lastModified: Date.now(),
                        });
                        callback(compressedFile);
                    },
                    "image/jpeg",
                    0.7 // Adjust quality here (0.7 means 70% quality)
                );
            };
        };
        reader.readAsDataURL(file); // Convert file to a data URL for the Image element
    };

    const uploadSingleFiles = (e) => {
        const files = e.target.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.size > 5 * 1024 * 1024) {
                setimagesizerror("Each file must be less than 5MB");
                setTimeout(() => {
                    setimagesizerror("");
                }, 5000);
                continue;
            }
            setimagesizerror("");

            // Compress image before uploading
            compressImage(file, (compressedFile) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const result = event.target.result;
                    let byteArray;

                    if (typeof result !== "string") {
                        byteArray = new Uint8Array(result);
                    }

                    const newImage = {
                        file: byteArray ? Array.from(byteArray) : null,
                        fileName: compressedFile.name,
                        fileExtension: compressedFile.name.split(".").pop(),
                        url: URL.createObjectURL(compressedFile),
                        reqAggID: agreementId,
                    };

                    console.log(newImage);
                    setSingleFile((prevFiles) => [...prevFiles, newImage]);
                    dispatch(setTenantImages(singleFile));
                };

                reader.readAsArrayBuffer(compressedFile); // Read compressed file as array buffer
            });
        }
    };

    const removeImage = (index) => {
        setSingleFile((prevFiles) => [
            ...prevFiles.slice(0, index),
            ...prevFiles.slice(index + 1),
        ]);
    };
    const validateForm = () => {
        const advanceAmount = formValues.advanceAmount;

        if (!advanceAmount) {
            toastr.error('Please add advance Amount.');
            return false;
        }
        if (!formValues.noticePeriod) {
            toastr.error('Notice Period Must in integer.');
            return false;
        }
        if (formValues.paymentTypeID == PaymentTypeEnum.Cheque) {
            if (SavebankForm.chequeNumber == '') {
                toastr.error('Please add Cheque.');
                return false;
            }
        }
        return true;
    };
    const [approveReq, setApproveReq] = useState();
    const handleSubmit = async (event) => {
        event.preventDefault();
        debugger;
        if (!validateForm()) {
            return;
        }
        console.log("agreementId", agreementId)

        try {
            const updatedData = {
                ...GetRequestData,
                noticePeriod: formValues.noticePeriod,
                advanceAmount: formValues.advanceAmount,
                paymentTypeID: formValues.paymentTypeID || 1,
                propertyUnitStatusID: formValues.propertyUnitStatusID || 1,
                desc: formValues.desc,
                requestTypeID: formValues.requestTypeID,
                status: formValues.requestTypeID == RequestTypeEnum.RENEWAL_REQUEST ? unitStatusEnum.In_Progress : formValues.status,
                isApprovedByTenant: false,
                isApprovedByLandLord: true
            }
            setApproveReq(updatedData)
            const result = await PutRequestAgreement({
                Agid: agreementId,
                updatedData
            }).unwrap();
            if (result.statusCode == 200) {
                if (showProceed) {
                    setShowProceed(false);
                }

                setgoback(false);
                setProceedAgg(null);
                toastr.success('Request has been submitted for review successfully.');
            }


        } catch (error) {
            debugger
            toastr.error('Failed to update.', error);
            console.error('Failed to update:', error);
            return;
        }

        const payload = preparePayload();

        try {
            await PostPropertyExpense(payload);
            console.log('Success: Data posted successfully.', payload);
        } catch (err) {
            console.error('Error:', payload);
            return;
        }

        try {
            // toastr.success('Attachment submitted successfully.');
        } catch (error) {
            console.error('Failed to update image:', error);
            return;
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);

    const handleChangeExpenseForm = (roleId, userTypeId) => {
        setReqExpenses((prevExpenseForm) => ({
            ...prevExpenseForm,
            [roleId]: {
                ...prevExpenseForm[roleId], // Preserve existing fields
                userTypeID: userTypeId, // Update userTypeID
            },
        }));
    };

    const handleBackClick = () => {
        setgoback(false);
        setProceedAgg(null);
    };

    useEffect(() => {
        // Scroll to the top of the page when this component is rendered
        window.scrollTo(0, 0);
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible(prev => !prev);
    };
    // const toggleDropdown1 = () => {
    //     setdropdownUVisible(prev => !prev);
    // };
    const toggleDropdown1 = (index) => {
        setDropdownVisibleIndexes((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleBillDelete = (index) => {
        setRequtilities((prevUtilities) => prevUtilities.filter((_, i) => i !== index));
        setDropdownVisibleIndexes(null); // Close the dropdown after deletion
    };

    // Handle edit
    const handleBillEdit = (utility, index) => {
        debugger
        const billToEdit = requtilities[index];
        setAddBillForm(billToEdit); // Populate form with the selected bill
        setIsEditing(true); // Set editing state
        setEditingIndex(index);
        setDropdownVisibleIndexes(null); // Close the dropdown
        handleOpenBillToast();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
                setDropdownVisibleIndexes(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex gap-2 align-items-center gap mb-3 agreementdoc">
                        {!showProceed && (

                            <i className="ti ti-arrow-narrow-left"
                                onClick={handleBackClick} style={{
                                    background: '#7E7E7E',
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    backgroundClip: "text",
                                    fontSize: "30px",
                                }} />
                        )}

                        <h4 className="py-2">Rental cheque & Utilities</h4>
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className='row rentalcheque'>

                            <div className={showProceed ? "col-md-12" : "col-md-6"}>
                                <h4 className=" pb-2">Who Will Pay</h4>
                                <div className="card tenantdetail personaldetail-card rentalcard">
                                    <div className="card-body">
                                        {expense?.map((role) => (
                                            <ExpenseFormRow
                                                key={role.id}
                                                role={role}
                                                selectedForm={reqexpenses[role.id] || {}}
                                                UserTypeEnum={UserTypeEnum}
                                                userTypeTextMap={userTypeTextMap}
                                                onChangeExpenseForm={handleChangeExpenseForm}
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Rent input */}
                                <div className="col-md-12 ">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Rent OMR</label>
                                        <input
                                            className="form-control"
                                            name="RENT"
                                            value={formValues.proposeRent}
                                            onChange={handleChange}
                                            placeholder="OMR 115"
                                        />
                                    </div>
                                </div>
                                {/* Notice Period */}
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Notice Period In days</label>
                                        <input
                                            className="form-control"
                                            name="noticePeriod"
                                            value={formValues.noticePeriod}
                                            onChange={handleChange}
                                            placeholder="30"
                                        />
                                    </div>
                                </div>
                                {/* Security Deposit */}
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Security Deposit OMR</label>
                                        <input
                                            // onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}
                                            className="form-control"
                                            name="advanceAmount"
                                            value={formValues.advanceAmount}
                                            onChange={handleChange}
                                            placeholder="OMR 200"
                                            required
                                        />
                                        <div className="invalid-feedback">Enter Security Deposit</div>
                                    </div>
                                </div>
                                {/* Security Deposit Type */}
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Security Deposit Type</label>
                                        <select
                                            className="form-select"
                                            name="paymentTypeID"
                                            value={formValues.paymentTypeID}
                                            onChange={handleChange}
                                            disabled={isButtonDisabled}
                                        >
                                            {PaymentType?.slice(0, 2).map((role) => (
                                                <option key={role.id} value={role.id}>
                                                    {role.desc}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {formValues.paymentTypeID === 2 && (
                                        <>
                                            {!chequeAdded && (
                                                <div className='pb-2'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-radius"
                                                        id="middlecenterToastBtn"
                                                        onClick={handleOpenChequeToast}
                                                    >
                                                        Add Cheque
                                                    </button>
                                                </div>
                                            )}
                                            {showChequeInfo && (
                                                <div className='row'>

                                                    <div className="col-md-12 card addbill-popup">
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between align-items-center">

                                                                <h4>Cheque</h4>

                                                                <i
                                                                    className="ti ti-menu-2"
                                                                    onClick={toggleDropdown} // Add click event handler
                                                                    style={{
                                                                        background: '#A64041',
                                                                        WebkitBackgroundClip: "text",
                                                                        WebkitTextFillColor: "transparent",
                                                                        backgroundClip: "text",
                                                                        fontSize: "30px",
                                                                        cursor: "pointer" // Change cursor to pointer
                                                                    }}
                                                                />

                                                                {/* Dropdown menu */}
                                                                {dropdownVisible && (
                                                                    <div ref={dropdownRef} className="billdropdown-menu">
                                                                        <p onClick={handleOpenChequeToast}>Edit</p>
                                                                        <p onClick={handlechequeDelete}>Delete</p>

                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <h5 className='d-flex align-items-center gap-2'>

                                                                    <FontAwesomeIcon icon={faMoneyCheckDollar} />
                                                                    {bankDesc}
                                                                    {/* {bankdata?.find(bank => bank.bankType === bankForm.bankID)?.desc || 'Not selected'}Use the description instead of enum */}
                                                                </h5>
                                                                <p>Cheque Number:<span>{SavebankForm.chequeNumber} </span></p>
                                                                <p>Date: <span>{dayjs(SavebankForm.dueDate).format('DD-MM-YYYY')}</span></p>
                                                                <p>Amount: <span>{formatCurrency(SavebankForm.amount)}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {/* Unit Status */}
                                <div className="col-md-12">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Mark Unit Status as</label>
                                        <select
                                            className="form-select"
                                            name="propertyUnitStatusID"
                                            value={formValues.propertyUnitStatusID}
                                            onChange={handleChange}
                                        >
                                            {propertyStatus?.map((role) => (
                                                <option key={role.id} value={role.id}>
                                                    {role.desc}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>


                            </div>
                            {/* Additional Notes */}
                            <div className={showProceed ? "col-md-12" : "col-md-6"}>
                                <h4 className=" pb-2">Additional Notes(optional)</h4>
                                <div className=" ">
                                    <div className="">
                                        <textarea
                                            rows={7}
                                            cols={7}
                                            className="form-control rentalcard"
                                            name="additionalNotes"
                                            value={formValues.desc}
                                            onChange={handleChange}
                                            placeholder="Write terms & Conditions"
                                        />
                                    </div>
                                </div>
                                <div className=" agreementdoc  py-3">
                                    <div className='row'>
                                        <div className='col-md-3'>

                                            <div
                                                className=""
                                                style={{ position: "relative" }}
                                            >
                                                <div className="upload-btn-wrapper">
                                                    <button
                                                        type="button"
                                                        className="btn  btn-danger btn-radius"
                                                        disabled={singleFile.length === 5}
                                                    >
                                                        Upload File
                                                    </button>
                                                    {/* (maximum 5mb file) */}
                                                    <input
                                                        type="file"
                                                        name="myfile"
                                                        onChange={uploadSingleFiles}
                                                        multiple
                                                        disabled={singleFile.length === 5}
                                                    />
                                                </div>
                                                {imagesizerror && (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            position: "absolute",
                                                            top: "45px",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        {imagesizerror}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <button
                                                type="submit"
                                                className={`btn  ${isButtonLoading ? 'btn-disabled' : 'addfeature-btn'}`}
                                                disabled={isButtonLoading}
                                            >{isButtonLoading ? (
                                                <>
                                                    {" "}
                                                    <span className="spinner-border spinner-border-sm mr-1 "></span>
                                                </>
                                            ) : null}
                                                SUBMIT FOR REVIEW
                                            </button>
                                        </div>
                                        <div className='col-md-3'>
                                            <button
                                                type="button"
                                                className="btn  btn-danger btn-radius"
                                                id="middlecenterToastBtn"
                                                onClick={handleOpenBillToast}
                                            >
                                                ADD BILL
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    {singleFile.length !== 0 &&
                                        singleFile.map((image, index) => (
                                            <div key={index} className="d-flex justify-content-between">

                                                <div>{image.fileName}</div>
                                                <div>
                                                    <i className="ti ti-trash" style={{
                                                        background: '#A64041',
                                                        WebkitBackgroundClip: "text",
                                                        WebkitTextFillColor: "transparent",
                                                        backgroundClip: "text",
                                                        fontSize: "30px",
                                                    }}
                                                        onClick={() => removeImage(index)} />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div className='row'>
                                    {requtilities.map((utility, index) => {
                                        const utilityDescription = utilitydata.find(u => u.utilityTypes === +utility.utilityTypeID) || 'Unknown Utility';

                                        return (
                                            <div key={index} className="col-md-12 card addbill-popup">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h4>Utilities Bill</h4>
                                                        <i
                                                            className="ti ti-menu-2"
                                                            onClick={() => toggleDropdown1(index)}
                                                            style={{
                                                                background: '#A64041',
                                                                WebkitBackgroundClip: "text",
                                                                WebkitTextFillColor: "transparent",
                                                                backgroundClip: "text",
                                                                fontSize: "30px",
                                                                cursor: "pointer" // Change cursor to pointer
                                                            }}
                                                        />
                                                        {dropdownVisibleIndexes == index && (
                                                            <div ref={dropdownRef} className="billdropdown-menu">
                                                                <p onClick={() => handleBillEdit(utility, index)}>Edit</p>
                                                                <p onClick={() => handleBillDelete(index)}>Delete</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <h5 className='d-flex align-items-center gap-2'>
                                                            {/* <FontAwesomeIcon icon={faReceipt} /> */}
                                                            {utilityDescription?.desc === "Water" && <FontAwesomeIcon icon={faWater} />}
                                                            {utilityDescription?.desc === "Gas" && <FontAwesomeIcon icon={faGasPump} />}
                                                            {utilityDescription?.desc === "Electricity" && <FontAwesomeIcon icon={faBolt} />}


                                                            {utilityDescription?.desc}
                                                        </h5>
                                                        <p>Meter ID: <span>{utility.meterID}</span></p>
                                                        <p>Meter Reading: <span>{utility.meterReading}</span></p>
                                                        <p>Date: <span>{dayjs(utility.meterReadingDate).format('DD-MM-YYYY')}</span></p>
                                                        <p>Amount: <span>{formatCurrency(utility.outstandingAmount)}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>

                        </div>
                    </Form>

                </div>
            </div>
            <div className="toast-container position-fixed top-50 start-50 translate-middle">

                <Toast
                    show={billToastVisible}
                    onClose={handleCloseBillToast}
                    className="colored-toast bg-white terminateToast negotiate-popup"
                >
                    <AddBill
                        addBillForm={addBillForm}
                        handleChangeBillForm={handleChangeBillForm}
                        handleAddBill={handleAddBill}
                        billvalidated={billvalidated}
                        utilitydata={utilitydata}
                        handleCloseBillToast={handleCloseBillToast}
                    />
                </Toast>

            </div>
            <div className="toast-container position-fixed top-50 start-50 translate-middle">
                <Toast
                    show={chequeToastVisible}
                    onClose={handleCloseChequeToast}
                    className="colored-toast bg-white terminateToast negotiate-popup"
                >
                    <AddCheque
                        bankForm={bankForm}
                        handleChangeBankForm={handleChangeBankForm}
                        handleAddBank={handleAddBank}
                        chequevalidated={chequevalidated}
                        bankdata={bankdata}
                        formValues={formValues}
                        handleCloseChequeToast={handleCloseChequeToast}
                    />
                </Toast>
            </div>
        </>
    );
}

export default React.memo(ProceedAgreement);
