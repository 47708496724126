import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../core/common/collapse-header";
import { PMSDashBoardApi } from "../../Services/DashboardServices";
import { useDispatch, useSelector } from "react-redux";
import PMSCharts from "../pms/PmsDashboardCharts/PMSCharts";
import { useGetPmsDashboardQuery } from "../../redux/CommonApi";
import { Loader } from "../../shared/Loader";
import { StaffDashboard } from "../Models/StaffManagement/StaffDashboard";
import { ResponseMessage } from "../Models/GenericModel/ResponseMessage";

const Dashboard = () => {
  const route = all_routes;
  const dispatch = useDispatch();
  const landlordId = parseInt(sessionStorage.getItem("userId"), 10);
  const { data, error, isLoading } = useGetPmsDashboardQuery({landlordId}) as {data:any, error:any, isLoading:boolean};

  const location = useLocation();
  const isLinkActive = (route) => {
    return location.pathname === route;
  };


  const iconMapping = {
    "Total Building":
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Properties.svg",
    Units:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Units.svg",
    Tenants:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Tenant.svg",
    Income:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg",
    Expense:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Expense.svg",
    "Net Income":
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg",
    Staff:
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/Customers.svg",
    "Security Group":
      "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.securitygroup.svg",
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              
              <div className="card main-card">
                <div className="card-body">
                  <div className="row">
                    {isLoading ? (
                      // <div>Loading...</div>
                      <Loader />
                    ) : (
                      data?.data.map((item, index) => (
                        <div key={index} className="col-xl-3 col-lg-6">
                          <div
                            className={`campaign-box bg-${
                              index % 4 === 0
                                ? "danger"
                                : index % 4 === 1
                                ? "warning"
                                : index % 4 === 2
                                ? "purple"
                                : "success"
                            }-light`}
                          >
                            <div className="campaign-img">
                              <span>
                                <img
                                  src={
                                    iconMapping[item.name] ||
                                    "ti ti-brand-campaignmonitor"
                                  }
                                />
                              </span>
                              <p>{item.name}</p>
                            </div>
                            <h2>{item.totalCount}</h2>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <PMSCharts />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
