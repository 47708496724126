import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../style/scss/mainatenance.scss";
import {
  useMaintenanceRequestImagesMutation,
  useReopenMaintenanceRequestMutation,
} from "../../redux/CommonApi";
import { unitStatusEnum } from "../../redux/RequestTypeEnum";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { json } from "stream/consumers";
import { useDispatch } from "react-redux";
import { setReAssignToNewTechnician, setReAssignToNewTechnicianImages } from "../../redux/Commonslice";
const MaintenancceReopenPopup = ({
  handleReopenClose,
  maintenanceRequestDetail,
  setReopenpopup,
  setPermissionpopup,
  buttonTextReopen,
  setTechnicianpopup,
  isTechnicianpopup,
  isReopenpopup
}) => {

  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);
  const [ReopenMaintenanceRequest] = useReopenMaintenanceRequestMutation();

  const [maintenanceRequestImages] = useMaintenanceRequestImagesMutation();
  const [uploadedImages, setUploadedImages] = React.useState([]);
  const [errors, setError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

    React.useEffect(() => {
   if(isReopenpopup === false){
        setUploadedImages([]);
        setDescription("");
   }
  }, [isReopenpopup]);

  const validateDescription = (text) => {
    if (!text.trim()) {
      setDescriptionError("Description is required.");
      return false;
    }
    setDescriptionError("");
    return true;
  };
  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    setDescription(text);
    validateDescription(text);
  };
  const uploadSingleFiles = (e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      if (file.size > 5 * 1024 * 1024) {
        setError("Each file must be less than 5MB");
        setTimeout(() => {
          setError("");
        }, 5000);
        continue;
      }

      setError("");

      reader.onload = (event) => {
        const result = event.target.result;
        let byteArray;

        if (typeof result !== "string") {
          byteArray = new Uint8Array(result);
        }

        const newImage = {
          status: unitStatusEnum.Reopen,
          image: byteArray ? Array.from(byteArray) : null,
          defaultIndicator: true,
          uniqueIdentifier: 2,
          maintenanceRequestMainID: 0,
          url: URL.createObjectURL(file),
          name: file.name,
        };

        setUploadedImages((prevImages) => [...prevImages, newImage]);
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handleRemoveImage = (index) => {
    const filteredImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(filteredImages);
  };

  const handleSubmitReopen = async () => {
    if (!validateDescription(description)) {
      toastr.error("Description is required.");
      return;
    }
    try {
      const reopenRequest = JSON.stringify({
        date: new Date(),
        name: sessionStorage.getItem("userName"),
        status: unitStatusEnum.Reopen,
        userTypeId: 1,
        isCreatedBy: 1,
        UserType: "Landlord",
      });

      const assignedRequest = JSON.stringify({
        date: new Date(),
        name: sessionStorage.getItem("userName"),
        status: unitStatusEnum.Assign,
        userTypeId: 1,
        isCreatedBy: 1,
        UserType: "Landlord",
      });
      const combinedRequest = `${reopenRequest}, ${assignedRequest}`;
      const updatedRequest = {
        ...maintenanceRequestDetail,
        status:buttonTextReopen === "Re-Open" ? unitStatusEnum.Reopen :  unitStatusEnum.Reopen,
        description: `${maintenanceRequestDetail.description}</br> ${description}`,
        json: buttonTextReopen === "Re-Open" ? reopenRequest : reopenRequest,
      };
      if (buttonTextReopen === "Re-Open") {
        setloader(true);
        const response = await ReopenMaintenanceRequest(updatedRequest);
      }
      let updatedMaintenanceRequestImages;
        if (updatedRequest.token) {
          // Remove 'url', 'name' and add 'maintenanceRequestMainID'
           updatedMaintenanceRequestImages = uploadedImages.map(
            ({ url, name, ...item }) => ({
              ...item,
              maintenanceRequestMainID: updatedRequest.token,
              setTechnicianpopup,
            })
          );
        }
      
          if (buttonTextReopen === "Re-Open") {
            for (const image of updatedMaintenanceRequestImages) {
              await maintenanceRequestImages(image);
            }
            setloader(false);
          toastr.success(buttonTextReopen);
          setReopenpopup(false);
          setPermissionpopup(false);
        }

      else if (buttonTextReopen === "Re Assign To New Technician") {
        dispatch(setReAssignToNewTechnician(updatedRequest));
        dispatch(setReAssignToNewTechnicianImages(updatedMaintenanceRequestImages));
        // setUploadedImages([]);
        // setDescription("");
        setTechnicianpopup(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="sidebar-layout w-25 w-sm-100">
        <>
          <div className="maintenance_Reopen pt-0 p-4">
            <div className="align-items-center d-flex justify-content-between pt-4 pb-2">
              <h2>
                {buttonTextReopen === "Re-Open"
                  ? `${buttonTextReopen} Request`
                  : buttonTextReopen}{" "}
              </h2>
              <Link
                to="#"
                className="sidebar-close toggle-btn"
                onClick={handleReopenClose}
              >
                <i className="ti ti-x" />
              </Link>
            </div>
            <div className="d-flex pb-2">
              <p className="addprperty-h4 pt-1">
                <span className="ps-2">Descripton</span>
              </p>
            </div>
            <textarea
              rows={10}
              cols={10}
              className="form-control form-textarea"
              placeholder="Write about Request...."
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
             {/* {descriptionError && (
              <p style={{ color: "red", marginTop: "5px" }}>{descriptionError}</p>
            )} */}

            <div className="row">
              <div className="container-fluid" style={{ paddingTop: "20px" }}>
                <form>
                  <div className="form-group multi-preview">
                    <div className="image-grid">
                      {uploadedImages.length > 0 &&
                        uploadedImages.map((image, index) => (
                          <div key={index} className="image-wrapper1">
                            <img
                              src={image.url}
                              alt={image.name}
                              className="uploaded-image"
                              style={{ aspectRatio: "3/2" }}
                            />
                            <span
                              className="remove_img d-flex justify-content-center align-items-center"
                              onClick={() => handleRemoveImage(index)}
                            >
                              X
                            </span>
                          </div>
                        ))}
                    </div>

                    <div
                      className="upload-wrapper"
                      style={{ position: "relative" }}
                    >
                      <div className="upload-btn-wrapper">
                        <button
                          type="button"
                          className="btn btn-danger btndangerbackground"
                          disabled={uploadedImages.length === 5}
                        >
                          Upload Images
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          onChange={uploadSingleFiles}
                          multiple
                          disabled={uploadedImages.length === 5}
                        />
                      </div>
                      {errors && (
                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "45px",
                          }}
                        >
                          {errors}
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="text-end" style={{ paddingRight: "20px" }}>
                  <i className="fa fa-picture-o fafaicon" aria-hidden="true">
                    <span className="fafaicon ps-2">
                      {uploadedImages.length}
                    </span>
                  </i>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <button
                disabled={loader || isTechnicianpopup}
                className="btn btn-primary  add-popup w-100 p-3 border rounded-1"
                onClick={handleSubmitReopen}
              >
                {loader ? (
                  <>
                    <i className="fa fa-spinner fa-spin"></i>
                  </>
                ) : null}
                {buttonTextReopen || ""}
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default MaintenancceReopenPopup;
