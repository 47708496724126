import React, { useState, useEffect } from "react";
import "../../../style/scss/unitCategory.scss";
import styles from "./Image.module.css";
const PropertyMasterImageupload = ({ singleFile, removeImage }) => {
  // const [singleFile, setSingleFile] = useState([]);

  //   const storageKey = `uploadedImages_${UnitcategoryId}`;

  // useEffect(() => {
  //   const savedImages = JSON.parse(localStorage.getItem(storageKey)) || [];
  //   setSingleFile(savedImages);
  // }, [storageKey]);

  //   useEffect(() => {
  //     localStorage.setItem(storageKey, JSON.stringify(singleFile));
  //   }, [singleFile, storageKey]);

  // const uploadSingleFiles = (e) => {
  //   const files = e.target.files;

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     const reader = new FileReader();

  //     reader.onload = (event) => {
  //       const result = event.target.result;
  //       let byteArray;

  //       if (typeof result !== "string") {
  //         byteArray = new Uint8Array(result);
  //       }

  //       const newImage = {
  //         propertyMasterID: 1,
  //         image: byteArray ? Array.from(byteArray) : null,
  //         url: URL.createObjectURL(file),
  //         name: file.name,
  //         ext: file.name.split('.').pop(),
  //       };
  //       setSingleFile((prevFiles) => [...prevFiles, newImage]);
  //     };

  //     reader.readAsArrayBuffer(file);
  //   }
  // };

  // const removeImage = (index) => {
  //   setSingleFile((prevFiles) => [
  //     ...prevFiles.slice(0, index),
  //     ...prevFiles.slice(index + 1),
  //   ]);
  // };

  return (
    <>

      <div className="form-group multi-preview">



        <div className="">
          <div className="row">
            {singleFile.length !== 0 &&
              singleFile.map((image, index) => (
           

                <div key={index} className="col-md-1 pb-2">
                <article
                  className={styles.article}
                  style={{ backgroundImage: `url(${image.url})` }}
                >
                  <h1 className={styles.header} onClick={() => removeImage(index)}>x</h1>
                </article>
              </div>


              ))}
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="text-end" style={{ paddingRight: "20px" }}>
          <i className="fa fa-picture-o fafaicon" aria-hidden="true">
            <span className="fafaicon ps-2">{singleFile.length}</span>
          </i>
        </div>
      </div> */}
    </>
  );
};

export default PropertyMasterImageupload;
