import React from "react";
import Nodata from "../feature-module/pms/Nodata";
import { useNavigate } from "react-router-dom";
import { Loader } from "../shared/Loader";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
export const RentCollectionAllTenants = ({
  Rentstatusdata,
  ischeck,
  setIscheckedTenant,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [phoneno, setphoneno] = React.useState("");
  const profileimg =
    "https://img.freepik.com/premium-vector/traditional-arabic-man-head-icon-vector-avatar_55610-6301.jpg";
  const handleCardClick = (e, aggrementId) => {
    e.preventDefault();
    navigate("/admin/rentHistory", {
      state: { aggrementId: aggrementId },
    });
  };
  // Handle Select All
  const handleCheckClick = (e, data) => {
    const phoneNumber = data?.phoneNumber || "";
    setphoneno(phoneNumber);
    const isChecked = e.target.checked;

    setIscheckedTenant(isChecked);
    setOpen(isChecked);
  };
  const handleClose1 = (bo) => {
    setOpen(bo);
  };
  const handleClose = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // const phoneNumber = data?.phoneNumber || '';
    const message = `Mumtalikati Reminder: Your rent is pending. Please pay your Pending Rent on first priority!`;

    if (!phoneno) {
      return;
    }

    const whatsappLink = isMobile
      ? `whatsapp://send?phone=${phoneno}&text=${encodeURIComponent(message)}`
      : `https://wa.me/?phone=${phoneno}&text=${encodeURIComponent(message)}`;

    window.location.href = whatsappLink;

    setOpen(false);
  };
  return (
    <div className="row gx-2 gy-2">
      {Rentstatusdata && Rentstatusdata?.length > 0 ? (
        Rentstatusdata.map((tenant, index) => {
          // const isSelected = selectedRequests.includes(tenant.tenantID);
          return (
            <div
              key={index}
              className={`col-xxl-6 col-xl-12 col-sm-12 mb-1 all_tenants `}
            >
              <div className="card m-0 card-shadow-1 cursor-pointer">
                <div className="card-body">
                  <div className="row">
                    <div
                      className={`col-sm-2  sm-user ${
                        ischeck === true ? "d-flex gap-4" : ""
                      }`}
                    >
                      {ischeck && (
                        <input
                          type="radio"
                          onClick={(e) => handleCheckClick(e, tenant)}
                        />
                      )}
                      <img
                        src={profileimg}
                        className="no-gap"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "50%",
                        }}
                        alt="Profile"
                        onClick={(e) => handleCardClick(e, tenant.agreementID)}
                      />
                    </div>
                    <div
                      className="col-sm-4 text-start py-2"
                      onClick={(e) => handleCardClick(e, tenant.agreementID)}
                    >
                      <h5 className=" sm-user text-truncate ">
                        {tenant.tenantName}
                      </h5>
                      <p className="sm-user">Caption: {tenant.tag}</p>
                    </div>
                    <div
                      className="col-sm-1 text-center"
                      onClick={(e) => handleCardClick(e, tenant.agreementID)}
                    ></div>
                    <div
                      className="col-sm-5 py-2"
                      onClick={(e) => handleCardClick(e, tenant.agreementID)}
                    >
                      <h5 className=" text-center ">
                        Amount:{" "}
                        <span className="">{tenant.totalPending.toFixed(1)} OMR</span>
                      </h5>
                      <p
                        className="text-center"
                        onClick={(e) => handleCardClick(e, tenant.agreementID)}
                      >
                        Due Date:{" "}
                        <span className="text-center">
                          {dayjs(tenant.aggStartDate).format("DD MMM YYYY")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Nodata />
      )}
      {Rentstatusdata?.length < 0 && <Nodata />}
      <div className="modal-wrapper">
        <Modal
          size="lg"
          show={open}
          onHide={() => handleClose1(false)}
          animation={false}
          centered
          dialogClassName="centered-modal custom-modal-bg1 w-25 w-sm-100 "
        >
          <div className="modal-content-wrapper ">
            <div className="text-start">Reminder Message</div>
            {/* <div className="text-center">{"Confirmation!"}</div> */}
            <Modal.Body>
              <div className="pt-3">
                Mumtalikati Reminder: Your rent is pending. Please pay your
                Pending Rent on first priority!
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end align-items-center">
              <div className="pt-2">
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                  onClick={() => handleClose()}
                >
                  Send
                </button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </div>
  );
};
