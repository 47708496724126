import api from "../Interceptor";


export const PMSDashBoardApi = async () => {
  try {
    
  
    const landlordId = parseInt(sessionStorage.getItem("userId"), 10);
    const response = await api.post(
      `/PMSDashBoard/WebViewDashBoard`,{landlordId:landlordId}
    );
   
    if (response.status === 200) {
      
      
    } else {
      console.error("Failed to Get permissions data:", response.statusText);
    }
    return response;
  } catch (error) {
    console.error("Error getting permission data:", error);
  }
};
