import React, { useState, useEffect, useRef } from "react";
import { useGetNotificationTitleIncludingDetailsQuery } from "../../../redux/CommonApi";
import NotificationTabs from "./NotificationTabs";
import SubNotification from "./SubNotification";
import Overdue from "./Overdue";

const PmsNotification = () => {
  const [activeTab, setActiveTab] = useState("");
  const [activeSubTabs, setActiveSubTabs] = useState({});
  const [iSUpdateData, setIsUpdateData] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const {
    data: notificationData,
    error,
    isLoading,
  } = useGetNotificationTitleIncludingDetailsQuery({});
  const userId = sessionStorage.getItem("userId");
  const tabContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [Pmsnotification, setPmsNotifications] = useState([]);

  useEffect(() => {
    if (notificationData) {
      setNotifications(notificationData);
      if (notificationData.length > 0) {
        const initialTab = notificationData[0].notificationTypeName;
        setActiveTab(initialTab);
        const initialActiveSubTabs = notificationData.reduce(
          (acc, notification) => {
            acc[notification.notificationTypeName] =
              notification.notificationSubTypeList[0].subTypeName;
            return acc;
          },
          {}
        );
        setActiveSubTabs(initialActiveSubTabs);
      }
    }
  }, [notificationData,iSUpdateData]);

  useEffect(() => {
    const activeNotification = notifications.find(
      (notification) => notification.notificationTypeName === activeTab
    );
    if (activeNotification) {
      setActiveSubTabs((prevSubTabs) => ({
        ...prevSubTabs,
        [activeTab]: activeNotification.notificationSubTypeList[0].subTypeName,
      }));
    }
  }, [activeTab, notifications]);

  const handleTabClick = (event, tabName) => {
    event.stopPropagation();
    setActiveTab(tabName);
  };
  const handleSubTabClick = (event, subTypeName) => {
    event.stopPropagation();
    setActiveSubTabs((prevSubTabs) => ({
      ...prevSubTabs,
      [activeTab]: subTypeName,
    }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Logic to close the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const scrollLeft = (event) => {
    event.stopPropagation();
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  const scrollRight = (event) => {
    event.stopPropagation();
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
  };

  if (error) {
    return <div>Error loading data</div>;
  }
  const activeNotification = notifications.find(
    (notification) => notification.notificationTypeName === activeTab
  );

  return (
    <>
      {/* <div
        className="dropdown-menu dropdown-menu-end notification-dropdown"
       
      > */}
      <div  ref={dropdownRef}>
      <div className="topnav-dropdown-header">
          <h4 className="notification-title">Smart Notifications</h4>
        </div>
        <div className="noti-content">
          <div className="tab-container-wrapper px-4">
            <div className="scroller scroller-left ps-2" onClick={scrollLeft}>
              <i className="fa-solid fa-chevron-left"></i>
            </div>
            <div className="scroller scroller-right pe-2" onClick={scrollRight}>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
            <div className="wrapper-nav" ref={tabContainerRef}>
              <NotificationTabs
                notifications={notifications}
                activeTab={activeTab}
                setActiveTab={(tabName, event) =>
                  handleTabClick(event, tabName)
                }
              />
            </div>
          </div>
          {activeNotification && (
            <SubNotification
              subTypes={activeNotification.notificationSubTypeList}
              activeSubTab={
                activeSubTabs[activeNotification.notificationTypeName]
              }
              setActiveSubTab={(subTypeName, event) =>
                handleSubTabClick(event, subTypeName)
              }
            />
          )}
          <div>
            {activeNotification && (
              <Overdue
                userId={userId}
                Pmsnotification={Pmsnotification}
                setPmsNotifications={setPmsNotifications}
                setIsUpdateData={setIsUpdateData}
                requestTypeId={
                  activeNotification.notificationSubTypeList.find(
                    (subType) =>
                      subType.subTypeName ===
                      activeSubTabs[activeNotification.notificationTypeName]
                  ).id
                }
              />
            )}
          </div>
        </div>
      </div>
     
      {/* </div> */}
    </>
  );
};

export default PmsNotification;
