import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../ImageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import { UserProfileDetail } from "../../../Services/ProfileServices";
import { imageUpload } from "../../../Services/StaffServices";
import { Loader } from "../../../shared/Loader";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

interface AvatarSquareProps {
    name: string;
    imageUrl: string | null;
    size?: number; // Optional size prop for flexibility
}

const SquareAvatar = styled(Avatar)(({ size }: { size?: number }) => ({
    width: size || 50, // Default size: 50px
    height: size || 50,
    borderRadius: 8, // Rounded corners, adjust as needed
}))
const route = all_routes;

interface FormData {
  name: string;
  userName: string;
  phoneNumber: string;
  email: string;

  dob: string;
  nationality: string;
}

interface FormErrors {
  name?: string;
  userName?: string;
  phoneNumber?: string;
  email?: string;
  dob?: string;
  nationality?: string;
}

const Profile = () => {
  const [loading, setloading] = useState(false);
  const [ProfileImageError, setProfileImageError] = useState("");
  const [UploadProfileImage, setUploadProfileImage] = useState({
    document: [],
    docName: "",
  });
  const [profileimage, setprofileimage] = useState("");
  const [formData, setFormData] = useState<FormData>({
    name: "",
    userName: "",
    phoneNumber: "",
    email: "",
    dob: "",
    nationality: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        const resdata = await UserProfileDetail();

        if (resdata) {
          const response = resdata.res;
          setprofileimage(resdata.image);
          setFormData({
            ...formData,
            name: response.name,
            phoneNumber: response.contact,
            email: response.email,
            userName: response.userName,
            dob: response.dob ? response.dob.split("T")[0] : null,
            nationality: response.nationality,
          });
        }
        setloading(false);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchData();
  }, []);
  const letterAvatar = formData.name.charAt(0).toUpperCase();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file.size > 2 * 1024 * 1024) {
      setProfileImageError("File size should not exceed 2MB");
      e.target.value = "";
      setprofileimage("");
      setTimeout(() => {
        setProfileImageError("");
      }, 5000);
      return;
    }
    const createimageUrl = URL.createObjectURL(file);
    setprofileimage(createimageUrl);
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result as ArrayBuffer;
      const byteArray = new Uint8Array(arrayBuffer);

      const profileImage = {
        document: Array.from(byteArray),
        docName: file.name,
      };

      setUploadProfileImage(profileImage);

      const profileImageResponse = UploadUserDocument(profileImage);

      imageUpload(profileImageResponse);
    };

    reader.readAsArrayBuffer(file);
  };

  const UploadUserDocument = (uploadImage) => {
    const landlordId = sessionStorage.getItem("userId");
    const profileImageResponse = {
      status: 1,
      document: Array.from(uploadImage.document),
      docName: uploadImage.docName,
      userID: landlordId,
      mediaTypeID: 5,
    };
    return profileImageResponse;
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            {/* <div className="page-header">
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <h4 className="page-title">Settings</h4>
                </div>
                <div className="col-sm-8 text-sm-end">
                  <div className="head-icons"></div>
                </div>
              </div>
            </div> */}

            <div className="card settings-tab ">
              <div className="card-body pb-0">
                <div className="settings-menu">
                  <ul className="nav">
                    <li>
                      <Link to={route.userProfile} className="active">
                        {/* <i className="ti ti-settings-cog" />  */}
                        Profile
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="card ">
                  <div className="card-body">
                    <div className="settings-header">
                      <h4></h4>
                    </div>
                    {!loading ? (
                      <>
                        <div className="settings-form">
                          <form>
                            <div className="settings-sub-header">
                              <h6>User Information</h6>
                              <p></p>
                            </div>
                            <div className="form-wrap">
                              <div className="profile-upload">
                                <div className="profile-upload-img">
                                  <SquareAvatar
                                    src={profileimage || undefined}
                                    alt={formData.name}
                                    size={50}
                                    style={{
                                      backgroundColor: profileimage ? "transparent" : "#3f51b5",
                                      color: profileimage ? "inherit" : "#fff",
                                    }}
                                  >
                                    {!profileimage && letterAvatar} {/* Fallback to letter if no image */}
                                  </SquareAvatar>
                                  {/* <span>
                                    <img
                                      className="profileimg card-shadow-1"
                                      src={
                                        profileimage ||
                                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                      }
                                    />
                                  </span> */}
                                </div>
                                <div className="profile-upload-content">
                                  <label className="profile-upload-btn card-shadow-1">
                                    <i className="ti ti-file-broken" /> Upload
                                    File
                                    <input
                                      type="file"
                                      id="imag"
                                      className="input-img"
                                      onChange={handleFileUpload}
                                    // style={{box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.2)}}
                                    />
                                  </label>
                                  <p>
                                    {" "}
                                    {ProfileImageError && (
                                      <span style={{ color: "red" }}>
                                        {ProfileImageError}
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="profile-details">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Name <span className="text-danger"></span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control Profile_Shadow "
                                      name="name"
                                      value={formData.name}
                                      onChange={handleChange}
                                      disabled
                                    />
                                    {errors.name && (
                                      <p className="text-danger">
                                        {errors.name}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      User Name{" "}
                                      <span className="text-danger"></span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control Profile_Shadow "
                                      name="userName"
                                      value={formData.userName}
                                      onChange={handleChange}
                                      disabled
                                    />
                                    {errors.userName && (
                                      <p className="text-danger">
                                        {errors.userName}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Phone Number{" "}
                                      <span className="text-danger"></span>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control Profile_Shadow"
                                      name="phoneNumber"
                                      value={formData.phoneNumber}
                                      onChange={handleChange}
                                      disabled
                                    />
                                    {/* {errors.phoneNumber && <p className="text-danger">{errors.phoneNumber}</p>} */}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Email{" "}
                                      <span className="text-danger"></span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control Profile_Shadow"
                                      name="email"
                                      value={formData.email}
                                      onChange={handleChange}
                                      disabled
                                    />
                                    {errors.email && (
                                      <p className="text-danger">
                                        {errors.email}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="profile-address">
                              <div className="settings-sub-header">
                                <h6>Detail</h6>
                                <p></p>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Dob <span className="text-danger"></span>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control Profile_Shadow"
                                      name="dob"
                                      value={formData.dob}
                                      onChange={handleChange}
                                      disabled
                                    />
                                    {errors.dob && (
                                      <p className="text-danger">
                                        {errors.dob}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Nationality{" "}
                                      <span className="text-danger"></span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control Profile_Shadow"
                                      name="nationality"
                                      value={formData.nationality}
                                      onChange={handleChange}
                                      disabled
                                    />
                                    {errors.nationality && (
                                      <p className="text-danger">
                                        {errors.nationality}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="submit-button">
                          <Link to="#" className="btn btn-light">
                            Cancel
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Save Changes
                          </button>
                        </div> */}
                          </form>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
