import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import React from 'react'
import { Form } from 'react-bootstrap'
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const AddCheque = ({
    bankForm,
    handleChangeBankForm,
    handleAddBank,
    chequevalidated,
    bankdata,
    formValues,
    handleCloseChequeToast
}) => {
  return (
    <Form className='pt-4' noValidate validated={chequevalidated} onSubmit={handleAddBank}>
    <div className="row">
        <div className="col-md-12">
            {/* Bank Name */}
            <div className="form-wrap">
                <label className="col-form-label">Bank Name</label>
                <select
                    className="form-select negotiate-form"
                    name="bankID"
                    value={bankForm.bankID}
                    onChange={handleChangeBankForm}
                    required
                >
                    <option value="">Select Bank</option>
                    {bankdata?.map((bank) => (
                        <option key={bank.bankType} value={bank.bankType}>
                            {bank.desc}
                        </option>
                    ))}
                </select>
                <Form.Control.Feedback type="invalid">
                    Bank Name is required.
                </Form.Control.Feedback>
            </div>

            {/* Cheque Number */}
            <div className="form-wrap">
                <label className="col-form-label">Cheque Number</label>
                <input
                    className="form-control negotiate-form"
                    name="chequeNumber"
                    placeholder="Enter Cheque Number"
                    value={bankForm.chequeNumber}
                    onChange={handleChangeBankForm}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Enter Cheque Number.
                </Form.Control.Feedback>
            </div>

            {/* Amount */}
            <div className="form-wrap">
                <label className="col-form-label">Amount (OMR)</label>
                <input
                    className="form-control negotiate-form"
                    name="amount"
                    placeholder="Enter Amount in OMR"
                    value={formValues.advanceAmount}
                    disabled={true}
                    required
                    type="number"
                    min={0}
                />
                <Form.Control.Feedback type="invalid">
                    Enter Amount.
                </Form.Control.Feedback>
            </div>

            {/* Due Date */}
            <div className="form-wrap">
                <label className="col-form-label">Due Date</label>
                <div className="form-control negotiate-form duedate-form">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            defaultValue={dayjs()}
                            value={bankForm.dueDate ? dayjs(bankForm.dueDate) : null}
                            onChange={(date) =>
                                handleChangeBankForm({
                                    target: { name: "dueDate", value: date?.toISOString() },
                                })
                            }
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    </div>

    {/* Action Buttons */}
    <div className="d-flex justify-content-end gap-2 pb-4 pt-3">
        <button
            type="button"
            className="btn addfeature-btn"
            onClick={handleCloseChequeToast}
        >
            Cancel
        </button>
        <button type="submit" className="btn btn-danger">
            Save
        </button>
    </div>
</Form>
  )
}

export default AddCheque