import { maintenanceRequestDashboard, recentMaintenanceRequests } from "../../MaintenanceRequest/Model/maintenanceRequestDashboard";
import { ITenantDashboard, TenantDashboard } from "./TenantDashboard";

export interface IRecentMaintenanceRequest {
  requestId: number;
  categoryName: string;
  categoryId: number;
  maintenanceCategoryURL: string;
  unitCategoryURL: string;
  propertySubTypeURL: string;
  priority: string | null;
  desc: string;
  buildingName: string;
  buildingImage: string;
  unitCategory: string;
  createdDate: string;
  caption: string;
  serviceProviderName: string | null;
  propertyUnitID: number;
  status: number;
  ratingPoint: number | null;
  feedBack: string | null;
  }
  export class RecentMaintenanceRequest  implements IRecentMaintenanceRequest{
    requestId: number;
    categoryName: string;
    categoryId: number;
    maintenanceCategoryURL: string;
    unitCategoryURL: string;
    propertySubTypeURL: string;
    priority: string | null;
    desc: string;
    buildingName: string;
    buildingImage: string;
    unitCategory: string;
    createdDate: string;
    caption: string;
    serviceProviderName: string | null;
    propertyUnitID: number;
    status: number;
    ratingPoint: number | null;
    feedBack: string | null;

  constructor(data: RecentMaintenanceRequest ){
    this.requestId = data.requestId ?? 0;
    this.categoryName = data.categoryName ?? '';
    this.categoryId = data.categoryId ?? 0;
    this.maintenanceCategoryURL = data.maintenanceCategoryURL ?? '';
    this.unitCategoryURL = data.unitCategoryURL ?? '';
    this.propertySubTypeURL = data.propertySubTypeURL ?? '';
    this.priority = data.priority ?? '';
    this.desc = data.desc ?? '';
    this.buildingName = data.buildingName ?? '';
    this.buildingImage = data.buildingImage ?? '';
    this.unitCategory = data.unitCategory ?? '';
    this.createdDate = data.createdDate ?? '';
    this.caption = data.caption ?? '';
    this.serviceProviderName = data.serviceProviderName ?? '';
    this.propertyUnitID = data.propertyUnitID ?? 0;
    this.status = data.status ?? 0;
    this.ratingPoint = data.ratingPoint ?? 0;
    this.feedBack = data.feedBack ?? '';

  }
  
  }







