// PropertyDetail.js
import React from "react";
import {
  useGetPropertyCategoryDetailViewQuery,
  useGetPropertyfeatureQuery,
} from "../../../redux/CommonApi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import UnitsCard from "./UnitsCard";
import { Loader } from "../../../shared/Loader";
import { PropertyCategoryDetail } from "../../Models/Pms/PropertyCategoryDetail";
import { PropertyFeature } from "../../Models/Pms/PropertyFeature";

const fallbackImage =
  "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";

const UnitDetail = ({ propertyId, selectedPropertyUnit }) => {
  const userId = sessionStorage.getItem("userId");
  const { data, isLoading, error } = useGetPropertyCategoryDetailViewQuery({
    userId: +userId,
    unitCategoryId: selectedPropertyUnit,
    PropertyMasterId: propertyId,
  }) as {data: PropertyCategoryDetail[], isLoading: boolean, error: any};
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const { data: propertyfeature } = useGetPropertyfeatureQuery({
    PropertyMasterId: propertyId,
  })  as {data:PropertyFeature[]};
  if (isLoading) return <Loader/>;
  if (error) return <p>Error loading property details</p>;
  const images = data[0].imageString || [];
  const hasImages = images.length > 0;
  const hasMultipleImages = images.length > 1;
  const mainFeatures =
    propertyfeature?.filter(
      (feature) => feature.propertyUnitCategoryID === selectedPropertyUnit
    ) || [];
  return (
    <>
      <div className="row ">
        <div className="col-md-6">
          {hasImages ? (
            hasMultipleImages ? (
              <>
                {/* Swiper for Property Images */}
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={`Slide ${index}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={`Thumb ${index}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            ) : (
              <img
                src={images[0]}
                alt="Single Image"
                style={{ height: "45vh", width: "100%" }}
              />
            )
          ) : (
            <img className="fallback-img" src={fallbackImage} alt="Fallback" />
          )}
        </div>
        <div className="col-md-6 ">
          <div>
            {/* <h4 className="py-2">{data[0].propertyMasterName}</h4> */}
            {/* <p>{data[0].description}</p> */}
          </div>
          <div className="row">
            <div className="col-md-6 propertydetail">
              <h6 className="py-2">Property Information</h6>
              <div>
                <p className="page-title">
                  Type: <span>{data[0].propertySubTypeName}</span>
                </p>
                <p>
                  Building ID: <span>{data[0].buildingNumber}</span>
                </p>
                <p className="truncate">
                  Building Name: <span>{data[0].propertyMasterName}</span>
                </p>

                <p className="fs-15 totalunits-address truncate">
                  <i
                    className="ti ti-map-pin-filled "
                    style={{
                      background:
                        "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }}
                  />{" "}
                  {data[0].addressStr}
                </p>
                <p>
                  Units: <span>{data[0].totalUnit}</span>
                </p>
                {/* <p>Occupied: <span>{data.occupied}</span></p>
                                <p>Partially Occupied: <span>{data.partiallyOccupied}</span></p>
                                <p className='' style={{
                                    background: 'linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    backgroundClip: 'text',
                                }}>Vacant: <span>{data.vacant}</span></p> */}
                <p className="d-flex">
                  {/* Categories: {data.totalcategories} (
                                    <ul className='d-flex'>
                                        {data.categoriesList.map((category, index) => (
                                            <li key={index}>
                                                {category.propertyUnitName}
                                                {index < data.categoriesList.length - 1 && ','}
                                            </li>
                                        ))}
                                    </ul>
                                    ) */}
                </p>

                {/* <ul className='d-flex'>
                                    {data.?data.categoriesList.map((category, index) => (
                                        <li key={index}>
                                            {category.propertyUnitName}: {category.propertyCategoryUnit}
                                             {index < data.categoriesList.length - 1 && ' /'}
                                        </li>
                                    ))}
                                </ul> */}
              </div>
            </div>
            {mainFeatures && mainFeatures.length > 0 && (
            <div className="col-md-6">
              <h6 className="py-2 page-title">Main Features</h6>
              <div>
                <ul>
                  {mainFeatures.map((feature, index) => (
                    <li key={index} className="page-title">{feature.feature.desc}</li>
                  ))}
                </ul>
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitDetail;
