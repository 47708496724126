import { Feature } from "./Feature";

export interface IPropertyFeature  {
  propertyMasterID: number;
  propertyMaster: string | null;
  propertyUnitCategoryID: number;
  propertyUnitCategory: string | null;
  featureID: number;
  feature: Feature;
  propertyTypes: number;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string;
  modifyTime: string;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;
}

export class PropertyFeature  implements IPropertyFeature  {
  propertyMasterID: number;
  propertyMaster: string | null;
  propertyUnitCategoryID: number;
  propertyUnitCategory: string | null;
  featureID: number;
  feature: Feature;
  propertyTypes: number;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string;
  modifyTime: string;
  isCreatedBy: number | null;
  isUpdatedBy: number | null;

  constructor(data: Partial<PropertyFeature >) {
    this.propertyMasterID = data.propertyMasterID ?? 0;
    this.propertyMaster = data.propertyMaster ?? null;
    this.propertyUnitCategoryID = data.propertyUnitCategoryID ?? 0;
    this.propertyUnitCategory = data.propertyUnitCategory ?? null;
    this.featureID = data.featureID ?? 0;
    this.feature = new Feature(data.feature);
    this.propertyTypes = data.propertyTypes ?? 0;
    this.id = data.id ?? 0;
    this.status = data.status ?? 0;
    this.statusCode = data.statusCode ?? null;
    this.createdTime = data.createdTime ?? '';
    this.modifyTime = data.modifyTime ?? '';
    this.isCreatedBy = data.isCreatedBy ?? null;
    this.isUpdatedBy = data.isUpdatedBy ?? null;

  }
}
