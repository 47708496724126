
export interface IRentDashboard {
  totalpending: number;
  rentPendingPercentage: number;
  rentReceivedPercentage: number;
  totalRentReceived: number;
  totalOutstandingAmount: number;
  }
  export class RentDashboard implements IRentDashboard {
    totalpending: number;
  rentPendingPercentage: number;
  rentReceivedPercentage: number;
  totalRentReceived: number;
  totalOutstandingAmount: number;

    constructor(data: RentDashboard) {
      this.totalpending = data.totalpending ?? 0;
      this.rentPendingPercentage = data.rentPendingPercentage ?? 0;
      this.rentReceivedPercentage = data.rentReceivedPercentage ?? 0;
      this.totalRentReceived = data.totalRentReceived ?? 0;
      this.totalOutstandingAmount = data.totalOutstandingAmount ?? 0;
    }
  }
  