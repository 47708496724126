import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
const useStyles = makeStyles(() => ({
  ul: {
    display: "flex",
    justifyContent: "center",
    "& .Mui-selected": {
      color: "#fff",
      background:
        "linear-gradient(to bottom,rgba(223, 24, 26, 0.85),   rgb(158, 42, 43))",
    },
    "& .MuiPaginationItem-previousNext": {
      color: "#9E2A2B",
    },
    "& .MuiPaginationItem-previousNext:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
}));
export const StackPagination = ({
  tenancyRequestCount,
  rowNumbers,
  pageNumber,
  handlePageChange,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <Stack spacing={2} mt={3}>
      <div
        style={{
          padding: "4px",
          borderRadius: "15px",
          background: "#fff",
          boxShadow:
            "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
          border: "1px solid var(--gradientttt, rgba(190, 12, 47, 0.70))",
        }}
      >
        <div
          style={{
            borderRadius: "8px",
            backgroundColor: "#fff",
            padding: "2px",
          }}
        >
          {/* <Pagination
          classes={{ ul: classes.ul }}
          count={Math.ceil(tenancyRequestCount / rowNumbers)}
          page={pageNumber}
          onChange={handlePageChange}
        /> */}

          <Pagination
            classes={{ ul: classes.ul }}
            count={Math.ceil(tenancyRequestCount / rowNumbers)}
            page={pageNumber}
            onChange={handlePageChange}
            siblingCount={isSmallScreen ? 0 : 1} // Fewer siblings on small screens
            boundaryCount={1} // Show first and last page always
            variant="outlined"
          />
        </div>
      </div>
    </Stack>
  );
};
