// PropertyDetail.js
import React from "react";
import {
  useGetPropertyCategoryDetailViewQuery,
  useGetPropertyfeatureQuery,
  useGetPropertyMasterDetailQuery,
} from "../../../redux/CommonApi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import UnitsCard from "./UnitsCard";
import { Loader } from "../../../shared/Loader";
import { PropertySubTypeEnum } from "../../../redux/RequestTypeEnum";
import { OwnerPropertyMasterDetail } from "../../Models/Pms/OwnerPropertyMasterDetail";
import { PropertyFeature } from "../../Models/Pms/PropertyFeature";


const fallbackImage =
  "https://firebasestorage.googleapis.com/v0/b/mumtalikati-e8dbd.appspot.com/o/svgIcons%2FGroup%201865.png?alt=media&token=794f7eb8-9409-4199-a0a0-75fc3e576361";

const PropertyDetail = ({ propertyId, status, setSelectedPropertyUnit }) => {
  const userId = sessionStorage.getItem("userId");
  const { data, error, isLoading } = useGetPropertyMasterDetailQuery({
    PropertyId: propertyId,
    status: status,
  }) as {data:OwnerPropertyMasterDetail, error:any, isLoading:boolean};
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const { data: propertyfeature } = useGetPropertyfeatureQuery({
    PropertyMasterId: propertyId,
  }) as {data:PropertyFeature[]};
  if (isLoading) return <Loader/>;
  if (error) return <p>Error loading property details</p>;

  const images = data.imageString || [];
  const hasImages = images.length > 0;
  const hasMultipleImages = images.length > 1;
  const mainFeatures =
    propertyfeature?.filter(
      (feature) => feature.propertyUnitCategoryID === null
    ) || [];

  return (
    <>
      <div className="row pb-4">
        <div className="col-md-6">
          {hasImages ? (
            hasMultipleImages ? (
              <>
                {/* Swiper for Property Images */}
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={`Slide ${index}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={`Thumb ${index}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            ) : (
              <img
                src={images[0]}
                alt="Single Image"
                style={{ height: "45vh", width: "100%" }}
                className="pb-4"
              />
            )
          ) : (
            <img
              className=" pb-4 img-fluid"
              src={fallbackImage}
              alt="Fallback"
              style={{ width: "100%" }}
            />
          )}
    
        </div>
        <div className="col-md-6">
          <div>
            <h4 className="py-2">{data.propertyMasterName}</h4>
            <p>{data.description}</p>
          </div>
          <div className="row">
            <div className="col-md-6 propertydetail">
              <h6 className="py-2">Property Information</h6>
              <div>
                <p className="page-title">
                  Building ID: <span className="page-title">{data.buildingNumber}</span>
                </p>
                <p className="truncate">
                  Building Name: <span>{data.propertyMasterName}</span>
                </p>
                <p>
                  Plot Number: <span>{data.plotNumber}</span>
                </p>
                <p className=" totalunits-address ">
                  <i
                    className="ti ti-map-pin-filled "
                    style={{
                      background:
                        "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }}
                  />{" "}
                  {data.addressStr}
                </p>
                <p>
                  Total Units: <span>{data.totalUnits}</span>
                </p>
                <p>
                  Occupied: <span>{data.occupied}</span>
                </p>

                <p
                  className=""
                  style={{
                    background:
                      "linear-gradient(to right, rgba(190, 12, 47, 0.7), rgba(230, 51, 50, 0.9), rgba(203, 7, 83, 0.8),rgba(188, 1, 119, 0.9))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                  }}
                >
                  Vacant: <span>{data.vacant}</span>
                </p>
                {data && (data?.propertySubTypeId === PropertySubTypeEnum.Building || data?.propertySubTypeId === PropertySubTypeEnum.MixUse) ? (<>  <p>
                  Partially Occupied: <span>{data.partiallyOccupied}</span>
                </p>
                  <div className="d-flex">
                    Categories: {data.totalcategories}
                    {/*(
                   <ul className="d-flex">
                    {data.categoriesList.map((category, index) => (
                      <li key={index}>
                        {category.propertyUnitName}
                        {index < data.categoriesList.length - 1 && ","}
                      </li>
                    ))}
                  </ul> 
                  )*/}
                  </div>

                  <ul className="d-flex">
                    {data.categoriesList.map((category, index) => (
                      <li key={index}>
                        {category.propertyUnitName}:{" "}
                        {category.propertyCategoryUnit}
                        {index < data.categoriesList.length - 1 && " /"}
                      </li>
                    ))}
                  </ul> </>) : (<></>)}

              </div>
            </div>
            {mainFeatures && mainFeatures.length > 0 && (
              <div className="col-md-6">
                <h6 className="py-2 page-title">Main Features</h6>
                <div>
                  <ul>
                    {mainFeatures.map((data, index) => (
                      <li key={index} className="page-title">{data.feature.desc}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <UnitsCard
        propertyId={propertyId}
        status={status}
        setSelectedPropertyUnit={setSelectedPropertyUnit}
      />
    </>
  );
};

export default PropertyDetail;
