import { ImageUrl } from "./ImageUrl";
import { JsonList } from "./JsonList";

export interface IMaintenanceRequestDetail {
  requestId: number;
  userId: number;
  profileImage: string;
  token: number;
  description: string;
  categoryId: number;
  maintenanceCategoryName: string;
  unitID: number;
  status: number;
  json: string;
  name: string;
  urgency: string | null;
  priorityId: number | null;
  buildingNumber: string;
  contact: string | null;
  imageUrl: ImageUrl[];
  userTypeId: number;
  jsonList: JsonList[];
  caption: string;
  isAlreadyReview: boolean;
  serviceProviderId: number | null;
}

export class MaintenanceRequestDetail implements IMaintenanceRequestDetail {
  requestId: number;
  userId: number;
  profileImage: string;
  token: number;
  description: string;
  categoryId: number;
  maintenanceCategoryName: string;
  unitID: number;
  status: number;
  json: string;
  name: string;
  urgency: string | null;
  priorityId: number | null;
  buildingNumber: string;
  contact: string | null;
  imageUrl: ImageUrl[];
  userTypeId: number;
  jsonList: JsonList[];
  caption: string;
  isAlreadyReview: boolean;
  serviceProviderId: number | null;

  constructor(data: MaintenanceRequestDetail) {
    this.requestId = data.requestId;
    this.userId = data.userId;
    this.profileImage = data.profileImage;
    this.token = data.token;
    this.description = data.description;
    this.categoryId = data.categoryId;
    this.maintenanceCategoryName = data.maintenanceCategoryName;
    this.unitID = data.unitID;
    this.status = data.status;
    this.json = data.json;
    this.name = data.name;
    this.urgency = data.urgency;
    this.priorityId = data.priorityId;
    this.buildingNumber = data.buildingNumber;
    this.contact = data.contact;
    this.imageUrl = data.imageUrl.map(item => new ImageUrl(item));
    this.userTypeId = data.userTypeId;
    this.jsonList = data.jsonList.map(item => new JsonList(item));
    this.caption = data.caption;
    this.isAlreadyReview = data.isAlreadyReview;
    this.serviceProviderId = data.serviceProviderId;
  }
}