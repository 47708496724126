
export interface IAgreementDetail  {
  landLordID: number;
  landlordUser: string | null;
  tenantID: number;
  tenantUser: string | null;
  corporateID: number | null;
  corporateUser: string | null;
  reqUsrID: number | null;
  reqUser: string | null;
  reqUsrTypeID: number | null;
  reqUsrType: string | null;
  rentalModeID: number;
  rentalMode: string | null;
  rentalFrequencyID: number;
  rentalFrequency: string | null;
  paymentTypeID: number;
  paymentType: string | null;
  aggStartDate: string; 
  aggEndDate: string; 
  advanceAmount: number;
  terms: number;
  rentalUnitID: number;
  rentalUnit: string | null;
  rentAmount: number;
  balanceAmount: number | null;
  propertyUnitStatusID: number;
  propertyUnitStatus: string | null;
  desc: string;
  noticePeriod: number | null;
  feedback: string | null;
  id: number;
  status: number;
  statusCode: string | null;
  createdTime: string; 
  modifyTime: string;
  isCreatedBy: number;
  isUpdatedBy: number;
  }
  export class AgreementDetail implements IAgreementDetail {
    landLordID: number;
    landlordUser: string | null;
    tenantID: number;
    tenantUser: string | null;
    corporateID: number | null;
    corporateUser: string | null;
    reqUsrID: number | null;
    reqUser: string | null;
    reqUsrTypeID: number | null;
    reqUsrType: string | null;
    rentalModeID: number;
    rentalMode: string | null;
    rentalFrequencyID: number;
    rentalFrequency: string | null;
    paymentTypeID: number;
    paymentType: string | null;
    aggStartDate: string; 
    aggEndDate: string; 
    advanceAmount: number;
    terms: number;
    rentalUnitID: number;
    rentalUnit: string | null;
    rentAmount: number;
    balanceAmount: number | null;
    propertyUnitStatusID: number;
    propertyUnitStatus: string | null;
    desc: string;
    noticePeriod: number | null;
    feedback: string | null;
    id: number;
    status: number;
    statusCode: string | null;
    createdTime: string; 
    modifyTime: string; 
    isCreatedBy: number;
    isUpdatedBy: number;

    constructor(data: AgreementDetail) {
    this.landLordID = data.landLordID ?? 0;
    this.landlordUser = data.landlordUser ?? null;
    this.tenantID = data.tenantID ?? 0;
    this.tenantUser = data.tenantUser ?? null;
    this.corporateID = data.corporateID ?? null;
    this.corporateUser = data.corporateUser ?? null;
    this.reqUsrID = data.reqUsrID ?? null;
    this.reqUser = data.reqUser ?? null;
    this.reqUsrTypeID = data.reqUsrTypeID ?? null;
    this.reqUsrType = data.reqUsrType ?? null;
    this.rentalModeID = data.rentalModeID ?? 0;
    this.rentalMode = data.rentalMode ?? null;
    this.rentalFrequencyID = data.rentalFrequencyID ?? 0;
    this.rentalFrequency = data.rentalFrequency ?? null;
    this.paymentTypeID = data.paymentTypeID ?? 0;
    this.paymentType = data.paymentType ?? null;
    this.aggStartDate = data.aggStartDate ?? '';
    this.aggEndDate = data.aggEndDate ?? '';
    this.advanceAmount = data.advanceAmount ?? 0;
    this.terms = data.terms ?? 0;
    this.rentalUnitID = data.rentalUnitID ?? 0;
    this.rentalUnit = data.rentalUnit ?? null;
    this.rentAmount = data.rentAmount ?? 0;
    this.balanceAmount = data.balanceAmount ?? null;
    this.propertyUnitStatusID = data.propertyUnitStatusID ?? 0;
    this.propertyUnitStatus = data.propertyUnitStatus ?? null;
    this.desc = data.desc ?? '';
    this.noticePeriod = data.noticePeriod ?? null;
    this.feedback = data.feedback ?? null;
    this.id = data.id ?? 0;
    this.status = data.status ?? 0;
    this.statusCode = data.statusCode ?? null;
    this.createdTime = data.createdTime ?? '';
    this.modifyTime = data.modifyTime ?? '';
    this.isCreatedBy = data.isCreatedBy ?? 0;
    this.isUpdatedBy = data.isUpdatedBy ?? 0;

    }
  }
  