

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ExpenseDashboardModel } from "../feature-module/Models/Expense/ExpenseDashboard";
import { IResponseMessage, ResponseMessage } from "../feature-module/Models/GenericModel/ResponseMessage";
import { BuildingExpense } from "../feature-module/Models/Expense/BuildingExpense";
import { PropertyMasterTransactions } from "../feature-module/Models/Expense/PropertyMasterTransactions";
import { ExpenseDetail } from "../feature-module/Models/Expense/ExpenseDetail";
import { AddExpenseRequest } from "../feature-module/Models/Expense/AddExpenseRequest";
import { ExpenseCategory, IExpenseCategory } from "../feature-module/Models/Expense/ExpenseCategory";
import { ExpenseSubCategory } from "../feature-module/Models/Expense/ExpenseSubCategory";
import { UpdateExpenseStatus } from "../feature-module/Models/Expense/UpdateExpenseStatus";
import { RentDashboard } from "../feature-module/Models/RentCollection/RentDashboard";
import { RentalFrequency } from "../feature-module/Models/RentCollection/RentalFrequency";
import { RentCollectionDashboardProperty } from "../feature-module/Models/RentCollection/RentCollectionDashboardProperty";
import { RentCollectionResponse } from "../feature-module/Models/RentCollection/RentCollectionResponse";
import { PropertyRentStatus } from "../feature-module/Models/RentCollection/PropertyRentStatus";
import { number } from "yup";
import { AgreementRentHistory } from "../feature-module/Models/RentCollection/AgreementRentHistory";
import { AgreementDetail } from "../feature-module/Models/RentCollection/AgreementDetail";
import { AgreementTransactionsDetail } from "../feature-module/Models/RentCollection/AgreementTransactionsDetail";
import { AgreementRentTransactions } from "../feature-module/Models/RentCollection/AgRentTransactions";
import { PropertyMaster } from "../feature-module/Models/RentCollection/PropertyMaster";
import { AgreementPropertyUnit } from "../feature-module/Models/RentCollection/AgreementPropertyUnit";
import { PaymentType } from "../feature-module/Models/RentCollection/PaymentType";
import { UserPendingLiabilitie } from "../feature-module/Models/RentCollection/UserPendingLiabilitie";
import { RentRequest } from "../feature-module/Models/RentCollection/RentRequest";
import { Bank } from "../feature-module/Models/RentCollection/Bank";
import { AgreementRentTransactionsDetail } from "../feature-module/Models/RentCollection/AgreementRentTransactionsDetail";
import { Receipt } from "../feature-module/Models/RentCollection/Receipt";
import { TransactionUpdate } from "../feature-module/Models/RentCollection/TransactionUpdate";
import { MaintenanceResponseMessage } from "../feature-module/Models/GenericModel/MaintenanceResponseMessage";
import { MaintenanceDashboardData } from "../feature-module/Models/MaintenanceRequest/MaintenanceDashboardData";
import { MaintenanceRequestData } from "../feature-module/MaintenanceRequest/Model/maintenanceRequestDashboard";
import { MaintenanceRequestDetail } from "../feature-module/Models/MaintenanceRequest/MaintenanceRequestDetail";
import { SingleResponseMessage } from "../feature-module/Models/GenericModel/SingleResponseMessage";
import { MaintenanceRequestBuildings } from "../feature-module/Models/MaintenanceRequest/MaintenanceRequestBuildings";
import { MaintenanceRequestUnits } from "../feature-module/Models/MaintenanceRequest/MaintenanceRequestUnits";
import { DashboardRecentRequests } from "../feature-module/Models/MaintenanceRequest/DashboardRecentRequests";
import { MaintenanceRequest } from "../feature-module/Models/MaintenanceRequest/MaintenanceRequest";
import { MaintenanceRequestCategory } from "../feature-module/Models/MaintenanceRequest/MaintenanceRequestCategory";
import { RequestDetail } from "../feature-module/Models/MaintenanceRequest/RequestDetail";
import { MaintenanceRequestImage } from "../feature-module/Models/MaintenanceRequest/MaintenanceRequestImage";
import { StaffDashboard } from "../feature-module/Models/StaffManagement/StaffDashboard";
import { LandlordStaff } from "../feature-module/Models/StaffManagement/LandlordStaff";
import { OwnerDashBoard } from "../feature-module/Models/Pms/OwnerDashBoard";
import { OwnerPropertyMaster } from "../feature-module/Models/Pms/OwnerPropertyMaster";
import { OwnerPropertyMasterDetail } from "../feature-module/Models/Pms/OwnerPropertyMasterDetail";
import { PropertyFeature } from "../feature-module/Models/Pms/PropertyFeature";
import { PropertyCategoryDetail } from "../feature-module/Models/Pms/PropertyCategoryDetail";



const userId = sessionStorage.getItem("userId");
export const CommonApi = createApi({
  reducerPath: "CommonApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_USER_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Platform", "web");
      headers.set("Origin", "https://mumtalikati.com");
      headers.set("Content-Type", "application/json"); 
      return headers;
    },
  }),
  tagTypes: ["Login"],
  endpoints: (builder) => ({
    getAuthenticateUser: builder.mutation({
      query: ( object ) => ({
        url: "/Users/Authenticate",
        method: "POST",
        body: object,
      }),
      invalidatesTags: ["Login"],
    }),
    getUserImg: builder.query({
      query: (userID) => `/Users/GetUserImage?userID=${userID}`,
      providesTags: ["Login"],
    }),
    getRentCollectionDashboard: builder.query<RentDashboard, { userID: number; rentalFrequencyID: number }>({
      query: ({userID,rentalFrequencyID}) => `/RentCollection/RentCollectionDashboard?landlordId=${userID}&rentalFrequencyID=${rentalFrequencyID}`,
      transformResponse: (response: RentDashboard) => new RentDashboard(response),
      providesTags: ["Login"],
    }),
    // getPmsDashboard: builder.query<ResponseMessage<StaffDashboard>, {landlordId:number}>({  
    //   query: (landlordId) => ({
    //     url: "/PMSDashBoard/WebViewDashBoard",
    //     method: "POST",
    //     body: { landlordId },
    //     providesTags: ["Login"],
    //   }),
    // }),

    getPmsDashboard: builder.query<ResponseMessage<StaffDashboard[]>, { landlordId: number }>({
      query: ({ landlordId }) => ({
        url: "/PMSDashBoard/WebViewDashBoard",
        method: "POST",
        body: { landlordId },
      }),
      transformResponse: (response: any): ResponseMessage<StaffDashboard[]> => {
        return new ResponseMessage<StaffDashboard[]>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: any) => new StaffDashboard(item)), // Map each item to StaffDashboard instance
        });
      }
    }),
    


    getAllStaffManagement: builder.query<ResponseMessage<LandlordStaff[]>, {userID:number, pageNumber:number, rowNumber:number }>({
      query: ({ userID, pageNumber, rowNumber }) =>
        `/Staff/AllLandlordStaffManagement?userID=${userID}&pageNumber=${pageNumber}&rowNumber=${rowNumber}`,
      transformResponse : (response: any) => {
        return new ResponseMessage<LandlordStaff[]>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: any) => new LandlordStaff(item)), 
        })

      },
      providesTags: ["Login"],
    }),


    getViewDashBoard: builder.mutation({
      query: ({landLordID, PropertyMasterID, dateFrom, dateto, TenantID, unitIDs,
      }) => ({
        url: "/PMSDashBoard/ViewDashBoard",
        method: "POST",
        body: {
          landLordID,
          PropertyMasterID,
          dateFrom,
          dateto,
          TenantID,
          unitIDs,
        },
      }),
      invalidatesTags: ["Login"],
    }),
    getPropertyMasterByLandlord: builder.query({
      query: ({ userID }) =>
        `/PropertyMaster/GetPropertyMasterByLandlord?landlord=${userID}&ListingPurposeID=1`,
      providesTags: ["Login"],
    }),
    getAllTenant: builder.query({
      query: () => `/Tenant/AllTenant?userTypeId=2`,
      providesTags: ["Login"],
    }),
    getAllUnits: builder.query({
      query: ({ PropertyMasterID }) =>
        `/PropertyUnit/GetPropertyUnitByPropertyMasterID?PropertyMasterID=${PropertyMasterID}`,
      providesTags: ["Login"],
    }),
    getOwnerDashBoard: builder.query<OwnerDashBoard, {userID:number} >({
      query: ({ userID }) =>
        `/OwnerDashBoard/GetOwnerDashBoard?LandLordID=${userID}&ListingPurposeID=1&PropertyStatusID=1`,
      providesTags: ["Login"],
    }),
    getTenantDashBoard: builder.query({
      query: ({ userID }) => `/Tenant/TenantDashBoard?LandLordID=${userID}`,
      providesTags: ["Login"],
    }),
    getTenantDashboardMaintenanceRequest: builder.query({
      query: ({ userID }) =>
        `/TenantDashboardMaintenanceRequest/TenantDashboardMaintenanceRequest?UserID=${userID}&UserTypeID=1`,
      providesTags: ["Login"],
    }),
    getIncomeOverview: builder.query({
      query: (landlordId) => ({
        url: `/PMSDashBoard/PMSDashBoard`,
        method: "POST",
        body: { landlordId },
        providesTags: ["Login"],
      }),
    }),
    getuserSmartNotification: builder.query({
      query: ({ userID, requestType, pageNumber, rowNumber }) =>
        `/Notification/GetuserSmartNotification?userId=${userID}&userTypeId=1&requestTypeId=${requestType}&pageNumber=${pageNumber}&rowNumbers=${rowNumber}`,
      providesTags: ["Login"],
    }),
    getMaintenanceRequest: builder.mutation({
      query: ({ requestId, status }) => ({
        url: "/MaintenanceRequest/ProcessMaintenanceRequestStatus",
        method: "PUT",
        body: { requestId, status },
      }),
      invalidatesTags: ["Login"],
    }),
    getNotificationTitleIncludingDetails: builder.query({
      query: () => `/Setup/GetNotificationTitleIncludingDetails?UserTypeId=1`,
      providesTags: ["Login"],
    }),
    getOwnerPropertyMaster: builder.query<OwnerPropertyMaster[], {pageNumber:number, rowNumber:number, userID:number, Status:number}>({
      query: ({ pageNumber, rowNumber, userID, Status }) =>
        `/OwnerPropertyDetail/GetOwnerPropertyMaster?PageNumber=${pageNumber}&RowsNumbers=${rowNumber}&LandLordID=${userID}&ListingPurposeID=1&PropertyStatusID=1&Status=${Status}`,
      transformResponse : (response : OwnerPropertyMaster[]) =>{
        return response.map((item)=> new OwnerPropertyMaster(item));
      },
      providesTags: ["Login"],
    }),
    getPropertyMasterDetail: builder.query<OwnerPropertyMasterDetail, {PropertyId:number,status:number }>({
      query: ({ PropertyId, status }) =>
        `/OwnerPropertyDetail/GetPropertyMasterDetail?PropertyMasterID=${PropertyId}&listingpurposeID=1&status=${status}`,
      transformResponse: (response: any): OwnerPropertyMasterDetail => {
        return new OwnerPropertyMasterDetail(response);
      },
      providesTags: ["Login"],
    }),
    getPropertyfeature: builder.query<PropertyFeature[], {PropertyMasterId: number}>({
      query: ({ PropertyMasterId }) => `/PropertyFeature/${PropertyMasterId}`,
      transformResponse : (response: PropertyFeature[]) =>{
        return response.map((item) =>  new PropertyFeature(item) )
      },
      providesTags: ["Login"],
    }),
    getPropertyMasterCategoryDetail: builder.query<OwnerPropertyMasterDetail[], {PropertyMasterId: number, status: number}>({
      query: ({ PropertyMasterId, status }) =>
        `/OwnerPropertyDetail/GetPropertyMasterCategoryDetail?PropertyMasterID=${PropertyMasterId}&listingpurposeID=1&status=${status}`,
     transformResponse : (reponse : OwnerPropertyMasterDetail[] ) =>{
      return reponse.map((item) => new OwnerPropertyMasterDetail(item));
     },
      providesTags: ["Login"],
    }),
    getPropertyCategoryDetailView: builder.query<PropertyCategoryDetail[], {userId:number, unitCategoryId: number, PropertyMasterId:number}>({
      query: ({ userId, unitCategoryId, PropertyMasterId }) =>
        `OwnerPropertyDetail/PropertyCategoryDetailView?LandLordID=${userId}&UnitCategoryID=${unitCategoryId}&PropertyMasterID=${PropertyMasterId}`,
      transformResponse : (response: PropertyCategoryDetail[]) =>{
        return response.map((item) => new PropertyCategoryDetail(item));
      },
      providesTags: ["Login"],
    }),
    getTenancyRequest: builder.query({
      query: ({ userID, pageNumber, rowNumber, requestTypeID }) =>
        `TenancyRequest/TenancyRequest?userID=${userID}&option=1&requestTypeID=${requestTypeID}&pageNumber=${pageNumber}&rowNumbers=${rowNumber}`,
    }),
    getExpiredAgreement: builder.query({
      query: ({ pageNumber, rowNumber, userID }) =>
        `Tenant/ExpiredAgreement?pageNumber=${pageNumber}&rowNumbers=${rowNumber}&userID=${userID}&option=1`,
      providesTags: ["Login"],
    }),
    getExpiredAgreementin30Days: builder.query({
      query: ({ pageNumber, rowNumber, userID }) =>
        `Tenant/AgreementExpiringIn30Days?pageNumber=${pageNumber}&rowNumbers=${rowNumber}&userID=${userID}&option=1`,
      providesTags: ["Login"],
    }),
    TenancyRequestTotalCount: builder.query({
      query: ({ userID, requestTypeID }) =>
        `TenancyRequest/TenancyRequestTotalCount?userID=${userID}&option=1&requestTypeID=${requestTypeID}`,
      providesTags: ["Login"],
    }),
    AgreementExpiringIn30DaysCount: builder.query({
      query: ({ userID }) =>
        `Tenant/AgreementExpiringIn30DaysCount?userID=${userID}&option=1`,
      providesTags: ["Login"],
    }),
    ExpiredAgreementCount: builder.query({
      query: ({ userID }) =>
        `Tenant/ExpiredAgreementCount?userID=${userID}&option=1`,
      providesTags: ["Login"],
    }),
    GetAgreementRenewRequest: builder.query({
      query: ({ userID, pageNumber, rowNumber }) =>
        `RenewableRequestAg/AgreementRenewRequest?userID=${userID}&option=1&pageNumber=${pageNumber}&rowNumbers=${rowNumber}`,
      providesTags: ["Login"],
    }),
    GetTerminatedAgreement: builder.query({
      query: ({ userID, pageNumber, rowNumber }) =>
        `Agreement/GetTerminatedAgreement?UserID=${userID}&UserTypeID=1&pageNumber=${pageNumber}&rowNumbers=${rowNumber}`,
      providesTags: ["Login"],
    }),
    AgreementRenewRequestCount: builder.query({
      query: ({ userID }) =>
        `RenewableRequestAg/AgreementRenewRequestCount?userID=${userID}&option=1`,
      providesTags: ["Login"],
    }),
    NegotiationAgreement: builder.query({
      query: ({ userID, requestTypeID, pageNumber, rowNumber }) =>
        `Tenant/NegotiationAgreement?UserID=${userID}&userTypeID=1&requestTypeID=${requestTypeID}&pageNumber=${pageNumber}&rowNumbers=${rowNumber}`,
      providesTags: ["Login"],
    }),
    CurrentAgreementByUserId: builder.query({
      query: ({ userID, pageNumber, rowNumber }) =>
        `Tenant/CurrentAgreementByUserId?pageNumber=${pageNumber}&rowNumbers=${rowNumber}&userID=${userID}&userTypeID=1`,
      providesTags: ["Login"],
    }),
    PropertyUnitCategoryTypes: builder.query({
      query: () =>
        `Setup/PropertyUnitCategoryTypes`,
      providesTags: ["Login"],
    }),
 
    GetPropertyMasterSubTypesIncludingDetails: builder.query({
      query: () => `/Setup/GetPropertyMasterSubTypesIncludingDetails`,
      providesTags: ["Login"],
    }),
    GetPropertyMasterTypes: builder.query({
      query: () => `/Setup/GetPropertyMasterTypes`,
      providesTags: ["Login"],
    }),
    GetGovernorate: builder.query({
      query: () => `Setup/GetGovernorate`,
      providesTags: ["Login"],
    }),
    GetCountry: builder.query({
      query: () => `Setup/GetCountry`,
      providesTags: ["Login"],
    }),
    GetWilayat: builder.query({
      query: () => `Setup/GetWilayat`,
      providesTags: ["Login"],
    }),
    GetAllAreas: builder.query({
      query: () => `Setup/GetAllAreas`,
      providesTags: ["Login"],
    }),
    GetWilayatAndGovernateByAreaId: builder.query({
      query: ({ AreaId }) =>
        `Address/GetWilayatAndGovernateByAreaId?AreaId=${AreaId}`,
      providesTags: ["Login"],
    }),
    GetPropertyFeatures: builder.query({
      query: () => `Setup/PropertyFeatures`,
      providesTags: ["Login"],
    }),
    PropertyMaster: builder.mutation({
      query: ({
        landLordID,
        propertyMasterTypeID,
        propertyMasterSubTypeID,
        listingPurposeID,
        address: {
          streetAddress,
          postalCode,
          plot,
          building,
          wilayatID,
          areaID,
          area,
          countryID,
          governorateID,
          wayNumber,
          userID,
        },
        residentialUnits,
        commercialUnits,
        builtYear,
        numberofFloors,
        plotNumber,
        buildingNumber,
        name,
        description,
        propertyStatusID,
        lat,
        long,
      }) => ({
        url: "PropertyMaster",
        method: "POST",
        body: {
          landLordID,
          propertyMasterTypeID,
          propertyMasterSubTypeID,
          listingPurposeID,
          address: {
            streetAddress,
            postalCode,
            plot,
            building,
            wilayatID,
            areaID,
            area,
            countryID,
            governorateID,
            wayNumber,
            userID,
          },
          residentialUnits,
          commercialUnits,
          builtYear,
          numberofFloors,
          plotNumber,
          buildingNumber,
          name,
          description,
          propertyStatusID,
          lat,
          long,
        },
      }),
      invalidatesTags: ["Login"],
    }),
    PropertyUnit: builder.mutation({
      query: ({
        propertyMasterID,
        unitCategoryID,
        bedRoom,
        bathRoom,
        hall,
        kitchen,
        rentPrice,
        sellPrice,
        sqft,
        floorNumber,
        name,
        description,
        tag,
        count,
        parking,

      }) => ({
        url: "PropertyUnit",
        method: "POST",
        body: {
          propertyMasterID,
          unitCategoryID,
          bedRoom,
          bathRoom,
          hall,
          kitchen,
          rentPrice,
          sellPrice,
          sqft,
          floorNumber,
          name,
          description,
          tag,
          count,
          parking,
        },
      }),
      invalidatesTags: ["Login"],
    }),
    AddPropertyFeatures: builder.mutation({
      query: ({
        propertyMasterID,
        propertyUnitCategoryID,
        featureID,
        propertyTypes,

      }) => ({
        url: "PropertyFeature/AddPropertyFeatures",
        method: "POST",
        body: {
          propertyMasterID,
          propertyUnitCategoryID,
          featureID,
          propertyTypes,
        },
      }),
      invalidatesTags: ["Login"],
    }),
    AddPropertyMaster: builder.mutation({
      query: (Propertymaster) => ({
        url: "PropertyMaster",
        method: "POST",
        body: Propertymaster,
      }),
      invalidatesTags: ["Login"],
    }),
    AddPropertyUnit: builder.mutation({
      query: (Propertyunit) => ({
        url: "PropertyUnit",
        method: "POST",
        body: Propertyunit,
      }),
      invalidatesTags: ["Login"],
    }),
    getTenancyRequestDetail: builder.query({
      query: ({ requestAgID }) =>
        `TenancyRequest/TenancyRequestDetail?reqAgID=${requestAgID}&option=1`
    }),
    CurrentAgreementDetail: builder.query({
      query: ({ requestAgID, option }) =>
        `Tenant/CurrentAgreementDetail?agId=${requestAgID}&option=${option}`
    }),
    GetAgreementExpensebyAgID: builder.query({
      query: ({ requestAgID }) =>
        `Expense/GetAgreementExpensebyAgID?AgID=${requestAgID}`
    }),
    PDFView: builder.query({
      query: ({ requestAgID }) =>
        `PDFView/GetPDFView?AgID=${requestAgID}`
    }),
    AgreementAttachment: builder.query({
      query: ({ requestAgID }) =>
        `AgreementAttachment/AgreementAttachment?agID=${requestAgID}&option=1`
    }),
    AddPropertyFeature: builder.mutation({
      query: (propertyFeature) => ({
        url: "PropertyFeature/AddPropertyFeatures",
        method: "POST",
        body: propertyFeature,
      }),
      invalidatesTags: ["Login"],
    }),
    AddPropertyMasterImages: builder.mutation({
      query: (propertyMasterImage) => ({
        url: "PropertyMasterImages",
        method: "POST",
        body: propertyMasterImage,
      }),
      invalidatesTags: ["Login"],
    }),
    AddPropertyCategoryImage: builder.mutation({
      query: (propertyMasterImage) => ({
        url: "PropertyCategoryImage",
        method: "POST",
        body: propertyMasterImage,
      }),
      invalidatesTags: ["Login"],
    }),
    GetChequesByAgreementIDAndChequeTypeID: builder.query({
      query: ({ AgID, cheqId }) =>
        `Cheque/GetChequesByAgreementIDAndChequeTypeID?AgID=${AgID}&ChequeTypeID=${cheqId}`,
      providesTags: ["Login"],
    }),
    GetUtilitiesbyAg: builder.query({
      query: ({ AgID, cheqId }) =>
        `Utilities/GetUtilitiesbyAg?AgID=${AgID}`,
      providesTags: ["Login"],
    }),
    AggRentHistory: builder.query({
      query: ({ AgID, pagenumber, rownumber }) =>
        `RentCollection/AggRentHistory?agId=${AgID}&pageNumber=${pagenumber}&rowNumbers=${rownumber}`,
      providesTags: ["Login"],
    }),
    AgRentHistoryCount: builder.query({
      query: ({ AgID }) =>
        `RentCollection/AgRentHistoryCount?agId=${AgID}`,
      providesTags: ["Login"],
    }),
    GetAgreementDetail: builder.query<AgreementDetail, { AgID: number }>({
      query: ({ AgID }) =>
        `Agreement/GetAgreementDetail?AgID=${AgID}`,
      transformResponse: (response: AgreementDetail) => new AgreementDetail(response),
      providesTags: ["Login"],
    }),
    GetTransactionByAgID: builder.query<AgreementTransactionsDetail[], { AgID: number }>({
      query: ({ AgID }) =>
        `RentCollection/GetTransactionByAgID?agID=${AgID}`,
      transformResponse: (response: AgreementTransactionsDetail[]) => {
        return response.map((item) => new AgreementTransactionsDetail(item));
      },
      providesTags: ["Login"],
    }),
    AgreementTerminationView: builder.query({
      query: ({ AgID }) =>
        `Agreement/AgreementTerminationView?Agid=${AgID}`,
      providesTags: ["Login"],
    }),
    TerminateAgreement: builder.mutation({
      query: ({ AgId, feedback, status, isAdjusted }) => ({
        url: `Agreement/TerminateAgreement?Agid=${AgId}`,
        method: "PUT",
        body: { AgId, feedback, status, isAdjusted },
      }),
      invalidatesTags: ["Login"],
    }),
    PaymentTypes: builder.query({
      query: () => `Setup/PaymentTypes`,
      providesTags: ["Login"],
    }),
    GetExpenseTypes: builder.query({
      query: () => `Setup/GetExpenseTypes`,
      providesTags: ["Login"],
    }),
    GetPropertyUnitStatus: builder.query({
      query: () => `Setup/GetPropertyUnitStatus`,
      providesTags: ["Login"],
    }),
    GetBank: builder.query<Bank[], void>({
      query: () => `Setup/GetBank`,
      transformResponse : (response: Bank[]) => response.map((item) => new Bank(item)),
      providesTags: ["Login"],
    }),
    GetUtilityTypes: builder.query({
      query: () => `Setup/GetUtilityTypes`,
      providesTags: ["Login"],
    }),
    GetRequestAgreement: builder.query({
      query: ({ id }) => `RequestAgreement/${id}`,
      providesTags: ["Login"],
    }),
    PutRequestAgreement: builder.mutation({
      
      query: ({ Agid, updatedData }) => ({
        url: `RequestAgreement/${Agid}`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["Login"],
    }),

    PostPropertyExpense: builder.mutation({
      query: (PropertyExpense) => ({
        url: `RequestAgreement/PostPropertyExpense`,
        method: "POST",
        body: PropertyExpense,
      }),
      invalidatesTags: ["Login"],
    }),
    PostRequestAgreementAttachment: builder.mutation({
      query: (uploadimg) => ({
        url: `RequestAgreement/RequestAgreementAttachment`,
        method: "POST",
        body: uploadimg,
      }),
      invalidatesTags: ["Login"],
    }),
    updateRequestAgreement: builder.mutation({
      query: ({ Agid, updatedData }) => ({
        url: `RequestAgreement/updateRequestAgreement?id=${Agid}`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["Login"],
    }),
    Generaterecepit: builder.query<Receipt, {rentID: number}>({
      query: ({ rentID }) =>
        `RentCollection/generate-receipt?RentID=${rentID}`,
      transformResponse: (response: Receipt) => new Receipt(response),
    }),
    ExpiredAgreementDetail: builder.query({
      query: ({ agId }) =>
        `Tenant/AgreementDetail?agId=${agId}&option=1`
    }),
    UpdateTransactionRent: builder.mutation({
      query: (Renttransaction) => ({
        url: `RentCollection/UpdateTransaction`,
        method: "PUT",
        body: Renttransaction,
      }),
      invalidatesTags: ["Login"],
    }),
    UpdateActiveSession: builder.mutation({
      query: ({userID, updatedData }) => ({
        url: `ActiveSession/${userID}`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["Login"],
    }),
    GetOwnerPropertyMasterTotalCount: builder.query({
      query: ({ userID, Status }) =>
        `OwnerPropertyDetail/GetOwnerPropertyMasterTotalCount?LandLordID=${userID}&ListingPurposeID=1&PropertyStatusID=1&Status=${Status}`,
      providesTags: ["Login"],
    }),
    RentFrequency: builder.query({
      query: () => `Setup/RentFrequency`,
      providesTags: ["Login"],
    }),
    RentMode: builder.query({
      query: () => `Setup/RentMode`,
      providesTags: ["Login"],
    }),
    RenewableRequestAg: builder.mutation({
      query: (RenewableRequest) => ({
        url: `RenewableRequestAg`,
        method: "POST",
        body: RenewableRequest,
      }),
      invalidatesTags: ["Login"],
    }),
    GetAgreementEndDate: builder.query({
      query: ({ rentalModeID, rentalFrequencyID, agStartDate, terms }) =>
        `RequestAgreement/GetAgreementEndDate?RentalModeID=${rentalModeID}&RentalFrequencyID=${rentalFrequencyID}&AgStartDate=${agStartDate}&Terms=${terms}`,
      providesTags: ["Login"],
    }),
    RequestAggreementAttachment: builder.query({
      query: ({ requestAgID }) =>
        `RequestAgreementAttachment/RequestAggreementAttachment?ReqAgID=${requestAgID}`,
      providesTags: ["Login"],
    }),
    MaintenanceRequestDashboard: builder.query<MaintenanceResponseMessage<MaintenanceDashboardData>, { UserID: number; UserTypeID: number }>({
      query: ({ UserID, UserTypeID }) =>
        `TenantDashboardMaintenanceRequest/TenantDashboardMaintenanceRequest?UserID=${UserID}&UserTypeID=${UserTypeID}`,
      transformResponse: (response: MaintenanceResponseMessage<MaintenanceDashboardData>) =>
        new MaintenanceResponseMessage<MaintenanceDashboardData>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: new MaintenanceDashboardData(response.data),
        }),
      providesTags: ["Login"],
    }),
    TotalMaintenanceRequest: builder.query<ResponseMessage<DashboardRecentRequests>, {UserID: number, UserTypeID:number, Status:number, PageNumber:number, PriorityId:number }>({
      query: ({ UserID, UserTypeID, Status, PageNumber, PriorityId }) => {
        // Build the base query string
        let queryString = `TenantDashboardMaintenanceRequest/TotalMaintenanceRequest?UserID=${UserID}&UserTypeID=${UserTypeID}&Status=${Status}&PageNumber=${PageNumber}&RowNumber=12`;
        // Append PriorityId only if it's not null
        if (PriorityId !== null) {
          queryString += `&PriorityId=${PriorityId}`;
        }

        return queryString;
      },
      transformResponse :(response : ResponseMessage<DashboardRecentRequests>) => {
        return new ResponseMessage<DashboardRecentRequests>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item) => new DashboardRecentRequests(item)),
        });

      },
      providesTags: ["Login"],
    }),
    MaintenanceRequestBuildingAndUnits: builder.query<ResponseMessage<MaintenanceRequestBuildings>, {UserID: number, UserTypeID: number}>({
      query: ({ UserID, UserTypeID }) =>
        `TenantDashboardMaintenanceRequest/MaintenanceRequestBuildingAndUnits?UserId=${UserID}&UserTypeId=${UserTypeID}`,
      providesTags: ["Login"],
      transformResponse: (response: ResponseMessage<MaintenanceRequestBuildings>) => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch maintenance request buildings.");
        }
        return new ResponseMessage<MaintenanceRequestBuildings>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: MaintenanceRequestBuildings) => new MaintenanceRequestBuildings(item)),
        });
      }
    }),

    GetMaintenanceCategory: builder.query<ResponseMessage<MaintenanceRequestCategory>, void>({
      query: () => `Setup/GetMaintenanceCategory`,
      providesTags: ["Login"],
      transformResponse: (response: ResponseMessage<MaintenanceRequestCategory>) => {
        if (response.statusCode !== 200){
          throw new Error(response.message || "Failed to fetch maintenance category.");
        }
        return new ResponseMessage<MaintenanceRequestCategory>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: MaintenanceRequestCategory) => new MaintenanceRequestCategory(item)),
        })
      },
    }),

    TenantPropertyUnitsMaintenace: builder.query<ResponseMessage<MaintenanceRequestUnits>, {propertyMasterID: number, UserTypeID:number }>({
      query: ({ propertyMasterID, UserTypeID }) =>
        `TenantDashboardMaintenanceRequest/TenantPropertyUnitsMaintenace?propertyMasterID=${propertyMasterID}&UserId=${+userId}&UserTypeId=${UserTypeID}`,
      providesTags: ["Login"],
      transformResponse: (response: ResponseMessage<MaintenanceRequestUnits>) => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch maintenance request units.");
        }
        return new ResponseMessage<MaintenanceRequestUnits>({ 
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: MaintenanceRequestUnits) => new MaintenanceRequestUnits(item)),
          });
      },
     
    }),
    CreateMaintenanceRequest: builder.mutation<RequestDetail, RequestDetail >({
      query: (createMaintenanceRequestobj : RequestDetail) => ({
        url: `MaintenanceRequest/CreateMaintenanceRequest`,
        method: "POST",
        body: JSON.stringify( createMaintenanceRequestobj),
      }),
      transformResponse : (response: RequestDetail) => { 

       return new RequestDetail(response);
      },
      invalidatesTags: ["Login"],
    }),
    MaintenanceRequestImages: builder.mutation({
      query: (maintenanceRequestImages: MaintenanceRequestImage) => ({
        url: `MaintenanceRequestImages`,
        method: "POST",
        body: maintenanceRequestImages,
      }),
      invalidatesTags: ["Login"],
    }),
    MaintenanceRequestDetailById: builder.query<SingleResponseMessage <MaintenanceRequestDetail>, { requestID: number, UserTypeID: number}>({
      query: ({ requestID, UserTypeID }) =>
        `MaintenanceRequest/MaintenanceRequestDetailById?RequestID=${requestID}&UserTypeId=${UserTypeID}`,
      providesTags: ["Login"],
      transformResponse: (response: SingleResponseMessage<MaintenanceRequestDetail>) =>
        new SingleResponseMessage<MaintenanceRequestDetail>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: new MaintenanceRequestDetail(response.data),
        }),
     
    }),

    ReopenMaintenanceRequest: builder.mutation({
      query: (ReopenMaintenanceRequestobj: MaintenanceRequestDetail ) => ({
        url: `MaintenanceRequest/ProcessMaintenanceRequest`,
        method: "POST",
        body: JSON.stringify(ReopenMaintenanceRequestobj),
      }),
      invalidatesTags: ["Login"],
    }),
    AssignServiceProvider: builder.mutation({
      query: (AssignServiceProviderObj: MaintenanceRequest) => ({
        url: `MaintenanceRequest/AssignServiceProvider`,
        method: "POST",
        body: JSON.stringify(AssignServiceProviderObj),
      }),
      invalidatesTags: ["Login"],
    }),
  
    GetsetupRentFrequency: builder.query<RentalFrequency[], void>({
      query: () =>  `Setup/RentFrequency`,
      transformResponse: (response: RentalFrequency[]) => {
        return response.map((item) => new RentalFrequency(item));
      },
      providesTags: ["Login"],
    }),
    GetRentCollectionDashboardProperty: builder.query<ResponseMessage<RentCollectionDashboardProperty>, {userID: number, pageNumber: number}>({
      query: ({ userID, pageNumber }) =>
        `RentCollection/RentCollectionDashboardProperty?LandLordID=${userID}&pageNumber=${pageNumber}&rowNumbers=${10}`,

      transformResponse: (response: ResponseMessage<RentCollectionDashboardProperty>) => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch expense sub categories.");
        }
        return new ResponseMessage<RentCollectionDashboardProperty>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: RentCollectionDashboardProperty) => new RentCollectionDashboardProperty(item)),
        });      
      },
      providesTags: ["Login"],
    }),
    GetAgRentTransactionsbyLandLord: builder.query<AgreementRentTransactions[] ,{ userID: number, Option:number, pageNumber: number}>({
      query: ({ userID, Option,pageNumber }) =>
        `RentCollection/AgRentTransactionsbyLandLord?LandLordID=${userID}&Option=${Option}&pageNumber=${pageNumber}&rowNumbers=10`,
           transformResponse: (response: AgreementRentTransactions[]) => {
          return response.map((item) => new AgreementRentTransactions(item));
        },
      providesTags: ["Login"],
    }),
    GetAgRentTransactionsbyLandLordCount: builder.query({
      query: ({ userID, Option }) =>
        `RentCollection/AgRentTransactionsbyLandlordCount?LandLordID=${userID}&Option=${Option}`,
      providesTags: ["Login"],
    }),
    Getgeneratereceip: builder.query({
      query: ({ rentID }) =>
        `RentCollection/generate-receipt?RentID=${rentID}`,
      providesTags: ["Login"],
    }),
    GetPropertyRentStatus: builder.query<RentCollectionResponse<PropertyRentStatus>,{PropertyMasterID:number,Option: number, pageNumber:number}>({
      query: ({ PropertyMasterID, Option,pageNumber }) =>
        `RentCollection/PropertyRentStatus?PropertyMasterID=${PropertyMasterID}&Option=${Option}&pageNumber=${pageNumber}&rowNumbers=${12}`,
      transformResponse: (response: any) => {
        return new RentCollectionResponse<PropertyRentStatus>({
          totalPending: response.totalPending,
          totalCount: response.totalCount,
          data: response.data.map((item: any) => new PropertyRentStatus(item))
        })
      },
      providesTags: ["Login"],
    }),
    GetAgRentHistoryCount: builder.query({
      query: ({ AgreementId, Option,pageNumber }) =>
        `RentCollection/AgRentHistoryCount?agId=${AgreementId}`,
      providesTags: ["Login"],
    }),
    GetAggRentHistory: builder.query<AgreementRentHistory[], {AgreementId:number, pageNumber: number }>({
      query: ({ AgreementId,pageNumber }) =>
        `RentCollection/AggRentHistory?agId=${AgreementId}&pageNumber=${pageNumber}&rowNumbers=${10}`,
      transformResponse: (response: AgreementRentHistory[]) => {
        return response.map((item) => new AgreementRentHistory(item));
      },
      providesTags: ["Login"],
    }),
    GetAgRentTransactionsbyPropertyMasterID: builder.query<AgreementRentTransactions[], {PropertyMasterID: number, Option: number, pageNumber: number }>({
      query: ({ PropertyMasterID, Option,pageNumber }) =>
        `RentCollection/AgRentTransactions?PropertyMasterID=${PropertyMasterID}&Option=${Option}&pageNumber=${pageNumber}&rowNumbers=10`,
     transformResponse: (response: AgreementRentTransactions[]) => { 
        return response.map((item) => new AgreementRentTransactions(item));
      },
      providesTags: ["Login"],
    }),
    GetAgRentTransactionsbyPropertyMasterIDCount: builder.query({
      query: ({ PropertyMasterID, Option }) =>
        `RentCollection/AgRentTransactionsCount?PropertyMasterID=${PropertyMasterID}&Option=${Option}`,
      providesTags: ["Login"],
    }),
    // GetAgRentTransactionsDetail: builder.query({
    //   query: ({ rentID}) =>
    //     `RentCollection/AgRentTransactionsDetail?RentID=${rentID}`,
    //   providesTags: ["Login"],
    // }),
    GetAgRentTransactionsDetail: builder.query<AgreementRentTransactionsDetail, { rentID:number}>({
      query: ({ rentID}) =>
        `RentCollection/AgRentTransactionsDetail?RentID=${rentID}`,
      providesTags: ["Login"],
    }),
    PutRentCollection: builder.mutation<void, { updatedData: TransactionUpdate }>({
      query: ({updatedData }) => ({
        url: `RentCollection`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["Login"],
    }),
    GetAgPropertyMaster: builder.query<PropertyMaster[], { userID: number }>({
      query: ({ userID}) =>
        `RentCollection/GetAgPropertyMaster?landlordID=${userID}`,
     transformResponse: (response: PropertyMaster[]) => {
      return response.map((item) => new PropertyMaster(item));
     },
      providesTags: ["Login"],
    }),
    GetAgPropertyUnit: builder.query<AgreementPropertyUnit [], {PropertyMasterid: number }>({
      query: ({PropertyMasterid}) =>
        `RentCollection/GetAgPropertyUnit?PropertyMasterID=${PropertyMasterid}`,
      transformResponse: (response: AgreementPropertyUnit[]) => {
        return response.map((item)=> new AgreementPropertyUnit(item));
      },
      providesTags: ["Login"],
    }),
    GetUserPendingLiabilities: builder.query<UserPendingLiabilitie[], {RentalUnitid: number, userID: any}>({
      query: ({RentalUnitid,userID}) =>
        `RentCollection/UserPendingLiabilities?RentalUnitID=${RentalUnitid}&UserId=${userID}&UserTypeId=1`,
      transformResponse: (response: UserPendingLiabilitie[]) => {
        return response.map((item) => new UserPendingLiabilitie(item));
      },
      providesTags: ["Login"],
    }),
    GetUserPendingLiabilitiesByAgId: builder.query<UserPendingLiabilitie[], {RentalUnitid:number, aggID: number} >({
      query: ({RentalUnitid,aggID}) =>
        `RentCollection/GetUserPendingLiabilitiesByAgId?RentalUnitID=${RentalUnitid}&AgId=${aggID}`,
      transformResponse: (response: UserPendingLiabilitie[]) => {
        return response.map((item) => new UserPendingLiabilitie(item));
      },
      providesTags: ["Login"],
    }),
    GetPaymentTypes: builder.query<PaymentType[], void>({
      query: () => `/Setup/PaymentTypes`,
      transformResponse: (response: PaymentType[]) => {
        return response.map((item) => new PaymentType(item));
      },
      providesTags: ["Login"],
    }),
    PostRentCollection: builder.mutation({
      query: (rentCollection:RentRequest) => ({
        url: `RentCollection`,
        method: "POST",
        body: rentCollection,
      }),
      invalidatesTags: ["Login"],
    }),
    GetExpenseDashboard: builder.query<ExpenseDashboardModel, { landlordId: number }>({
      query: ({ landlordId }) => ({
        url: `Expense/Dashboard?landlordId=${landlordId}`,
      }),
      transformResponse: (response: any) => {
       if (response.statusCode !== 200) {
        throw new Error(response.message || "Failed to fetch expense dashboard data.");
      }
        return new ExpenseDashboardModel(response?.data?.[0]);
      },
    }),

    // GetExpenseLandlordBuildingList: builder.query({
    //   query: ({landLordID,pageNumber}) =>
    //     `Expense/LandlordBuildingList?landLordID=${landLordID}&listingPurposeID=1&pageNumber=${pageNumber}&rowNumbers=12`,
    //   providesTags: ["Login"],
    // }),

    GetExpenseLandlordBuildingList: builder.query<ResponseMessage<BuildingExpense>, { landLordID: number; pageNumber: number }>({
      query: ({ landLordID, pageNumber }) =>
        `Expense/LandlordBuildingList?landLordID=${landLordID}&listingPurposeID=1&pageNumber=${pageNumber}&rowNumbers=12`,
      providesTags: ["Login"],
      transformResponse: (response: any): ResponseMessage<BuildingExpense> => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch expense dashboard data.");
        }
        return new ResponseMessage<BuildingExpense>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: any) => new BuildingExpense(item)),
        });
      }
      
    }),
    GetExpensePropertyMasterTransactions: builder.query<ResponseMessage<PropertyMasterTransactions>,{landLordID:number; pageNumber:number; rowNumbers:number}>({
      query: ({landLordID,pageNumber,rowNumbers}) =>
        `Expense/PropertyMasterTransactions?landlordID=${landLordID}&pageNumber=${pageNumber}&rowNumbers=${rowNumbers}`,
      providesTags: ["Login"],
      transformResponse: (response: any): ResponseMessage<PropertyMasterTransactions> => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch expense dashboard data.");
        }
        return new ResponseMessage<PropertyMasterTransactions>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: any) => new PropertyMasterTransactions(item)),
        });
      }
    }),
    ExpenseTransactionsByPropertyMaster: builder.query<ResponseMessage<PropertyMasterTransactions>,{propertyMasterID: number; landLordID: number; pageNumber: number; rowNumbers:number }>({
      query: ({propertyMasterID,landLordID,pageNumber,rowNumbers}) =>
        `Expense/PropertyMasterTransactions?propertyMasterID=${propertyMasterID}&landlordID=${landLordID}&pageNumber=${pageNumber}&rowNumbers=${rowNumbers}`,
      providesTags: ["Login"],
      transformResponse: (response: any): ResponseMessage<PropertyMasterTransactions> => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch expense dashboard data.");
        }
        return new ResponseMessage<PropertyMasterTransactions>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: any) => new PropertyMasterTransactions(item)),
        });
      }
    }),
    ExpenseTransactionDetails: builder.query<ResponseMessage<ExpenseDetail>, {expenseID: number}>({
      query: ({ expenseID }) =>
        `Expense/TransactionDetails?expenseID=${expenseID}`,
      providesTags: ["Login"],
      transformResponse: (response: any): ResponseMessage<ExpenseDetail> => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch expense dashboard data.");
        }
        return new ResponseMessage<ExpenseDetail>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: [new ExpenseDetail(response.data[0])],
        });
      }
    }),
    ExpenseUpdateRequest: builder.mutation({
      query: (updatedData: UpdateExpenseStatus) => ({
        url: `Expense/Approve?id=${updatedData.expenseId}`,
        method: "PUT",
        body: JSON.stringify(updatedData),
      }),
      invalidatesTags: ["Login"],
    }),
    


    ExpenseCategory: builder.query<ResponseMessage<ExpenseCategory>, void>({
      query: () => `Setup/GetExpenseCategory`,
      transformResponse: (response: ResponseMessage<ExpenseCategory>) => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch expense categories.");
        }
        return new ResponseMessage<ExpenseCategory>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: ExpenseCategory) => new ExpenseCategory(item)),
        });
      },
      providesTags: ["Login"],
    }),

    
    SubCategoryExpense: builder.query<ResponseMessage<ExpenseSubCategory>, void>({
      query: () =>
        `Setup/GetExpSubCategories`,
      transformResponse: (response: ResponseMessage<ExpenseSubCategory>) => {
        if (response.statusCode !== 200) {
          throw new Error(response.message || "Failed to fetch expense sub categories.");
        }
        return new ResponseMessage<ExpenseSubCategory>({
          message: response.message,
          statusCode: response.statusCode,
          totalCount: response.totalCount,
          data: response.data.map((item: ExpenseSubCategory) => new ExpenseSubCategory(item)),
        });      
      },
      providesTags: ["Login"],
    }),
    GetLandlordBuildingUnits: builder.query({
      query: ({propertyMasterID}) =>
        `Landlord/LandlordBuildingUnits?propertyMasterID=${propertyMasterID}`,
      providesTags: ["Login"],
    }),
    PostExpense: builder.mutation({
      query: (expenseList: AddExpenseRequest[]) => ({
        url: `Expense/PostExpense`,
        method: "POST",
        body: expenseList, 
      }),
      invalidatesTags: ["Login"],
    }),
    GetOwnerPropertyMasterCount: builder.query({
      query: ({  userID, Status }) =>
        `OwnerPropertyDetail/GetOwnerPropertyMasterTotalCount?LandLordID=${userID}&ListingPurposeID=1&PropertyStatusID=1&Status=${Status}`,
      providesTags: ["Login"],
    }),
    LogoutRequest: builder.mutation({
      query: ({ UserID, updatedData }) => ({
        url: `Users/Logout?id=${UserID}`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["Login"],
    })
  }),

});

export const {
  useGetAuthenticateUserMutation,
  useGetUserImgQuery,
  useGetPmsDashboardQuery,
  useGetAllStaffManagementQuery,
  useGetViewDashBoardMutation,
  useGetPropertyMasterByLandlordQuery,
  useGetAllTenantQuery,
  useGetAllUnitsQuery,
  useGetOwnerDashBoardQuery,
  useGetTenantDashBoardQuery,
  useGetTenantDashboardMaintenanceRequestQuery,
  useGetIncomeOverviewQuery,
  useGetuserSmartNotificationQuery,
  useGetMaintenanceRequestMutation,
  useGetNotificationTitleIncludingDetailsQuery,
  useGetOwnerPropertyMasterQuery,
  useGetPropertyMasterDetailQuery,
  useGetPropertyfeatureQuery,
  useGetPropertyMasterCategoryDetailQuery,
  useGetPropertyCategoryDetailViewQuery,
  useGetTenancyRequestQuery,
  useGetExpiredAgreementQuery,
  useGetExpiredAgreementin30DaysQuery,
  useTenancyRequestTotalCountQuery,
  useAgreementExpiringIn30DaysCountQuery,
  useExpiredAgreementCountQuery,
  useGetAgreementRenewRequestQuery,
  useGetTerminatedAgreementQuery,
  useAgreementRenewRequestCountQuery,
  useNegotiationAgreementQuery,
  useCurrentAgreementByUserIdQuery,
  useGetPropertyMasterSubTypesIncludingDetailsQuery,
  usePropertyUnitCategoryTypesQuery,
  useGetPropertyMasterTypesQuery,
  useGetCountryQuery,
  useGetWilayatQuery,
  useGetGovernorateQuery,
  useGetAllAreasQuery,
  useGetWilayatAndGovernateByAreaIdQuery,
  useGetPropertyFeaturesQuery,
  usePropertyMasterMutation,
  usePropertyUnitMutation,
  useAddPropertyFeaturesMutation,
  useAddPropertyMasterMutation,
  useAddPropertyUnitMutation,
  useGetTenancyRequestDetailQuery,
  useCurrentAgreementDetailQuery,
  useGetAgreementExpensebyAgIDQuery,
  useAddPropertyFeatureMutation,
  useAddPropertyMasterImagesMutation,
  useAddPropertyCategoryImageMutation,
  usePDFViewQuery,
  useAgreementAttachmentQuery,
  useGetChequesByAgreementIDAndChequeTypeIDQuery,
  useGetUtilitiesbyAgQuery,
  useAggRentHistoryQuery,
  useAgRentHistoryCountQuery,
  useGetAgreementDetailQuery,
  useGetTransactionByAgIDQuery,
  useAgreementTerminationViewQuery,
  useTerminateAgreementMutation,
  useGetExpenseTypesQuery,
  useGetPropertyUnitStatusQuery,
  usePaymentTypesQuery,
  useGetRequestAgreementQuery,
  usePutRequestAgreementMutation,
  useGetBankQuery,
  useGetUtilityTypesQuery,
  usePostPropertyExpenseMutation,
  usePostRequestAgreementAttachmentMutation,
  useUpdateRequestAgreementMutation,
  useGeneraterecepitQuery,
  useExpiredAgreementDetailQuery,
  useUpdateTransactionRentMutation,
  useGetOwnerPropertyMasterTotalCountQuery,
  useRentFrequencyQuery,
  useRentModeQuery,
  useRenewableRequestAgMutation,
  useGetAgreementEndDateQuery,
  useRequestAggreementAttachmentQuery,
  useMaintenanceRequestDashboardQuery,
  useTotalMaintenanceRequestQuery,
  useMaintenanceRequestBuildingAndUnitsQuery,
  useGetMaintenanceCategoryQuery,
  useTenantPropertyUnitsMaintenaceQuery,
  useCreateMaintenanceRequestMutation,
  useMaintenanceRequestImagesMutation,
  useMaintenanceRequestDetailByIdQuery,
  useReopenMaintenanceRequestMutation,
  useAssignServiceProviderMutation,
  useGetRentCollectionDashboardQuery,
  useGetsetupRentFrequencyQuery,
  useGetRentCollectionDashboardPropertyQuery,
  useGetAgRentTransactionsbyLandLordQuery,
  useGetAgRentTransactionsbyLandLordCountQuery,
  useGetgeneratereceipQuery,
  useGetPropertyRentStatusQuery,
  useGetAgRentHistoryCountQuery,
  useGetAggRentHistoryQuery,
  useGetAgRentTransactionsbyPropertyMasterIDQuery,
  useGetAgRentTransactionsbyPropertyMasterIDCountQuery,
  useGetAgRentTransactionsDetailQuery,
  usePutRentCollectionMutation,
  useGetAgPropertyMasterQuery,
  useGetAgPropertyUnitQuery,
  useGetUserPendingLiabilitiesQuery,
  useGetUserPendingLiabilitiesByAgIdQuery,
  useGetPaymentTypesQuery,
  usePostRentCollectionMutation,
  useGetExpenseDashboardQuery,
  useGetExpenseLandlordBuildingListQuery,
  useGetExpensePropertyMasterTransactionsQuery,
  useExpenseTransactionsByPropertyMasterQuery,
  useExpenseTransactionDetailsQuery,
  useExpenseUpdateRequestMutation,
  useExpenseCategoryQuery,
  useSubCategoryExpenseQuery,
  useGetLandlordBuildingUnitsQuery,
  usePostExpenseMutation,
  useGetOwnerPropertyMasterCountQuery,

  useUpdateActiveSessionMutation,
  useLogoutRequestMutation
} = CommonApi;
