import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { SidebarData } from "../json/sidebarData";
import ImageWithBasePath from "../ImageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
import { setExpandMenu, setMobileSidebar } from "../redux/Commonslice";
import { all_routes } from "../router/all_routes";
import { useGetUserImgQuery } from "../redux/CommonApi";

const Sidebar = ({ userID }) => {
  const { data, error, isLoading } = useGetUserImgQuery(userID);
  const Image = data?.imageString;
  const location = useLocation();
  const expandMenu = useSelector((state: any) => state.CRMS.expandMenu);
  const dispatch = useDispatch();

  const mobileSidebar = useSelector((state: any) => state.CRMS.mobileSidebar);
  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");
  const userName = localStorage.getItem("userName");
  useEffect(() => {
    // Loop through SidebarData to find the active item and open its parent
    SidebarData.forEach((mainLabel) => {
      mainLabel.submenuItems.forEach((title: any) => {
        if (location.pathname.startsWith(title.link)) {
          // setSubopen(mainLabel.label); // Open the parent
          setSubsidebar(title.label); // Open the submenu
        }
      });
    });
  }, [location.pathname]);

  const toggleSidebar = (title: any) => {
    setSubopen(subOpen === title ? "" : title);
    if (window.innerWidth <= 575) {
      dispatch(setMobileSidebar(!mobileSidebar));
    }
  };

  const toggleSubsidebar = (subitem: any) => {
    setSubsidebar(subsidebar === subitem ? "" : subitem);
  };

  const toggle = () => {
    dispatch(setExpandMenu(expandMenu ? false : true));
  };

  return (
    <>
      <div
        id="sidebar"
        className={`sidebar ${expandMenu ? "open" : ""}`}
        onClick={toggle}
      >
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              {/* <ul>
                <li className="clinicdropdown">
                  <Link to="/">
                    <img        
                                      className="img-fluid"
                                      src={Image || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
                      alt="Profile"
                    />
                    <div className="user-names">
                      <h5>{userName}</h5>
                      <h6>Tech Lead</h6>
                    </div>
                  </Link>
                </li>
              </ul> */}
              <ul>
                {SidebarData?.map((mainLabel, index) => (
                  <li className="clinicdropdown" key={index}>
                    {/* <h6 className="submenu-hdr">{mainLabel?.label}</h6> */}
                    <ul>
                      {mainLabel?.submenuItems?.map((title: any, i) => {
                        return (
                          <li className="submenu" key={i}>
                            {/* <Link
                              to={
                                title?.link === all_routes.leadsDashboard
                                  ? "/jaganb"
                                  : title?.link
                              }
                              onClick={() => toggleSidebar(title?.label)}
                              className={`${subOpen === title?.label ? "subdrop active" : ""
                                } ${location.pathname.startsWith(title.link)
                                  ? "active"
                                  : ""
                                }`}
                            >
                              <i className={title.icon}></i>
                              <span>{title?.label}</span>
                            </Link> */}
                            <Link
                              to={title?.link === all_routes.leadsDashboard ? "/jaganb" : title?.link}
                              onClick={() => toggleSidebar(title?.label)}
                              className={`${subOpen === title?.label ? "subdrop " : ""} 
                                                                  ${(location.pathname === "/admin/addproperty" && title?.label === "Add Property") || (location.pathname === "/admin/addExpense" && title?.label === "Expense Management") ||
                                 ( (location.pathname === '/admin/rentCollection' || location.pathname === '/admin/rentHistory' || location.pathname === '/admin/addrent' )&& title?.label === "Rent Collection" )  
                                  ? "active"
                                  : ""}
                                ${location.pathname.startsWith(title.link) ? "active" : ""}`}
                            >
                              {/* <i className={title.icon}></i> */}
                              <img
                                src={title.img} alt="img" width={'30px'} height={'20px'} style={{
                                  filter: (location.pathname.startsWith(title.link ) || (location.pathname === "/admin/addExpense" && title?.label === "Expense Management") || (location.pathname === "/admin/addrent" && title?.label === "Rent Collection")) ? "contrast(0%) brightness(2)" : " brightness(0) saturate(100%) invert(18%) sepia(82%) saturate(1572%) hue-rotate(332deg) brightness(101%) contrast(96%)", // Apply filter when active
                                }} />
                              <span>{title?.label}</span>
                            </Link>
                            <ul
                              style={{
                                display:
                                  subOpen === title?.label ? "block" : "none",
                              }}
                            >
                              {title?.submenuItems?.map(
                                (item: any, titleIndex: any) => (
                                  <li
                                    className="submenu submenu-two"
                                    key={titleIndex}
                                  >
                                    <Link
                                      to={item?.link}
                                      className={`${subsidebar === item?.label
                                        ? "active"
                                        : ""
                                        }`}
                                      onClick={() =>
                                        toggleSubsidebar(item?.label)
                                      }
                                    >
                                      {item?.label}
                                    </Link>
                                    <ul
                                      style={{
                                        display:
                                          subsidebar === item?.label
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {item?.submenuItems?.map(
                                        (subItem: any, subIndex: any) => (
                                          <li key={subIndex}>
                                            <Link
                                              to={subItem?.link}
                                              className={`${subItem?.link ===
                                                location.pathname
                                                ? "active"
                                                : ""
                                                }`}
                                            >
                                              {subItem?.label}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </li>
                                )
                              )}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
