// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .article {
    height: 100%;
    position: relative;
    overflow: hidden;
}
.picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ti {
    font-size: 80px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
} */
.YldEHYXpmlMZeG317uwg {
    position: relative;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100px; /* Adjust the height as needed */
    border-radius: 8px; /* Optional: round the corners */
    overflow: hidden;
  }
  
  .wUrap7byHWNWSOimBM9V {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%; /* Optional: make it circular */
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 0px;
  }
  `, "",{"version":3,"sources":["webpack://./src/feature-module/pms/AddProperty/Image.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;GAsBG;AACH;IACI,kBAAkB;IAClB,sBAAsB;IACtB,2BAA2B;IAC3B,WAAW;IACX,aAAa,EAAE,gCAAgC;IAC/C,kBAAkB,EAAE,gCAAgC;IACpD,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,kBAAkB,EAAE,+BAA+B;IACnD,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":["/* .article {\r\n    height: 100%;\r\n    position: relative;\r\n    overflow: hidden;\r\n}\r\n.picture {\r\n    object-fit: cover;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.ti {\r\n    font-size: 80px;\r\n    color: white;\r\n    text-align: center;\r\n    position: absolute;\r\n    top: 0;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n    height: fit-content;\r\n    margin: auto;\r\n} */\r\n.article {\r\n    position: relative;\r\n    background-size: cover;\r\n    background-position: center;\r\n    width: 100%;\r\n    height: 100px; /* Adjust the height as needed */\r\n    border-radius: 8px; /* Optional: round the corners */\r\n    overflow: hidden;\r\n  }\r\n  \r\n  .header {\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n    color: white;\r\n    padding: 10px;\r\n    cursor: pointer;\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    border-radius: 50%; /* Optional: make it circular */\r\n    width: 30px;\r\n    height: 30px;\r\n    text-align: center;\r\n    line-height: 0px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article": `YldEHYXpmlMZeG317uwg`,
	"header": `wUrap7byHWNWSOimBM9V`
};
export default ___CSS_LOADER_EXPORT___;
