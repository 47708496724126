// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setPropertyMasterUnitFeature,setPropertyMasterFeature } from '../../../redux/Commonslice';

// interface Feature {
//     featureTypesID: number;
//     desc: string;
//     id: number;
//     status: number;
// }

// enum FeatureType {
//     MainFeatures = 1,
//     Utilities = 2,
//     Facing = 3,
// }

// type GroupedFeatures = { [key: string]: Feature[] };

// const FeatureModal = ({ unitCategoryID, FeatureData,idx}: { unitCategoryID: number; FeatureData: any,idx:number }) => {
//     const dispatch = useDispatch();
//     const [addfeatureform, setAddfeatureform] = useState<{ [unitCategoryID: number]: any[] }>({});
//     const [selectedFeatures, setSelectedFeatures] = useState<any []>([]);
//     const storageKey = `uploadedFeature_${unitCategoryID}`;
//     const unit = useSelector((state: any) => state.CRMS.propertyId);
//     const buildind = useSelector((state: any) => state.CRMS.buidIndex);
//     const buildsetected = useSelector((state: any) => state.CRMS.propertyMasterUnitFeature || []);
//     const isActiveBuilding = (propertyMasterID, index) => {
//         const selectedArray = buildsetected[index]; // Access the array by the index key
//         if (
//             Array.isArray(selectedArray) &&
//             selectedArray.some((b) => b.featureID === propertyMasterID)
//         ) {
//             return true; // If featureID is found in the array, it's checked
//         }
//         return false; 
//     };
    
//     const handleCheckboxChange4 = (featureID: number, unitCategoryID: number) => {
//         setSelectedFeatures((prevSelected) => {
//             if (prevSelected.includes(featureID)) {
//                 // Remove feature if already selected
//                 return prevSelected.filter(id => id !== featureID);
//             } else {
//                 // Add feature if not already selected
//                 return [...prevSelected, featureID];
//             }
//         });
    
//         setAddfeatureform((prevForm) => {
//             const updatedForm = [...(prevForm[unitCategoryID] || [])];
//             const featureIndex = updatedForm.findIndex((item) => item.featureID === featureID);
    
//             if (featureIndex !== -1) {
//                 // Remove feature if it already exists
//                 updatedForm.splice(featureIndex, 1);
//             } else {
//                 // Add new feature
//                 updatedForm.push({
//                     propertyMasterID: 0,
//                     propertyUnitCategoryID: unitCategoryID,
//                     featureID: featureID,
//                     propertyTypes: 1,
//                 });
//             }
    
//             // Dispatch the updated features to Redux
//             const buildind = 0; // Replace with the correct value if dynamic
//             dispatch(
//                 setPropertyMasterUnitFeature({
//                     buildind,
//                     unitCategoryID,
//                     features: updatedForm,
//                 })
//             );
    
//             return { ...prevForm, [unitCategoryID]: updatedForm };
//         });
//     };
    
    
//     // const isActiveBuilding = (propertyMasterID, index) => {
//     //     debugger
//     //     console.log("hfjsgdf",buildsetected[0])

//     //     return (
//     //         Array.isArray(buildsetected) &&
//     //         buildsetected[index] && // Ensure the index exists in buildsetected
//     //         buildsetected[index].filter((b) => b.featureID === propertyMasterID)
//     //     );
//     // };
//     // const isActiveBuilding = (propertyMasterID,indx) => {
//     //     debugger
//     //     console.log("Checking buildsetected:", buildsetected[indx] );
        
//     //     const isActive = Array.isArray(buildsetected) && buildsetected.some((b) => {
//     //         console.log("Checking featureID:", b.featureID, "against propertyMasterID:", propertyMasterID);
//     //         return b.featureID === indx;
//     //     });
//     //     console.log(`isActive for propertyMasterID ${propertyMasterID}:`, isActive);
//     //     return isActive;
//     // };
    
//     //   const isActiveUnit = (unitID) =>
//     //     selectedFeatures.some(
//     //       (b) =>
//     //         b.featureID === featureID &&
//     //         b.units.some((unit) => unit.unitID === unitID)
//     //     );
//     // Reset selected features when changing unit category
//     useEffect(() => {
//         setSelectedFeatures([]);
//     }, [unit]);

//     const getFeatureTypeName = (id: number) => {
//         switch (id) {
//             case FeatureType.MainFeatures:
//                 return "Main Features";
//             case FeatureType.Utilities:
//                 return "Utilities";
//             case FeatureType.Facing:
//                 return "Facing";
//             default:
//                 return "Unknown";
//         }
//     };

//     const groupedFeatures: GroupedFeatures = FeatureData?.reduce((acc, feature) => {
//         const featureTypeName = getFeatureTypeName(feature.featureTypesID);
//         acc[featureTypeName] = acc[featureTypeName] || [];
//         acc[featureTypeName].push(feature);
//         return acc;
//     }, {} as GroupedFeatures);

//     const uploadedFeature = useSelector((state: any) => {
//         if (unitCategoryID && state.CRMS?.propertyMasterUnitFeature) {
//             return state.CRMS.propertyMasterUnitFeature[unit] ?? null;
//         }
//         return null;
//     });

//     // Handle checkbox changes and update selected features
//     // const handleCheckboxChange = (featureID: number, unitCategoryID: number) => {
//     //     setSelectedFeatures((prevSelected) => {
//     //         const featuresForCategory = prevSelected[unitCategoryID] || [];

//     //         // Toggle the feature ID in the array for this unit category
//     //         const updatedFeaturesForCategory = featuresForCategory.includes(featureID)
//     //             ? featuresForCategory.filter((id) => id !== featureID) // Remove feature if it exists
//     //             : [...featuresForCategory, featureID]; // Add feature if not already selected

//     //         return {
//     //             ...prevSelected,
//     //             [unitCategoryID]: updatedFeaturesForCategory
//     //         };
//     //     });

//     //     // Update the form data with the selected features
//     //     setAddfeatureform((prevForm) => {
//     //         const currentFormForCategory = prevForm[unitCategoryID] || [];
//     //         const isFeatureAlreadyAdded = currentFormForCategory.some((item) => item.featureID === featureID);

//     //         const updatedFormForCategory = isFeatureAlreadyAdded
//     //             ? currentFormForCategory.filter((item) => item.featureID !== featureID) // Remove feature from form
//     //             : [
//     //                 ...currentFormForCategory,
//     //                 {
//     //                     propertyMasterID: 0,
//     //                     propertyUnitCategoryID: unitCategoryID,
//     //                     featureID: featureID,
//     //                     propertyTypes: 1,
//     //                 },
//     //             ]; // Add new feature to form

//     //         return {
//     //             ...prevForm,
//     //             [unitCategoryID]: updatedFormForCategory
//     //         };
//     //     });

//     //     // Dispatch the updated form data to the Redux store
//     //     const updatedFeatures = [...(uploadedFeature || []), {
//     //         propertyMasterID: 0,
//     //         propertyUnitCategoryID: unitCategoryID,
//     //         featureID: featureID,
//     //         propertyTypes: 1,
//     //     }];

//     //     dispatch(setPropertyMasterUnitFeature({ unitCategoryID, features: updatedFeatures }));
//     // };
//     // const [selectedFeatures1, setSelectedFeatures1] = useState<Map<number, number[]>>(new Map());
 
//     const handleCheckboxChange = (featureID: number,unitCategoryID: number) => {
//         if(unitCategoryID===0){
//             setSelectedFeatures((prevSelected) => {
//                 if (prevSelected.includes(featureID)) {
//                     return prevSelected.filter(id => id !== featureID);
//                 } else {
//                     return [...prevSelected, featureID];
//                 }
//             });
//             setAddfeatureform((prevForm) => {
//                 if (Array.isArray(prevForm)) {
//                     if (prevForm.some((item) => item.featureID === featureID)) {
//                         return prevForm.filter((item) => item.featureID !== featureID);
//                     } else {
//                         return [
//                             ...prevForm,
//                             {
//                                 propertyMasterID: 0,
//                                 propertyUnitCategoryID: null,
//                                 featureID: featureID,
//                                 propertyTypes: 1,
//                             },
//                         ];
//                     }
//                 } else {
//                     return [
//                         {
//                             propertyMasterID: 0,
//                             propertyUnitCategoryID: null,
//                             featureID: featureID,
//                             propertyTypes: 1,
//                         },
//                     ];
//                 }
//             });
//             // dispatch(setPropertyMasterFeature(addfeatureform ));
//         }else{
//             debugger
//             // setSelectedFeatures1((prevSelected) => {
//             //     const newSelected = new Map(prevSelected);
            
//             //     if (newSelected.has(index)) {
//             //       const features = newSelected.get(index) || [];
//             //       if (features.includes(featureID)) {
//             //         // Remove the featureID
//             //         newSelected.set(
//             //             idx,
//             //           features.filter((id) => id !== featureID)
//             //         );
//             //       } else {
//             //         // Add the featureID
//             //         newSelected.set(idx, [...features, featureID]);
//             //       }
//             //     } else {
//             //       // Add a new index with the featureID
//             //       newSelected.set(idx, [featureID]);
//             //     }
//             //     console.log( "sdwqswq",newSelected)
//             //     return newSelected;
//             //   });
//             setSelectedFeatures((prevSelected) => {
//                 if (prevSelected.includes(featureID)) {
//                     debugger
//                     return prevSelected.filter((id) => id !== featureID);
//                 } else {
//                     debugger
//                     return [...prevSelected, featureID];
//                 }
//             });
        
//             // Update the addfeatureform state
//             setAddfeatureform((prevForm) => {
//                 if (Array.isArray(prevForm)) {
//                     // Check if the feature already exists in the form for the current category
//                     if (prevForm.some((item) => item.featureID === featureID && item.propertyUnitCategoryID === unitCategoryID)) {
//                         // Remove feature from the form if unchecked
//                         return prevForm.filter((item) => !(item.featureID === featureID && item.propertyUnitCategoryID === unitCategoryID));
//                     } else {
//                         // Add new feature to the form
//                         return [
//                             ...prevForm,
//                             {
//                                 propertyMasterID: 0,
//                                 propertyUnitCategoryID: unitCategoryID,
//                                 featureID: featureID,
//                                 propertyTypes: 1,
//                                 index:buildind
//                             },
//                         ];
//                     }
//                 } else {
//                     return [
//                         {
//                             propertyMasterID: 0,
//                             propertyUnitCategoryID: unitCategoryID,
//                             featureID: featureID,
//                             propertyTypes: 1,
//                             index:buildind
//                         },
//                     ];
//                 }
//             });
        
//             // Dispatch an action to update features in the store
//             const feature = {
//                 propertyMasterID: 0,
//                 propertyUnitCategoryID: unitCategoryID,
//                 featureID: featureID,
//                 propertyTypes: 1,
//                 index:buildind
//             };
        
//             const updatedFeatures = [...(uploadedFeature || []), feature];
//             debugger
//             console.log("selectedFeatures",buildsetected)
//             // dispatch(setPropertyMasterUnitFeature({ unitCategoryID, features: updatedFeatures }));
//             dispatch(setPropertyMasterUnitFeature({buildind, unitCategoryID, features: updatedFeatures }));

//         }
       
//     };
//     const handleSave = () => {
//         if(window.location.pathname==="/admin/unitcategory"){
//             // dispatch(setPropertyMasterUnitFeature(addfeatureform)) 
//         }else if(unit===0){
            
//             dispatch(setPropertyMasterFeature(addfeatureform))
//         }

//     };
//     const handleCheckboxChange2 = (featureID: number, unitCategoryID: number) => {
//         if (unitCategoryID === 0) {
//             setSelectedFeatures((prevSelected) => {
//                 if (prevSelected.includes(featureID)) {
//                     return prevSelected.filter(id => id !== featureID);
//                 } else {
//                     return [...prevSelected, featureID];
//                 }
//             });
//             setAddfeatureform((prevForm) => {
//                 if (Array.isArray(prevForm)) {
//                     if (prevForm.some((item) => item.featureID === featureID)) {
//                         return prevForm.filter((item) => item.featureID !== featureID);
//                     } else {
//                         return [
//                             ...prevForm,
//                             {
//                                 propertyMasterID: 0,
//                                 propertyUnitCategoryID: null,
//                                 featureID: featureID,
//                                 propertyTypes: 1,
//                             },
//                         ];
//                     }
//                 } else {
//                     return [
//                         {
//                             propertyMasterID: 0,
//                             propertyUnitCategoryID: null,
//                             featureID: featureID,
//                             propertyTypes: 1,
//                         },
//                     ];
//                 }
//             });
//            dispatch(setPropertyMasterFeature(addfeatureform));
//         } else {
//             setSelectedFeatures((prevSelected) => {
//                 if (prevSelected.includes(featureID)) {
//                     return prevSelected.filter((id) => id !== featureID);
//                 } else {
//                     return [...prevSelected, featureID];
//                 }
//             });
//             setAddfeatureform((prevForm) => {
//                 if (Array.isArray(prevForm)) {
//                     if (prevForm.some((item) => item.featureID === featureID && item.propertyUnitCategoryID === unitCategoryID)) {
//                         return prevForm.filter((item) => !(item.featureID === featureID && item.propertyUnitCategoryID === unitCategoryID));
//                     } else {
//                         return [
//                             ...prevForm,
//                             {
//                                 propertyMasterID: 0,
//                                 propertyUnitCategoryID: unitCategoryID,
//                                 featureID: featureID,
//                                 propertyTypes: 1,
//                                 index: buildind,
//                             },
//                         ];
//                     }
//                 } else {
//                     return [
//                         {
//                             propertyMasterID: 0,
//                             propertyUnitCategoryID: unitCategoryID,
//                             featureID: featureID,
//                             propertyTypes: 1,
//                             index: buildind,
//                         },
//                     ];
//                 }
//             });
//             // Dispatch updated features
//             const feature = {
//                 propertyMasterID: 0,
//                 propertyUnitCategoryID: unitCategoryID,
//                 featureID: featureID,
//                 propertyTypes: 1,
//                 index: buildind,
//             };
//             const updatedFeatures = [...(uploadedFeature || []), feature];
//             console.log("selectedFeatures", buildsetected);
//              dispatch(setPropertyMasterUnitFeature({ buildind, unitCategoryID, features: updatedFeatures }));
//         }
//     };
//     const handleCheckboxChange1 = (featureID, unit) => {
//         setSelectedFeatures((prevState) => {
//             const updatedState = { ...prevState };
//             const selectedArray = updatedState[unit.index] || [];
//             if (selectedArray.some((b) => b.featureID === featureID)) {
//                 debugger
//                 // If the featureID is already selected, remove it
//                 updatedState[unit] = selectedArray.filter((b) => b.featureID !== featureID);
//             } else {
//                 debugger
//                 // If the featureID is not selected, add it
//                 updatedState[unit] = [
//                     ...selectedArray,
//                     { featureID, propertyMasterID: unit.propertyMasterID, propertyUnitCategoryID: unit.propertyUnitCategoryID }
//                 ];
//             }
//             return updatedState;
//         });
//     };
//     return (
//         <>
//               {/* <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
//             {JSON.stringify(groupedFeatures, null, 2)}
//           </pre> */}
//             <div className="modal fade" id="exampleModalScrollable3" tabIndex={-1} aria-labelledby="exampleModalScrollable3" data-bs-keyboard="false" aria-hidden="true" key={buildind}>
//                 <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable addproperty-modal">
//                     <div className="modal-content modal-padding">
//                         <div className="modal-header px-3 py-2 addproperty-modal-header">
//                             <h4 className="modal-title modal-text " id="staticBackdropLabel3">
//                                 Property Features{buildind}
//                             </h4>
//                             <button type="button" className="btn-close modal-text" data-bs-dismiss="modal" aria-label="Close" />
//                         </div>
//                         <div className="modal-body px-3">
//                             {Object.entries(groupedFeatures || {}).map(([featureTypeName, features]) => (
//                                 <div key={featureTypeName}>
//                                     <h4 className='text-start'>{featureTypeName}</h4>
//                                     <div className="row py-3 text-start">
//                                         {features && features.map((feature) => (
//                                             <div key={feature.id} className="form-check form-check-lg modal-checkbox align-items-center col-md-6 col-sm-6 col-lg-6 py-2">
//                                                 <input
//                                                     className="form-check-input"
//                                                     type="checkbox"
//                                                     id={`checkbox-${feature.id}`}
//                                                     checked={isActiveBuilding(feature.id,idx)}

//                                                     // checked={selectedFeatures.includes(feature.id)}
//                                                     onChange={() => handleCheckboxChange4(feature.id, unit)}
//                                                 />
//                                                 <label className="form-check-label modal-p" htmlFor={`checkbox-${feature.id}`}>
//                                                     {feature.desc}
//                                                 </label>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 </div>
//                             ))}
//                             <div className="text-center pb-4">
//                                 <button type="button" className="btn next-btn" data-bs-dismiss="modal" onClick={handleSave}>
//                                     Save
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default FeatureModal;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPropertyMasterUnitFeature,setPropertyMasterFeature } from '../../../redux/Commonslice';

interface Feature {
    featureTypesID: number;
    desc: string;
    id: number;
    status: number;
}

enum FeatureType {
    MainFeatures = 1,
    Utilities = 2,
    Facing = 3,
}

type GroupedFeatures = { [key: string]: Feature[] };

const FeatureModal = ({ unitCategoryID, FeatureData }: { unitCategoryID: number; FeatureData: any }) => {
    const dispatch = useDispatch();
    const [addfeatureform, setAddfeatureform] = useState<{ [unitCategoryID: number]: any[] }>({});
    const [selectedFeatures, setSelectedFeatures] = useState<number []>([]);
    const storageKey = `uploadedFeature_${unitCategoryID}`;
    const unit = useSelector((state: any) => state.CRMS.propertyId);

    // Reset selected features when changing unit category
    useEffect(() => {
        setSelectedFeatures([]);
    }, [unit]);

    const getFeatureTypeName = (id: number) => {
        switch (id) {
            case FeatureType.MainFeatures:
                return "Main Features";
            case FeatureType.Utilities:
                return "Utilities";
            case FeatureType.Facing:
                return "Facing";
            default:
                return "Unknown";
        }
    };

    const groupedFeatures: GroupedFeatures = FeatureData?.reduce((acc, feature) => {
        const featureTypeName = getFeatureTypeName(feature.featureTypesID);
        acc[featureTypeName] = acc[featureTypeName] || [];
        acc[featureTypeName].push(feature);
        return acc;
    }, {} as GroupedFeatures);

    const uploadedFeature = useSelector((state: any) => {
        if (unitCategoryID && state.CRMS?.propertyMasterUnitFeature) {
            return state.CRMS.propertyMasterUnitFeature[unit] ?? null;
        }
        return null;
    });

    // Handle checkbox changes and update selected features
    // const handleCheckboxChange = (featureID: number, unitCategoryID: number) => {
    //     setSelectedFeatures((prevSelected) => {
    //         const featuresForCategory = prevSelected[unitCategoryID] || [];

    //         // Toggle the feature ID in the array for this unit category
    //         const updatedFeaturesForCategory = featuresForCategory.includes(featureID)
    //             ? featuresForCategory.filter((id) => id !== featureID) // Remove feature if it exists
    //             : [...featuresForCategory, featureID]; // Add feature if not already selected

    //         return {
    //             ...prevSelected,
    //             [unitCategoryID]: updatedFeaturesForCategory
    //         };
    //     });

    //     // Update the form data with the selected features
    //     setAddfeatureform((prevForm) => {
    //         const currentFormForCategory = prevForm[unitCategoryID] || [];
    //         const isFeatureAlreadyAdded = currentFormForCategory.some((item) => item.featureID === featureID);

    //         const updatedFormForCategory = isFeatureAlreadyAdded
    //             ? currentFormForCategory.filter((item) => item.featureID !== featureID) // Remove feature from form
    //             : [
    //                 ...currentFormForCategory,
    //                 {
    //                     propertyMasterID: 0,
    //                     propertyUnitCategoryID: unitCategoryID,
    //                     featureID: featureID,
    //                     propertyTypes: 1,
    //                 },
    //             ]; // Add new feature to form

    //         return {
    //             ...prevForm,
    //             [unitCategoryID]: updatedFormForCategory
    //         };
    //     });

    //     // Dispatch the updated form data to the Redux store
    //     const updatedFeatures = [...(uploadedFeature || []), {
    //         propertyMasterID: 0,
    //         propertyUnitCategoryID: unitCategoryID,
    //         featureID: featureID,
    //         propertyTypes: 1,
    //     }];

    //     dispatch(setPropertyMasterUnitFeature({ unitCategoryID, features: updatedFeatures }));
    // };
    const handleCheckboxChange = (featureID: number,unitCategoryID: number) => {
    
        if(unitCategoryID===0){
            
    
            setSelectedFeatures((prevSelected) => {
                if (prevSelected.includes(featureID)) {
                    return prevSelected.filter(id => id !== featureID);
                } else {
                    return [...prevSelected, featureID];
                }
            });
            setAddfeatureform((prevForm) => {
                if (Array.isArray(prevForm)) {
                    if (prevForm.some((item) => item.featureID === featureID)) {
                        return prevForm.filter((item) => item.featureID !== featureID);
                    } else {
                        return [
                            ...prevForm,
                            {
                                propertyMasterID: 0,
                                propertyUnitCategoryID: null,
                                featureID: featureID,
                                propertyTypes: 1,
                            },
                        ];
                    }
                } else {
                    return [
                        {
                            propertyMasterID: 0,
                            propertyUnitCategoryID: null,
                            featureID: featureID,
                            propertyTypes: 1,
                        },
                    ];
                }
            });
            // dispatch(setPropertyMasterFeature(addfeatureform ));
        }else{
            
            setSelectedFeatures((prevSelected) => {
                if (prevSelected.includes(featureID)) {
                    return prevSelected.filter((id) => id !== featureID);
                } else {
                    return [...prevSelected, featureID];
                }
            });
        
            // Update the addfeatureform state
            setAddfeatureform((prevForm) => {
                if (Array.isArray(prevForm)) {
                    // Check if the feature already exists in the form for the current category
                    if (prevForm.some((item) => item.featureID === featureID && item.propertyUnitCategoryID === unitCategoryID)) {
                        // Remove feature from the form if unchecked
                        return prevForm.filter((item) => !(item.featureID === featureID && item.propertyUnitCategoryID === unitCategoryID));
                    } else {
                        // Add new feature to the form
                        return [
                            ...prevForm,
                            {
                                propertyMasterID: 0,
                                propertyUnitCategoryID: unitCategoryID,
                                featureID: featureID,
                                propertyTypes: 1,
                            },
                        ];
                    }
                } else {
                    return [
                        {
                            propertyMasterID: 0,
                            propertyUnitCategoryID: unitCategoryID,
                            featureID: featureID,
                            propertyTypes: 1,
                        },
                    ];
                }
            });
        
            // Dispatch an action to update features in the store
            const feature = {
                propertyMasterID: 0,
                propertyUnitCategoryID: unitCategoryID,
                featureID: featureID,
                propertyTypes: 1,
            };
        
            const updatedFeatures = [...(uploadedFeature || []), feature];
            dispatch(setPropertyMasterUnitFeature({ unitCategoryID, features: updatedFeatures }));
        }
       
    };
    const handleSave = () => {
        if(window.location.pathname==="/admin/unitcategory"){
            // dispatch(setPropertyMasterUnitFeature(addfeatureform)) 
        }else if(unit===0){
            
            dispatch(setPropertyMasterFeature(addfeatureform))
        }

    };
    return (
        <>
            <div className="modal fade" id="exampleModalScrollable3" tabIndex={-1} aria-labelledby="exampleModalScrollable3" data-bs-keyboard="false" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable addproperty-modal">
                    <div className="modal-content modal-padding">
                        <div className="modal-header px-3 py-2 addproperty-modal-header">
                            <h4 className="modal-title modal-text " id="staticBackdropLabel3">
                                Property Features
                            </h4>
                            <button type="button" className="btn-close modal-text" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body px-3">
                            {Object.entries(groupedFeatures || {}).map(([featureTypeName, features]) => (
                                <div key={featureTypeName}>
                                    <h4 className='text-start'>{featureTypeName}</h4>
                                    <div className="row py-3 text-start">
                                        {features && features.map((feature) => (
                                            <div key={feature.id} className="form-check form-check-lg modal-checkbox align-items-center col-md-6 col-sm-6 col-lg-6 py-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`checkbox-${feature.id}`}
                                                    checked={selectedFeatures.includes(feature.id)}
                                                    onChange={() => handleCheckboxChange(feature.id, unit)}
                                                />
                                                <label className="form-check-label modal-p" htmlFor={`checkbox-${feature.id}`}>
                                                    {feature.desc}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                            <div className="text-center pb-4">
                                <button type="button" className="btn next-btn" data-bs-dismiss="modal" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeatureModal;
