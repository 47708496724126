import Building from "../../../../public/propertyIcons/Building.svg";
import TownHouse from "../../../../public/propertyIcons/townhouse.svg";
import LowerPortion from "../../../../public/propertyIcons/lowerprtion.svg";
import UpperPortion from "../../../../public/propertyIcons/upperportion.svg";
import PentHouse from "../../../../public/propertyIcons/penthouse.svg";
import Villa from "../../../../public/propertyIcons/villa.svg";
import Room from "../../../../public/propertyIcons/room.svg";
import Flat from "../../../../public/propertyIcons/flat.svg";

import MixUse from "../../../../public/propertyIcons/mixuse.svg";
import WareHouse from "../../../../public/propertyIcons/warehouse.svg";
import Shop from "../../../../public/propertyIcons/shop.svg";
import BusinessCenter from "../../../../public/propertyIcons/businesscenter.svg";
import Factory from "../../../../public/propertyIcons/factory.svg";
import Hall from "../../../../public/propertyIcons/hall.svg";
const PropperyIcons = {
    // Residential icons
    Building, TownHouse, LowerPortion,
    UpperPortion, PentHouse, Villa,
    Room, Flat,
    // Commercial
    MixUse, WareHouse, Shop,
    BusinessCenter, Factory,
    Hall
}

export default PropperyIcons;