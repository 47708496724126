export interface IStaffDashboard {
  name: string;
  totalCount: number;
  iconUrl: string;
}

export class StaffDashboard implements IStaffDashboard {
  name: string;
  totalCount: number;
  iconUrl: string;

  constructor(data: Partial<IStaffDashboard>) {
    this.name = data.name ?? '';
    this.totalCount = data.totalCount ?? 0;
    this.iconUrl = data.iconUrl ?? '';
  }
}
