import { RecentMaintenanceRequest } from "./RecentMaintenanceRequest";
import { TotalRequest } from "./TotalRequest";

export interface IDashboardRecentRequests {
  totalRequest: TotalRequest;
  recentMaintenanceRequests: RecentMaintenanceRequest[];
}

export class DashboardRecentRequests implements IDashboardRecentRequests {
  totalRequest: TotalRequest;
  recentMaintenanceRequests: RecentMaintenanceRequest[];

  constructor(data: DashboardRecentRequests) {
    this.totalRequest = data.totalRequest;
    this.recentMaintenanceRequests = data.recentMaintenanceRequests.map(
      (item) => new RecentMaintenanceRequest(item)
    );
  }
}